<template>
  <div class="box-index">
    <!-- banner -->
    <div class="banner">
      <el-carousel style="width: 100%; height: 430px;">
        <el-carousel-item>
          <div class="swiper-slide bannerblock bannerblock1">
            <div class="maincontent bannerblockcontent">
              <p
                  class="bannerdes"
                  style="color: #ffffff; font-size: 50px; font-weight: 800; font-style: italic;"
              >
                三销冠
              </p>
              <p
                  class="bannerdes"
                  style="color: #ffffff; font-size: 32px; font-weight: 700;"
              >
                私 域 自 动 成 交 系 统
              </p>
              <p class="bannerdes" style="color: #ffffff">Ai销售·Ai客服·Ai专家</p>
              <div class="btnbox">
                <div @click="goto3xg">前往三销冠</div>
              </div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="swiper-slide bannerblock bannerblock2">
            <div class="maincontent bannerblockcontent">
              <div class="btnbox" style="margin-left: 250px;margin-top: 350px;">
                <div @click="alerttanchuang">使用Ai场景直播</div>
              </div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="swiper-slide bannerblock bannerblock3">
            <div class="maincontent bannerblockcontent">
              <img
                  style="width: 527px; height: auto; display: block"
                  src="../assets/banner111.png"
                  alt=""
              />
              <p
                  class="bannerdes"
                  style="color: #ffffff; font-size: 32px; font-weight: 700"
              >
                专注构建丰富易用的AI指令平台
              </p>
              <p class="bannerdes" style="color: #ffffff">三只阿猫智能科技</p>
              <div class="btnbox">
                <div @click="alerttanchuang">使用移动端</div>
              </div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="swiper-slide bannerblock bannerblock4">
            <div class="maincontent bannerblockcontent">
              <img
                  style="width: 424px; height: auto; display: block"
                  src="../assets/banner222.png"
                  alt=""
              />
              <p class="bannerdes" style="color: #ffffff">数据隔离，安全放心</p>
              <div class="btnbox">
                <div @click="showlianxi">企业定制</div>
                <div @click="showlianxi">服务商定制</div>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
<!--      <div class="swiper mySwiperbb">-->
<!--        <div class="swiper-wrapper">-->
<!--          <div class="swiper-slide bannerblock">-->
<!--            <div class="maincontent bannerblockcontent">-->
<!--              <img-->
<!--                  style="width: 527px; height: auto; display: block"-->
<!--                  src="../assets/banner111.png"-->
<!--                  alt=""-->
<!--              />-->
<!--              <p-->
<!--                  class="bannerdes"-->
<!--                  style="color: #ffffff; font-size: 32px; font-weight: 700"-->
<!--              >-->
<!--                专注构建丰富易用的AI指令平台-->
<!--              </p>-->
<!--              <p class="bannerdes" style="color: #ffffff">三只阿猫智能科技</p>-->
<!--              <div class="btnbox">-->
<!--                <div @click="alerttanchuang">使用移动端</div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="swiper-slide bannerblock">-->
<!--            <div class="maincontent bannerblockcontent">-->
<!--              <img-->
<!--                  style="width: 424px; height: auto; display: block"-->
<!--                  src="../assets/banner222.png"-->
<!--                  alt=""-->
<!--              />-->
<!--              <p class="bannerdes" style="color: #ffffff">数据隔离，安全放心</p>-->
<!--              <div class="btnbox">-->
<!--                <div @click="showlianxi">企业定制</div>-->
<!--                <div @click="showlianxi">服务商定制</div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="swiper-pagination"></div>-->
<!--      </div>-->
      <!-- <div class="maincontent bannerbox">
                <h2 class="title">让人类轻松掌控AI世界</h2>
                <p class="bannerdes">专注打造强大易用的指令库</p>
                <div class="howask">学习如何提问></div>
                <div class="btnbox">
                    <a @click="$router.push('/chat')">体验AI聊天</a>
                    <a @click="$router.push('/draw')">体验AI绘画</a>
                </div>
                <div class="floatbox">
                    <div><img src="../assets/float1.png" alt=""><span>微信</span></div>
                    <div><img src="../assets/float2.png" alt=""><span>公众号</span></div>
                </div>
            </div> -->
    </div>
    <!-- banner下面的条 -->
    <div class="bannerbottom">
      <div class="maincontent bannercontentbox">
        <div style="display: flex; align-items: center">
          <img
              style="display: inline-block; width: 66px; margin-right: 20px"
              src="../assets/iconbannerbottom.png"
              alt=""
          />
          <span>快来创作你的第一个作品吧~</span>
        </div>
      </div>
    </div>
    <!-- 聊天画画入口 -->
    <div style="background-color: #ebf2fa; padding: 80px 0">
      <div class="zhishibox">
        <div>
          <h2>进入AI世界</h2>
        </div>
        <div class="btnbox">
          <div @click="$router.push('/chat')">
            <div><span>进入</span><span>AI聊天</span></div>
            <img src="../assets/ailiaoyian.png" alt="" />
          </div>
          <div @click="$router.push('/draw')">
            <div><span> 进入</span><span>AI绘画</span></div>
            <img src="../assets/aihuihua.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <!-- 指令 -->
    <div class="commandbox">
      <div class="instruction maincontent">
        <div class="instop">
          <h2>体验阿猫智能对话指令</h2>
          <img src="../../public/static/img/20230607112203.png" alt="" />
          <!-- <div class="instopinputbox">
                    <el-input placeholder="搜索你需要的内容" v-model="input2">
                        <el-button slot="append" icon="el-icon-search"></el-button>
                    </el-input>
                    <el-select v-model="value" placeholder="请选择">
                        <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-select v-model="value" placeholder="请选择">
                        <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div> -->
        </div>
        <div class="categroybox" style="display: none">
          <div class="categroybig">
            <span>一级：</span>
            <div class="catecontent">
              <span class="current">全部</span>
              <span>论文</span>
              <span>自助百科</span>
              <span>趣味知识</span>
              <span>辩论/演讲</span>
              <span>影视</span>
              <span>服装</span>
              <span>艺术</span>
              <span>医疗</span>
            </div>
          </div>
          <div class="categroysmall">
            <span>二级：</span>
            <div class="catecontent">
              <span class="current">全部</span>
              <span>媒体运营</span>
              <span>自助百科</span>
              <span>趣味知识</span>
              <span>辩论/演讲</span>
              <span>影视</span>
              <span>服装</span>
              <span>艺术</span>
              <span>医疗</span>
            </div>
          </div>
        </div>
        <!-- 列表 -->
        <div class="ai_grid-4">
          <div style="margin: auto;" v-for="item in directList" :key="item.id">
            <div
                v-if="item.type == 'DRAW_DIRECTIVE'"
                class="instruntblock haveimgblock"
            >
              <!-- 标签 推荐 上新等 -->
              <div class="toptags green">上新</div>
              <div class="topinst">
                <div class="topinstleft">
                  <img :src="item.headimgurl" alt="" />
                  <div class="topinsright">
                    <p>{{ item.nickName }}</p>
                    <!-- <span>关注</span> -->
                  </div>
                </div>
                <span class="time">{{ item.gmtCreate }}</span>
              </div>
              <div class="content">
                <h3 @click="goshow(item)">{{ item.squareName }}</h3>
                <p @click="goshow(item)">{{ item.squareDesc }}</p>
                <div class="imgandword">
                  <div
                      class="ccctentimgleft"
                      :style="{ 'background-image': 'url(' + item.imgsrc + ')' }"
                  ></div>
                  <div class="cccontetins" @click="goshow(item)">
                    <span
                        v-for="(itm, idx) in item.userChatLogList"
                        :key="idx"
                    >{{ itm.question }}</span
                    >
                  </div>
                  <div class="cccontenttags">
                    <span v-for="(itm, idx) in item.sysTagList" :key="idx"
                    >#{{ itm.tagName }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="instbottomtag">
                <span>{{ item.categoryName }}</span>
              </div>
              <div class="otherinst">
                <div class="otherinstleft">
                  <!-- <span><img src="../assets/zan.png" alt="">222</span> -->
                  <span
                      v-if="item.likeStatus == 'NO' || item.likeStatus == null"
                  ><img src="../assets/like.png" @click="dianzan(item)" />{{
                      item.likeCount
                    }}</span
                  >
                  <span v-if="item.likeStatus == 'YES'"
                  ><img src="../assets/ylike.png" @click="dianzan(item)" />{{
                      item.likeCount
                    }}</span
                  >
                  <span @click="goshow(item)"
                  ><img src="../assets/pl.png" alt="" />{{
                      item.commentCount
                    }}</span
                  >
                </div>
                <button @click="goAct(item)">我也要用</button>
              </div>
            </div>
            <div
                v-if="
                item.type == 'CHAT_DIRECTIVE' || item.type == 'APP_DIRECTIVE'
              "
                class="instruntblock"
            >
              <!-- 标签 推荐 上新等 -->
              <div class="toptags">推荐</div>
              <div class="topinst">
                <div class="topinstleft">
                  <img :src="item.headimgurl" alt="" />
                  <div class="topinsright">
                    <p>{{ item.nickName }}</p>
                    <!-- <span>关注</span> -->
                  </div>
                </div>
                <span class="time">{{ item.gmtCreate }}</span>
              </div>
              <div class="content">
                <h3 @click="goshow(item)">{{ item.squareName }}</h3>
                <p @click="goshow(item)">{{ item.squareDesc }}</p>
                <div class="cccontetins" @click="goshow(item)">
                  <span v-for="(itm, idx) in item.userChatLogList" :key="idx">{{
                      itm.questionTemplate
                          ? itm.questionTemplate
                          : itm.question + "/" + itm.answer
                    }}</span>
                  <!-- <span v-else v-for="(itm, idx) in item.userChatLogList" :key="idx">{{ itm.question }}/{{
                                        itm.answer }}</span> -->
                </div>
                <div class="cccontenttags">
                  <span v-for="(itm, idx) in item.sysTagList" :key="idx"
                  >#{{ itm.tagName }}
                  </span>
                </div>
              </div>
              <div class="instbottomtag">
                <span>{{ item.categoryName }}</span>
              </div>
              <div class="otherinst">
                <div class="otherinstleft">
                  <!-- <span><img src="../assets/zan.png" alt="">222</span> -->
                  <span
                      v-if="item.likeStatus == 'NO' || item.likeStatus == null"
                  ><img src="../assets/like.png" @click="dianzan(item)" />{{
                      item.likeCount
                    }}</span
                  >
                  <span v-if="item.likeStatus == 'YES'"
                  ><img src="../assets/ylike.png" @click="dianzan(item)" />{{
                      item.likeCount
                    }}</span
                  >
                  <span @click="goshow(item)"
                  ><img src="../assets/pl.png" alt="" />{{
                      item.commentCount
                    }}</span
                  >
                </div>
                <button @click="goAct(item)">我也要用</button>
              </div>
            </div>
          </div>
        </div>
        <button class="moreshow" @click="gosquare" v-if="showmorebtn">
          查看更多
        </button>
      </div>
    </div>
    <!-- 助手 -->
    <div class="assistant">
      <img class="bgimg3" src="../../public/static/img/bgimg3.png" alt="">
      <div class="maincontent assistantbox">
        <div class="ass">
          <h2>最智能的助手，最有趣的伙伴</h2>
          <img src="../../public/static/img/20230607112203.png" alt="" />
        </div>

        <div class="ai_grid-4">
          <div class="assblock">
            <img src="../assets/ass1.png" alt="" />
            <div class="asscontent">
              <h3>专业的知识库</h3>
              <p>
                阿猫智能具备庞大的知识库，包含各种领域的专业知识，由此为你提供及时、准确的解答和指…
              </p>
            </div>
          </div>
          <div class="assblock">
            <img src="../assets/ass2.png" alt="" />
            <div class="asscontent">
              <h3>超前的智能科技</h3>
              <p>
                阿猫智能采用先进的人工智能技术，包括机器学习和自然语言处理等，能够理解你的意图和语句上下文，提供更加精准的回答和服务。
              </p>
            </div>
          </div>
          <div class="assblock">
            <img src="../assets/ass3.png" alt="" />
            <div class="asscontent">
              <h3>提高工作、学习效率</h3>
              <p>
                作为你的协助者，在工作中，阿猫智能可以帮你完成各种任务，解放你的双手，提高效率…
              </p>
            </div>
          </div>
          <div class="assblock">
            <img src="../assets/ass4.png" alt="" />
            <div class="asscontent">
              <h3>个性化的聊天体验</h3>
              <p>
                不同人有不同的沟通习惯，阿猫智能可以根据你的喜好和习惯制定个性化的聊天方式，满足你的…
              </p>
            </div>
          </div>
          <div class="assblock">
            <img src="../assets/ass5.png" alt="" />
            <div class="asscontent">
              <h3>多样化的绘画功能</h3>
              <p>
                无论你是想涂鸦还是创作艺术品，阿猫智能都可以提供丰富多样、易于操作的绘画功能，并支持…
              </p>
            </div>
          </div>
          <div class="assblock">
            <img src="../assets/ass6.png" alt="" />
            <div class="asscontent">
              <h3>安全的保障措施</h3>
              <p>
                阿猫智能非常注重用户隐私与安全，采用了多项严密的加密技术，保证你的数据永远不会被泄…
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 伙伴 -->
    <div class="partnerbox">
      <div class="maincontent pmainbox">
        <h2>成为阿猫智能合作伙伴</h2>
        <div class="partnertopbox">
          <div class="pleftimg"></div>
          <div class="prightdes">
            <h2>阿猫智能盛大招募</h2>
            <p>
              欢迎加入阿猫智能代理商行列!我们正在面向全国各地招募大客户企业定制、代理商、服务商，共同推广智能聊天和绘画工具-阿猫智能，为广大用户带来更便捷高效的为通体验。在数字化时代，人工智能的应用将会越来越广泛，智能聊天工具的市场需求也越来越大。而作为智能聊天工具领域的佼佼者之一，阿猫智能以其先进的技术和差异化的产品优势，备受广大用户和代理商的青睐。<br />
              如果你是一家有影响力的大客户企业或是有强大的销售网络和渠道资源的代理商或服务商，那么阿猫智能将是你最好的合作伙伴。我们提供丰富的优惠政策和完善的售后服务，让你轻松获得收益，同时为用户带来更高品质的使用体验。
              不论你是想提高自身收益还是为用户提供更优质的产品服务，阿猫智能都是你不容错过的机遇之一。我们期待与您一起合作，为用户带来更好的智能聊天和绘画体验!
            </p>
          </div>
        </div>
        <!-- 轮播 -->
        <div>
          <div>
            <div class="ai-grid">
              <div class=" blockswiper" >
                <h2>企业定制</h2>
                <p>6亿人喜爱的工具, 官方全力帮扶</p>
                <!-- <a href="">查看详情 ></a> -->
                <div @click="showlianxi">立即了解</div>
              </div>
              <div class=" blockswiper">
                <h2>运营商</h2>
                <p>渠道拓展</p>
                <!-- <a href="">查看详情 ></a> -->
                <div @click="showlianxi">立即了解</div>
              </div>
              <div class=" blockswiper">
                <h2>代理商</h2>
                <p>开通合作, 是在ai行业做生意的第一步</p>
                <!-- <a href="">查看详情 ></a> -->
                <div @click="showlianxi">立即了解</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="linksbottom">
      <img class="bgimg" src="../../public/static/img/buttom1.png" alt="">
      <div class="maincontent pmainbox">
        <h2>已获众多合作伙伴信赖</h2>
        <div class="maincontent linksbox partner_grid">
          <img src="../assets/link3.png" alt="" />
          <img src="../assets/link1.png" alt="" />
          <img src="../assets/link2.png" alt="" />
          <img src="../assets/link5.png" alt="" />
          <img src="../assets/link4.png" alt="" />
          <img src="../assets/link6.png" alt="" />
          <img src="../assets/link7.png" alt="" />
          <img src="../assets/link8.png" alt="" />
        </div>
      </div>
    </div>
    <div class="bottomlinks" style="display: none">
      <div class="maincontent linksbox">
        <div class="leftlink">
          <div class="rowlink">
            <a href=""><img src="../assets/link1.png" alt="" /></a>
            <a href=""><img src="../assets/link2.png" alt="" /></a>
          </div>
          <div class="rowlink">
            <a href=""><img src="../assets/link1.png" alt="" /></a>
            <a href=""><img src="../assets/link2.png" alt="" /></a>
          </div>
          <div class="rowlink">
            <a href=""><img src="../assets/link1.png" alt="" /></a>
            <a href=""><img src="../assets/link2.png" alt="" /></a>
          </div>
          <div class="rowlink">
            <a href=""><img src="../assets/link1.png" alt="" /></a>
            <a href=""><img src="../assets/link2.png" alt="" /></a>
          </div>
        </div>
        <div class="rightlink">
          <h2>使用阿猫智能AI平台</h2>
          <p>
            已覆盖商业化产品的大多平台, 并与30个平台签订长期合作关系,
            <br />并保证了平台合作的稳定性.
          </p>
        </div>
      </div>
    </div>
    <!-- 联系弹窗 -->
    <el-dialog
        title="提示"
        :visible.sync="showlianxivis"
        width="400px"
        :close-on-press-escape="false"
        :before-close="handleCloseshowlianxi"
    >
      <div
          style="
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        "
      >
        <span style="margin-bottom: 20px">扫码了解详情</span>
        <img style="width: 300px" src="../assets/bobwx.png" alt="" />
      </div>
    </el-dialog>

    <!--  -->
    <el-dialog
        title="提示"
        :visible.sync="tanchuangbox"
        width="400px"
        :close-on-press-escape="false"
        :before-close="handleCloseshowlianxi2"
    >
      <div
          style="
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        "
      >
        <span style="margin-bottom: 20px">扫码体验</span>
        <img
            style="width: 300px"
            src="https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/static/all/public.jpeg"
            alt=""
        />
      </div>
    </el-dialog>
  </div>
</template>
<script>
// import Swiper from "swiper";
import "swiper/dist/css/swiper.css";
import { getDirectiveSquareList, saveSquareLike } from "@/api";
import {mapGetters} from "vuex";
export default {
  name: "index",
  components: {},
  computed: {
    ...mapGetters(["userinfo", "notifyOpen", "clientHeight", "clientWidth"]),
  },
  data() {
    return {
      directList: null,
      tanchuangbox: false,
      showlianxivis: false,
      showmorebtn: false,
    };
  },
  watch: {},
  beforeDestroy() {},
  mounted() {
    //    const aa = new Swiper('.mySwiper', {
    //         slidesPerView: "auto",
    //         spaceBetween: 30,
    //     })
    //     this.getDirectiveSquareListmethod()
  },
  activated() {
    // const aa = new Swiper(".mySwiper", {
    //   slidesPerView: "auto",
    //   spaceBetween: 30,
    // });
    // console.log(aa);
    // const bb = new Swiper(".mySwiperbb", {
    //   slidesPerView: "auto",
    //   spaceBetween: 0,
    //   direction: "horizontal",
    //   speed: 300,
    //   delay: 5000,
    //   autoplay: 3000,
    //   effect: "slide",
    //   pagination: ".swiper-pagination",
    //   paginationClickable: true,
    // });
    // console.log(bb);
    this.getDirectiveSquareListmethod();
  },
  methods: {
    goto3xg(){
      //打开外部网页
      window.open('http://3xg.3amao.com/');
    },
    alerttanchuang() {
      this.tanchuangbox = true;
    },
    showlianxi() {
      this.showlianxivis = true;
    },
    handleCloseshowlianxi2() {
      this.tanchuangbox = false;
    },
    handleCloseshowlianxi() {
      this.showlianxivis = false;
    },
    goAct(item) {
      // 判断是聊天还是画画 type==DRAW_DIRECTIVE 画画 CHAT_DIRECTIVE 聊天
      console.log(item);
      if (item.type == "DRAW_DIRECTIVE") {
        this.$router.push({
          path: "/draw", // 目标路由的名称或路径
          query: {
            param: JSON.stringify(item.userChatLogList),
          },
        });
      }
      if (item.type == "CHAT_DIRECTIVE") {
        this.$router.push({
          path: "/chat", // 目标路由的名称或路径
          query: {
            param: JSON.stringify(item.userChatLogList),
          },
        });
      }
      if (item.type == "APP_DIRECTIVE") {
        this.$router.push({
          path: "/showdetaildis/" + item.id, // 目标路由的名称或路径
          query: {
            param: "square",
          },
        });
      }
    },
    goshow(item) {
      // console.log('item',item)
      if (item.type == "APP_DIRECTIVE") {
        this.$router.push({
          path: "/showdetaildis/" + item.id,
          query: {
            param: "square",
          },
        });
      } else {
        this.$router.push({ path: "/showdetail/" + item.id });
      }
      // this.$router.push({ path: '/showdetail/' + item.id })
    },
    dianzan(item) {
      // 判断登录 没登录先登录
      if (!localStorage.getItem("token")) {
        this.$message({
          type: "error",
          message: "请先登录",
          duration:1500
        });
        return false;
      }
      console.log(item);
      let data = {
        likeStatus: item.likeStatus == "YES" ? "NO" : "YES",
        squareId: item.id,
      };
      saveSquareLike(data).then((res) => {
        console.log(res);
        this.getDirectiveSquareListmethod();
      });
      console.log(item);
    },
    dianpl(item) {
      // 点赞
      console.log(item, "点评论");
    },
    gosquare() {
      this.$router.push({ path: "/square" });
    },
    jingqingqidai() {
      this.$message({
        type: "info",
        message: "敬请期待",
        duration:1500
      });
    },
    // 创作

    // 获取广场列表
    getDirectiveSquareListmethod() {
      let data = {
        page: 1,
        size: 6,
      };
      getDirectiveSquareList(data).then((res) => {
        console.log(res);
        const datas = res.obj.records;
        let arrr = [];
        for (let a in datas) {
          if (
              datas[a].type == "DRAW_DIRECTIVE" &&
              datas[a].userChatLogList[0]
          ) {
            console.log(datas[a]);
            let imgsrc = JSON.parse(datas[a].userChatLogList[0].answer)[0].url;
            // console.log(imgsrc)
            arrr.push({
              ...datas[a],
              imgsrc: imgsrc,
            });
          } else {
            arrr.push(datas[a]);
          }
        }
        this.showmorebtn = true;
        this.directList = arrr;
        // console.log(arrr)
      });
    },
  },
};
</script>

<style scoped>
body::-webkit-scrollbar {
  display: none;
}
.swiper-container {
  width: 100%;
  height: 100%;
}

.ass h2 {
  width: 100%;
  text-align: center;
}

.ass img {
  width: 100px;
  margin-top: 10px;
  margin: auto;
}

.bannerblock {
  height: 430px;
}

.banner {
  /*width: 1920px;*/
  width: 100%;
  /*height: 426px;*/
  /* background: #e2edf9 url(../assets/banner.png) no-repeat center; */
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.bannerbottom {
  /*width: 1920px;*/
  width: 100%;
  height: 70px;
  background: url(../assets/ioconbg.png) no-repeat center;
  /* background: #4fa4fc url(../assets/yyyd.png) no-repeat center; */
  background-size: cover;
}

.bannerbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
}

.bannerbox .title {
  font-size: 30px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #292929;
  line-height: 32px;
}

.bannerdes {
  font-size: 22px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #5f5f5f;
  line-height: 44px;
  margin-top: 20px;
}

.ai-grid{
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.ai-grid div {
  margin-left: 10px;
  margin-top: 10px;
}

.howask {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 14px;
  margin-top: 22px;
}

.btnbox {
  margin-top: 36px;
  display: flex;

}

@media screen and (max-width: 1000px) {
  .zhishibox {
    padding: 0 10px!important;
  }
  .btnbox {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.btnbox > div {
  width: 161px;
  height: 48px;
  background: linear-gradient(270deg, #0790ff 0%, #40d1ff 100%);
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  margin-right: 15px;
  cursor: pointer;
}

.btnbox > div:nth-child(1) {
  background: linear-gradient(270deg, #0790ff 0%, #40d1ff 100%);
  color: #ffffff;
}

.btnbox > div:nth-child(2) {
  background: transparent;
  color: #ffffff;
  border: 1px solid #ffffff;
}

.btnbox a {
  width: 161px;
  height: 48px;
  background: #519eff;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  margin-right: 15px;
  cursor: pointer;
}

.btnbox a:nth-child(1) {
  background: #519eff;
  color: #ffffff;
}

.btnbox a:nth-child(2) {
  background: transparent;
  color: #519eff;
  border: 1px solid #519eff;
}

.bannerbottom {
  display: flex;
  align-items: center;
}

.bannercontentbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 32px;
  /* padding-left: 120px; */
}

.bannercontentbox a:link,
.bannercontentbox a:hover,
.bannercontentbox a:active,
.bannercontentbox a:visited {
  color: #ffffff;
  width: 161px;
  height: 48px;
  border-radius: 24px;
  border: 1px solid #ffffff;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  background: url(../assets/fj.png) no-repeat 34px center;
  background-size: 34px 34px;
  padding-left: 72px;
  line-height: 48px;
  text-decoration: none;
}

footer {
}

.footerbottom {
  background: #000000;
  font-size: 11px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #737373;
  text-align: center;
}

.footerbottom span {
  margin: 0 40px;
}

.footertop {
  height: 191px;
  background: #131313;
  color: #eaeaea;
}

.footertop {
  display: flex;
  align-items: center;
}

.footertop .maincontent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footertop .maincontent .left {
}

.footertop .maincontent .left p:nth-child(1) {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}

.footertop .maincontent .left p:nth-child(2) {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #eaeaea;
}

.footertop .maincontent .left p:nth-child(3) {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}

.footertop .maincontent .center {
}

.footertop .maincontent .center p {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #c3c3c3;
}

.footertop .maincontent .right {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.footertop .maincontent .right p {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #cdcdcd;
  font-size: 12px;
  margin-top: 4px;
}

.bottomlinks {
  background: #292c35;
  height: 566px;
  display: flex;
  align-items: center;
}

.linksbox {
}

.ai_grid-4 {
  display: grid;
  grid-template-columns: auto auto auto auto;
}

@media screen and (max-width: 1700px) {
  .ai_grid-4 {
    grid-template-columns: auto auto auto;
  }
}

@media screen and (max-width: 1400px) {
  .ai_grid-4 {
    grid-template-columns: auto auto;
  }
}

@media screen and (max-width: 1000px) {
  .ai_grid-4 {
    grid-template-columns: auto;
  }
}

.partner_grid {
  display: grid!important;
  grid-template-columns: auto auto auto auto;
}

@media screen and (max-width: 1300px) {
  .partner_grid {
    grid-template-columns: auto auto auto;
  }
}

@media screen and (max-width: 1080px) {
  .partner_grid {
    grid-template-columns: auto auto;
  }
}

@media screen and (max-width: 830px) {
  .partner_grid {
    grid-template-columns: auto;
  }
}

@media screen and (max-width: 1300px) {
  .partner_grid {
    grid-template-columns: auto auto auto;
  }
}

@media screen and (max-width: 1080px) {
  .partner_grid {
    grid-template-columns: auto auto;
  }
}

@media screen and (max-width: 830px) {
  .partner_grid {
    grid-template-columns: auto;
  }
}

.rightlink h2 {
  font-size: 33px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 46px;
  margin-bottom: 17px;
}

.rightlink {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #dfe0e1;
  line-height: 22px;
}

.leftlink {
  width: 516px;
}

.rowlink {
  width: 100%;
  display: flex;
  justify-items: flex-start;
}

.rowlink:nth-child(2n + 2) {
  display: flex;
  justify-items: flex-end;
  justify-content: flex-end;
}

.rowlink a {
  width: 197px;
  height: 83px;
  background: #323642;
  border-radius: 13px;
  display: flex;
  align-items: center;
  justify-items: center;
  margin: 24px 24px 0 0;
}

.rowlink img {
  width: 143px;
  height: 46px;
  margin: 0 auto;
}

.rowlink {
  display: flex;
}

.partnerbox {
  /*height: 1069px;*/
  background: #ebf2fa;
  padding: 10px 0 90px;
}

.pmainbox h2 {
  display: block;
  font-size: 26px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #292929;
  line-height: 32px;
  margin-bottom: 50px;
}

.partnertopbox {
  background-color: #ffffff;
  display: flex;
}

@media screen and (max-width: 1200px) {
  .partnertopbox {
    flex-direction: column;
    align-items: center;
  }
  .pleftimg {
    /*width: 100% !important;*/
  }
  .prightdes {
    padding: 0 10px!important;
    width: 100% !important;
  }
  .prightdes p {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #7c7c7c;
    line-height: 24px;
  }
  .prightdes h2 {
    font-size: 26px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 37px;
    margin-top: 10px!important;
    margin-bottom: 14px;
  }
}

@media screen and (max-width: 600px) {
  .maincontent {
    padding: 0 10px!important;
  }
}

.pleftimg {
  width: 100%;
  max-width: 462px;
  height: 348px;
  overflow: hidden;
  background: url(../assets/位图@2x.png) no-repeat center;
  background-size: cover
}

.prightdes {
  width: calc(100% - 462px);
  padding-left: 57px;
  padding-right: 57px;
}

.prightdes p {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #7c7c7c;
  line-height: 24px;
}

.prightdes h2 {
  font-size: 26px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 37px;
  margin-top: 52px;
  margin-bottom: 14px;
}

.blockswiper:nth-child(1) {
  background: url(../assets/swiper1.png) no-repeat center;
  background-size: cover;
}

.blockswiper:nth-child(2) {
  background: url(../assets/swiper2.png) no-repeat center;
  background-size: cover;
}

.blockswiper:nth-child(3) {
  background: url(../assets/swiper4.png) no-repeat center;
  background-size: cover;
}

.blockswiper {
  width: 341px !important;
  height: 358px !important;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.blockswiper h2 {
  font-size: 36px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 50px;
  margin-bottom: 10px;
}

.blockswiper p {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 22px;
  margin-bottom: 10px;
}

.blockswiper > div {
  width: 111px;
  height: 42px;
  background: #ffffff;
  border-radius: 9px;
  font-size: 17px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 24px;
  margin-top: 20px;
  border: none;
  outline: none;
  cursor: pointer;
  line-height: 42px;
  text-align: center;
}

.blockswiper a:link,
.blockswiper a:hover,
.blockswiper a:visited,
.blockswiper a:active {
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 21px;
  text-decoration: none;
}

.pbottomswiper {
  overflow: hidden;
  margin-top: 30px;
}

.assistant {
  background-color: #ebf2fa;
  /*padding-top: 100px;*/
  position: relative;

}

.bgimg3{
  width: 450px;
  height: 400px;
  position: absolute;
  top: 280px;
  right: 300px;
}

.assistantbox h2 {
  /* margin-top: 100px; */
  font-size: 26px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #292929;
  line-height: 32px;
  margin-bottom: 20px;
}

.assistantbox {
  height: 792px;
}

.blockassbox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.assblock {
  position: relative;
  padding-top: 24px;
  transition: all 0.3s ease-out;
  margin-top: 54px;
}

.assblock > img {
  width: 100px;
  position: absolute;
  right: 30px;
  top: -10px;
}

.asscontent {
  width: 380px;
  height: 200px;
  background:rgba(255, 255, 255, 0.3);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  cursor: pointer;
  padding: 40px;
}

.assblock:hover {
  transform: scale(1.2);
}

.assblock:hover .asscontent {
  background:rgba(255, 255, 255, 0.3);
  box-shadow: 4px 15px 60px 0px rgba(197, 199, 201, 0.33);
  border-radius: 12px;
}

.asscontent h3 {
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
  line-height: 20px;
  margin-bottom: 12px;
  text-align: left;
}

.asscontent p {
  text-align: left;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 23px;
}

.commandbox {
  background-color: #ebf2fa;
  padding: 50px 0;
}

.instop h2 {
  width: 100%;
  text-align: center;
  margin-bottom: 23px;
}

.instop img {
  width: 100px;
  margin-top: 30px;
  margin: auto;
}

.categroybox {
  background-color: #ebf2fa;
  padding: 20px 24px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}

.categroybox > div {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.catecontent {
  /* margin-top: 20px; */
}

.catecontent span {
  margin: 30px 20px;
  padding: 4px 10px;
  cursor: pointer;
}

.catecontent .current {
  background: #f1f7ff;
  border-radius: 3px;
  color: #519eff;
  padding: 4px 10px;
  border-radius: 8px;
}

.catecontent {
  max-width: calc(100% - 100px);
}

.instruction {
  padding-bottom: 40px;
}

.instructionlist {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

/* justify-content: space-between; */
> div {
  margin-right: 15px;
}

> div:nth-child(3n + 3) {
  margin-right: 0 !important;
}
}

.instruntblock {
  background: #ffffff;
  border-radius: 8px;
  position: relative;
  max-width: 390px;
  /*height: 371px;*/
  padding: 20px 16px;
  margin: 9px 0;
}

.instruntblock .toptags {
  position: absolute;
  right: 0;
  top: 0;
  width: 47px;
  height: 20px;
  background: linear-gradient(310deg, #3c83ff 0%, #619fff 100%);
  border-radius: 0px 0px 0px 15px;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  text-align: center;
}

.topinst {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}

.topinstleft {
  display: flex;
  align-items: center;
}

.topinstleft .topinsright {
  margin-left: 10px;
}

.topinstleft .topinsright span {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #519eff;
  line-height: 12px;
}

.topinstleft .topinsright p {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #2c343e;
  line-height: 12px;
}

.topinstleft img {
  width: 37px;
  height: 37px;
  border-radius: 50%;
}

.topinst .time {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #969899;
  line-height: 12px;
}

.instruntblock .content {
  /* max-height: 436px; */
  height: 206px;
}

.instruntblock .content p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #969899;
  line-height: 22px;
  margin-top: 10px;
  word-break: break-all;
}

.instruntblock .content h3 {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #292929;
  line-height: 16px;
  margin-top: 12px;
  word-break: break-all;
}

.instruntblock .content .cccontetins {
  margin-top: 16px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #969899;
  overflow: hidden;
  line-height: 22px;
  max-height: 110px;
}

.instruntblock .instbottomtag {
  margin-top: 10px;
}

.instruntblock .instbottomtag span {
  display: inline-block;
  background: #f4f5f5;
  border-radius: 2px;
  padding: 4px 10px;
  background: #f4f5f5;
  border-radius: 2px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #474b51;
  line-height: 12px;
}

.instruntblock .content .cccontenttags {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #19559e;
  line-height: 12px;
  margin-top: 8px;
}

.instruntblock .otherinst {
  display: flex;
  justify-content: space-between;
}

.otherinstleft span {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #989898;
  line-height: 12px;
  display: inline-block;
  margin-right: 20px;
  margin-top: 10px;
  display: flex;
  cursor: pointer;
  align-items: center;
}

.otherinst button {
  width: 89px;
  height: 33px;
  background: #ffffff;
  border-radius: 17px;
  border: 1px solid #f0f0f0;
  outline: none;
  cursor: pointer;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  line-height: 17px;
}

.otherinstleft img {
  display: inline-block;
  width: 15px;
  height: auto;
  margin-right: 6px;
}

.otherinstleft {
  display: flex;
}

.green {
  background: linear-gradient(310deg, #2fc3b0 0%, #34c4a4 100%) !important;
}

.haveimgblock {
}

.imgandword {
  position: relative;
  padding: 0 0 0 166px;
}

.ccctentimgleft {
  position: absolute;
  left: 0;
  top: 0;
  width: 156px;
  height: 151px;
  overflow: hidden;
  background-size: cover;
}

.moreshow {
  display: block;
  width: 222px;
  height: 39px;
  background: #ffffff;
  border-radius: 20px;
  border: 1px solid #519eff;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #519eff;
  margin: 40px auto 80px;
  cursor: pointer;
}

.commandcontent {
  background-color: #f6f8fa;
}

.commboxcontent {
  display: flex;
  justify-content: space-between;
}

.commleft {
  width: 180px;
  height: 1018px;
  background: #ffffff;
  padding: 14px 22px;
}

.leftsearch {
  width: 136px;
  height: 39px;
  background: #f1f2f4;
  overflow: hidden;
  border-radius: 4px;
  position: relative;
}

.leftsearch input {
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  width: 136px;
  height: 39px;
  background: #f1f2f4;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #929495;
  line-height: 20px;
  text-indent: 20px;
}

.leftsearch img {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 12px;
  height: 12px;
}

.listcontentleft span i {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #33383e;
  line-height: 14px;
  font-style: normal;
  margin-left: 10px;
}

.listcontentleft span {
  display: block;
  width: 124px;
  height: 32px;
  background: #ffffff;
  border-radius: 3px;
  border: 1px solid #e8eaec;
  text-align: center;
  line-height: 32px;
  margin-top: 12px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #33383e;
  cursor: pointer;
}

.listcontentleft p {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #777777;
  line-height: 12px;
  margin-top: 30px;
}

body {
  position: relative;
}

.floatbox {
  position: fixed;
  z-index: 9;
  /*right: calc(((100% - 1200px) / 2) - 26px);*/
  width: 80px;
  height: 80px;
}

.floatbox > div {
  width: 52px;
  height: 52px;
  background: #ffffff;
  box-shadow: 4px 7px 20px 0px rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 30px;
  cursor: pointer;
}

.floatbox .el-button {
  width: 52px;
  height: 52px;
  background: #ffffff;
  box-shadow: 4px 7px 20px 0px rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 30px;
  cursor: pointer;
}

.floatbox > div > span {
  /* width: 100%;
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; */
}

.floatbox .el-button span {
  text-align: center;
}

.floatbox .el-button img {
  width: 14px;
  height: 14px;
  display: inline-block;
  margin-bottom: 10px;
}

.floatbox > div > img {
  width: 14px;
  height: 14px;
  display: inline-block;
  margin-bottom: 10px;
}

.commmain {
  width: 706px;
}

.commright {
  width: 290px;
  background-color: #ffffff;
}

.instruntblock .content .imgandword .cccontetins {
  margin-top: 16px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #969899;
  overflow: hidden;
  line-height: 22px;
  max-height: 130px;
}

.instruntblock .content .imgandword .cccontetins span {
  display: block;
  word-break: break-all;
}

.maincontent {
  width: 100%;
  height: 100%;
  padding: 0 100px;
  margin: 0 auto;
}
</style>
<style scoped lang="less">
.banner {
  .swiper {
    width: 100%;
    height: 430px;
  }
}

.el-carousel /deep/ .el-carousel__container {
  height: 430px;
}

.bannerblock {
  // background-size: cover;
  .bannerblockcontent {
    //height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .btnbox {
      a {
        color: #ffffff;
      }

      a:nth-child(2) {
      }

      a:nth-child(1) {
        background: linear-gradient(270deg, #0790ff 0%, #40d1ff 100%);
        border-radius: 23px;
      }
    }
  }
}

.bannerblock1{
  background: url(../assets/banner2.png)
  no-repeat center;
  background-size: cover;
}
.bannerblock2{
  background: url(../assets/banner3.png)
  no-repeat center;
  background-size: cover;
}
.bannerblock3{
  background: url(https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/office/banner1.png)
  no-repeat center;
  background-size: cover;
}
.bannerblock4{
  background: url(https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/office/banner1.png)
  no-repeat center;
  background-size: cover;
}


.zhishibox {
  width: 100%;
  padding: 0 100px;
  margin: 0 auto;
  background: url(../assets/aibg.png) no-repeat center;
  background-size: contain;

  .btnbox {
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
      width: 584px;
      height: 285px;
      background: rgba(255, 255, 255, 0.4);
      border-radius: 8px;
      border: 2px solid #ffffff;
      backdrop-filter: blur(10px);
      font-size: 40px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #3674f3;
      line-height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin: 0 15px;

      > div {
        display: flex;
        flex-direction: column;

        span:nth-child(1) {
          font-size: 40px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #232534;
          line-height: 56px;
        }

        span:nth-child(2) {
          font-size: 40px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #3674f3;
          line-height: 56px;
        }
      }

      img {
        display: block;
        width: 218px;
        height: auto;
        margin-left: 30px;
      }
    }
  }
}

.linksbottom {

  padding-bottom: 30px;
  background: #ebf2fa;
  position: relative;
  .bgimg{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;left: 0;
  }
  .linksbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: rgba(255, 255, 255, 0.52);
    border-radius: 16px;
    border: 2px solid #ffffff;
    backdrop-filter: blur(10px);
    box-sizing: border-box;
    //padding: 52px;
    margin: 0 auto 0;

    img {
      width: 250px;
      height: 100px;
      background: #ffffff;
      border-radius: 8px;
      margin-bottom: 30px;
    }
  }
}

.mySwiperbb {
  position: relative;
}
</style>
<style>
.swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  background-color: #ffffff !important;
}
</style>
