<template>
  <div class="examples">
    <div>
      <h1 class="default-text">{{ title }}</h1>
    </div>
    <!-- <div style="display: flex; flex-direction: row; align-items: center;">
      <svg style="color: #fff; width: 24px; height: 24px;" stroke="currentColor" fill="none" stroke-width="1.5" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" class="h-6 w-6" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="5"></circle><line x1="12" y1="1" x2="12" y2="3"></line><line x1="12" y1="21" x2="12" y2="23"></line><line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line><line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line><line x1="1" y1="12" x2="3" y2="12"></line><line x1="21" y1="12" x2="23" y2="12"></line><line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line><line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line></svg>
    </div> -->
    <div class="questionbox">
      <div class="examples-btn" v-for="(item, index) in examples" :key="index" @click="handleExample(item)" type="default"
        round>{{ item }}</div>
    </div>
    <div class="more" @click="moreshow()">查看更多<i class="el-icon-arrow-down"></i></div>
    <!-- 盒子 -->
    <el-drawer title="热门标签" center :visible.sync="drawer" direction="rtl">
      <div class="disbox">
        <el-tabs v-model="activeName" >
          <el-tab-pane v-for="(i, t) in tagList" :label="i.tagName" :key="t" :name="i.tagName">
            <ul class="infinite-list" style="overflow:auto">
              <li v-for="(itm, idx) in i.tagTextList" @click="handleExample(itm),drawer=false" :key="idx" class="infinite-list-item">{{ itm }}</li>
            </ul>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { getSysTagList } from '@/api'
export default {
  name: 'QuestionExamples',
  props: {
    from: {
      type: String,
      default: 'chat'
    },
    title: {
      type: String
    },
    examples: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      activeName: '',
      tagList: null,
      drawer: false
    }
  },
  mounted() {
    this.getSysTagList()
  },
  methods: {
    getSysTagList() {
      getSysTagList({ tagType: 'CHAT' }).then(res => {
        // console.log(res.obj)/
        this.tagList = res.obj
        this.activeName = res.obj[0].tagName
      })
    },
    moreshow() {
      this.drawer = true
    },
    handleExample(example) {
      this.$eventBus.$emit(this.from + 'example', example)
    }
  }
}
</script>
<style lang="less" scoped>
.questionbox {
  margin-top: 20px;
}

.examples {
  width: 630px;
  overflow-y: auto;
  display: flex;
  height: 80%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  .default-text {
    color: #333333;
  }
}

.questionbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .examples-btn {
    width: 30%;
    height: 66px;
    border-radius: 8px;
    box-sizing: border-box;
    line-height: 26px;
    cursor: pointer;
    font-size: 16px;
    padding: 10px;
    margin-bottom: 12px;
    background-color: #f7f7f8;
    background-color: rgb(255 255 255);
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden; //溢出内容隐藏
    text-overflow: ellipsis; //文本溢出部分用省略号表示
    display: -webkit-box; //特别显示模式
    -webkit-line-clamp: 2; //行数
    line-clamp: 2;
    -webkit-box-orient: vertical; //盒子中内容竖直排列
    border: 1px solid #E5E5E5;
  }

}

.more {
  cursor: pointer;
  margin-top: 20px;
}
.disbox{
  padding: 20px 20px;
  .infinite-list-item{
    background: #F6F7FB;
    padding: 5px 10px;
    height: 40px;
    list-style: none;
    line-height:40px;
    color: #333;
    margin-bottom: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    cursor: pointer;
  }
}
</style>
