let msgList = []
let lastAnswer = ''
export default {
    chatgpt3(apiKey, serverUrl, maxTokens, temperature, params) {
        console.log(localStorage.getItem('msgList'));
        if (localStorage.getItem('msgList')==null) {
            console.log('新的会话');
            msgList = []
            lastAnswer = ''
        }
        if (params.system && params.system !== '') {
            msgList.push({"role":"system","content":params.system})
        }

      msgList.push({"role":"user","content":params.question})
      localStorage.setItem('msgList',JSON.stringify(msgList))
      const isgroup = JSON.parse(localStorage.getItem("groupCon"));
      const isgroupData = localStorage.getItem("groupDataType");
        lastAnswer = ''
        const eventSource = new EventSource(serverUrl, {
            payload: {"model":"gpt-3.5-turbo",'userId':params.kefu?-1:null,'groupId':params.kefu?params.kefu:isgroup?isgroup.id:null,'groupDataType':params.kefu?'WITHIN':isgroupData,"messages": msgList,"temperature": temperature,"chatChannelEnum": params.chatChannelEnum,"chatLevel": params.chatLevel,"stream":true,"max_tokens":maxTokens,"user":"test",'chatId':params.chatId?params.chatId:localStorage.getItem('')},
            headers: {
                'token':localStorage.getItem('token'),
                'Authorization': 'Bearer ' + apiKey,
                'X-Forwarded-For': '1.1.1.1'
            }
        });
        eventSource.onopen = () => {
            params.onEvent({type: 'OPEN'})
        };
        eventSource.onmessage = (event) => {
            if (event.data === null || event.data === undefined || event.data === '') {
                return;
            }
            if (event.data === '[DONE]') {
                params.onEvent({type: 'DONE'})
                let tmp = lastAnswer
                if (tmp.length > 10) {
                    tmp = tmp.substring(0, 10)
                }
                msgList.push({role: 'assistant', content: tmp})
                return
            }

            const data = JSON.parse(event.data)
            if (!data) {
                return;
            }

            if (data.choices && data.choices.length > 0) {
                this.answerIndex++
                let answer = ''
                let finishReason = ''
                data.choices.forEach(r => {
                    if (r.delta.content) {
                        if (r.delta.content === '\n' || r.delta.content === '\n\n') {
                            return
                        }
                        answer += r.delta.content
                    }
                    finishReason = r.finish_reason
                })
                lastAnswer += answer
                params.onEvent({type: 'UPDATE', content: lastAnswer, tips: finishReason,chatId: Number(data.id)})
            }

        };
        eventSource.onerror = (event) => {
            console.log('ERROR', event)
            params.onEvent({type: 'ERROR'})
        };
        eventSource.stream()
        return eventSource
    },
}
