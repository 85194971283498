<template>
  <div>
    <ConversationContent v-for="(item, index) in conversation.messages" :key="index" :content="item" :chat-id="item.chatId"></ConversationContent>
  </div>
</template>
<script>
import ConversationContent from '@/components/ConversationContent.vue'
export default {
  name: 'Conversation',
  components: {
    ConversationContent
  },
  props: {
    conversation: {
      type: Object,
      default() {
        return {
          id: 0,
          channel: '',
          messages: [],
        }
      }
    }
  },
  data() {
    return {
    }
  },
}
</script>
<style lang="less">
</style>
