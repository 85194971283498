<template>
  <div class="white-panel" :style="`height: ${height}px; overflow: auto;`">
    <div style="padding: 1rem 1rem;">
      <div style="display: flex; flex-direction: column;">
        <div style="text-align: center;">
          <img :src="templateDetail.headIcon" style="width: 4rem; height: 4rem;">
        </div>
        <div style="white-space: pre-wrap; padding: 0 3rem; font-size: 0.9rem;">{{templateDetail.extParams ? templateDetail.extParams.tips : ''}}</div>
        <div v-if="templateDetail.extParams" style="padding-top: 1rem; display: flex; flex-direction: row; flex-wrap: wrap; justify-content: center;">
          <van-button class="chat-template-example" v-for="(item, index) in templateDetail.extParams.examples" :key="index" size="mini" @click="handleExampleClick(item)">{{`示例${index + 1}`}}</van-button>
        </div>
        <div style="padding-top: 1rem;">
          <van-field
              v-model="question"
              rows="7"
              autosize
              label=""
              type="textarea"
              placeholder=""
              maxlength="200"
              show-word-limit
          />
        </div>
        <div style="padding-top: 1rem;">
          <van-button round style="width: 100%;" type="info" :loading="loading" @click="handleSearch">立即生成</van-button>
        </div>
        <div v-if="result !== ''">
          <div class="answer" style="padding: 0.5rem; margin-top: 1rem;">
            <vue-markdown class="markdown-body" :style="`width: ${width}px;`" :anchorAttributes="{'target': '_blank'}"
                          :source="result + (loading ? loadingSpan : '')">
            </vue-markdown>
          </div>
          <div id="chatTemplateCopy" style="padding-top: 1rem;">
            <van-button v-if="loading" round style="width: 100%;" type="primary" @click="handleStop">停止生成</van-button>
            <van-button v-else round style="width: 100%;" type="primary" @click="handleCopy(result)">复制内容</van-button>
<!--            <svg @click="handleCopy(result)" t="1680676807366" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4769" width="22" height="22"><path d="M629.39164 415.032528l-163.616428-163.616428c-7.992021-7.992021-20.947078-7.992021-28.939099 0-7.992021 8.002254-7.992021 20.957311 0 28.949332l128.680754 128.680754-175.548178 0L389.968689 184.082552c0-11.2973-9.168824-20.466124-20.466124-20.466124L21.813818 163.616428c-11.307533 0-20.466124 9.168824-20.466124 20.466124l0 818.08214c0 11.307533 9.15859 20.466124 20.466124 20.466124l593.108273 0c11.307533 0 20.466124-9.15859 20.466124-20.466124L635.388215 429.512311C635.388215 424.078555 633.229039 418.880159 629.39164 415.032528zM594.455967 981.698568l-552.176025 0L42.279942 204.548676l306.756499 0 0 224.963635c0 11.2973 9.15859 20.466124 20.466124 20.466124l224.953402 0L594.455967 981.698568z" p-id="4770" fill="#2c2c2c"></path><path d="M1023.978511 265.895883l0 572.652382c0 11.307533-9.15859 20.466124-20.466124 20.466124l-307.86167 0c-11.2973 0-20.466124-9.15859-20.466124-20.466124 0-11.2973 9.168824-20.466124 20.466124-20.466124l287.395546 0L983.046263 286.362007l-224.953402 0c-11.307533 0-20.466124-9.168824-20.466124-20.466124L737.626737 40.932248l-306.756499 0 0 75.693959c0 11.307533-9.168824 20.466124-20.466124 20.466124-11.307533 0-20.466124-9.15859-20.466124-20.466124L389.93799 20.466124c0-11.2973 9.15859-20.466124 20.466124-20.466124l347.688747 0c11.2973 0 20.466124 9.168824 20.466124 20.466124l0 224.963635 175.548178 0-128.680754-128.680754c-7.992021-7.992021-7.992021-20.947078 0-28.949332 7.992021-7.992021 20.947078-7.992021 28.939099 0l163.616428 163.626661C1021.819334 255.263731 1023.978511 260.462127 1023.978511 265.895883z" p-id="4771" fill="#2c2c2c"></path></svg>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueMarkdown from 'vue-markdown'
import chatgpt from '@/utils/chatgpt'
import {tickets, answer, template} from '@/api'
import {mapGetters} from "vuex";
export default {
  name: 'ChatTemplateDetail',
  components: {
    VueMarkdown
  },
  computed: {
    ...mapGetters([
      'userinfo',
      'notifyOpen',
      'clientHeight',
      'clientWidth'
    ]),
    height() {
      let h = this.clientHeight - 50 - 10
      if (this.notifyOpen) {
        h -= 40
      }
      return h
    },
    width() {
      return this.clientWidth - 44
    },
    memberCard() {
      let r = null
      if (this.userinfo.cardList && this.userinfo.cardList.length > 0) {
        this.userinfo.cardList.forEach(i => {
          if (i.cardType === 'Member') {
            r = i
          }
        })
      }
      return r
    },
  },
  data() {
    return {
      loadingSpan: '<span class="typed-cursor" style="margin-left: 0.3rem; color: #fff;">▋</span>',
      templateDetail: {},
      loading: false,
      question: '',
      result: '',
      chatId: '',
      client: null,
    }
  },
  activated() {
    this.getTemplate()
  },
  mounted() {
    this.getTemplate()
  },
  methods: {
    scrollToBottom() {
      let end = document.getElementById('chatTemplateCopy')
      if (end) {
        end.scrollIntoView()
      }
    },
    getTemplate() {
      this.question = ''
      this.result = ''
      this.templateDetail = {}
      const id = this.$route.query.id
      template({id: id}).then(res => {
        this.templateDetail = res.obj
      })
    },
    handleExampleClick(item) {
      this.question = item
    },
    handleCopy(content) {
      this.$copyText(content).then(() => {
        this.$toast('复制成功')
      }).catch(err => {
        console.error('copy', err)
      })
    },
    handleStop() {
      if (this.client) {
        this.client.close()
      }
      this.loading = false
    },
    handleSearch() {

      if (this.question === '') {
        this.$toast('请输入内容')
        return
      }

      let that = this
      const param = {
        onEvent(param) {
          switch (param.type) {
            case 'OPEN':
              break
            case 'UPDATE':
              that.loading = true
              that.result = param.content
              if (that.memberCard === null && param.tips === 'length') {
                that.result += '【字数超过限制，您可在 "个人中心"  充值会员 获得无限制体验】'
                that.$toast('字数超过限制，您可在 "个人中心"  充值会员 获得无限制体验')
              }
              that.scrollToBottom()
              break
            case 'DONE':
            {
              that.loading = false

              answer({chatId: this.chatId, answer: that.result})
              break
            }
            case 'ERROR':
              that.loading = false
              that.result = '【服务器繁忙，请稍后重试！】'
              break
          }
        },
        system: this.templateDetail.extParams.system,
        question: this.question,
        newChat: true,
      }

      that.loading = true

      tickets({channelName: 'ChatGPT3', question: this.question}).then(res => {
        if (res.obj.count !== null && res.obj.count <= 0) {
          param.onEvent({type: 'UPDATE', content: '【当日免费次数已用完，您可在 "个人中心"  充值会员 获得无限制额度 或 邀请好友(推广1人将新增3次免费额度）】'})
          param.onEvent({type: 'DONE'})
          return
        } else {

          this.$getLoginInfo()

          this.chatId = res.obj.chatId

          const chatServerUrl = "/api/search/v3"
          const apiKey = res.obj.apiKey
          const maxTokens = res.obj.maxTokens

          this.client = chatgpt.chatgpt3(apiKey, chatServerUrl, maxTokens, 1.2, param)
        }

      }).catch(error => {
        console.log(error)
        param.onEvent({type: 'UPDATE', content: '【服务器繁忙，请稍后重试！】'})
        param.onEvent({type: 'DONE'})
      })
    },
  }
}
</script>
<style lang="less">
.chat-template-example {
  margin: 0 0.5rem;
  padding: 0 0.5rem;
}
</style>
