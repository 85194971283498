
const state = {
  baseUrl: '',
  clientHeight: document.body.clientHeight,
  clientWidth: document.body.clientWidth,
  userinfo: null,
  group: null,
  groupEnergy: null,
  channelList: [],
  askCount: 0,
  askLimit: 3,
  shared: false,
  notifyOpen: true,
}

const mutations = {
  SET_BASE_URL: (state, baseUrl) => {
    state.baseUrl = baseUrl
  },
  SET_USERINFO: (state, userinfo) => {
    let title = '三只阿猫智能科技'
    if (userinfo.cardList && userinfo.cardList.length > 0) {
      userinfo.cardList.forEach(i => {
        if (i.cardType === 'Member') {
          title = '三只阿猫智能科技'
        }
      })
    }
    document.title = title
    state.userinfo = userinfo
  },
  SET_GROUP: (state, group) => {
    state.group = group
    if (null !== group) {
      localStorage.setItem("groupCon", JSON.stringify(group))
    } else {
      localStorage.removeItem("groupCon")
    }
  },
  SET_GROUP_ENERGY: (state, energy) => {
    state.groupEnergy = energy
  },
  SET_GROUP_NAME: (state, name) => {
    if (state.group !== null) {
      state.group.groupName = name
    }
  },
  SET_APP_SIZE: (state, data) => {
    state.clientHeight = data.height
    state.clientWidth = data.width
  },
  SET_SHARED: (state, shared) => {
    state.shared = shared
    state.askCount = 0
  },
  ADD_ASK_COUNT: (state) => {
    state.askCount++
  },
  SET_NOTIFY_OPEN: (state, open) => {
    state.notifyOpen = open
  },
  SET_CHANNEL_LIST: (state, list) => {
    state.channelList = list
  },
  LOAD_FROM_CACHE: (state) => {
    const groupJson = localStorage.getItem('groupCon')
    if (groupJson) {
      state.group = JSON.parse(groupJson)
    }
  }
}

const actions = {
  setBaseUrl({ commit }, baseUrl) {
    commit('SET_BASE_URL', baseUrl)
  },
  loadFromCache({ commit }) {
    commit('LOAD_FROM_CACHE')
  },
  setUserinfo({ commit }, userinfo) {
    commit('SET_USERINFO', userinfo)
  },
  setGroup({ commit }, group) {
    commit('SET_GROUP', group)
  },
  setGroupEnergy({ commit }, energy) {
    commit('SET_GROUP_ENERGY', energy)
  },
  setGroupName({ commit }, name) {
    commit('SET_GROUP_NAME', name)
  },
  setAppSize({ commit }, data) {
    commit('SET_APP_SIZE', data)
  },
  setShared({ commit }, shared) {
    commit('SET_SHARED', shared)
  },
  addAskCount({ commit }) {
    commit('ADD_ASK_COUNT')
  },
  setNotifyOpen({commit}, open) {
    commit('SET_NOTIFY_OPEN', open)
  },
  setChannelList({commit}, list) {
    commit('SET_CHANNEL_LIST', list)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
