<template>
  <div id="app">
    <div :style="`min-height: ${height}px;`">
      <!-- <div v-if="userinfo.userNo && userinfo.userNo !== ''" :style="`height: ${height}px; overflow: hidden;`"> -->
      <!-- <van-notice-bar v-if="notifyOpen && userinfo.notify && userinfo.notify !== ''" mode="closeable" left-icon="volume-o" -->
      <!-- @close="handleCloseNotify" :text="userinfo.notify" /> -->
      <div class="index-box">
        <div class="content">
          <nav class="top_nav" style="display: flex">
            <div class="logo">
              <img src="./assets/logo.png" alt="" style="width: 45px"/>
            </div>
            <div class="header-nav-select-org">
              <el-dropdown
                  trigger="click"
                  @command="handleCommand"
                  style="
                float: left;
                font-size: 20px;
                color: #1fa9f8;
                line-height: 50px;
                display: inline-block;
                margin-left: 30px;
                margin-top: 10px;
              "
              >
              <span class="el-dropdown-link">
                {{
                  group != null ? group.groupName : "三只阿猫智能科技"
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                      class="dropdown-item"
                      v-for="(item, i) in userinfo.userGroupList"
                      :key="i"
                      :command="item.groupVo"
                  >{{ item.groupVo.groupName }}
                    <i
                        class="el-icon-more more"
                        style="float: right; margin-top: 12px; margin-right: -10px"
                        @click.stop="group_edit(item.groupVo, item.userRole)"
                    ></i>
                  </el-dropdown-item>
                  <el-dropdown-item divided command="new"
                  >三只阿猫智能科技
                  </el-dropdown-item
                  >
                  <el-dropdown-item
                      divided
                      command="add"
                      icon="el-icon-circle-plus"
                  >创建我的企业
                  </el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div class="maincontent navbox" style="width: auto; margin-right: 50px">
              <div class="header-nav">
                <div class="rightmenu">
                  <!-- <a class="curr" >首页</a> -->
                  <div
                      :class="{ menuitem: true, curr: actmenu == item.path }"
                      v-for="(item, index) in menu"
                      :key="index"
                      @click="gorouter(item.path)"
                      style="font-size: 18px"
                  >
                    {{ item.name }}
                  </div>
                  <!-- <a class="chuang" href="javascript:;" @click="chuangzuo"
                    >开始创作</a
                  > -->

                  <!-- <a href="">AI绘画</a>
                      <a href="">指令广场</a>
                      <a href="">开始创作</a> -->
                  <!-- <el-button type="primary" class="chuangzuo">开始创作</el-button>
                  <span style="font-size:24px;margin: 0 20px;" class="el-icon-bell"></span> -->

                  <el-dropdown
                      style="margin-left: 10px; margin-right: 30px"
                      @command="goChuang"
                  >
                    <el-button type="primary">
                      开始创作<i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                          command="1"
                          v-if="menu_show_list.includes('AI应用')"
                      >创建应用指令
                      </el-dropdown-item
                      >
                      <el-dropdown-item
                          command="2"
                          v-if="menu_show_list.includes('AI聊天')"
                      >创建聊天指令
                      </el-dropdown-item
                      >
                      <el-dropdown-item
                          command="3"
                          v-if="menu_show_list.includes('AI绘画')"
                      >创建绘画指令
                      </el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
              <div class="header-nav-energy"
                   style="margin-right: 20px;display: flex;align-items: center;font-size: 20px;padding: 10px;border-radius: 20px;background-color: #f3f3f3;" @click="gotoMy(1)">
                <img
                    src="../public/static/img/dianchi.png"
                    alt=""
                    style="width: 35px; margin-top: 0; height: 25px"
                />
                <span
                    style="
                      margin-left: 5px;
                      color: rgb(214, 174, 42);
                      cursor: default;
                    "
                >{{ energA }}</span
                >
              </div>
              <div
                  style="
                    width: 80px;
                    text-align: center;
                    cursor: default;
                    border-left: 1px solid #e4e4e4;
                  "
                  @click="gotoMy(4)"
                  v-if="group_role == 'ADMIN' || group_role == 'EXPERT'"
              >
                <img
                    src="../public/static/img/qiye.png"
                    alt=""
                    style="width: 30px; margin: auto"
                /><span>企业管理</span>
              </div>
              <div
                  style="
                    width: 80px;
                    text-align: center;
                    margin-right: 10px;
                    cursor: default;
                    border-right: 1px solid #e4e4e4;
                  "
                  @click="kefu = true"
              >
                <img
                    src="../public/static/img/kefu.png"
                    alt=""
                    style="width: 30px; margin: auto;"
                /><span>在线客服</span>
              </div>
              <div
                  class="nologin"
                  style="display: inline-block"
                  @click="loginshow"
                  v-if="!userinfo.nickname"
              >
                <el-button class="udata" type="primary"
                ><i
                    style="font-size: 16px; margin-right: 10px"
                    class="el-icon-user"
                ></i
                >登录
                </el-button
                >
              </div>
              <el-popover
                  v-else
                  v-model="popoverVisible"
                  placement="bottom"
                  width="300"
                  trigger="click"
                  visible-arrow="false">
                <div>
                  <div class="popover_top" @click="gotoMy(1)">
                    <img :src="userinfo.headimgurl" alt=""/>
                    <span class="name">{{ userinfo.nickname }}</span>
                    <!-- <div class="dailibox">
                      <div class="daili">代理</div>
                      <span>{{ userinfo.isDaili[0].endTime }}到期</span>
                    </div> -->
                    <span class="txt1">做任务领取能量</span>
                  </div>
                  <div class="tui">
                    <div class="tui1">
                        <span class="num">{{
                            userinfo.sumMoney ? userinfo.sumMoney : 0
                          }}</span>
                      <span class="shou">推广收益</span>
                    </div>
                    <div class="tui1">
                        <span class="num">{{
                            userinfo.userCount ? userinfo.userCount : 0
                          }}</span>
                      <span class="shou">推广人数</span>
                    </div>
                  </div>
                  <!-- <div class="day_neng">
                    <div class="txt">
                      <span class="txt1">{{ keName }}</span>
                      <span class="txt2" @click="gotoMy(1)">查看套餐></span>
                    </div>

                    <el-progress
                      :percentage="(energA / energB) * 100"
                      :show-text="false"
                      style="width: 80%; margin: auto"
                    ></el-progress>
                    <div class="txt_buttom">
                      <span class="txt1">剩余{{ energA }}</span>
                      <span class="txt2">共{{ energB }}</span>
                    </div>
                  </div> -->

                  <div class="mai">
                    <span class="txt1">购买套餐</span>
                    <span class="txt2">立刻获取能量能量</span>
                    <div class="mai_but" @click="gotoMy(1)">立即购买</div>
                  </div>
                  <div class="select_all">
                    <!-- <div
                      class="select"
                      @click="gotoMy(4)"
                      v-if="group_role == 'ADMIN' || group_role == 'EXPERT'"
                    >
                      企业管理
                    </div> -->
                    <!-- <div class="select">服务商</div> -->

                    <div class="header-nav-select-org-menu select">
                      <el-dropdown
                          trigger="click"
                          @command="handleCommand"
                          style="font-size: 20px;color: #1fa9f8;line-height: 50px;display: inline-block;">
                          <span class="el-dropdown-link">
                            {{
                              group != null ? group.groupName : "三只阿猫智能科技"
                            }}<i class="el-icon-arrow-down el-icon--right"></i>
                          </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item
                              class="dropdown-item"
                              v-for="(item, i) in userinfo.userGroupList"
                              :key="i"
                              :command="item.groupVo"
                          >{{ item.groupVo.groupName }}
                            <i
                                class="el-icon-more more"
                                style="float: right; margin-top: 12px; margin-right: -10px"
                                @click.stop="group_edit(item.groupVo, item.userRole)"
                            ></i>
                          </el-dropdown-item>
                          <el-dropdown-item divided command="new"
                          >三只阿猫智能科技
                          </el-dropdown-item
                          >
                          <el-dropdown-item
                              divided
                              command="add"
                              icon="el-icon-circle-plus"
                          >创建我的企业
                          </el-dropdown-item
                          >
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>

                    <div class="header-nav-energy-menu select" style="margin-right: 20px;display: flex;align-items: center;font-size: 20px;padding: 10px;border-radius: 20px;background-color: #f3f3f3;" @click="gotoMy(1)">
                      <img
                          src="../public/static/img/dianchi.png"
                          alt=""
                          style="width: 35px; margin-top: 0; height: 25px"
                      />
                      <span
                          style="
                      margin-left: 5px;
                      color: rgb(214, 174, 42);
                      cursor: default;
                    "
                      >{{ energA }}</span
                      >
                    </div>

                    <div class="header-nav-menu select" v-for="(item, index) in menu" :key="index" @click="gorouter(item.path)" style="font-size: 18px">
                      {{ item.name }}
                    </div>

                    <div class="select" @click="gotoMy(3)" v-if="!group_role">
                      代理商
                    </div>
                    <!-- <div class="select">第三方应用入驻</div> -->
                    <!-- <div class="select">意见反馈</div> -->
                    <div
                        class="select"
                        @click="endLogin"
                        style="color: rgb(204, 100, 100)"
                    >
                      退出登陆
                    </div>
                  </div>
                </div>
                <div
                    style="
                      display: inline-block;
                      font-size: 18px;
                      margin-right: -20px;
                    "
                    slot="reference"
                >
                  <div class="user">
                    <img :src="userinfo.headimgurl" alt=""/>
                    {{
                      userinfo.nickname.length > 4
                          ? userinfo.nickname.slice(0, 3) + ".."
                          : userinfo.nickname
                    }}
                  </div>
                </div>
              </el-popover>
            </div>
          </nav>
          <!-- <div class="top">
            <img class="logo" src="https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/logo/apple/60.png" alt="">
            阿猫智能助手
          </div>
          <div class="menu">
            <div class="menublock">{{ item.name }}
            </div>
          </div>
          <div class="rightusermsg">
            <div class="kaitonf">
              <div data-v-54d5b2c4="" class="vip-text mr-6"><img data-v-54d5b2c4=""
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAcCAMAAAA3HE0QAAAASFBMVEUAAADPdw/PeBDPdw/PdRDPcBDPdw/Pdw/Pdw/Qdw7Ndg7OdQ7PeBDPdw7Pdw7Mdg3Pdw/////nu4fYkTz89/DhqmnViC357uFvtbTAAAAAEHRSTlMA3yDvMBDPv6+fgGBAj29Qf5iguAAAAJxJREFUKM99kUcSwkAMBLXBCRPGAfP/n+ICgWp3bPW1u+Yy8qUD0Tdi3HHAxXwMR0GyiRvgTkQck6IGA04YqgHm+gn686CTnQfgTwTAnRjhMtrAts5Q5tcCJUmCskzTrH5aNyhB8r947oV6G8giORRF4UP+XWVF4aMIFZWnovJcbJXngrwVrrfCPBfsuTDvFea5YM9FG8WhadtGCt6RXSM7WRFJ0AAAAABJRU5ErkJggg=="
                  class="">
                立即开通</div>
            </div>
            <div class="nologin" @click="loginshow">
              <span class="el-icon-user"></span>
              <el-button class="udata" type="text">点击登录</el-button>
            </div>
            <div class="usdata">
              <span class="el-icon-setting"></span>
              <el-button class="udata"  type="text">个人中心</el-button>
            </div>
          </div> -->
        </div>
      </div>
      <keep-alive>
        <router-view @update="upgroupdata" @myEvent="getNow"/>
      </keep-alive>
    </div>
    <!-- 登录弹窗 -->
    <el-dialog
        title=""
        :visible.sync="dialogTableVisible"
        show-close
        width="400px"
        @close="closeDialog"
    >
      <div class="loginbox">
        <h2>扫码登录阿猫智能助手</h2>
        <div class="erweia">
          <img :src="scodeimg" alt=""/>
        </div>
        <div>使用微信扫码-关注公众号登录</div>
        <!-- <div class="left">

      </div>
      <div class="right"></div> -->
      </div>
    </el-dialog>
    <footer v-if="showfooter">
      <div class="footertop">
        <div class="maincontent" style="max-width: 1000px; padding: 0 10px;">
          <div class="left">
            <h4 style="margin-bottom: 20px; display: block">
              三只阿猫智能科技
            </h4>
            <p style="margin-bottom: 10px; display: block">联系我们</p>
            <a
                style="color: #fff; margin-bottom: 20px"
                href="mailto:maitoukugan@3amao.com"
            >maitoukugan@3amao.com</a
            >
            <p style="margin-top: 10px">周一至周五 9:00-18:00</p>
          </div>
          <div class="center">
            <p>研发团队： 三只阿猫智能科技</p>
            <p>研发总部：成都市高新区梓州大道领馆国际8号楼</p>
          </div>
          <div class="right">
            <img
                src="https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/static/all/public.jpeg"
                alt=""
                style="width: 45px"
            />
            <p>体验AI智能</p>
            <p>微信扫码即可体验</p>
          </div>
        </div>
      </div>
      <div class="footerbottom">
        <div class="maincontent">
          <span>版权所有 @成都三只阿猫智能科技有限公司</span>
          <span>备案号：蜀ICP备2023012676号</span>
        </div>
      </div>
    </footer>
    <el-dialog
        title="提示"
        :visible.sync="tanchuangbox"
        width="400px"
        :close-on-press-escape="false"
        :before-close="handleCloseshowlianxi2"
    >
      <div
          style="
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        "
      >
        <span style="margin-bottom: 20px">扫码前往个人中心获取能量</span>
        <img
            style="width: 300px"
            src="https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/static/all/public.jpeg"
            alt=""
        />
      </div>
    </el-dialog>
    <el-dialog
        title="创建我的企业"
        :visible.sync="iscreate"
        style="border-radius: 12px"
    >
      <div class="addbox">
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="企业名称">
            <el-input
                style="width: 300px"
                maxlength="20"
                v-model="form.groupName"
            ></el-input>
          </el-form-item>
          <el-form-item label="企业可见菜单" label-width="100px">
            <el-checkbox-group v-model="form.menuList">
              <el-checkbox label="AI聊天" name="chat"></el-checkbox>
              <el-checkbox label="AI应用" name="app"></el-checkbox>
              <el-checkbox label="AI绘画" name="draw"></el-checkbox>
            </el-checkbox-group>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="addMy">确认创建</el-button>
            <el-button @click="cancle">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog
        title="企业信息"
        :visible.sync="isedit"
        style="border-radius: 12px"
    >
      <div class="addbox">
        <el-form ref="form" :model="edit" label-width="80px">
          <el-form-item label="企业名称">
            <el-input
                style="width: 300px"
                maxlength="20"
                v-model="edit.groupName"
                :disabled="edit_role == 'ADMIN' ? false : true"
            ></el-input>
          </el-form-item>
          <el-form-item
              label="企业可见菜单"
              label-width="100px"
              v-if="edit_role == 'ADMIN'"
          >
            <el-checkbox-group v-model="edit.menuList">
              <el-checkbox label="AI聊天" name="chat"></el-checkbox>
              <el-checkbox label="AI应用" name="app"></el-checkbox>
              <el-checkbox label="AI绘画" name="draw"></el-checkbox>
            </el-checkbox-group>
          </el-form-item>

          <el-form-item>
            <el-button
                type="primary"
                @click="editMy"
                v-if="edit_role == 'ADMIN'"
            >保存
            </el-button
            >
            <el-button @click="isedit = false" v-if="edit_role == 'ADMIN'"
            >取消
            </el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <el-drawer title="你好！我是三只阿猫在线客服" :visible.sync="kefu" :size="kefuDrawerSize">
      <kefu></kefu>
    </el-drawer>
  </div>
</template>

<script>
import Kefu from "@/views/kefu.vue";

// import { nextTick } from "process";
import {mapGetters} from "vuex";
import {
  generateWechatQRCode,
  loginStatusCheck,
  newUserGroup,
  checkGroupInviteCode,
  checkGroupUserExist,
  groupMemberNotRequireApply,
  updateUserGroup,
  getUserGroup,
} from "@/api";
import store from "./store";

export default {
  name: "App",
  components: {Kefu},
  computed: {
    ...mapGetters(["userinfo", "notifyOpen", "clientHeight", "clientWidth"]),
    isMobile() {
      return this.clientWidth < 1300
    },
    kefuDrawerSize() {
      if (this.clientWidth < 400) {
        return '100%'
      } else {
        return 400
      }
    },
    height() {
      return this.clientHeight - 50;
    },
    group() {
      return this.$store.state.app.group
    },
    groupEnergy() {
      return this.$store.state.app.groupEnergy
    },
    group_role() {
      if (this.userinfo && this.userinfo.userGroupList && this.group) {
        for (let i = 0; i < this.userinfo.userGroupList.length; i++) {
          if (this.userinfo.userGroupList[i].groupId == this.group.id) {
            return this.userinfo.userGroupList[i].userRole;
          }
        }
      }
      return ''
    },
    energA() {
      if (this.groupEnergy) {
        return this.groupEnergy.currentEnergy
      }
      if (this.userinfo && this.userinfo.userEnergyResponse) {
        return this.userinfo.userEnergyResponse.userAvailableEnergy
      }
      return 0
    },
    energB() {
      if (this.groupEnergy) {
        return this.groupEnergy.energyTotal
      }
      if (this.userinfo && this.userinfo.userEnergyResponse) {
        return this.userinfo.userEnergyResponse.userTotalEnergy
      }
      return 0
    }
  },
  data() {
    return {
      popoverVisible: false,
      dialogTableVisible: false,
      kefu: false,
      iscreate: false,
      isedit: false,
      form: {
        groupName: "",
        menuList: [],
      },
      edit: {
        id: null,
        groupName: "",
        menuList: [],
      },
      menu_show_list: ["首页", "AI聊天", "AI应用", "AI绘画", "广场", "商城"],
      menu: [
        {
          path: "/",
          name: "首页",
        },
        {
          path: "/chat",
          name: "AI聊天",
        },
        {
          path: "/app",
          name: "AI应用",
        },
        {
          path: "/draw",
          name: "AI绘画",
        },
        {
          path: "/square",
          name: "广场",
        },
        {
          path: "",
          name: "商城",
        },
      ],
      active: 0,
      actmenu: "/",
      timeer: null,
      userdata: null,
      sessionId: "",
      showfooter: false,
      scodeimg: "",
      tanchuangbox: false,
      headericon: "",
      invite_code: null,
      edit_role: null,
    };
  },

  watch: {
    $route: {
      handler: function (to, from) {
        this.invite_code = to.query.invite_code;
        if (this.invite_code != null) {
          console.log(localStorage.getItem("token"));
          if (!localStorage.getItem("token")) {
            console.log("没登陆");
            this.$alert("请先登录", "提示", {
              confirmButtonText: "确定",
              callback: () => {
              },
            });
          } else {
            let data = {
              inviteCode: this.invite_code,
            };
            checkGroupInviteCode(data).then((res) => {
              console.log(res);
              let obj = res.obj;
              if (res.success == true) {
                console.log(1111111);
                let data = {
                  groupId: res.obj.groupId,
                  userId: JSON.parse(localStorage.getItem("udata")).userId,
                };
                checkGroupUserExist(data).then((res) => {
                  console.log(res);
                  if (res.obj == true) {
                    this.$alert("你已在该企业中", "提示", {
                      confirmButtonText: "确定",
                      callback: () => {
                        const currentPath = this.$route.path;
                        const pathWithoutParams = currentPath.split("?")[0];
                        // this.$router.replace(pathWithoutParams)
                        this.$router.push({path: pathWithoutParams});
                        window.location.reload(true);
                      },
                    });
                  } else {
                    this.$alert(
                        obj.inviteNickName + "邀请你加入该企业",
                        obj.groupName,
                        {
                          confirmButtonText: "确定加入",
                          callback: () => {
                            let data3 = {
                              groupCode: obj.groupCode,
                              groupId: obj.groupId,
                              groupName: obj.groupName,
                              inviteNickName: obj.inviteNickName,
                              inviteUserId: obj.inviteUserId,
                            };
                            groupMemberNotRequireApply(data3).then((res) => {
                              console.log(res);
                              if (res.success == true) {
                                this.$message({
                                  type: "success",
                                  message: `已申请`,
                                });
                                const currentPath = this.$route.path;
                                const pathWithoutParams =
                                    currentPath.split("?")[0];
                                // this.$router.replace(pathWithoutParams)
                                this.$router.push({path: pathWithoutParams});
                                window.location.reload(true);
                              }
                            });
                          },
                        }
                    );
                  }
                });
              } else {
                console.log("失败");
                this.$alert(res.detailMessage, "提示", {
                  confirmButtonText: "确定",
                  callback: () => {
                    this.$message({
                      type: "info",
                      message: `取消加入`,
                    });
                  },
                });
              }
            });
            console.log(this.invite_code);
          }
        }
        if (to.path == "/") {
          this.showfooter = true;
        } else {
          this.showfooter = false;
        }
        if (from.path == to.path) {
          return false;
        } else {
          this.actmenu = to.path;
          // nextTick();
        }
      },
    },
  },

  mounted() {
    // let queryStr = window.location.search;
    // let queryParams = new URLSearchParams(queryStr);
    this.getNow();
    if (this.group) {
      this.menu_show_list = this.group.menuJson
          ? JSON.parse(this.group.menuJson)
          : ["首页", "AI聊天", "AI应用", "AI绘画", "指令广场", "指令市场"];
      if (this.group.menuJson) {
        let arr = [];
        for (let i = 0; i < this.menu_show_list.length; i++) {
          for (let j = 0; j < this.menu.length; j++) {
            if (this.menu_show_list[i] === this.menu[j].name) {
              arr.push(this.menu[j]);
            }
          }
        }
        if (localStorage.getItem("menu") != this.group.id) {
          this.$router.push({path: arr[0].path});
        }
        this.menu = arr;
        localStorage.setItem("menu", this.group.id)
      }
    }
  },
  methods: {
    group_edit(e, q) {
      this.isedit = !this.isedit;
      console.log(e, q);
      this.edit.groupName = e.groupName;
      this.edit.menuList = JSON.parse(e.menuJson);
      this.edit.id = e.id;
      this.edit_role = q;

      // this.edit=e
    },
    getNow() {
    },
    upgroupdata(e) {
      this.$store.dispatch('app/setGroupName', e)
    },
    alerttanchuang() {
      this.tanchuangbox = true;
    },
    handleCloseshowlianxi2() {
      this.tanchuangbox = false;
    },
    gotoMy(e) {
      this.popoverVisible = false
      if (e == 4) {
        this.$router.push({path: "/Group"});
      } else {
        if (e == 3) {
          this.$router.push({path: "/daili"});
        } else {
          this.$router.push({path: "/my"});
        }
      }
    },

    handleCommand(command) {
      if (command == "add") {
        console.log(this.userinfo);
        if (!this.userinfo.userId) {
          this.$message({
            type: "info",
            message: "请登录！"
          });
          return
        }
        this.iscreate = true;
      } else if (command == "new") {
        this.$store.dispatch('app/setGroup', null)
        this.gotoMy();
        window.location.reload(true);
      } else {
        console.log(command)
        this.$store.dispatch('app/setGroup', command)
        // this.$router.push({ path: this.menu[0].path });
        window.location.reload(true);
      }
    },
    addMy() {
      if (this.form.groupName == "") {
        this.$message({
          type: "error",
          message: "企业名称不能为空！",
        });
      } else if (this.form.menuList == []) {
        this.$message({
          type: "error",
          message: "至少选择一个菜单模块！",
        });
      } else {
        newUserGroup(this.form).then((res) => {
          console.log(res);
          if (res.success == true) {
            this.$message({
              type: "success",
              message: "创建成功",
            });
            this.$getLoginInfo();
            this.$forceUpdate();
            this.iscreate = false;
          } else {
            this.$message({
              type: "error",
              message: `创建失败：` + res.detailMessage,
            });
          }
        });
      }
      console.log(this.form);
    },
    editMy() {
      if (this.edit.groupName == "") {
        this.$message({
          type: "error",
          message: "企业名称不能为空！",
        });
      } else if (this.edit.menuList == []) {
        this.$message({
          type: "error",
          message: "至少选择一个菜单模块！",
        });
      } else {
        updateUserGroup(this.edit).then((res) => {
          console.log(res);
          if (res.success == true) {
            this.$message({
              type: "success",
              message: "修改成功",
            });
            this.$getLoginInfo();
            this.$forceUpdate();
            this.isedit = false;
            if (this.group.id == this.edit.id) {
              let data = {
                groupId: this.edit.id,
              };
              getUserGroup(data).then((res) => {
                console.log(res.obj);
                this.$store.dispatch('app/setGroup', res.obj)
                // this.menu=this.edit.menuList
                let arr = [];
                let allmenu = [
                  {
                    path: "/",
                    name: "首页",
                  },
                  {
                    path: "/chat",
                    name: "AI聊天",
                  },
                  {
                    path: "/app",
                    name: "AI应用",
                  },
                  {
                    path: "/draw",
                    name: "AI绘画",
                  },
                  {
                    path: "/square",
                    name: "指令广场",
                  },
                  {
                    path: "",
                    name: "指令商城",
                  },
                ];
                for (let i = 0; i < this.edit.menuList.length; i++) {
                  for (let j = 0; j < allmenu.length; j++) {
                    if (this.edit.menuList[i] === allmenu[j].name) {
                      arr.push(allmenu[j]);
                    }
                  }
                }
                this.menu = arr;
                this.$router.push({path: this.menu[0].path});
              });
            }
          } else {
            this.$message({
              type: "error",
              message: `修改失败：` + res.detailMessage,
            });
          }
        });
      }
      console.log(this.edit);
    },
    cancle() {
      this.form = {
        groupName: "",
        menuList: [],
      };
      this.iscreate = false;
    },
    goChuang(command) {
      console.log("跳转", command);
      if (command == 1) {
        this.$router.push({
          path: "/app", // 目标路由的名称或路径
          query: {
            param: "isGochuang",
          },
        });
      } else if (command == 2) {
        this.$router.push({path: "/chat"});
      } else if (command == 3) {
        this.$router.push({path: "/draw"});
      }
    },
    closeDialog() {
      this.dialogTableVisible = false;
      // this.timeer = null
      clearInterval(this.timeer);
    },
    loginStatusCheckmethod() {
      if (!this.sessionId) {
        return false;
      }
      const data = this.sessionId;
      const that = this;
      loginStatusCheck(data).then((res) => {
        console.log(res);
        if (res.obj) {
          // this.$message({
          //   type: 'success',
          //   message: '登录成功'
          // });
          that.dialogTableVisible = false;
          that.$getLoginInfo();
          localStorage.setItem("token", res.obj);
          store.dispatch("app/token", res.obj);
          console.log(that.timeer);
          // this.udata = localStorage.getItem()
          clearInterval(that.timeer);
          console.log("执行没有", that.userinfo);
          window.location.reload(true);
        }
      });
    },
    generateWechatQRCode() {
      generateWechatQRCode().then((res) => {
        this.scodeimg = res.obj.qrCodeUrl;
        this.sessionId = res.obj.sessionId;
        this.dialogTableVisible = true;
        if (!this.sessionId) {
          return false;
        }
        const that = this;
        console.log("this.sessionId", this.sessionId);
        this.timeer = setInterval(function () {
          that.loginStatusCheckmethod();
        }, 3000);
      });
    },
    loginshow() {
      this.generateWechatQRCode();
    },
    endLogin() {
      localStorage.removeItem("token");
      localStorage.removeItem("groupCon");
      window.location.reload(true);
    },
    goucenter() {
      this.$message({
        type: "info",
        message: "敬请期待",
        duration: 1500,
      });
      return false;
      // this.$router.push({ path: '/profile' })
    },
    gorouter(path) {
      this.popoverVisible = false
      if (path == "/profile") {
        return;
      }
      if (path == "") {
        this.$message({
          type: "info",
          message: "敬请期待",
          duration: 1500,
        });
        return;
      } else {
        this.$router.push({path: path});
      }
    },
    handleCloseNotify() {
      this.$store.dispatch("app/setNotifyOpen", false);
    },
  },
};
</script>

<style lang="less">
@media screen and (max-width: 1300px) {
  .header-nav {
    display: none !important;
  }
}

@media screen and (min-width: 1300px) {
  .header-nav-menu {
    display: none !important;
  }
}

@media screen and (max-width: 670px) {
  .header-nav-energy {
    display: none !important;
  }
}

@media screen and (min-width: 670px) {
  .header-nav-energy-menu {
    display: none !important;
  }
}

@media screen and (max-width: 520px) {
  .header-nav-select-org {
    display: none !important;
  }
}

@media screen and (min-width: 520px) {
  .header-nav-select-org-menu {
    display: none !important;
  }
}

body::-webkit-scrollbar {
  display: none;
}

.dropdown-item {
  position: relative;
}

.more {
  display: none;
}

.dropdown-item:hover .more {
  display: inline-block;
}

.user {
  display: flex;
  align-items: center;

  img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    display: inline-block;
    margin-right: 10px;
  }

  .dailibox {
    font-size: 20 rpx;

    .daili {
      margin-right: 30 rpx;
      display: inline-block;
      border-radius: 6 rpx;
      font-size: 20 rpx;
      padding: 0 10 rpx;
      color: #fff;
      background: #549fff;
    }
  }
}

.popover_top {
  width: 90%;
  height: 80px;
  margin: auto;
  position: relative;

  img {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    position: absolute;
  }

  .name {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #1e2023;
    line-height: 16px;
    position: absolute;
    left: 60px;
    top: 5px;
  }

  .txt1 {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #8b8c8f;
    line-height: 12px;
    position: absolute;
    left: 60px;
    top: 35px;
  }
}

.tui {
  width: 70%;
  height: 50px;
  margin: auto;
  display: flex;
  justify-content: space-between;

  .tui1 {
    width: 40%;
    text-align: center;

    .num {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #1e2023;
      line-height: 20px;
      display: block;
    }

    .shou {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #6d6e70;
      line-height: 14px;
      display: block;
    }
  }
}

.day_neng {
  width: 90%;
  height: 90px;
  background: #eff6f8;
  border-radius: 10px;
  margin: auto;

  .txt {
    width: 80%;
    padding-top: 15px;
    padding-bottom: 20px;
    margin: auto;
    display: flex;
    justify-content: space-between;

    .txt1 {
      font-size: 13px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #1e2023;
      line-height: 18px;
    }

    .txt2 {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #1e2023;
      line-height: 12px;
    }
  }

  .txt_buttom {
    width: 80%;
    padding-top: 10px;
    padding-bottom: 20px;
    margin: auto;
    display: flex;
    justify-content: space-between;

    .txt1 {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #6d6e70;
      line-height: 12px;
    }

    .txt2 {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #6d6e70;
      line-height: 12px;
    }
  }
}

.mai {
  width: 90%;
  height: 62px;
  background: linear-gradient(249deg, #81b0f8 0%, #b1d2f9 56%, #d3e9ff 100%);
  border-radius: 10px;
  margin: auto;
  margin-top: 10px;
  position: relative;

  .txt1 {
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #0a3b70;
    line-height: 14px;
    position: absolute;
    top: 10px;
    left: 20px;
  }

  .txt2 {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #305d8d;
    line-height: 14px;
    position: absolute;
    top: 35px;
    left: 20px;
  }

  .mai_but {
    width: 58px;
    height: 22px;
    background: #0b507d;
    border-radius: 8px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    line-height: 22px;
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

.select_all {
  .select {
    width: 90%;
    height: 53px;
    margin: auto;
    background: #ffffff;
    margin-top: 10px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #666666;
    line-height: 53px;
    padding-left: 20px;
  }

  .select:hover {
    width: 90%;
    height: 53px;
    margin: auto;
    background: #ffffff;
    margin-top: 10px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #1c1f23;
    line-height: 53px;
    padding-left: 20px;
    background: #eff5f8;
    border-radius: 10px;
  }
}

.chuang {
  padding: 10px;
  background: #519eff;
  border-radius: 10px;
  font-size: 20px;
  color: #ffffff;
  margin-right: 20px;
}

#app {
  // width: 1400px;
  margin: 0 auto;
  // padding: 0 40px;
}

.index-box {
  display: flex;
  width: 100%;
  height: 70px;
  flex-direction: row;

  .content {
    width: 100%;
    height: 90%;
    // padding: 30px 0;

    .rightusermsg {
      display: flex;

      .nologin {
        font-size: 16px;
        margin-left: 30px;

        button {
          font-size: 16px;
        }
      }

      .kaitonf {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        cursor: pointer;

        .vip-text {
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 20px;
            margin-right: 10px;
          }
        }
      }

      .udata {
        color: #333;
        margin-left: 10px;
        background: #519eff;
        border-radius: 4px;
      }
    }

    .menu {
      display: flex;

      .menublock {
        margin: 0 20px;
        cursor: pointer;
      }
    }
  }
}

.loginbox {
  border-radius: 16px;
  width: 100%;

  h2 {
    text-align: center;
  }

  div {
    text-align: center;
  }

  .erweia {
    width: 250px;
    height: 250px;
    margin: 0 auto;

    img {
      width: 250px;
      height: 250px;
    }
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

nav {
  background: #ffffff;
  height: 70px;
  border-bottom: 1px solid #ddd;
  position: fixed;
  width: 100%;
  z-index: 99;
}

.navbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: 50px;
  height: 45px;
  float: left;
  margin-left: 10px;
}

img {
  display: block;
  width: 350px;
  height: auto;
  margin-top: 10px;
}

.rightmenu {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #111111;
  line-height: 16px;
  display: flex;
  align-items: center;
  float: right;
  margin-top: 10px;

  .menuitem {
    display: inline-block;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #111111;
    line-height: 16px;
    text-decoration: none;
    margin: 0 18px;
    cursor: pointer;
  }

  .curr {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #519eff !important;
    line-height: 14px;
  }
}

// .rightmenu a:link,
// .rightmenu a:hover,
// .rightmenu a:active,
// .rightmenu a:visited {
//   font-size: 14px;
//   font-family: PingFangSC-Regular, PingFang SC;
//   font-weight: 400;
//   color: #111111;
//   line-height: 16px;
//   text-decoration: none;
//   margin: 0 28px;
// }

// .rightmenu .curr {
//   font-size: 14px;
//   font-family: PingFangSC-Medium, PingFang SC;
//   font-weight: 500;
//   color: #519EFF !important;
//   line-height: 14px;
// }

.maincontent {
  width: 100%;
  margin: 0 auto;
}
</style>
<style scoped>
footer {
}

.footerbottom {
  background: #000000;
  font-size: 11px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #737373;
  text-align: center;
}

.footerbottom span {
  margin: 0 40px;
}

.footertop {
  height: 191px;
  background: #131313;
  background: url(../public/static/img/bottom_black.png);
  background-size: cover;
  color: #eaeaea;
}

.footertop {
  display: flex;
  align-items: center;
}

.footertop .maincontent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footertop .maincontent .left {
}

.footertop .maincontent .left p:nth-child(1) {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}

.footertop .maincontent .left p:nth-child(2) {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #eaeaea;
}

.footertop .maincontent .left p:nth-child(3) {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}

.footertop .maincontent .center {
}

.footertop .maincontent .center p {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #c3c3c3;
}

.footertop .maincontent .right {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.footertop .maincontent .right p {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #cdcdcd;
  font-size: 12px;
  margin-top: 4px;
}

.bottomlinks {
  background: #292c35;
  height: 566px;
  display: flex;
  align-items: center;
}

.linksbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rightlink h2 {
  font-size: 33px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 46px;
  margin-bottom: 17px;
}

.rightlink {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #dfe0e1;
  line-height: 22px;
}

.leftlink {
  width: 516px;
}

.rowlink {
  width: 100%;
  display: flex;
  justify-items: flex-start;
}

.rowlink:nth-child(2n + 2) {
  display: flex;
  justify-items: flex-end;
  justify-content: flex-end;
}

.rowlink a {
  width: 197px;
  height: 83px;
  background: #323642;
  border-radius: 13px;
  display: flex;
  align-items: center;
  justify-items: center;
  margin: 24px 24px 0 0;
}

.rowlink img {
  width: 143px;
  height: 46px;
  margin: 0 auto;
}

.rowlink {
  display: flex;
}

.partnerbox {
  height: 1069px;
  background: #f9f9f9;
  padding: 160px 0 90px;
}

.pmainbox h2 {
  display: block;
  font-size: 26px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #292929;
  line-height: 32px;
  margin-bottom: 50px;
}

.partnertopbox {
  background-color: #ffffff;
  display: flex;
}

.pleftimg {
  width: 462px;
  height: 348px;
  overflow: hidden;
  background: url(./assets/pig.png) no-repeat center;
  background-size: cover;
}

.prightdes {
  width: calc(100% - 462px);
  padding-left: 57px;
}

/* .udata {
  display: inline-block;
  width: 75px;
  height: 28px;
  color: #333;
  margin-left: 10px;
  background: #519EFF;
  border-radius: 4px;
  color: #ffffff;
} */
#app {
  background-color: #f6f8fa;
}

.v-modal {
  z-index: 9999;
}

.el-dialog__wrapper {
  z-index: 999999;
}

.el-dialog {
  border-radius: 12px !important;
}
</style>
<style>
::v-deep .v-modal {
  z-index: 9999;
}

::v-deep .el-dialog__wrapper {
  z-index: 999999;
}

.el-dialog {
  border-radius: 12px !important;
}
</style>
