<template>
  <div
    class="searchinputbox"
    ref="searchInput"
    v-loading="loading2"
    element-loading-text="正在切换中..."
  >
    <div
      style="
        margin: 0 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
      "
    >
      <div
        style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        "
      >
        <div style="display: flex">
          <el-select
            v-model="searchChannel1"
            style="width: 300px"
            placeholder="选择平台"
            @change="select2()"
          >
            <el-option
              v-for="(item, index) in searchChannelList"
              :key="index"
              :label="item.channelName"
              :value="item.channelNameLevel"
              :disabled="item.disabled"
            >
              <span>{{ item.channelDesc }}</span
              ><span class="small"> {{ item.desc }}</span></el-option
            >
          </el-select>
          <div
            class="change"
            style="
              display: flex;
              align-items: center;
              line-height: 30px;
              margin-left: 40px;
              cursor: default;
            "
            v-if="isgroup"
          >
            <el-checkbox
              label="挂载领域知识模型"
              name="type"
              style="transform: scale(1.4); margin-left: 10px"
              v-model="checked"
              @change="changeType"
            ></el-checkbox>
          </div>
        </div>

        <div class="right">
          <span class="daochu" @click="daochu" v-if="!isgroup">
            <i class="el-icon-upload2"></i>导出
          </span>
          <el-select
            v-model="searchForm1.tempParam"
            placeholder="选择模式"
            @change="select()"
          >
            <el-option
              v-for="(item, index) in tempParams"
              :key="index"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </div>
      </div>

      <div class="searchinputboxtextarea">
        <el-input
          type="textarea"
          :rows="2"
          :autosize="{ minRows: 2, maxRows: 4 }"
          @keydown.native="handleKeyCode($event)"
          @focus="handleSearchInputFocus"
          placeholder="请输入你的问题（回车键发送，ctrl+回车键换行）"
          v-model="searchForm.question"
        />

        <span v-if="!loading" class="default-text" @click="handleSearch"
          ><img
            src="https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/static/all/fasong.png"
            alt=""
        /></span>
        <span v-else class="default-text" @click="handleSearch"
          ><img src="../assets/stop.png" alt=""
        /></span>
        <!-- <span v-if="!loading" class="default-text">发送 <img src="https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/static/all/fasong.png" alt="" /></span>
          <span v-else class="default-text">停止<img src="../assets/stop.png" alt="" /></span> -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { getUserGroup } from "@/api";

export default {
  name: "SearchInput",
  props: {
    chatid: {
      type: Number,
      default: null,
    },
    inputvalue: {
      type: String,
      default: "",
    },
    from: {
      type: String,
      default: "chat",
    },
  },
  computed: {
    ...mapGetters(["userinfo", "channelList"]),
    memberCard() {
      let r = null;
      if (this.userinfo.cardList && this.userinfo.cardList.length > 0) {
        this.userinfo.cardList.forEach((i) => {
          if (i.cardType === "Member") {
            r = i;
          }
        });
      }
      return r;
    },

    // searchChannelList() {
    //   const list = []
    //   this.channelList.forEach(i => {
    //     if (!i.disabled) {
    //       if (this.from === 'chat') {
    //         if (i.type === 'chat') {
    //           list.push(i)

    //         }
    //       } else if (this.from === 'draw') {
    //         if (i.type === 'draw') {
    //           list.push(i)
    //         }
    //       }
    //     }
    //   })
    //   return list
    // }
  },
  watch: {
    localStorageValue(newValue) {
      // 处理localStorageValue变化的逻辑
      console.log('localStorageValue变化了:', newValue);
      if (newValue=='WITHIN') {
        this.checked = true;
      }else{
        this.checked = false;
      }
    }
  },
  created() {
    // 监听localStorage的变化
    window.addEventListener('storage', () => {
      this.localStorageValue = localStorage.getItem('groupDataType');
    });
  },
  data() {
    return {
      loading2: false,
      index: 0,
      isgroupFileJson: null,
      localStorageValue: localStorage.getItem('groupDataType'),
      searchChannelList: "",
      groupDataType: "GENERAL",
      checked: false,
      isgroup: null,
      loading: false,
      searchChannel: "",
      searchChannel1: "",
      searchForm1: {
        question: "",
        tempParam: { name: "平衡", code: "balanced", subname: "适合综合科类" },
      },
      searchForm: {
        question: "",
        tempParam: { name: "平衡", code: "balanced", subname: "适合综合科类" },
      },
      showSelectTempParam: false,
      searchChannel2: null,
      tempParams: [
        { name: "精确", code: "precise", subname: "适合理工科类" },
        { name: "创意", code: "creative", subname: "适合文史科类" },
        { name: "平衡", code: "balanced", subname: "适合综合科类" },
      ],
    };
  },
  beforeDestroy() {
    this.$eventBus.$off(this.from + "example");
    this.$eventBus.$off(this.from + "searchDone");
  },
  mounted() {
    localStorage.removeItem("groupDataType");
    this.isgroup = JSON.parse(localStorage.getItem("groupCon"));
    let newarr = [];
    const ChannelList = JSON.parse(localStorage.getItem("ChannelList"));

    if (ChannelList) {
      ChannelList.forEach((i) => {
        if (!i.disabled) {
          if (i.type === "chat") {
            newarr.push(i);
          }
        }
      });
      this.searchChannelList = newarr;
      this.searchChannel = newarr[0].channelName;
      this.searchChannel2 = newarr[0];
      this.searchChannel1 = newarr[0].channelDesc + newarr[0].desc;
    }

    // console.log(newarr)

    this.$eventBus.$on(this.from + "example", (example) => {
      this.searchForm.question = example;
    });

    this.$eventBus.$on(this.from + "searchDone", () => {
      this.searchForm.question = "";
      this.loading = false;
    });
    if (this.inputvalue) {
      this.searchForm.question = this.inputvalue;
    }
  },
  methods: {
    handleKeyCode(event) {
      if (event.keyCode == 13) {
        if (event.ctrlKey) {
          this.searchForm.question = this.searchForm.question + "\n";
        } else {
          console.log(event.metaKey);
          event.preventDefault();
          this.handleSearch();
        }
      }
    },
    isStop() {
      this.loading = false;
    },
    daochu() {
      // 点击了导出
      this.$parent.dcfatherMethod();
    },
    select() {
      const result = this.tempParams.filter(
        (item) => item.code === this.searchForm1.tempParam
      );
      this.searchForm.tempParam = result;
      console.log(this.searchForm.tempParam);
    },
    select2() {
      const result2 = this.searchChannelList.filter(
        (item) => item.channelNameLevel === this.searchChannel1
      )[0];
      console.log(this.searchChannel1);
      this.searchChannel1 = result2.channelDesc + result2.desc;
      this.searchChannel = result2;
      this.searchChannel2 = result2;
    },
    handleSearch() {
      if (!this.loading) {
        if (this.searchChannel === "") {
          this.$notify({
            title: "警告",
            message: "请选择平台",
            type: "warning",
          });
          return;
        }

        if (this.searchForm.question === "") {
          this.$message("请输入你的问题");
          return;
        }

        this.loading = true;
        const param = {
          question: this.searchForm.question,
          tempParam: this.searchForm1.tempParam,
          searchChannel: this.searchChannel2,
        };
        this.$eventBus.$emit(this.from + "search", param);
      } else {
        this.$eventBus.$emit(this.from + "stop");
      }
    },
    input_stop() {
      this.$eventBus.$emit(this.from + "stop");
    },
    handleShowClearChat() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确认删除聊天记录",
        })
        .then(() => {
          localStorage.setItem("conversationList", "");
          this.$eventBus.$emit(this.from + "clearChat");
        })
        .catch(() => {});
    },
    handleShowSelectTempParam() {
      this.showSelectTempParam = true;
    },
    handleTempParamClick(param) {
      this.searchForm.tempParam = param;
      this.showSelectTempParam = false;
    },
    handleSearchInputFocus() {
      // setTimeout(() => {
      //   let scrollElem = this.$refs.searchInput
      //   if (scrollElem) {
      //     scrollElem.scrollIntoView()
      //     document.body.scrollTop = document.body.scrollHeight
      //   }
      // }, 100)
    },
    changeType() {
      this.checked = false;
      if (this.groupDataType == "GENERAL") {
        let data = {
          groupId: JSON.parse(localStorage.getItem("groupCon")).id,
        };
        let user =this.userinfo;
        for (let i = 0; i < user.userGroupList.length; i++) {
          if (
            JSON.parse(localStorage.getItem("groupCon")).id ==
            user.userGroupList[i].groupId
          ) {
            this.index = i;
          }
        }
        getUserGroup(data).then((res) => {
          console.log(res.obj);
          this.isgroupFileJson = res.obj.groupFileJson;
          if (this.isgroupFileJson != null) {
            this.$emit("shuaxing");
            this.loading2 = true;
            localStorage.setItem("groupDataType", "WITHIN");
            setTimeout(() => {
              this.loading2 = false;
              this.groupDataType = "WITHIN";
            }, 1000);
            this.checked = true;
            this.searchForm1 = {
              question: "",
              tempParam: {
                name: "精确",
                code: "precise",
                subname: "适合理工科类",
              },
            };
            this.tempParams = [
              { name: "创意", code: "creative", subname: "适合文史科类" },
              { name: "平衡", code: "balanced", subname: "适合综合科类" },
              { name: "精确", code: "precise", subname: "适合理工科类" },
            ];
            console.log(this.searchForm1);
          } else {
            localStorage.removeItem("groupDataType");
            if (user.userGroupList[this.index].userRole == "ORDINARY") {
              this.$dialog
                .confirm({
                  title: "提示",
                  message: "当前企业暂未上传领域知识库，请联系管理员或专家上传",
                })
                .then(() => {})
                .catch(() => {});
            } else {
              this.$dialog
                .confirm({
                  title: "提示",
                  message: "当前企业暂未上传领域知识库，请前往上传",
                })
                .then(() => {
                  this.$router.push({ path: "/my?soat=" + 4 });
                })
                .catch(() => {});
            }
          }
        });
      } else {
        this.$emit("shuaxing");
        this.loading2 = true;
        // localStorage.removeItem("groupDataType");
        localStorage.setItem("groupDataType", "GENERAL");
        setTimeout(() => {
          this.groupDataType = "GENERAL";
          this.loading2 = false;
        }, 1000);
        this.checked = false;
        this.searchForm = {
          question: "",
          tempParam: {
            name: "平衡",
            code: "balanced",
            subname: "适合综合科类",
          },
        };
        this.tempParams = [
          { name: "精确", code: "precise", subname: "适合理工科类" },
          { name: "创意", code: "creative", subname: "适合文史科类" },
          { name: "平衡", code: "balanced", subname: "适合综合科类" },
        ];
      }
    },
  },
};
</script>
<style lang="less" >
.searchinputbox {
  background: #ffffff;
  padding: 20px 40px;
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
}
.searchinputboxtextarea {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  position: relative;
  img {
    display: inline-block;
    width: 40px;
    height: 40px;
  }
  .el-textarea {
    width: 95%;
  }
  .default-text {
    color: #333;
    position: absolute;
    cursor: pointer;
    right: 0;
  }
}
.small {
  font-size: 12px;
}
.daochu {
  justify-self: flex-end;
  margin-right: 20px;
  cursor: pointer;
}
</style>
