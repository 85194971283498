<template>
    <div>
      <div class="conversation" v-if="content.type == 'question'">
        <div class="left">
          <div
            class="default-text"
            style="display: flex; flex-direction: row; align-items: center"
          >
            <div
              v-if="!content.open"
              style="display: flex; flex-direction: row; align-items: center"
            >
              <span class="default-text">思考中</span>
              <div class="load default-text"></div>
            </div>
            <div class="content" v-else>
              <div>{{ content.text }}</div>
              <div class="copy" @click="handleCopy(content.text)">
                <img
                  src="https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/static/all/fuzhi.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div class="headimg">
          <img :src="userinfo.headimgurl" alt="" />
        </div>
      </div>
      <div class="answer" v-if="content.type != 'question'">
        <div class="headimg">
          <img
            src="https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/logo/apple/60.png"
            alt=""
          />
        </div>
        <div class="right">
          <div
            class="default-text"
            style="display: flex; flex-direction: row; align-items: center"
          >
            <div
              v-if="!content.open"
              style="display: flex; flex-direction: row; align-items: center"
            >
              <span class="default-text">思考中</span>
              <div class="load default-text"></div>
            </div>
            <div class="ccontent" v-else>
              <MarkdownPreview
                theme="oneDark"
                :copyCode="true"
                :anchorAttributes="{ target: '_blank' }"
                :initialValue="
                  content.text + (content.loading ? loadingSpan : '')
                "
              >
              </MarkdownPreview>
              <div class="aniu-btn">
                <img
                  class="btn-img"
                  @click="handleCopy(content.text)"
                  src="https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/static/all/fuzhi.png"
                  alt=""
                />
                <img
                  class="btn-img"
                  v-if="
                    content.answerLikeTag == 'EMPTY' ||
                    content.answerLikeTag == 'NO'
                  "
                  @click="(content.answerLikeTag = 'YES'), niceORlose(content,'YES')"
                  src="https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/static/all/zan.png"
                  alt=""
                />
                <img
                  class="btn-img"
                  v-if="content.answerLikeTag == 'YES'"
                  @click="
                    (content.answerLikeTag = 'EMPTY'), niceORlose(content,'EMPTY')
                  "
                  src="https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/static/all/zan1.png"
                  alt=""
                />
                <img
                  class="btn-img"
                  v-if="
                    content.answerLikeTag == 'EMPTY' ||
                    content.answerLikeTag == 'YES'
                  "
                  @click="(content.answerLikeTag = 'NO'), niceORlose(content,'NO')"
                  src="https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/static/all/cai.png"
                  alt=""
                />
                <img
                  class="btn-img"
                  v-if="content.answerLikeTag == 'NO'"
                  @click="
                    (content.answerLikeTag = 'EMPTY'), niceORlose(content,'EMPTY')
                  "
                  src="https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/static/all/cai1.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import VueMarkdown from "vue-markdown";
  import { MarkdownPreview } from "vue-meditor";
  import { mapGetters } from "vuex";
  import { updateChatLog } from "@/api";
  export default {
    name: "ConversationContent",
    components: {
      VueMarkdown,
      MarkdownPreview,
    },
    props: {
      chatId: {
        type: String,
        default: "",
      },
      content: {
        type: Object,
        default() {
          return {
            type: "question",
            icon: "",
            text: "",
            loading: false,
          };
        },
      },
    },
    computed: {
      ...mapGetters(["clientWidth","userinfo"]),
      className() {
        if (this.content.type === "question") {
          return "conversation";
        }
        return "conversation answer";
      },
      width() {
        return this.clientWidth - 100;
      },
    },
    data() {
      return {
        loadingSpan:
          '<span class="typed-cursor" style="margin-left: 0.3rem; color: #fff;">▋</span>',
      };
    },
    mounted() {
    },
    created() {},
    methods: {
      niceORlose(e,a) {
        let data = {
        answerLikeTag: a, // 答案点赞标记 YES NO
        groupDataType:'WITHIN',
        id: e.id?e.id:localStorage.getItem('newChatLog'), // 单条记录的id
        groupId: JSON.parse(localStorage.getItem("groupCon"))?JSON.parse(localStorage.getItem("groupCon")).id:null,
      };
        console.log(data);
        updateChatLog(data).then((res) => {
          console.log(res);
        });
      },
      caimethod(e) {
        console.log(e);
      },
      handleCopy(content) {
        this.$copyText(content)
          .then(() => {
            this.$message({
              type: "success",
              message: `复制成功`,
              duration: 1000,
            });
          })
          .catch((err) => {
            console.error("copy", err);
          });
      },
    },
  };
  </script>
  <style lang="less" scoped>
  body{
    font-size: 14px;
  }
  .conversation {
    color: #333;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 30px;
    font-size: 18px;
  
    .content {
      background-color: #378efc;
      padding: 15px;
      color: #ffffff !important;
      border-radius: 8px;
  
      .copy {
        text-align: left;
        margin-top: 10px;
  
        img {
          width: 30px;
          height: 30px;
        }
      }
    }
  
    .headimg {
      margin-left: 20px;
  
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
    }
  
    .markdown-body {
      color: #333;
    }
  }
  
  .default-text {
    color: #333;
  }
  
  .markdown-body p {
    color: #333 !important;
  }
  
  .aniu-btn {
    display: block;
    img {
      display: inline-block;
      width: 30px;
      height: 30px;
      margin: 0 10px;
    }
  }
  
  .answer {
    padding: 30px;
    color: #333;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  
    .ccontent {
      padding: 10px;
      border-radius: 8px;
      // background-color: #eff1f8;
      border: 1px solid #d4d4d4;
      max-width: 65vw;
    }
  
    .markdown-preview {
      background: #eff1f8 !important;
    }
  
    .headimg {
      margin-right: 20px;
  
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
    }
  
    .default-text {
      color: #333;
    }
  }
  </style>
  