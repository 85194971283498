<template>
    <div
      class="searchinputbox"
      ref="searchInput"
    >
      <div
        style="
          margin: 0 0.5rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
        "
      >
        <div class="searchinputboxtextarea">
          <el-input
            type="textarea"
            :rows="2"
            :autosize="{ minRows: 2, maxRows: 4 }"
            @keydown.native="handleKeyCode($event)"
            @focus="handleSearchInputFocus"
            placeholder="请输入你的问题（回车键发送，ctrl+回车键换行）"
            v-model="searchForm.question"
          />
  
          <span v-if="!loading" class="default-text" @click="handleSearch2"
            ><img
              src="https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/static/all/fasong.png"
              alt=""
          /></span>
          <span v-else class="default-text" @click="handleSearch2"
            ><img src="../assets/stop.png" alt=""
          /></span>
        </div>
      </div>
    </div>
  </template>
  <script>
  import { mapGetters } from "vuex";
  
  export default {
    name: "SearchInput2",
    props: {
      chatid: {
        type: Number,
        default: null,
      },
      inputvalue: {
        type: String,
        default: "",
      },
      from: {
        type: String,
        default: "chat",
      },
    },
    computed: {
      ...mapGetters(["userinfo", "channelList"]),
      memberCard() {
        let r = null;
        if (this.userinfo.cardList && this.userinfo.cardList.length > 0) {
          this.userinfo.cardList.forEach((i) => {
            if (i.cardType === "Member") {
              r = i;
            }
          });
        }
        return r;
      },
  
    },
    watch: {
     
    },
    data() {
      return {
        loading2: false,
        index: 0,
        isgroupFileJson: null,
        searchChannelList: "",
        groupDataType: "GENERAL",
        checked: false,
        isgroup: null,
        loading: false,
        searchChannel: "",
        searchChannel1: "",
        searchForm1: {
          question: "",
          tempParam:{ name: "精确", code: "precise", subname: "适合理工科类" },
        },
        searchForm: {
          question: "",
          tempParam:{ name: "精确", code: "precise", subname: "适合理工科类" },
        },
        showSelectTempParam: false,
        searchChannel2: null,
        tempParams: [
          { name: "精确", code: "precise", subname: "适合理工科类" },
          { name: "创意", code: "creative", subname: "适合文史科类" },
          { name: "平衡", code: "balanced", subname: "适合综合科类" },
        ],
      };
    },
    beforeDestroy() {
      this.$eventBus.$off(this.from + "example2");
      this.$eventBus.$off(this.from + "searchDone");
    },
    mounted() {
      localStorage.removeItem("groupDataType");
      this.isgroup = JSON.parse(localStorage.getItem("groupCon"));
      let newarr = [];
      const ChannelList = JSON.parse(localStorage.getItem("ChannelList"));
      console.log("ChannelList", ChannelList);
      ChannelList.forEach((i) => {
        if (!i.disabled) {
          if (i.type === "chat") {
            newarr.push(i);
          }
        }
      });
      // console.log(newarr)
      this.searchChannelList = newarr;
      this.searchChannel = newarr[0].channelName;
      this.searchChannel2 = newarr[0];
      this.searchChannel1 = newarr[0].channelDesc + newarr[0].desc;
  
      this.$eventBus.$on(this.from + "example2", (example) => {
        this.searchForm.question = example;
      });
  
      this.$eventBus.$on(this.from + "searchDone", () => {
        this.searchForm.question = "";
        this.loading = false;
      });
      if (this.inputvalue) {
        this.searchForm.question = this.inputvalue;
      }
    },
    methods: {
      handleKeyCode(event) {
        if (event.keyCode == 13) {
          if (event.ctrlKey) {
            this.searchForm.question = this.searchForm.question + "\n";
          } else {
            console.log(event.metaKey);
            event.preventDefault();
            this.handleSearch2();
          }
        }
      },
      isStop() {
        this.loading = false;
      },
      select() {
        const result = this.tempParams.filter(
          (item) => item.code === this.searchForm1.tempParam
        );
        this.searchForm.tempParam = result;
        console.log(this.searchForm.tempParam);
      },
      select2() {
        const result2 = this.searchChannelList.filter(
          (item) => item.channelNameLevel === this.searchChannel1
        )[0];
        console.log(this.searchChannel1);
        this.searchChannel1 = result2.channelDesc + result2.desc;
        this.searchChannel = result2;
        this.searchChannel2 = result2;
      },
      handleSearch2() {
        if (!this.loading) {
          if (this.searchChannel === "") {
            this.$notify({
              title: "警告",
              message: "请选择平台",
              type: "warning",
            });
            return;
          }
  
          if (this.searchForm.question === "") {
            this.$message("请输入你的问题");
            return;
          }
  
          this.loading = true;
          const param = {
            question: this.searchForm.question,
            tempParam: this.searchForm.tempParam,
            searchChannel: this.searchChannel2,
          };
          this.$eventBus.$emit(this.from + "search2", param);
        } else {
          this.$eventBus.$emit(this.from + "stop2");
        }
      },
      input_stop() {
        this.$eventBus.$emit(this.from + "stop2");
      },
      handleShowClearChat() {
        this.$dialog
          .confirm({
            title: "提示",
            message: "确认删除聊天记录",
          })
          .then(() => {
            localStorage.setItem("conversationList", "");
            this.$eventBus.$emit(this.from + "clearChat2");
          })
          .catch(() => {});
      },
      handleShowSelectTempParam() {
        this.showSelectTempParam = true;
      },
      handleTempParamClick(param) {
        this.searchForm.tempParam = param;
        this.showSelectTempParam = false;
      },
      handleSearchInputFocus() {
        // setTimeout(() => {
        //   let scrollElem = this.$refs.searchInput
        //   if (scrollElem) {
        //     scrollElem.scrollIntoView()
        //     document.body.scrollTop = document.body.scrollHeight
        //   }
        // }, 100)
      }
    },
  };
  </script>
  <style lang="less" scoped>
  .searchinputbox {
    background: #ffffff;
    padding: 20px 40px;
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
  }
  .searchinputboxtextarea {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    position: relative;
    img {
      display: inline-block;
      width: 40px;
      height: 40px;
    }
    .el-textarea {
      width: 85%;
    }
    .default-text {
      color: #333;
      position: absolute;
      cursor: pointer;
      right: 0;
    }
  }
  .small {
    font-size: 12px;
  }
  .daochu {
    justify-self: flex-end;
    margin-right: 20px;
    cursor: pointer;
  }
  </style>
  