<template>
  <div class="index" style="min-height: calc(100vh - 92px)">
    <div
      class="chat-panel"
      :style="`height: ${height}px;overflow-y: auto;overflow-x: hidden;`"
    >
      <div
        :style="`height: ${
          height + 30
        }px;overflow-y: auto;overflow-x: hidden;padding-bottom: 60px`"
      >
        <div ref="qaContent" v-if="conversationList.length > 0">
          <Conversation2
            v-for="(item, index) in conversationList"
            :key="index"
            :conversation="item"
          ></Conversation2>
          <div
            id="conversationListEnd"
            style="height: 0px; overflow: hidden"
          ></div>
        </div>
        <QuestionExamples2 v-else></QuestionExamples2>
      </div>
      <SearchInput2
        :chatid="chatid"
        :inputvalue="inputvalue"
        ref="childRef"
      ></SearchInput2>

      <van-dialog
        v-model="showImgDialog"
        :title="showImgTitle"
        @close="handleShowImgClose"
      >
        <img style="width: 100%" :src="showImgUrl" />
      </van-dialog>
    </div>
  </div>
</template>
  <script>
import Conversation2 from "@/components/kefuSation.vue";
import SearchInput2 from "@/components/kefuSearch.vue";
import QuestionExamples2 from "@/components/kefuQuestion.vue";

// import { tickets, answer, getSysTagList, getRandomSysTag, getChatList, getChatLogByChatId, updateChat } from '@/api'
import {
  tickets,
  answer,
  getChatLogByChatId,
  updateChat,
  getCategoryList,
  getThreeamaoAiGroupId,
} from "@/api";
import chatgpt from "@/utils/chatgpt";
import bing from "@/utils/bing";
import stabilityai from "@/utils/stabilityai";
import { mapGetters } from "vuex";
export default {
  name: "chat",
  components: {
    Conversation2,
    SearchInput2,
    QuestionExamples2,
  },
  computed: {
    ...mapGetters([
      "notifyOpen",
      "userinfo",
      "clientHeight",
      "shared",
      "askCount",
      "askLimit",
      "channelList",
    ]),
    height() {
      let h = this.clientHeight - 160;
      if (this.notifyOpen) {
        h -= 40;
      }
      return h;
    },
    memberCard() {
      let r = null;
      if (this.userinfo.cardList && this.userinfo.cardList.length > 0) {
        this.userinfo.cardList.forEach((i) => {
          if (i.cardType === "Member") {
            r = i;
          }
        });
      }
      return r;
    },
  },
  data() {
    return {
      questionParam: null,
      bugou: false,
      form: {
        name: "",
        desc: "",
      },
      conversationList: [],
      checkList: [],
      bigoptions: [],
      suboptions: [],
      tagvalue: [],
      currdaochu: 1,
      exportChannel: null,
      historyList: [],
      chatid: null,
      pushStatus: null,
      subcatevalue: null,
      subcatevalueid: null,
      bigcatevalue: null,
      cateoptions: [],
      tagsoptions: [],
      chatLevel: null,
      daochudrawer: false,
      searchChannelList: null,
      total: 0,
      pages: {
        page: 1,
        size: 10,
      },
      dialogTableVisiblebugou: false,
      examples: [],
      showImgDialog: false,
      value: "",
      showImgUrl: "",
      cateid: "",
      showImgTitle: "",
      showSelectSearchChannel: false,
      sysTagList: [],
      bingConversation: null,
      inputvalue: "",
      groupDataType: "",
      kefu_id: "",
      newChatLog: [],
    };
  },
  watch: {},
  beforeDestroy() {
    this.$eventBus.$off("chatsearch");
    this.$eventBus.$off("chatstop");
    this.$eventBus.$off("cahtclearChat");
  },
  created() {
    let newarr = [];
    const ChannelList = JSON.parse(localStorage.getItem("ChannelList"));
    console.log("ChannelList", ChannelList);
    ChannelList.forEach((i) => {
      if (!i.disabled) {
        if (i.type === "chat") {
          newarr.push(i);
        }
      }
    });
    // console.log(newarr)
    this.searchChannelList = newarr;
    // 看看有没有值
    if (this.$route.query.param) {
      const paramValue = this.$route.query.param
        ? JSON.parse(this.$route.query.param)
        : "";
      console.log("paramValue", paramValue);
      const aa = paramValue.map(function (obj) {
        return obj.question;
      });
      // console.log(aa)
      this.inputvalue = aa.join(",");
      // this.$eventBus.$emit(this.from + 'example', paramValue[0].question)
    }
  },
  mounted() {
    let data = { id: 0 };
    getThreeamaoAiGroupId(data).then((res) => {
      console.log(res.obj);
      this.kefu_id = res.obj.id;
    });
    // const str = localStorage.getItem('conversationList')
    // if (str && str !== '') {
    //   this.conversationList = JSON.parse(str)
    // }

    this.$eventBus.$on("chat" + "search2", (param) => {
      this.handleSearch2(param);
    });
    this.$eventBus.$on("chat" + "stop2", () => {
      this.handleStop2();
    });
    this.$eventBus.$on("chat" + "clearChat2", () => {
      this.conversationList = [];
    });
  },
  activated() {
    this.scrollToBottom();
    // 看看有没有值
    if (this.$route.query.param) {
      const paramValue = this.$route.query.param
        ? JSON.parse(this.$route.query.param)
        : "";
      console.log("paramValue", paramValue);
      const aa = paramValue.map(function (obj) {
        return obj.question;
      });
      // console.log(aa)
      this.inputvalue = aa.join(",");
      // this.$eventBus.$emit(this.from + 'example', paramValue[0].question)
    }
  },
  methods: {
    cancle() {
      this.daochudrawer = false;
    },
    bigcatechange(e) {
      console.log(e);
      this.cateid = e;
      this.subcatevalue = null;
      this.getCategoryListsub();
    },
    subchange(e) {
      const result = this.suboptions.filter((item) => item.id === e);
      // console.log('result',result)

      this.subcatevalue = result[0].name;
    },
    tagchange(e) {
      const result = e.map((tagName) => {
        let item = this.tagList.find((i) => i.tagName === tagName);
        if (item) {
          return item;
        } else {
          return { tagName: tagName, tagType: null };
        }
      });

      // console.log('result', result)
      // console.log('e', e)
      // sysTagList
      this.sysTagList = result;
    },
    // 获取分类数据
    getCategoryListsub() {
      let data = {
        id: this.cateid,
      };
      getCategoryList(data).then((res) => {
        this.suboptions = res.obj;
      });
    },
    getCategoryList() {
      let data = {
        id: "",
      };
      getCategoryList(data).then((res) => {
        this.bigoptions = res.obj;
      });
    },
    nextdaochu(dt) {
      this.exportChannel = dt;
      this.currdaochu += 1;
    },
    dcfatherMethod() {
      console.log("导出父组件方法");
      // const isdaochu = this.pushStatus
      // if (isdaochu != null) {
      //   this.$message({
      //     type: 'error',
      //     message: '已被导出，请勿重新操作'
      //   });
      //   return false
      // }
      this.daochudrawer = true;

      if (this.conversationList.length < 1) {
        this.$message({
          type: "error",
          message: "当前没有内容可导出",
          duration: 1500,
        });
        this.daochudrawer = false;
      }
    },
    // 新绘画
    newchat() {
      this.$refs.childRef.input_stop();
      this.conversationList = [];
      this.chatid = null;
    },
    updateChat(datas) {
      updateChat(datas).then((res) => {
        console.log("删除成功", res);
        this.$message({
          type: "success",
          message: "删除成功",
          duration: 1500,
        });
      });
    },
    // 删除单条方法
    deleteDialog(id, ids) {
      this.historyList.splice(id, 1);
      let datas = {
        chatId: ids,
        chatDeleteTag: "YES",
      };
      this.updateChat(datas);
    },
    // 根据id查历史
    gothis(item) {
      this.pushStatus = item.pushStatus;
      let data = {
        chatId: item.id, // 单条记录的id
        groupId: this.isgroup ? this.isgroup.id : null,
      };
      this.chatid = item.id;
      getChatLogByChatId(data).then((res) => {
        this.conversationList = res.obj.records;
      });
    },

    scrollToBottom() {
      let end = document.getElementById("conversationListEnd");
      if (end) {
        end.scrollIntoView();
      }
    },
    handleShowImgClose() {
      this.$store.dispatch("app/setShared", true);
    },
    async getAnswerV3(apiKey, serverUrl, maxTokens, param, params) {
      // console.log('param',param)
      // console.log('params',params)
      if (this.userinfo.userNo === "1760168472489234433") {
        console.log("test");
      }
      if (
        param.searchChannel.channelName === "ChatGPT3" ||
        param.searchChannel.channelName === "ChatGPT4" ||
        param.searchChannel.channelName === "XfSpark"
      ) {
        let temperature = 1;
        if (param.tempParam.code === "creative") {
          temperature = 1.2;
        } else if (param.tempParam.code === "balanced") {
          temperature = 1;
        } else if (param.tempParam.code === "precise") {
          temperature = 0.7;
        }
        //apiKey, serverUrl, maxTokens, temperature, params
        param.client = chatgpt.chatgpt3(
          apiKey,
          serverUrl,
          maxTokens,
          temperature,
          params
        );
      } else if (param.searchChannel.channelName === "Bing") {
        param.client = await bing.chat(param.tempParam.code, params);
      } else if (param.searchChannel.channelName === "Stabilityai") {
        param.client = stabilityai.join("", param);
      }
      console.log("这个是拿到的param.client", param.client);
      console.log("这个是拿到的param", param);
    },
    handleStop2() {
      this.conversationList[
        this.conversationList.length - 1
      ].messages[1].open = true;
      this.conversationList[
        this.conversationList.length - 1
      ].messages[1].loading = false;
      this.$eventBus.$emit("chat" + "searchDone");
      if (this.questionParam.client) {
        this.questionParam.client.close();
      }
    },
    handleSearch2(param) {
      if (!localStorage.getItem("token")) {
        this.$message({
          type: "error",
          message: "请先登录",
          duration: 1500,
        });
        return false;
      }
      // console.log("channelList", this.searchChannelList);
      // console.log("param.searchChannel", param.searchChannel.channelName);
      // console.log(
      //   "this.searchChannelList.filter(item => item.channelName === param.searchChannel.channelName)[0]",
      //   this.searchChannelList.filter(
      //     (item) => item.channelName === param.searchChannel.channelName
      //   )[0]
      // );
      let params = param;
      // console.log(params)
      params.chatChannelEnum = "ChatGPT4";
      params.chatLevel = param.searchChannel.chatLevel;
      params.groupDataType = this.groupDataType;
      params.kefu = this.kefu_id;
      params.chatId = this.chatid;
      console.log(params);
      this.questionParam = params;
      this.questionParam.onEvent = (param) => {
        switch (param.type) {
          case "OPEN":
            break;
          case "UPDATE":
            this.conversationList[
              this.conversationList.length - 1
            ].messages[1].open = true;
            this.conversationList[
              this.conversationList.length - 1
            ].messages[1].loading = true;
            this.conversationList[
              this.conversationList.length - 1
            ].messages[1].text = param.content;
            this.scrollToBottom();
            if (param.tips == "stop") {
              let data = {
                chatId: param.chatId, // 单条记录的id
                groupId: this.isgroup ? this.isgroup.id : null,
              };
              this.chatid = param.chatId;
              getChatLogByChatId(data).then((res) => {
                console.log(res.obj.records);
                this.newChatLog = res.obj.records;
                console.log(
                  this.newChatLog[this.newChatLog.length - 1].messages[1].id
                );
                localStorage.setItem('newChatLog',this.newChatLog[this.newChatLog.length - 1].messages[1].id)
              });
            }

            break;
          case "DONE": {
            this.conversationList[
              this.conversationList.length - 1
            ].messages[1].open = true;
            this.conversationList[
              this.conversationList.length - 1
            ].messages[1].loading = false;

            if (
              this.conversationList[this.conversationList.length - 1]
                .channel !== "Stabilityai"
            ) {
              if (
                this.conversationList[this.conversationList.length - 1]
                  .chatId &&
                this.conversationList[this.conversationList.length - 1]
                  .chatId !== ""
              ) {
                answer({
                  chatId:
                    this.conversationList[this.conversationList.length - 1]
                      .chatId,
                  answer:
                    this.conversationList[this.conversationList.length - 1]
                      .messages[1].text,
                });
              }
            }

            this.$eventBus.$emit("chat" + "searchDone");
            const list = [];
            this.conversationList.forEach((i) => {
              if (
                this.conversationList[this.conversationList.length - 1]
                  .channel !== "Stabilityai"
              ) {
                list.push(i);
              }
            });
            localStorage.setItem("conversationList", JSON.stringify(list));

            break;
          }
          case "ERROR":
            this.conversationList[
              this.conversationList.length - 1
            ].messages[1].open = true;
            this.conversationList[
              this.conversationList.length - 1
            ].messages[1].loading = false;
            this.conversationList[
              this.conversationList.length - 1
            ].messages[1].text = "【服务器繁忙，请稍后重试！】";
            this.scrollToBottom();
            this.$eventBus.$emit("chat" + "searchDone");
            break;
        }
      };
        this.conversationList.push({
        chatId: param.chatId,
        channel: param.searchChannel,
        messages: [
          {
            type: "question",
            icon:
              this.userinfo.headimgurl !== ""
                ? this.userinfo.headimgurl
                : "manager",
            text: param.question,
            open: true,
            loading: false,
          },
          {
            type: "answer",
            icon: "ChatGPT4",
            text: "",
            open: false,
            loading: false,
            answerLikeTag: "EMPTY",

          },
        ],
        stop: false,
        connected: false,
      });



      tickets({
        channelName: "ChatGPT4",
        question: param.question,
      })
        .then((res) => {
          if (res.obj.count !== null && res.obj.count <= 0) {

            param.onEvent({ type: "DONE" });
            return;
          } else {
            this.scrollToBottom();
            this.conversationList[this.conversationList.length - 1].chatId =
              res.obj.chatId;

            const chatServerUrl = "/api/search/v3";
            const apiKey = res.obj.apiKey;
            const maxTokens = res.obj.maxTokens;

            this.getAnswerV3(
              apiKey,
              chatServerUrl,
              maxTokens,
              this.questionParam,
              params
            );
          }
        })
        .catch((error) => {
          console.log(error);
          param.onEvent({
            type: "UPDATE",
            content: "【服务器繁忙，请稍后重试！】",
          });
          param.onEvent({ type: "DONE" });
        });
    },
  },
};
</script>
  <style lang="less" scoped>
.contentdaochu {
  width: 270px;
  word-spacing: normal;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.index {
  display: flex;
  position: relative;

  .lefttools::-webkit-scrollbar {
    display: none;
  }
  .lefttools {
    min-width: 240px;
    height: 88vh;
    overflow: auto;
    box-sizing: border-box;
    padding: 20px 20px;
    color: #ffffff;
    background-color: #131313;
    font-size: 12px;

    .top {
      display: flex;
      justify-content: center;
      align-items: center;

      .logo {
        display: block;
        width: 64px;
        height: 64px;
        margin-right: 10px;
      }
    }

    .chatlist {
      .addchat {
        line-height: 40px;
        cursor: pointer;
        border: 1px dashed #dddddd;
        border-radius: 8px;
        text-align: center;
      }

      .chatlistbox {
        div {
          margin-top: 20px;
          line-height: 40px;
          text-align: center;
          border: 1px solid #dddddd;
          border-radius: 8px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;

          i {
            display: inline-block;
            margin: 0 10px;
          }
        }
      }
    }
  }

  .chat-panel {
    box-sizing: border-box;
    position: relative;
    z-index: auto;
    flex: auto;
    overflow: hidden;
    background: #ffffff;
    height: calc(100vh - 92px) !important;
  }
}

.titledes {
  text-align: center;
  font-size: 18px;
}

.actbtn {
  display: flex;
  justify-content: center;
}

.actblock {
  background: #378efc;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 8px;
  margin: 20px 20px;
}

.actblock:nth-child(1) {

}

.actblock:nth-child(2) {
  color: #9e5211;
  background: linear-gradient(177.23deg, #fffbf3 13.41%, #fbd898 97.69%);
}

.daochubox {
  display: flex;
  align-items: center;
  justify-content: center;

  .daochu {
    border: 1px solid #ddd;
    padding: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    cursor: pointer;

    img {
      margin-right: 20px;
      display: block;
    }
  }
}

.daochuliaotianlist {
  max-height: calc(50vh);
  height: calc(50vh);
  border-bottom: 1px solid #ddd;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px;
  max-width: 100%;

  .el-checkbox-group {
    .el-checkbox {
      display: flex;
      align-items: center;
      margin-top: 30px;

      .el-checkbox__label {
        .daochublock {
          display: flex;
          align-items: center;

          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 20px;
          }
        }
      }
    }
  }
}

.settingmsg {
  padding: 20px;

  .selectbox {
    display: flex;

    .blockselect {
      margin-right: 20px;
    }
  }
}

.el-dialog {
  border-radius: 12px !important;
}
</style>
  <style scoped>
::v-deep .el-dialog {
  border-radius: 12px !important;
}
</style>
