<template>
  <div class="showcontentbox">
    <div class="ccontent">
      <h3>{{ showdetail.squareName }}</h3>
      <div class="userbox">
        <div class="leftbox">
          <img :src="showdetail.headimgurl" alt="" />
          <div class="leftcontentbox">
            <h3>{{ showdetail.nickName }}</h3>
            <p>{{ showdetail.gmtCreate }}</p>
          </div>
        </div>
        <div class="rightbox">
          <!-- <el-button type="primary">关注</el-button> -->
          <el-button type="primary" @click="goAct(showdetail)"
            >去使用</el-button
          >
        </div>
      </div>
      <div style="margin-top: 20px">
        <el-tag type="info" v-for="(item, index) in sysTagList" :key="index">{{
          item.tagName
        }}</el-tag>
      </div>
      <div class="contet">{{ showdetail.squareDesc }}</div>
      <div class="chatlist">
        <h2>聊天内容</h2>
        <div class="chatlistlist">
          <div v-for="(itm, idx) in showdetail.userChatLogList" :key="idx">
            <div class="answer">
              <div>
                <div class="rightbox">
                  {{ itm.question }}
                </div>
                <img :src="showdetail.headimgurl" alt="" />
              </div>
            </div>
            <div class="question">
              <div>
                <img
                  src="https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/logo/apple/60.png"
                  alt=""
                />
                <div
                  class="rightbox"
                  v-if="showdetail.type == 'CHAT_DIRECTIVE'"
                >
                  <pre>{{ itm.answer }}</pre>
                </div>
                <div
                  class="rightbox"
                  v-if="showdetail.type == 'DRAW_DIRECTIVE'"
                >
                
                  <el-image
                     class="imgboxshow"
                     v-for="(itemimg, indeximg) in showdetail.imgsrc"
                    :key="indeximg"
                    :src="itemimg.url"
                    :preview-src-list="imgList"
                  >
                  </el-image>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 评论 -->
    <div class="conmmentbox">
      <h3>评论（{{ showdetail.commentCount }}）</h3>
      <div class="commentaddbox">
        <img :src="showdetail.headimgurl" alt="" />
        <div class="shurukuang">
          <el-input
            type="textarea"
            placeholder="说两句吧~"
            v-model="textarea"
            maxlength="30"
            show-word-limit
          ></el-input>
        </div>
        <el-button
          class="pinglun"
          @click="pinglun"
          v-if="!ishuifu"
          type="primary"
          >评论</el-button
        >
        <el-button class="pinglun" @click="huifu2" v-else type="primary"
          >回复</el-button
        >
      </div>
      <div class="commentlist">
        <div
          class="commentlistblock"
          v-for="(item, index) in showdetail.squareCommentResponseList"
          :key="index"
        >
          <div class="bigcomment">
            <img :src="item.commentHeadimgurl" alt="" />
            <div class="commentlistblockcontent">
              <div>
                <span class="title">{{ item.commentNickName }}</span
                ><span class="time">{{ item.gmtCreate }}</span>
              </div>
              <p class="">{{ item.commentText }}</p>
              <div class="huifu" @click="huifu(item)" type="info">回复</div>
            </div>
          </div>
          <div
            class="reply"
            v-for="(itm, idx) in item.squareCommentReplyResponseList"
            :key="idx"
          >
            <img :src="itm.commentHeadimgurl" alt="" />
            <div class="commentlistblockcontent">
              <div>
                <span class="title">{{ itm.replyNickName }}</span
                >回复<span class="title">{{ itm.receiveNickName }}</span
                ><span class="time">{{ itm.gmtCreate }}</span>
              </div>
              <p class="">{{ itm.commentReplyText }}</p>
              <div class="huifu" type="info" @click="huifu(itm)">回复</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getDirectiveSquareInfo,
  saveSquareComment,
  saveCommentReply,
} from "@/api";
export default {
  name: "showdetail",
  components: {},
  computed: {
    height() {
      let h = this.clientHeight - 60;
      if (this.notifyOpen) {
        h -= 40;
      }
      return h;
    },
  },
  data() {
    return {
      id: null,
      ishuifu: false,
      text: "",
      reply: null,
      textarea: "",
      showdetail: null,
      imgList:[]
    };
  },
  activated() {
    // console.log('是不是每次都走了这里')
    this.id = this.$route.params.id;
    this.getDirectiveSquareInfo();
  },
  mounted() {
    this.id = this.$route.params.id;
    this.getDirectiveSquareInfo();
    // console.log('this.$route.params.id', this.$route.params.id)
  },
  methods: {
    //
    goAct(item) {
      // 判断是聊天还是画画 type==DRAW_DIRECTIVE 画画 CHAT_DIRECTIVE 聊天
      console.log(item);
      if (item.type == "DRAW_DIRECTIVE") {
        this.$router.push({
          path: "/draw", // 目标路由的名称或路径
          query: {
            param: JSON.stringify(item.userChatLogList),
          },
        });
      }
      if (item.type == "CHAT_DIRECTIVE") {
        this.$router.push({
          path: "/chat", // 目标路由的名称或路径
          query: {
            param: JSON.stringify(item.userChatLogList),
          },
        });
      }
    },
    pinglun() {
      if (this.textarea.trim().length < 1) {
        this.$message({
          type: "error",
          message: "内容不能为空！",
          duration:1500
        });
        return false;
      }
      let data = {
        commentText: this.textarea,
        squareId: this.showdetail.id,
      };
      saveSquareComment(data).then((res) => {
        console.log(res);
        if (res.success) {
          this.$message({
            type: "success",
            message: "评论成功！",
            duration:1500
          });
          this.textarea = "";
          this.getDirectiveSquareInfo();
        }
      });
    },
    huifu(item) {
      this.ishuifu = true;
      console.log(item);
      this.reply = item;
    },
    huifu2() {
      if (this.textarea.trim().length < 1) {
        this.$message({
          type: "error",
          message: "内容不能为空！",
          duration:1500
        });
        return false;
      }
      console.log("this.reply", this.reply);
      let data = {
        commentId: this.reply.squareCommentId
          ? this.reply.squareCommentId
          : this.reply.id
          ? this.reply.squareCommentId
          : this.reply.id,
        receiveUserId: this.reply.commentUserId
          ? this.reply.commentUserId
          : this.reply.receiveUserId,
        replyText: this.textarea,
        squareId: this.showdetail.id,
      };
      saveCommentReply(data).then((res) => {
        if (res.success) {
          this.$message({
            type: "success",
            message: "评论成功！",
            duration:1500
          });
          this.textarea = "";
          this.getDirectiveSquareInfo();
        }
      });
    },
    getDirectiveSquareInfo() {
      let data = {
        id: this.id,
      };
      getDirectiveSquareInfo(data).then((res) => {
        const datas = res.obj;
        this.showdetail = datas;
        // let arrr = []
        if (datas.type == "DRAW_DIRECTIVE") {
          let imgsrc = JSON.parse(datas.userChatLogList[0].answer);
          this.showdetail.imgsrc = imgsrc;
          let arr=[]
          for (let i = 0; i < imgsrc.length; i++) {
            arr.push(imgsrc[i].url)
          }
          this.imgList=arr
          console.log(this.imgList);
        }
        // console.log(this.showdetail)
        // for (let a in datas) {
        //   if (datas[a].type == "DRAW_DIRECTIVE") {
        //     console.log(datas[a])
        //     let imgsrc = JSON.parse(datas[a].userChatLogList[0].answer)[0].url
        //     // console.log(imgsrc)
        //     arrr.push({
        //       ...datas[a],
        //       imgsrc: imgsrc
        //     })

        //   } else {
        //     arrr.push(datas[a])
        //   }
        // }
        // this.showdetail = arrr
      });
    },
  },
};
</script>
<style lang="less" scoped>
.showcontentbox {
  margin: 20px 0 0;

  .ccontent {
    width: 1200px;
    margin: 0 auto;
    padding: 60px 156px;
    background-color: #ffffff;

    h3 {
      font-size: 30px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #292929;
      line-height: 52px;
      margin-bottom: 60px;
    }

    .userbox {
      display: flex;
      justify-content: space-between;

      .leftbox {
        display: flex;
        align-items: center;

        h3 {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #2c343e;
          line-height: 16px;
          margin-bottom: 0;
        }

        p {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #969899;
          line-height: 12px;
          margin-top: 8px;
        }

        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin-right: 14px;
        }
      }
    }

    .contet {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      margin-top: 30px;
      line-height: 28px;
    }

    .chatlist {
      margin-top: 100px;

      h2 {
        display: block;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #222222;
        padding: 0 0 24px;
        border-bottom: 1px solid #efefef;
      }
    }

    .chatlistlist {
      // display: flex;
      // flex-wrap: wrap;
      // justify-content: space-between;

      .answer {
        margin: 50px 0 0;
        display: flex;
        justify-content: flex-end;

        > div {
          display: flex;
        }

        img {
          width: 48px;
          height: 48px;
          border-radius: 50%;
        }

        .rightbox {
          min-width: 480px;
          max-width: calc(1200px - 88px - 156px - 156px);
          min-height: 46px;
          background: #f4f4f4;
          position: relative;
          margin-right: 35px;
          padding: 20px;

          pre {
            word-wrap: break-word;
            white-space: break-spaces;
          }

          &::before {
            position: absolute;
            right: -16px;
            top: 50%;
            margin-top: -11px;
            z-index: 99;
            content: "";
            display: inline-block;
            border-top: 14px solid transparent;
            border-bottom: 14px solid transparent;
            border-left: 22px solid #f4f4f4;
          }
        }
      }

      .question {
        margin: 50px 0 0;
        display: flex;
        justify-content: flex-start;

        > div {
          display: flex;
        }

        img {
          width: 48px;
          height: 48px;
          border-radius: 50%;
        }

        .rightbox {
          padding: 20px;
          min-width: 480px;
          max-width: calc(1200px - 88px - 156px - 156px);
          min-height: 46px;
          background: #f4f4f4;
          position: relative;
          margin-left: 35px;

          pre {
            word-wrap: break-word;
            white-space: break-spaces;
          }

          &::before {
            position: absolute;
            left: -16px;
            top: 50%;
            margin-top: -11px;
            z-index: 99;
            content: "";
            display: inline-block;
            border-top: 14px solid transparent;
            border-bottom: 14px solid transparent;
            border-right: 22px solid #f4f4f4;
          }
        }
      }
    }
  }

  .conmmentbox {
    width: 1200px;
    margin: 20px auto;
    padding: 60px 156px;
    background-color: #ffffff;

    .commentlist {
      .reply {
        display: flex;
        padding-left: 50px;
        margin-top: 15px;

        img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          margin-right: 20px;
        }

        .commentlistblockcontent {
          .huifu {
            width: 44px;
            height: 26px;
            background: #f5f5f5;
            border-radius: 4px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #222222;
            line-height: 12px;
            border: none;
            cursor: pointer;
            outline: none;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 12px;
          }

          p {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #222222;
            line-height: 20px;
            margin: 8px 0;
          }

          > div {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #222222;
            line-height: 14px;

            .title {
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #222222;
              line-height: 14px;
              margin-right: 10px;
            }

            .title:nth-child(2) {
              margin-left: 10px;
            }

            .time {
              font-size: 12px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #bababa;
              line-height: 12px;
            }
          }
        }
      }

      .bigcomment {
        display: flex;

        img {
          width: 35px;
          height: 35px;
          border-radius: 50%;
        }

        .commentlistblockcontent {
          margin-left: 15px;

          .huifu {
            width: 44px;
            height: 26px;
            background: #f5f5f5;
            border-radius: 4px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #222222;
            line-height: 12px;
            border: none;
            cursor: pointer;
            outline: none;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 12px;
          }

          p {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #222222;
            line-height: 20px;
            margin-top: 13px;
          }

          > div {
            display: flex;
            align-items: center;

            .title {
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #222222;
              line-height: 14px;
            }

            .time {
              font-size: 12px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #bababa;
              line-height: 12px;
              margin-left: 10px;
            }
          }
        }
      }
    }

    .commentaddbox {
      margin-top: 40px;
      display: flex;
      flex-wrap: wrap;

      .pinglun {
        align-self: flex-end;
        margin-left: 50px;
        margin-top: 20px;
      }

      img {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        margin-right: 13px;
      }

      .shurukuang {
        width: 839px;
        height: 97px;
        border-radius: 8px;
        opacity: 0.5;

        // border: 1px solid #979797;
        .el-textarea {
          height: 100%;
          display: block;
        }
      }
    }
  }
}

.commentlistblock {
  margin-top: 30px;
}
.imgboxshow {
  display: block;
  max-width: 670px !important;
  min-width: 200px !important;
  height: auto !important;
  margin-top: 20px;
  border-radius: 0 !important;
}
::v-deep .el-textarea__inner {
  min-height: 97px !important;
}
</style>
