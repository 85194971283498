<template>
  <div class="drawbox">
    <div class="draw content contentdraw">
      <!-- 左边内容 -->
      <div class="right">
        <div class="contenttitle" style="text-align: center">创作记录</div>
        <div
            v-if="!mudrawList"
            style="font-size: 14px; text-align: center; padding: 20px;color: #FFF;"
          >
            还没有记录哦～快去生成吧～
          </div>
        <div class="lilist" style="margin: auto">
          <div class="liblock" v-for="(item, index) in mudrawList" :key="index">
            <div
              class="drawimg"
              v-if="item.imagesList[0]"
              @click="showimg(item, item.imagesList)"
            >
              <img
                style="width: 130px; height: 130px; border-radius: 10px"
                :src="item.imagesList[0].url"
                alt=""
              />
              <div class="icon">
                <i
                  @click.stop="delthisimg(item)"
                  :class="{
                    icon: true,
                    'el-icon-close': del_flag == true,
                  }"
                ></i>
              </div>

              <!-- :style="{ 'background-image': 'url(https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/draw/2023-05-19/399972be-7f06-47c3-9b61-a8c935e9d88e.png)' }"> -->
            </div>

            <div class="drawimg bgblack" v-else>
              <div
                class="error1"
                v-loading="item.status == 'QUEUING' ? true : false"
                element-loading-text="正在生成中"
              >
                <p v-if="item.status == 'QUEUING'">生成中</p>
                <p v-else-if="item.status == 'ERROR'">
                  生成失败，能量已退回
                  <span v-if="item.errorMessage"
                    >失败原因是；{{ item.errorMessage }}</span
                  >
                </p>
              </div>
              <div class="icon">
                <i
                  @click.stop="delthisimg(item)"
                  :class="{
                    icon: true,
                    'el-icon-close': del_flag == true,
                  }"
                ></i>
              </div>
            </div>

            <!-- <div class="drawimg"  :style="{ 'background-image': 'url(' + backgroundUrl + ')' }"></div> -->
          </div>

        </div>
      </div>

      <div
        class="contentshow"
        v-loading="loading"
        element-loading-text="正在准备生成中，稍后请在左侧查看生成结果..."
        element-loading-spinner="el-icon-loading"
      >
        <el-button
          class="rightdaochu"
          @click="daochudrawMethod"
          type="primary"
          v-if="!isgroup"
          ><span class="el-icon-upload2"></span>导出</el-button
        >
        <div class="kongzhuangtau" v-if="imgsrcList.length < 1">
          <img src="../assets/nochuangy.png" alt="" style="width: 120px" />
          <p style="text-align: center">
            等你好久了<br />快去右侧输入你的创意吧~
          </p>
        </div>
        <div class="imgboxshow" v-else>
          <!-- <div class="imgblock" v-for="(item, index) in imgsrcList" :key="index">
            <img :src="item.url" alt="">
          </div> -->
          <el-carousel height="550px" arrow="hover" :autoplay="false">
            <el-carousel-item v-for="(item, index) in imgsrcList" :key="index">
              <img :src="item.url" alt="" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <!-- 右边展示队列 -->
      <div class="left" v-loading="loading">
        <el-tabs
          v-model="activeName"
          @tab-click="handleClick"
          style="width: 100%"
          :stretch="true"
        >
        <el-tab-pane label="修复老照片" name="aixiu">
            <!-- <p style="text-align: center;">敬请期待~</p> -->
            <div>
              <div class="title">上传需要修复的照片</div>
              <el-upload
                class="avatar-uploader"
                :action="baseUrl + '/api/file/upload'"
                :headers="upload"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                :on-progress="pic_change"
                v-loading="loading3"
                element-loading-text="上传中"
                element-loading-spinner="el-icon-loading"
                style="text-align: center"
              >
                <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <!-- 数量 -->
              <div class="title">数量</div>
              <div class="numbox">
                <div
                  :class="{ nblock: true, curr: item == curnum }"
                  v-for="(item, index) in numList"
                  @click="selectnum(item)"
                  :key="index"
                >
                  {{ item }}
                </div>
              </div>
              <!-- n内容 -->
              <div class="cccontentbox">
                <!-- 通道及花费
                <div class="el-select-box">
                  <el-select
                    v-model="searchChannel2"
                    placeholder="选择平台"
                    @change="select2()"
                  >
                    <el-option
                      v-for="(item, index) in searchChannelList"
                      :key="index"
                      :label="item.channelName"
                      :value="item.channelNameLevel"
                      :disabled="item.disabled"
                      v-show="item.channelName == 'Sd'"
                    >
                      <span>{{ item.channelDesc }}</span
                      ><span class="small"> {{ item.desc }}</span></el-option
                    >
                  </el-select>
                </div> -->
                <!-- 内容box -->
                <!-- <div class="content" style="margin-top: 10px">
                  <el-input
                    type="textarea"
                    v-model="prompt"
                    :rows="3"
                    placeholder="补充关键词"
                  ></el-input>
                </div> -->
              </div>
              <el-button class="btn-shengcheng" round @click="shengcheng"
                >生成</el-button
              >
            </div>
          </el-tab-pane>
          <el-tab-pane label="文生图" name="aiTxt">
            <div>
              <!-- 风格 -->
              <div class="title">风格</div>
              <div
                class="fgbox"
                :style="moreShow ? '' : 'height: 120px;overflow: hidden;'"
              >
                <div
                  :class="{ fgblock: true, currfg: actfg == index }"
                  v-for="(item, index) in fglist"
                  :key="index"
                  @click="selectfg(index, item.name)"
                >
                  <img :src="item.src" alt="" />
                  <div>{{ item.name }}</div>
                </div>
              </div>
              <p
                style="color: #4284ff; text-align: center; cursor: context-menu"
                @click="moreShow = !moreShow"
              >
                {{ moreShow ? "收起" : "更多风格" }}
              </p>
              <!-- 数量 -->
              <div class="title">数量</div>
              <div class="numbox">
                <div
                  :class="{ nblock: true, curr: item == curnum }"
                  v-for="(item, index) in numList"
                  @click="selectnum(item)"
                  :key="index"
                >
                  {{ item }}
                </div>
              </div>
              <div class="title" style="margin-top: 10px;">示例</div>
              <!-- 示例 -->
              <div class="shilibox">
                <div
                  class="slblock"
                  v-for="(item, index) in tagListlist"
                  :key="index"
                  @click="selectTag(item)"
                >
                  {{ item }}
                </div>
                <img
                  @click="getRandomSysTagmethod"
                  style="cursor: pointer"
                  src="https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/static/all/genghuan.png"
                  alt=""
                />
              </div>
              <!-- n内容 -->
              <div class="cccontentbox">
                <!-- 通道及花费 -->
                <div class="el-select-box">
                  <el-select
                    v-model="searchChannel1"
                    placeholder="选择平台"
                    @change="select2()"
                  >
                    <el-option
                      v-for="(item, index) in searchChannelList"
                      :key="index"
                      :label="item.channelName"
                      :value="item.channelNameLevel"
                      :disabled="item.disabled"
                    >
                      <span>{{ item.channelDesc }}</span
                      ><span class="small"> {{ item.desc }}</span></el-option
                    >
                  </el-select>
                </div>
                <!-- 内容box -->
                <div class="content" style="margin-top: 20px">
                  <el-input
                    type="textarea"
                    v-model="prompt"
                    :rows="5"
                    placeholder="写下你的创意"
                  ></el-input>
                </div>
              </div>
              <el-button class="btn-shengcheng" round @click="shengcheng"
                >生成</el-button
              >
            </div>
          </el-tab-pane>
          <el-tab-pane label="图生图" name="aiImg">
            <div>
              <div class="title">上传参考图</div>
              <el-upload
                class="avatar-uploader"
                :action="baseUrl + '/api/file/upload'"
                :headers="upload"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                :on-progress="pic_change"
                v-loading="loading3"
                element-loading-text="上传中"
                element-loading-spinner="el-icon-loading"
                style="text-align: center"
              >
                <img v-if="imageUrl2" :src="imageUrl2" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <!-- 风格 -->
              <div class="title">风格</div>
              <div
                class="fgbox"
                style='height:120px;overflow: hidden;justify-content: space-evenly;'

              >
                <div
                  :class="{ fgblock: true, currfg: actfg == index }"
                  v-for="(item, index) in fglist2"
                  :key="index"
                  @click="selectfg(index, item.name)"
                >
                  <img :src="item.src" alt="" />
                  <div>{{ item.name }}</div>
                </div>
              </div>
              <!-- <p
                style="color: #4284ff; text-align: center; cursor: context-menu"
                @click="moreShow2 = !moreShow2"
              >
                {{ moreShow2 ? "收起" : "更多风格" }}
              </p> -->
              <!-- 数量 -->
              <div class="title">数量</div>
              <div class="numbox">
                <div
                  :class="{ nblock: true, curr: item == curnum }"
                  v-for="(item, index) in numList"
                  @click="selectnum(item)"
                  :key="index"
                >
                  {{ item }}
                </div>
              </div>
              <!-- n内容 -->
              <div class="cccontentbox">
                <!-- 通道及花费 -->
                <!-- <div class="el-select-box">
                  <el-select
                    v-model="searchChannel2"
                    placeholder="选择平台"
                    @change="select2()"
                  >
                    <el-option
                      v-for="(item, index) in searchChannelList"
                      :key="index"
                      :label="item.channelName"
                      :value="item.channelNameLevel"
                      :disabled="item.disabled"
                      v-show="item.channelName == 'Sd'"
                    >
                      <span>{{ item.channelDesc }}</span
                      ><span class="small"> {{ item.desc }}</span></el-option
                    >
                  </el-select>
                </div> -->
                <!-- 内容box -->
                <!-- <div class="content" style="margin-top: 10px">
                  <el-input
                    type="textarea"
                    v-model="prompt"
                    :rows="3"
                    placeholder="补充关键词"
                  ></el-input>
                </div> -->
              </div>
              <el-button class="btn-shengcheng" round @click="shengcheng"
                >生成</el-button
              >
            </div>
          </el-tab-pane>

        </el-tabs>
      </div>
    </div>
    <!--  -->
    <!-- 能量 不够弹窗 -->
    <el-dialog title="" :visible.sync="dialogTableVisiblebugou" width="400px">
      <div class="titledes">
        您的能量已不足,请去公众号 购买套餐 或者 完成任务 获取能量!
      </div>
      <div class="actbtn">
        <!-- <div class="actblock">去做任务</div>
        <div class="actblock">购买套餐</div> -->
      </div>
    </el-dialog>
    <!-- /导出指令 -->
    <el-dialog
      title="导出指令"
      :visible.sync="daochudrawer"
      width="550px"
      direction="rtl"
    >
      <!-- 选择导出到哪儿 -->
      <div class="daochuone" v-if="currdaochu == 1">
        <div class="daochubox">
          <div class="daochu" @click="nextdaochu('SQUARE')">
            <img src="../assets/dc1.png" alt="" style="width: 40px;"/>导出到广场
          </div>
          <div class="daochu" @click="nextdaochu('MARKET')">
            <img src="../assets/dc2.png" alt="" style="width: 40px;"/>导出到商城
          </div>
        </div>
      </div>
      <div class="one" v-if="currdaochu == 2">
        <!-- <div class="daochuliaotianlist">
          <el-checkbox-group v-model="checkList">
            <el-checkbox :label="item" v-for="(item, index) in imgsrcList" :key="index">
              <div class="daochublock">
                <img src="https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/logo/apple/60.png" alt="">
                <div class="contentdaochu">
                  <img style="border-radius:0!important;width:100px;height:100px" :src="item.url" alt="">
                </div>
              </div>
            </el-checkbox>
          </el-checkbox-group>

        </div> -->
        <!-- /作品设置 -->
        <div class="settingmsg">
          <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="作品名称:">
              <el-input v-model="form.name" placeholder="(必填)"></el-input>
            </el-form-item>
            <el-form-item label="作品描述:">
              <el-input
                v-model="form.desc"
                type="textarea"
                placeholder="(非必填)"
              ></el-input>
            </el-form-item>
            <el-form-item label="标签:">
              <el-select
                style="width: 100%"
                v-model="tagvalue"
                multiple
                filterable
                :multiple-limit="3"
                allow-create
                default-first-option
                placeholder="请选择标签"
                @change="tagchange"
              >
                <el-option
                  v-for="item in tagList"
                  :key="item.id"
                  :label="item.tagName"
                  :value="item.tagName"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="fabuchangjian"
                >确认发布</el-button
              >
              <el-button @click="cancle">取消</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="one"></div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getRandomSysTag,
  getUserChatDrawImageList,
  getUserTagList,
  deleteDrawImage,
  saveDrawTask,
  getCategoryList,
  exportDirective,
  getGroupEnergyBalance,
} from "@/api";
// import chatgpt from '@/utils/chatgpt'
// import bing from '@/utils/bing'
// import stabilityai from '@/utils/stabilityai'
import { mapGetters } from "vuex";
export default {
  name: "draw",
  components: {},
  computed: {
    ...mapGetters(["baseUrl", "userinfo", "channelList"]),
  },
  data() {
    return {
      from: "draw",
      loading3: false,
      activeName: "aixiu",
      upload: { serviceAuthentication: localStorage.getItem("token") },
      moreShow: false,
      moreShow2: false,
      form: {
        name: "",
        desc: "",
      },
      del_flag: true,
      imgsrcList: [],
      imgsrc: false,
      checkList: [],
      myswiper: null,
      daochudrawer: false,
      dialogTableVisiblebugou: false,
      bigoptions: [],
      suboptions: [],
      tagvalue: [],
      currdaochu: 1,
      exportChannel: null,
      historyList: [],
      tagListlist: [],
      chatid: null,
      subcatevalue: null,
      subcatevalueid: null,
      bigcatevalue: null,
      cateoptions: [],
      tagsoptions: [],
      pushStatus: null,
      chatLevel: null,
      tagList: null,
      prompt: null,
      acffenggetext: "",
      curnum: 1,
      fglist: [
        {
          name: "智能推荐",
          src: "https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/static/zhineng.jpg",
        },
        {
          name: "艺术创想",
          src: "https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/static/yishu.png",
        },
        {
          name: "唯美二次元",
          src: "https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/static/2ciyuan.jpg",
        },
        {
          name: "怀旧漫画风",
          src: "https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/static/huaijiu.jpg",
        },
        {
          name: "中国风",
          src: "https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/static/zhongguo.png",
        },
        {
          name: "概念插画",
          src: "https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/static/gainian.png",
        },
        {
          name: "明亮插画",
          src: "https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/static/mingliang.png",
        },
        {
          name: "梵高",
          src: "https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/static/fangao.png",
        },
        {
          name: "超现实主义",
          src: "https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/static/chaoxianshi.png",
        },
        {
          name: "动漫风",
          src: "https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/static/dongmanfeng.png",
        },
        {
          name: "插画",
          src: "https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/static/chahua.png",
        },
        {
          name: "像素艺术",
          src: "https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/static/xiangsu.png",
        },
      ],
      fglist2: [
        {
          name: "智能推荐",
          src: "https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/static/zhineng.jpg",
        },
        {
          name: "唯美二次元",
          src: "https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/static/2ciyuan.jpg",
        },
        {
          name: "动漫风",
          src: "https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/static/dongmanfeng.png",
        },
        {
          name: "插画",
          src: "https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/static/chahua.png",
        }
      ],
      numList: [1, 2, 3, 4],
      mudrawList: null,
      actfg: 0,
      searchChannel1: null,
      searchChannel2: null,
      currimgdata: null,
      searchChannel: null,
      loading: false,
      isgroup: null,
      imageUrl: "",
      imageUrl2: "",
      tab_flag: 0,
    };
  },
  watch: {},
  activated() {
    // console.log('是不是每次都走了这里')
    if (this.$route.query.param) {
      const paramValue = this.$route.query.param
        ? JSON.parse(this.$route.query.param)
        : "";
      console.log("paramValue", paramValue[0]);
      this.prompt = paramValue[0].question;
    }
  },
  created() {
    this.isgroup = JSON.parse(localStorage.getItem("groupCon"));
    // 看看有没有值
    if (this.$route.query.param) {
      const paramValue = this.$route.query.param
        ? JSON.parse(this.$route.query.param)
        : "";
      console.log("paramValue", paramValue[0]);
      this.prompt = paramValue[0].question;
    }

    // 分类
    // this.getCategoryList()
    this.$getLoginInfo();

    this.getRandomSysTagmethod();
    this.getSysTagListmethod();
    this.getUserChatDrawImageListmethod(0);
    let newarr = [];
    const ChannelList = JSON.parse(localStorage.getItem("ChannelList"));
    console.log("ChannelList", ChannelList);
    ChannelList.forEach((i) => {
      if (!i.disabled) {
        if (i.type === "draw") {
          newarr.push(i);
        }
      }
    });
    console.log(newarr);
    this.searchChannelList = newarr;
    this.searchChannel = newarr[2];
    this.searchChannel1 = newarr[2].channelDesc + newarr[2].desc;
    this.searchChannel2 = newarr[2].channelDesc + newarr[2].desc;
  },
  beforeDestroy() {},
  mounted() {},
  methods: {
    pic_change() {
      this.loading3 = true;
    },
    handleAvatarSuccess(res) {
      console.log("生成了");
      this.loading3 = false;
      if (this.tab_flag == 0) {
        this.imageUrl = res.obj.url;
      } else {
        this.imageUrl2 = res.obj.url;
      }
      console.log(this.imageUrl);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message({message: '上传图片只能是 JPG或PNG 格式!', offset: 80, type: 'error'});
      }
      if (!isLt2M) {
        this.$message({message: '上传图片大小不能超过 2MB!', offset: 80, type: 'error'});
      }
      return isJPG && isLt2M;
    },
    getSysTagListmethod() {
      let data = {
        tagType: "DRAW",
      };
      getUserTagList(data).then((res) => {
        this.tagList = res.obj;
      });
    },
    // 发布创建
    fabuchangjian() {
      console.log("currimgdata", this.currimgdata);
      console.log("checkList", this.checkList);
      // console.log('this.form', this.form)
      // console.log('subcatevalue子集id', this.subcatevalueid)
      // console.log('subcatevalue', this.subcatevalue)
      // console.log('sysTagList', this.sysTagList)
      // console.log('this.checkList', this.checkList)
      // if(this.currimgdata==null||this.checkList.length<1||this.exportChannel==null||this.subcatevalueid==null||this.subcatevalue==null|| this.form.name==''||this.form.desc==''){
      //   this.$message({
      //       type: 'error',
      //       message: '请检查数据是否完整'
      //     });
      //     return false
      // }
      if (
        this.exportChannel == null ||
        this.form.name == "" ||
        this.sysTagList == ""
      ) {
        this.$message({
          type: "error",
          message: "请检查数据是否完整",
          duration: 1500,
        });
        return false;
      }
      let result = [
        {
          question: this.currimgdata.imgQuestion,
          id: this.currimgdata.id,
          chatChannel: this.currimgdata.chatChannel,
          answer: JSON.stringify(this.imgsrcList),
        },
      ];
      let datas = {
        exportChannel: this.exportChannel,
        // 'categoryId': this.subcatevalueid,
        // 'categoryName': this.subcatevalue,
        sysTagList: this.sysTagList,
        sourceId: this.currimgdata.id,
        exportSource: "WORKS_EXPORT",
        name: this.form.name,
        desc: this.form.desc,
        directiveType: "DRAW_DIRECTIVE",
        chatLogList: result,
      };
      console.log(datas);
      exportDirective(datas).then((res) => {
        if (res.success == true) {
          this.exportChannel = "";
          // this.subcatevalueid = ''
          // this.subcatevalue = ''
          this.sysTagList = "";
          this.form.name = "";
          this.form.desc = "";
          this.checkList = [];
          this.tagvalue = [];
          this.$message({
            type: "success",
            message: "导出成功！",
            duration: 1500,
          });
          this.daochudrawer = false;
          this.currdaochu = 1;
        }
      });
    },
    nextdaochu(dt) {
      if (dt=='MARKET') {
        this.$message({
            type: "error",
            message: "敬请期待！",
            duration: 1500,
          });
    } else {
      this.exportChannel = dt;
      this.currdaochu += 1;
    }

    },
    del_show() {
      this.del_flag = !this.del_flag;
    },
    daochudrawMethod() {
      // const isdaochu = this.pushStatus;
      // if (isdaochu != null) {
      //   this.$message({
      //     type: "error",
      //     message: "已被导出，请勿重新操作",
      //   });
      //   return false;
      // }
      this.daochudrawer = true;
      if (this.imgsrcList.length < 1) {
        this.$message({
          type: "error",
          message: "当前没有内容可导出",
          duration: 1500,
        });
        this.daochudrawer = false;
      }
    },
    cancle() {
      this.daochudrawer = false;
    },
    bigcatechange(e) {
      console.log(e);
      this.cateid = e;
      this.subcatevalue = null;
      this.getCategoryListsub();
    },
    subchange(e) {
      const result = this.suboptions.filter((item) => item.id === e);
      // console.log('result',result)

      this.subcatevalue = result[0].name;
    },
    tagchange(e) {
      console.log(e);
      const result = e.map((tagName) => {
        let item = this.tagList.find((i) => i.tagName === tagName);
        if (item) {
          return { id: item.id, tagName: item.tagName, tagType: item.tagType };
        } else {
          return { tagName: tagName, tagType: null };
        }
      });

      console.log("result", result);
      // console.log('e', e)
      // sysTagList
      this.sysTagList = result;
    },
    shengcheng() {
      console.log(this.imageUrl);
      console.log(this.imageUrl2);
      console.log(this.prompt);
      console.log(this.tab_flag);
      if (!localStorage.getItem("token")) {
        this.$message({
          type: "error",
          message: "请先登录",
          duration: 1500,
        });
        return false;
      }
      if (!this.searchChannel) {
        console.log("没选通道");
        return false;
      }
      if (!this.imageUrl && this.tab_flag == 0) {
        this.$message({
          type: "error",
          message: "请上传一张示例图~",
          duration: 1500,
        });
        return false;
      }
      if (!this.imageUrl2 && this.tab_flag == 2) {
        this.$message({
          type: "error",
          message: "请上传一张示例图~",
          duration: 1500,
        });
        return false;
      }
      if (!this.prompt && this.tab_flag === 1) {
        this.$message({
          type: "error",
          message: "不告诉我画什么很难办的~",
          duration: 1500,
        });
        return false;
      }
      console.log(this.channelList);
      console.log(this.searchChannel);
      console.log(
        this.channelList.filter(
          (item) => item.channelName === this.searchChannel
        )[0]
      );
      console.log(
        this.channelList.filter(
          (item) => item.channelName === this.searchChannel.channelName
        )[0]
      );
      const needUse = this.channelList.filter(
        (item) => item.channelName === this.searchChannel.channelName
      )[0].energyPoints;
      console.log(JSON.parse(localStorage.getItem("groupCon")));
      this.isgroup = JSON.parse(localStorage.getItem("groupCon"));
      var nowNum = null;
      if (this.isgroup) {
        const udata = this.userinfo;
        let data = { groupId: udata.id, page: 1, size: 100 };
        getGroupEnergyBalance(data).then((res) => {
          console.log(res);
          nowNum = res.obj.currentEnergy;
          console.log("目前还有" + nowNum);
          console.log("目前还有needUse" + needUse);
          if (nowNum - needUse < 0) {
            // 能量不够
            console.log("能量不够啦");
            this.dialogTableVisiblebugou = true;
            this.$refs.childRef.isStop();
            return false;
          }
        });
      } else {
        const udata = this.userinfo;
        nowNum = udata ? udata.userEnergyResponse.userAvailableEnergy * 1 : "";
        console.log("目前还有" + nowNum);
        console.log("目前还有needUse" + needUse);
        if (nowNum - needUse < 0) {
          // 能量不够
          console.log("能量不够啦");
          this.dialogTableVisiblebugou = true;
          this.$refs.childRef.isStop();
          return false;
        }
      }

      const question =
        this.prompt +
        "   绘画风格为" +
        " " +
        (this.acffenggetext == "" ? "推荐" : this.fglist[0].name);
      console.log(JSON.parse(localStorage.getItem("groupCon")));
      let datas = {
        groupId: this.isgroup ? this.isgroup.id : null,
        chatChannelEnum: this.searchChannel.channelName,
        drawRequest: {
          channel: this.searchChannel.channelName,
          imgUrl:
            this.tab_flag == 0 && this.imageUrl
              ? this.imageUrl
              : this.tab_flag == 2 && this.imageUrl2
              ? this.imageUrl2
              : "",
          scene: this.tab_flag == 0 ? "FIX_OLD_PIC" : "DEFAULT",
          n: this.curnum,
          size: "512x512",
          prompt: this.tab_flag == 1 ? question : "",
        },
      };
      this.imgsrcList = [];
      saveDrawTask(datas).then((res) => {
        this.loading = true;
        this.$emit("myEvent");
        console.log(res);
        this.getUserChatDrawImageListmethod(1);
        setTimeout(() => {
          this.loading = false;
        }, 1500);
      });
    },
    delthisimg(item) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.deleteDrawImagemethod(item.id);
          this.getUserChatDrawImageListmethod(1);
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
    },
    // 删除
    deleteDrawImagemethod(id) {
      let data = {
        groupId: this.isgroup ? this.isgroup.id : null,
        id: id,
      };
      deleteDrawImage(data).then((res) => {
        if (res.success == true) {
          this.$message({
            type: "success",
            message: "删除成功",
            duration: 1500,
          });
        }
      });
    },
    handleClick(tab) {
      console.log(tab.index);
      if (tab.index == 0) {
        this.tab_flag = 0;
        this.searchChannel = this.searchChannelList[2];
      } else if (tab.index == 1) {
        this.tab_flag = 1;
        this.searchChannel = this.searchChannelList[2];
      } else if (tab.index == 2) {
        this.tab_flag = 2;
        this.searchChannel = this.searchChannelList[2];
      }
    },
    // 点击图片查看
    showimg(item, imglist) {
      this.currimgdata = item;
      this.imgsrcList = imglist;
      this.pushStatus = item.pushStatus;
      this.prompt = item.imgQuestion;
    },
    propt(item) {
      this.prompt = item.imgQuestion;
    },
    // 获取作品列表
    getUserChatDrawImageListmethod(num) {
      // console.log(this.numList)
      this.isgroup = JSON.parse(localStorage.getItem("groupCon"));
      let datas = {
        groupId: this.isgroup ? this.isgroup.id : null,
        page: 1,
        size: 100000,
      };
      if (num == 1) {
        // 需要遍历定时器判断列表中有没有status等于QUEUING 有就是每秒执行  没有就停止定时器
        const timerId = setInterval(() => {
          getUserChatDrawImageList(datas)
            .then((data) => {
              this.mudrawList = data.obj.records;
              // 检查返回的数据中是否存在状态为"QUEUING"的项
              const queuingItems = data.obj.records.filter(
                (item) => item.status === "QUEUING"
              );
              console.log(queuingItems.length);
              if (queuingItems.length <= 0) {
                console.log("No QUEUING items found. Stopping the requests.");
                clearInterval(timerId); // 停止定时器
              }
            })
            .catch((error) => {
              console.error("Error occurred:", error);
              clearInterval(timerId); // 停止定时器
            });
        }, 1000); // 每秒钟执行一次请求
      } else {
        getUserChatDrawImageList(datas).then((res) => {
          // console.log(res.obj.records)
          this.mudrawList = res.obj.records;
        });
      }

      // getUserChatDrawImageList(datas).then((res) => {
      //   console.log(res.obj.records)
      //   this.mudrawList = res.obj.records
      // })
    },
    // 选择风格
    selectfg(index, item) {
      this.actfg = index;
      this.acffenggetext = item.name;
      console.log(item.name);
    },
    // 选择张数
    selectnum(num) {
      this.curnum = num;
    },
    // 获取分类数据
    getCategoryListsub() {
      let data = {
        id: this.cateid,
      };
      getCategoryList(data).then((res) => {
        this.suboptions = res.obj;
      });
    },
    getCategoryList() {
      let data = {
        id: "",
      };
      getCategoryList(data).then((res) => {
        this.bigoptions = res.obj;
      });
    },
    select2() {
      const result2 = this.searchChannelList.filter(
        (item) => item.channelNameLevel === this.searchChannel1
      )[0];
      console.log(this.searchChannel1);
      this.searchChannel1 = result2.channelDesc + result2.desc;
      this.searchChannel = result2;
    },
    // 选中标签
    selectTag(item) {
      this.prompt = item;
    },
    getRandomItems(array = [], count) {
      const randomItems = [];

      // 验证数组长度是否大于等于 count
      if (array.length < count) {
        console.error("Array length is less than the requested count.");
        return randomItems;
      }

      // 从数组中随机取 count 条数据
      while (randomItems.length < count) {
        const randomIndex = Math.floor(Math.random() * array.length);
        randomItems.push(array[randomIndex]);
        array.splice(randomIndex, 1); // 避免重复选取同一项
      }

      return randomItems;
    },
    // 标签
    getRandomSysTagmethod() {
      let data = {
        tagType: "DRAW",
      };
      getRandomSysTag(data).then((res) => {
        const responsedata = res.obj.tagTextList;
        this.tagListlist = this.getRandomItems(responsedata, 2);
      });
    },
  },
};
</script>
<style lang="less" scoped>
body::-webkit-scrollbar {
  display: none;
}
.contentdaochu {
  width: 270px;
  word-spacing: normal;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.el-carousel.el-carousel--horizontal {
  width: 550px !important;
  height: 550px !important;
  img {
    display: block;
    width: 550px !important;
    height: 550px !important;
    margin-top: 0;
  }
}

.bgblack {
  color: #ffffff;
  font-size: 12px;
  margin-top: 20px;
  .error1 {
    width: 130px;
    height: 130px;
    border-radius: 10px;
    background-color: #949494;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
}

.drawbox {
  width: 100%;
  height: 90vh;
  min-width: 1280px;
  min-height: 620px;
  background: #fff;
  position: relative;
  display: flex;
  box-sizing: border-box;

  .contentdraw {
    display: flex;
    justify-content: space-between;
    flex: 1;

    .left {
      border-left: 1px solid #c0c4cc;
      padding: 0 20px;
      overflow-y: auto;
    }
    .left::-webkit-scrollbar {
      display: none;
    }

    .right {
      height: 90vh;
      padding: 0 20px;
      border-right: 1px solid #c0c4cc;
      background-color: #000;
      // position: fixed;
      // left: 0;
      overflow-y: scroll;
    }
    .right::-webkit-scrollbar {
      display: none;
    }

    > div {
      .contenttitle {
        line-height: 80px;
        position: relative;
        font-size: 24px;
        color: #fff;
      }
    }

    .left {
      position: relative;
      width: 330px;
      height: 90vh;
      box-sizing: border-box;
      // display: flex;
      flex-direction: column;

      .fgbox {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        .fgblock {
          width: 65px;
          cursor: pointer;
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          font-weight: 400;
          text-align: center;
          color: #fff;
          opacity: 0.8;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          div {
            width: 100%;
            font-weight: 400;
            font-size: 14px;
            line-height: 33px;
            text-align: center;
            color: #333;
            opacity: 0.8;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          img {
            width: 60px;
            margin-bottom: 4px;
            position: relative;
            font-size: 14px;
            color: #fff;
            box-sizing: border-box;
            border-radius: 10px;
          }
        }

        .currfg {
          img {
            width: 65px;
            padding: 2px;
            border-radius: 10px;
            border: 3px solid #388ffb;
          }
        }
      }

      .numbox {
        margin-top: 15px;
        display: flex;
        justify-content: space-between;

        .nblock {
          cursor: pointer;
          width: 60px;
          height: 34px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #388ffb;
          color: #388ffb;
          border-radius: 10px;
        }

        .curr {
          background: #388ffb;
          color: #fff;
        }
      }

      .shilibox {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        align-items: center;

        .slblock {
          max-width: 160px;
          background: #f6f7f6;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding: 10px 10px;
          border-radius: 20px;
          font-size: 14px;
          cursor: pointer;
        }

        img {
          width: 20px;
          height: 20px;
          margin-top: 0;
        }
      }

      .cccontentbox {
        margin-top: 20px;
        margin-bottom: 10px;

        .el-select {
          border: none;

          .el-input__inner {
            border: none !important;
          }
        }
      }

      .btn-shengcheng {
        width: 90%;
        background-color: rgb(55, 142, 252);
        text-align: center;
        margin: 0 5% 5% 5%;
        padding: 10px 0;
        border: 1px solid rgb(55, 142, 252);
        color: #fff;
      }
    }

    .contentshow {
      flex: 1;
      display: flex;
      height: 75%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      margin-top: 70px;

      .rightdaochu {
        margin-left: 540px;
        transform: scale(0.8);
      }

      > div {
        width: 550px;
        height: 550px;
        border: 1px dashed #c0c4cc;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
        margin-top: 10px;
      }

      .imgboxshow {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
        position: relative;

        .imgblock:nth-child(1),
        .imgblock:nth-child(2) {
          margin-top: 0 !important;
        }

        .imgblock {
          width: 600px;
          height: 600px;
          position: relative;
          background: #252525;
          border-radius: 12px;
          overflow: hidden;
          box-sizing: border-box;
          text-align: center;
          border: 1px solid #c0c4cc;
          margin-top: 40px;

          .el-carousel__container {
            height: 600px !important;
          }

          img {
            display: block;
            width: 100%;
          }
        }
      }
    }

    .right {
      height:90vh;
      //max-height:710px;
      width: 180px;
      overflow: auto;

      .liblock {
        display: flex;
        margin-bottom: 10px;

        .drawimg {
          display: flex;
          width: 150px;
          height: 130px;
          background-size: cover;
          // margin-right: 20px;
          border-radius: 8px;
          position: relative;
          margin-top: 10px;
          padding-top: 20px;
        }
        .icon {
          color: #fff;
          position: absolute;
          right: -5px;
          top: 5px;
          display: none;
          font-size: 20px;
        }
        .drawimg:hover .icon {
          color: #fff;
          position: absolute;
          right: -5px;
          top: 5px;
          display: block;
        }

        .rightbox {
          flex: 1;
          font-size: 16px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .top {
            display: none;
          }
          .bottom {
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
          }
          .title {
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden; //溢出内容隐藏
            text-overflow: ellipsis; //文本溢出部分用省略号表示
            display: -webkit-box; //特别显示模式
            -webkit-line-clamp: 4; //行数
            line-clamp: 4;
            -webkit-box-orient: vertical; //盒子中内容竖直排列
          }

          button:nth-child(2) {
            color: red;
          }

          button {
            padding: 0 !important;
          }
        }
      }
    }
  }
}

.daochubox {
  display: flex;
  align-items: center;
  justify-content: center;

  .daochu {
    border: 1px solid #ddd;
    padding: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    cursor: pointer;

    img {
      margin-right: 20px;
      display: block;
    }
  }
}

.contentdaochu {
  word-break: break-all;
}

.daochuliaotianlist {
  max-height: calc(50vh);
  height: calc(50vh);
  border-bottom: 1px solid #ddd;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px;
  max-width: 100%;

  .el-checkbox-group {
    .el-checkbox {
      display: flex;
      align-items: center;
      margin-top: 30px;

      .el-checkbox__label {
        .daochublock {
          display: flex;
          align-items: center;

          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 20px;
          }
        }
      }
    }
  }
}

.settingmsg {
  padding: 20px;

  .selectbox {
    display: flex;

    .blockselect {
      margin-right: 20px;
    }
  }
}
</style>
<style scoped>
::v-deep .el-dialog {
  border-radius: 12px !important;
}
</style>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin-top: 10px;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
</style>
