import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const constantRoutes = [
  {
    path: '/',
    component: (resolve) => require(['@/views/index'], resolve),
    name: 'index',
    meta: { title: 'index', icon: '', noCache: true, affix: true }
  },
  {
    path: '/square',
    component: (resolve) => require(['@/views/square'], resolve),
    name: 'square',
    meta: { title: 'square', icon: '', noCache: true, affix: true }
  },
  {
    path: '/showdetail/:id',
    component: (resolve) => require(['@/views/showdetail'], resolve),
    name: 'showdetail',
    meta: { title: 'chshowdetailat', icon: '', noCache: true, affix: true }
  },
  {
    path: '/showdetaildis/:id',
    component: (resolve) => require(['@/views/showdetaildis'], resolve),
    name: 'showdetaildis',
    meta: { title: 'showdetaildis', icon: '', noCache: true, affix: true }
  },
  {
    path: '/chat',
    component: (resolve) => require(['@/views/chat'], resolve),
    name: 'chat',
    meta: { title: 'chat', icon: '', noCache: true, affix: true }
  },
  {
    path: '/home',
    component: (resolve) => require(['@/views/home'], resolve),
    name: 'home',
    meta: { title: 'home', icon: '', noCache: true, affix: true }
  },
  {
    path: '/template',
    component: (resolve) => require(['@/views/chat_template'], resolve),
    name: 'template',
    meta: { title: 'ChatTemplate', icon: '', noCache: true, affix: true }
  },
  {
    path: '/template-detail',
    component: (resolve) => require(['@/views/chat_template_detail'], resolve),
    name: 'templateDetail',
    meta: { title: 'ChatTemplateDetail', icon: '', noCache: true, affix: true }
  },
  {
    path: '/draw',
    component: (resolve) => require(['@/views/draw'], resolve),
    name: 'draw',
    meta: { title: 'draw', icon: '', noCache: true, affix: true }
  },
  {
    path: '/app',
    component: (resolve) => require(['@/views/app'], resolve),
    name: 'app',
    meta: { title: 'app', icon: '', noCache: true, affix: true }
  },
  {
    path: '/create',
    component: (resolve) => require(['@/views/create'], resolve),
    name: 'create',
    meta: { title: 'create', icon: '', noCache: true, affix: true }
  },
  {
    path: '/profile',
    component: (resolve) => require(['@/views/profile'], resolve),
    name: 'profile',
    meta: { title: 'profile', icon: '', noCache: true, affix: true }
  },
  {
    path: '/commission',
    component: (resolve) => require(['@/views/commission'], resolve),
    name: 'commission',
    meta: { title: 'commission', icon: '', noCache: true, affix: true }
  },
  {
    path: '/my',
    component: (resolve) => require(['@/views/my'], resolve),
    name: 'my',
    meta: { title: 'my', icon: '', noCache: true, affix: true }
  },
  {
    path: '/group',
    component: (resolve) => require(['@/views/Group'], resolve),
    name: 'group',
    meta: { title: 'group', icon: '', noCache: true, affix: true }
  },
  {
    path: '/daili',
    component: (resolve) => require(['@/views/daili'], resolve),
    name: 'daili',
    meta: { title: 'daili', icon: '', noCache: true, affix: true }
  }
]

// 防止连续点击多次路由报错
let routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}

export default new Router({
  mode: 'hash', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})
