var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.content.type == 'question')?_c('div',{staticClass:"conversation"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"default-text",staticStyle:{"display":"flex","flex-direction":"row","align-items":"center"}},[(!_vm.content.open)?_c('div',{staticStyle:{"display":"flex","flex-direction":"row","align-items":"center"}},[_c('span',{staticClass:"default-text"},[_vm._v("思考中")]),_c('div',{staticClass:"load default-text"})]):_c('div',{staticClass:"content"},[_c('div',[_vm._v(_vm._s(_vm.content.text))]),_c('div',{staticClass:"copy",on:{"click":function($event){return _vm.handleCopy(_vm.content.text)}}},[_c('img',{attrs:{"src":"https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/static/all/fuzhi.png","alt":""}})])])])]),_c('div',{staticClass:"headimg"},[_c('img',{attrs:{"src":_vm.userinfo.headimgurl,"alt":""}})])]):_vm._e(),(_vm.content.type != 'question')?_c('div',{staticClass:"answer"},[_vm._m(0),_c('div',{staticClass:"right"},[_c('div',{staticClass:"default-text",staticStyle:{"display":"flex","flex-direction":"row","align-items":"center"}},[(!_vm.content.open)?_c('div',{staticStyle:{"display":"flex","flex-direction":"row","align-items":"center"}},[_c('span',{staticClass:"default-text"},[_vm._v("思考中")]),_c('div',{staticClass:"load default-text"})]):_c('div',{staticClass:"ccontent"},[_c('MarkdownPreview',{attrs:{"theme":"oneDark","copyCode":true,"anchorAttributes":{ target: '_blank' },"initialValue":_vm.content.text + (_vm.content.loading ? _vm.loadingSpan : '')}}),_c('div',{staticClass:"aniu-btn"},[_c('img',{staticClass:"btn-img",attrs:{"src":"https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/static/all/fuzhi.png","alt":""},on:{"click":function($event){return _vm.handleCopy(_vm.content.text)}}}),(
                _vm.content.answerLikeTag == 'EMPTY' ||
                _vm.content.answerLikeTag == 'NO'
              )?_c('img',{staticClass:"btn-img",attrs:{"src":"https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/static/all/zan.png","alt":""},on:{"click":function($event){(_vm.content.answerLikeTag = 'YES'), _vm.niceORlose(_vm.content,'YES')}}}):_vm._e(),(_vm.content.answerLikeTag == 'YES')?_c('img',{staticClass:"btn-img",attrs:{"src":"https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/static/all/zan1.png","alt":""},on:{"click":function($event){(_vm.content.answerLikeTag = 'EMPTY'),
                  _vm.niceORlose(_vm.content,'EMPTY')}}}):_vm._e(),(
                _vm.content.answerLikeTag == 'EMPTY' ||
                _vm.content.answerLikeTag == 'YES'
              )?_c('img',{staticClass:"btn-img",attrs:{"src":"https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/static/all/cai.png","alt":""},on:{"click":function($event){(_vm.content.answerLikeTag = 'NO'), _vm.niceORlose(_vm.content,'NO')}}}):_vm._e(),(_vm.content.answerLikeTag == 'NO')?_c('img',{staticClass:"btn-img",attrs:{"src":"https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/static/all/cai1.png","alt":""},on:{"click":function($event){(_vm.content.answerLikeTag = 'EMPTY'),
                  _vm.niceORlose(_vm.content,'EMPTY')}}}):_vm._e()])],1)])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"headimg"},[_c('img',{attrs:{"src":"https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/logo/apple/60.png","alt":""}})])
}]

export { render, staticRenderFns }