<template>
  <div style="background-color: #ffffff; height: 100%; width: 100%; overflow-y: auto;">
    <div class="second-panel" style="display: flex; flex-direction: row; padding: 1rem 2rem; align-items: flex-start;">
      <van-image
          round
          width="5rem"
          height="5rem"
          :src="userinfo.headimgurl"
      />
      <div style="padding: 0.3rem 1rem 0 1rem; display: flex; flex-direction: column;">
        <span class="default-text" style="font-size: 1rem; font-weight: bold;">{{userinfo.nickname}}</span>
        <div v-if="memberCard" style="display: flex; flex-direction: row; align-items: flex-end;">
          <van-tag type="primary" size="medium">{{memberCard.cardName}}</van-tag>
          <span class="default-text" style="font-size: 0.68rem; color: #ccc; margin-left: 0.2rem;">{{memberCard.endTime ? (memberCard.endTime + '到期') : '永久有效'}}</span>
        </div>
        <div v-if="partnerCard" style="display: flex; flex-direction: row; align-items: flex-end; padding-top: 0.2rem;">
          <van-tag type="warning" size="medium">{{partnerCard.cardName}}</van-tag>
          <span class="default-text" style="font-size: 0.68rem; color: #ccc; margin-left: 0.2rem;">{{partnerCard.endTime ? (partnerCard.endTime + '到期') : '永久有效'}}</span>
        </div>
      </div>
    </div>
    <div style="display: flex; flex-direction: column; justify-content: center; margin: 0 2rem;">
      <div style="display: flex; flex-direction: row; justify-content: space-between; padding: 1rem 0rem;">
        <div class="money-field">
          <span class="default-text">已推荐人数</span>
          <span class="default-text">{{userinfo.userCount}}</span>
        </div>
        <div class="money-field">
          <span class="default-text">佣金总计</span>
          <span class="default-text">￥{{userinfo.balanceHistory}}</span>
        </div>
        <div class="money-field">
          <span class="default-text">可提现佣金</span>
          <span class="default-text">￥{{balance}}</span>
<!--          <span class="default-text">{{`￥${userinfo.frozeBalance}处理中`}}</span>-->
        </div>
      </div>
      <van-button round type="primary" @click="handleGoWithdraw" :disabled="withdrawLoading">提现</van-button>
    </div>

    <div style="margin: 1rem 0; padding-bottom: 5rem;">
      <van-tabs type="card" v-model="tab" @change="handleTabChange">
        <van-tab title="推荐记录" name="user">
          <div style="margin: 1rem 2rem;">
            <van-row gutter="10">
              <van-col span="14" style="color: #8c8c8c;">昵称</van-col>
              <van-col span="10" style="color: #8c8c8c; text-align: right;">浏览时间</van-col>
            </van-row>
            <van-row gutter="20" v-for="(item, index) in userList" :key="index" style="border-top: 0.01rem solid #cccccc; padding: 0.3rem 0;">
              <van-col span="14" style="color: #070707;"><span style="word-break: break-all;">{{item.nickname}}</span></van-col>
              <van-col span="10" style="color: #070707; text-align: right;"><span>{{item.createTime}}</span></van-col>
            </van-row>
            <div style="width: 100%; text-align: center; color: #cccccc;">
              <span>只展示最近20条</span>
            </div>
          </div>
        </van-tab>
        <van-tab title="佣金记录" name="commission">
          <div style="margin: 1rem 2rem;">
            <van-row gutter="10">
              <van-col span="10" style="color: #8c8c8c;">昵称</van-col>
              <van-col span="5" style="color: #8c8c8c;">类型</van-col>
              <van-col span="9" style="color: #8c8c8c; text-align: right;">金额</van-col>
            </van-row>
            <van-row gutter="20" v-for="(item, index) in commissionList" :key="index" style="border-top: 0.01rem solid #fff;">
              <van-col span="10" style="color: #070707;"><span style="word-break: break-all;">{{item.nickname}}</span></van-col>
              <van-col span="5" style="color: #070707;"><span style="word-break: break-all;">{{item.level === 1 ? '直佣' : '间佣'}}</span></van-col>
              <van-col span="9" style="color: #070707; text-align: right;"><span>￥{{item.amount}}</span></van-col>
            </van-row>
            <div style="width: 100%; text-align: center; color: #cccccc;">
              <span>只展示最近20条</span>
            </div>
          </div>
        </van-tab>
        <van-tab title="提现记录" name="withdraw">
          <div style="margin: 1rem 2rem;">
            <van-row gutter="10">
              <van-col span="8" style="color: #8c8c8c;">金额</van-col>
              <van-col span="6" style="color: #8c8c8c;">状态</van-col>
              <van-col span="10" style="color: #8c8c8c; text-align: right;">时间</van-col>
            </van-row>
            <van-row gutter="20" v-for="(item, index) in withdrawList" :key="index" style="border-top: 0.01rem solid #fff;">
              <van-col span="8" style="color: #070707;"><span>￥{{item.amount}}</span></van-col>
              <van-col span="6" style="color: #070707;"><span>{{item.status}}</span></van-col>
              <van-col span="10" style="color: #070707; text-align: right;"><span>{{item.withdrawTime}}</span></van-col>
            </van-row>
            <div style="width: 100%; text-align: center; color: #cccccc;">
              <span>只展示最近20条</span>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>

    <van-overlay :show="showWithdrawDialog">
      <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; height: 100%;">
        <div style="width: 18rem; background-color: #fff; padding: 0.5rem; border-radius: 1rem;">
          <van-field class="withdraw-field withdraw-amount" label-width="7.5rem" v-model="withdrawAmount"
                     label="提现金额:" placeholder="" readonly/>
          <van-field class="withdraw-field" label-width="7.5rem" v-model="withdrawAccount"
                     label="收款微信号(必填):" placeholder="" :readonly="withdrawAccountSet"/>
          <div style="display: flex; flex-direction: column; align-items: center;">
            <van-button style="width: 100%; margin-top: 1rem;" round type="primary" @click="handleWithdraw">确认提现</van-button>
          </div>
        </div>

        <van-icon name="cross" size="3rem" color="#fff" style="margin-top: 1rem;" @click="showWithdrawDialog = false"/>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import {commissions, users, withdraw, withdraws, getWithdrawAccount} from '@/api'
import {mapGetters} from "vuex";
export default {
  name: 'commission',
  computed: {
    ...mapGetters([
      'userinfo'
    ]),
    balance() {
      if (this.userinfo.balance && this.userinfo.frozeBalance) {
        return (parseFloat(this.userinfo.balance) - parseFloat(this.userinfo.frozeBalance)).toFixed(2)
      }
      return 0.00
    },
    canWithdraw() {
      if (this.balance > 0) {

        let minWithdrawAmount = 0
        if (this.userinfo.minWithdrawAmount && this.userinfo.minWithdrawAmount !== '') {
          minWithdrawAmount = parseFloat(this.userinfo.minWithdrawAmount)
        }

        if (this.balance < minWithdrawAmount) {
          return false
        }

        return true
      }
      return false
    },
    memberCard() {
      let r = null
      if (this.userinfo.cardList && this.userinfo.cardList.length > 0) {
        this.userinfo.cardList.forEach(i => {
          if (i.cardType === 'Member') {
            r = i
          }
        })
      }
      return r
    },
    partnerCard() {
      let r = null
      if (this.userinfo.cardList && this.userinfo.cardList.length > 0) {
        this.userinfo.cardList.forEach(i => {
          if (i.cardType === 'Partner') {
            r = i
          }
        })
      }
      return r
    },
  },
  data() {
    return {
      withdrawLoading: false,
      tab: 'users',
      commissionList: [],
      userList: [],
      withdrawList: [],
      withdrawAccount: '',
      withdrawAccountSet: false,
      withdrawAmount: '',
      showWithdrawDialog: false,
    }
  },
  mounted() {
    this.getUsers()
  },
  methods: {
    handleGoWithdraw() {
      this.withdrawAmount = this.balance
      if (!this.withdrawAccountSet) {
        getWithdrawAccount().then(res => {
          if (res.obj && res.obj !== '') {
            this.withdrawAccount = res.obj
            this.withdrawAccountSet = true
          }
          this.showWithdrawDialog = true
        })
      } else {
        this.showWithdrawDialog = true
      }
    },
    handleWithdraw() {

      this.withdrawLoading = true

      if (!this.withdrawAccountSet) {
        if (this.withdrawAccount === '') {
          this.$toast('请输入收款微信账号')
          this.withdrawLoading = false
          return
        }
      }

      if (this.balance <= 0) {
        this.$toast('余额不足')
        this.withdrawLoading = false
        return
      }
      let minWithdrawAmount = 0
      if (this.userinfo.minWithdrawAmount && this.userinfo.minWithdrawAmount !== '') {
        minWithdrawAmount = parseFloat(this.userinfo.minWithdrawAmount)
      }

      if (this.balance < minWithdrawAmount) {
        this.$toast(`不满足最小 ${minWithdrawAmount} 元提现金额`)
        this.withdrawLoading = false
        return
      }

      withdraw({
        amount: this.withdrawAmount,
        withdrawAccount: this.withdrawAccount,
      }).then(() => {
        this.$getLoginInfo()
        this.$toast('提交成功')
        this.showWithdrawDialog = false
        this.withdrawLoading = false
        this.getWithdraws()
      }).catch(() => {
        this.$toast('提交失败')
        this.withdrawLoading = false
      })
    },
    getCommissions() {
      commissions().then(res => {
        this.commissionList = res.obj
      })
    },
    getUsers() {
      users().then(res => {
        this.userList = res.obj
      })
    },
    getWithdraws() {
      withdraws().then(res => {
        this.withdrawList = res.obj
      })
    },
    handleTabChange(tab) {
      if (tab === 'commission') {
        this.getCommissions()
      }
      if (tab === 'user') {
        this.getUsers()
      }
      if (tab === 'withdraw') {
        this.getWithdraws()
      }
    },
  }
}
</script>
<style lang="less">
.money-field {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.money-field span {
  color: #111111;
}
</style>
