import Vue from 'vue'
// import Vant from 'vant'
// import 'vant/lib/index.css'
import '@/assets/css/index.less'
import '@/plugins/vant'
import App from './App.vue'
import router from './router'
import store from './store'
// import cookie from 'js-cookie'
import VueClipboard from 'vue-clipboard2'
// import VueTypedJs from 'vue-typed-js'
import 'github-markdown-css/github-markdown.css'
import {getLoginInfo, getChatChannelList, getGroupEnergyBalance} from '@/api'
import {v4 as uuidv4} from 'uuid'
// import { Toast } from 'vant'
import 'swiper/dist/css/swiper.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(VueAwesomeSwiper)


Vue.use(ElementUI)

Vue.use(VueClipboard)

// Vue.use(VueTypedJs)

Vue.config.productionTip = false

window.onresize = () => {
  store.dispatch('app/setAppSize', {width: document.body.clientWidth, height: document.body.clientHeight})
}

Vue.prototype.$eventBus = new Vue()

Vue.prototype.$getUuid = () => {
  return uuidv4()
}

Vue.prototype.$getLoginInfo = () => {
  getLoginInfo({url: '', loginChannel:'WechatPC'}).then(res => {
    const data = res.obj
    console.log(12313);
    localStorage.setItem("user",data)
    store.dispatch('app/setUserinfo', data)
    getChatChannelList().then(res => {
      localStorage.setItem('ChannelList',JSON.stringify(res.obj))
      store.dispatch('app/setChannelList', res.obj)
    })

  }).catch(err => {
    console.log(err)
  })
}
// getChatChannelList().then(res => {
//   localStorage.setItem('ChannelList',JSON.stringify(res.obj))
//   store.dispatch('app/setChannelList', res.obj)
// })

const getGroupEnergy = () => {
  let data = { groupId: store.state.app.group.id, page: 1, size: 100 };
  getGroupEnergyBalance(data).then((res) => {
    store.dispatch('app/setGroupEnergy', res.obj)
  });
}

store.dispatch('app/loadFromCache').then(() => {
  if (store.state.app.group !== null) {
    getGroupEnergy()
  }
})

let baseUrl = ''
if (window.location.host.startsWith('localhost')) {
  baseUrl = 'http://test.1search.top'
}

store.dispatch('app/setBaseUrl', baseUrl)

getLoginInfo({url: '',loginChannel:'WechatPC'}).then(res => {

  const data = res.obj

  store.dispatch('app/setUserinfo', data)
  localStorage.setItem('udata',JSON.stringify(data))

  getChatChannelList().then(res => {
    localStorage.setItem('ChannelList',JSON.stringify(res.obj))
    store.dispatch('app/setChannelList', res.obj)
  })

}).catch(err => {
  console.log(err)
  // Toast({message: err.errorCode, type: 'warning'})
})

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})

