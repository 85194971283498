<template>
  <div class="all">
    <div class="left1">
      <el-tabs
        v-model="activeName2"
        style="width: 100%; margin: auto"
        :stretch="true"
      >
        <el-tab-pane label="购买套餐" name="1">
          <div class="taocan">
            <div
              class="taocan_con"
              v-for="(item1, i) in datalist"
              :key="i"
              :style="
                order.id == item1.itemId
                  ? 'border: 5px rgb(45, 189, 255) solid; padding: 4px;'
                  : ''
              "
              @click="change_order(item1)"
            >
              <div
                :class="
                  i == 0
                    ? 'top_d'
                    : i == 1
                    ? 'top_n'
                    : i == 2
                    ? 'top_j'
                    : 'top_y'
                "
              >
                <span class="txt1">{{ item1.itemName }}</span>
                <span class="txt2">{{
                  "立即获得" + item1.configEnergy.energy + "点能量"
                }}</span>
                <span class="txt3">有效期{{ item1.configEnergy.days }}天</span>
              </div>
              <p
                style="
                  font-size: 12px;
                  color: #999999;
                  text-align: center;
                  line-height: 40px;
                "
              >
                —— 套餐服务 ——
              </p>

              <div
                style="
                  color: red;
                  font-size: 12px;
                  text-align: center;
                  margin-top: -10px;
                "
              >
                <p v-if="group">
                  <span
                    style="
                      font-size: 14px;
                      text-decoration: line-through;
                      color: #ff9c1b;
                      display: block;
                    "
                    v-if="group.discountRatio != 1"
                    >{{ item1.price }}/份</span
                  >
                  ¥<span style="font-size: 22px"
                    >{{
                      (item1.price * group.discountRatio * 1).toFixed(2)
                    }}/份</span
                  >
                </p>
                <p v-else>
                  ¥<span style="font-size: 20px"
                    >{{ (item1.price * 1).toFixed(2) }}/份</span
                  >
                </p>
              </div>
            </div>
          </div>
          <div class="num">
            数量
            <el-input-number
              v-model="order.number"
              @change="handleChange"
              :min="1"
              :max="9999"
            ></el-input-number>
          </div>
          <div class="zhifu">
            <span class="heji">合计：¥ {{ order.taocanprice.toFixed(2) }}</span>
            <div class="but" @click="gopay"></div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="已购套餐" name="2">
          <div
            style="width: 100%; text-align: center"
            v-if="EnergyCardList.length == 0"
          >
            暂无套餐~
          </div>
          <div class="taocan" style="overflow: auto">
            <div
              class="taocan_con"
              v-for="(item, i) in EnergyCardList"
              :key="i"
              style="margin-bottom: 20px"
            >
              <div
                :class="
                  item.cardType == 'USER_CARD_TYPE_4' ||
                  item.cardType == 'GROUP_CARD_TYPE_4'
                    ? 'top_n'
                    : item.cardType == 'USER_CARD_TYPE_1' ||
                      item.cardType == 'GROUP_CARD_TYPE_1'
                    ? 'top_d'
                    : item.cardType == 'USER_CARD_TYPE_3' ||
                      item.cardType == 'GROUP_CARD_TYPE_4'
                    ? 'top_j'
                    : item.cardType == 'USER_CARD_TYPE_2' ||
                      item.cardType == 'GROUP_CARD_TYPE_4'
                    ? 'top_y'
                    : item.cardType == 'CARD_TYPE_REGISTER' ||
                      item.cardType == 'GROUP_CARD_TYPE_4'
                    ? 'top_x'
                    : 'top'
                "
                v-if="item.cardType != 'CARD_TYPE_PARTNER'"
              >
                <span class="txt1" style="line-height: 100px">{{
                  item.cardName
                }}</span>
                <span class="txt2">{{ item ? item.dailyEnergy : "" }}</span>
              </div>
              <div
                style="
                  font-size: 14px;
                  text-align: center;
                  margin-top: 20px;
                  margin-bottom: 20px;
                "
              >
                <span class="txt3" style="color: #000">当前剩余能量</span>
              </div>
              <el-progress
                v-if="item"
                :percentage="
                  ((item.energyTotal - item.energy) / item.energyTotal) * 100
                "
                :show-text="false"
                style="width: 80%; margin: auto"
              ></el-progress>
              <div class="tiao_txt">
                <span
                  :class="
                    item.cardType == 'USER_CARD_TYPE_4'
                      ? 'txta1'
                      : item.cardType == 'USER_CARD_TYPE_1'
                      ? 'txta4'
                      : item.cardType == 'USER_CARD_TYPE_3'
                      ? 'txta3'
                      : item.cardType == 'USER_CARD_TYPE_2'
                      ? 'txta2'
                      : ''
                  "
                  >{{ item.energyTotal - item.energy }}</span
                >
                <span class="txtb" v-if="item">{{ item.energyTotal }}</span>
              </div>
            </div>
          </div></el-tab-pane
        >
      </el-tabs>
    </div>

    <el-dialog
      title="微信支付"
      :visible.sync="tanchuangbox"
      width="400px"
      :close-on-press-escape="false"
      :before-close="handleCloseshowlianxi2"
    >
      <div
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        "
      >
        <span style="margin-bottom: 20px">请扫码完成支付！</span>
        <img style="width: 300px" :src="weima" alt="" />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getLoginInfo,
  getUserEnergyCardList,
  items,
  pay,
  geytMa,
  OrderStatus,
  getGroupCardList,
} from "@/api";
import Daili from "./daili.vue";
import Group from "./Group.vue";
import { mapGetters } from "vuex";
// import axios from 'axios'
export default {
  name: "my",
  components: { Daili, Group },
  computed: {
    ...mapGetters(["userinfo", "notifyOpen", "clientHeight"]),
  },
  data() {
    return {
      userData: null,
      activeName: "1",
      activeName2: "1",
      EnergyCardList: [],
      sum: 0,
      tanchuangbox: false,
      datalist: [],
      order: {
        id: 4,
        number: 1,
        itemType: "USER_CARD_TYPE_4",
        taocanName: "",
        taocanprice: 0,
      },
      nowOrder: "",
      Status: null,
      weima: null,
      isgroup: false,
      group: null,
      updatagroup: "",
      group_role: null,
    };
  },
  watch: {
    $route: {
      handler: function (to) {
        console.log(to.path);
        console.log(to.query);
        if (to.query) {
          this.activeName = to.query.soat;
        }
      },
    },
  },
  beforeDestroy() {},
  mounted() {
    let obj = JSON.parse(localStorage.getItem("groupCon"));
    let user = JSON.parse(localStorage.getItem("udata"));
    if (obj == null) {
      console.log(11111111);
    } else {
      this.group = JSON.parse(localStorage.getItem("groupCon"));
      for (let i = 0; i < user.userGroupList.length; i++) {
        if (user.userGroupList[i].groupId == obj.id) {
          this.group_role = user.userGroupList[i].userRole;
        }
      }
    }
    console.log(this.$route.query.soat);
    this.activeName = this.$route.query.soat ? this.$route.query.soat : "1";
    if (localStorage.getItem("groupCon")) {
      this.isgroup = true;
    }
    let data = {};
    getLoginInfo(data).then((res) => {
      console.log('my getLoginInfo', res.obj);
      this.userData = res.obj;
    });
    items().then((res) => {
      console.log(res.obj);
      if (res.obj.length >= 1) {
        const carddata = res.obj;
        let newArr = carddata.filter((obj) => {
          let regExp = new RegExp(this.isgroup ? "GROUP_" : "USER_", "i");
          return regExp.test(obj) || regExp.test(obj.itemType);
        });
        console.log(newArr);
        this.datalist = newArr;
      }
      console.log("所有卡", this.datalist);
      if (this.group) {
        this.order.taocanprice =
        this.datalist[3].price * this.group.discountRatio * this.order.number;
      } else {
        this.order.taocanprice =
        this.datalist[3].price *this.order.number;
      }

      this.order.id = this.datalist[3].itemId;
      this.order.itemType = this.datalist[3].itemType;
      this.nowOrder = this.datalist[3];
    });
    console.log(1111111, this.isgroup);
    if (this.isgroup == true) {
      this.EnergyCardList = [];
      var id = JSON.parse(localStorage.getItem("groupCon")).id;
      let data = {
        groupId: id,
        page: 1,
        size: 1000,
      };
      getGroupCardList(data).then((res) => {
        console.log(res.obj);
        this.EnergyCardList = res.obj.records;
        const sum = res.obj.reduce(
          (total, item) => total + item.cardReceiveEnergy,
          0
        );
        this.sum = sum;
      });
    } else {
      this.EnergyCardList = [];
      getUserEnergyCardList(data).then((res) => {
        console.log(res.obj);
        this.EnergyCardList = res.obj;
        const sum = res.obj.reduce(
          (total, item) => total + item.cardReceiveEnergy,
          0
        );
        this.sum = sum;
      });
    }
    console.log("33333##33", this.EnergyCardList);
  },
  activated() {},
  methods: {
    upgroupdata(e) {
      console.log(e);
      this.updatagroup = e;
      this.$emit("update", e);
    },
    alerttanchuang() {
      this.tanchuangbox = true;
    },
    handleCloseshowlianxi2() {
      this.tanchuangbox = false;
    },
    handleChange() {
      console.log(this.order.number);
      this.order.taocanprice =
        this.nowOrder.price * this.group.discountRatio * this.order.number;
    },
    change_order(e) {
      console.log(e);
      this.nowOrder = e;
      this.order.id = e.itemId;
      this.order.itemType = e.itemType;
      if (this.group) {
        this.order.taocanprice =
        this.nowOrder.price * this.group.discountRatio * this.order.number;
      } else {
        this.order.taocanprice =
        this.nowOrder.price  * this.order.number;
      }

      console.log(this.order.id, this.order.itemType, this.order.taocanprice);
    },
    gopay() {
      // console.log(JSON.parse(localStorage.getItem("groupCon")).id);
      console.log(this.order);
      let data = {
        aiItemList: [
          {
            cardType: this.order.itemType,
            id: this.order.id,
            number: this.order.number,
          },
        ],
        qrPay: true,
        groupId: JSON.parse(localStorage.getItem("groupCon"))
          ? JSON.parse(localStorage.getItem("groupCon")).id
          : null,
      };
      pay(data).then((res) => {
        console.log(res);
        let data1 = { codeData: res.obj.params };

        geytMa(data1).then((res) => {
          // const blob = new Blob([res]);
          const url = window.URL.createObjectURL(res);
          this.weima = url;
          console.log(this.weima);
          this.tanchuangbox = true;
        });
        this.getOrderStatus(res.obj.orderNo.toString());
      });
    },
    getOrderStatus(data) {
      let data1 = {
        orderNo: data,
      };
      console.log(1111);
      this.Status = setInterval(() => {
        OrderStatus(data1).then((res) => {
          console.log(res);
          if (res.obj == "Success") {
            this.$message({
              type: "success",
              message: `支付成功`,
              duration: 1500,
            });
            this.tanchuangbox = false;
            var id = JSON.parse(localStorage.getItem("groupCon")).id;
            let data = {
              groupId: id,
              page: 1,
              size: 1000,
            };
            clearInterval(this.Status);
            getGroupCardList(data).then((res) => {
              console.log(res.obj);
              this.EnergyCardList = res.obj.records;
              const sum = res.obj.reduce(
                (total, item) => total + item.cardReceiveEnergy,
                0
              );
              this.sum = sum;
            });

            return;
          } else if (res.obj == "Failed") {
            this.$message({
              type: "error",
              message: `支付失败`,
              duration: 1500,
            });
            this.tanchuangbox = false;

            clearInterval(this.Status);
            return;
          }
        });
      }, 1000);
    },
  },
};
</script>
<style lang="less" scoped>
.all {
  width: 100%;
  // height: 90vh;
  background: #f4f4f4;
  margin-top: -10px;
  padding-top: 30px;
}

.top {
  width: 1124px;
  height: 174px;
  background: #ffffff;
  border-radius: 8px;
  margin: auto;
  position: relative;

  .hared {
    width: 120px;
    height: 120px;
    border-radius: 60px;
    position: absolute;
    top: 30px;
    margin-left: 30px;
  }

  .name {
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 24px;
    position: absolute;
    top: 40px;
    left: 180px;
  }

  .txt {
    position: absolute;
    top: 120px;
    left: 180px;
  }
}

.left1 {
  width: 1124px;
  min-height: 700px;
  margin: auto;
  background: #ffffff;
  border-radius: 10px;
  margin-top: 10px;
}

.taocan::-webkit-scrollbar {
  display: none;
}
.taocan {
  width: 100%;
  min-height: 380px;
  margin: auto;
  margin-top: -80px;
  padding: 20px;
  display: grid;
  grid-template-columns: auto auto auto auto;
  // border: 1px red solid;

  .taocan_con {
    width: 200px;
    height: 200px;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 8px 42px 0px rgba(210, 216, 218, 1);
    border-radius: 16px;
    overflow: hidden;
    // border: 5px rgb(45, 189, 255) solid;

    .top_n {
      width: 100%;
      height: 100px;
      background: url(../../public/static/img/my/矩形备份\ 3@2x.png);
      background-size: cover;
      border-radius: 10px;
    }

    .top_y {
      width: 100%;
      height: 100px;
      background: url(../../public/static/img/my/矩形@2x.png);
      background-size: cover;
      border-radius: 10px;
    }

    .top_j {
      width: 100%;
      height: 100px;
      background: url(../../public/static/img/my/矩形备份\ 2@2x.png);
      background-size: cover;
      border-radius: 10px;
    }

    .top_d {
      width: 100%;
      height: 100px;
      background: url(../../public/static/img/my/矩形备份\ 6@2x.png);
      background-size: cover;
      border-radius: 10px;
    }

    .top_x {
      width: 100%;
      height: 100px;
      background: linear-gradient(316deg, #7262fd 0%, #927eff 100%);
      border-radius: 10px;
    }

    .top {
      width: 100%;
      height: 100px;
    }

    .txt1 {
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      display: block;
      text-align: center;
      line-height: 50px;
    }

    .txt2 {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      display: block;
      text-align: center;
    }

    .txt3 {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      display: block;
      text-align: center;
    }

    .tiao_txt {
      width: 80%;
      margin: auto;
      display: flex;
      justify-content: space-between;
      .txta1 {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #24b1fb;
      }
      .txta2 {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #33ca7b;
      }
      .txta3 {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #f8bc91;
      }
      .txta4 {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #a1b6d7;
      }
      .txtb {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #c7c7c7;
      }
    }
  }
}
.taocan_but {
  width: 98%;
  height: 80px;
  border-radius: 10px;
  background: url(../../public/static/img/my/编组\ 27@2x.png);
  background-size: cover;
  span {
    font-size: 20px;
    font-family: YouSheBiaoTiHei;
    color: #ffffff;
    line-height: 80px;
    margin-left: 150px;
  }
  .but {
    padding: 10px;
    background: #ffffff;
    border-radius: 13px;
    opacity: 0.9;
    color: #0e61df;
    float: right;
    margin-top: 20px;
    margin-right: 40px;
  }
}
.num {
  width: 300px;
  float: right;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: -50px;
}

.zhifu {
  width: 80%;
  height: 120px;
  margin: auto;
  margin-top: 20px;
  background: url(https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/static/all/gomai.png)
    no-repeat top center;
  position: relative;
  .heji {
    color: #fff;
    font-size: 20px;
    font-weight: 600px;
    position: absolute;
    top: 30px;
    left: 150px;
  }
  .but {
    width: 30%;
    height: 100px;
    // border: 1px red solid;
    position: absolute;
    right: 55px;
  }
}

.daili {
  width: 100%;
  height: 700px;
}
</style>
