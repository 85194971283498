const getters = {
  baseUrl: state => {
    return state.app.baseUrl
  },
  userinfo: state => {
    if (state.app.userinfo) {
      return state.app.userinfo
    } else {
      return {}
    }
  },
  token: state => state.app.userinfo,
  channelList: state => state.app.channelList,
  clientWidth: state => state.app.clientWidth,
  clientHeight: state => state.app.clientHeight,
  shared: state => state.app.shared,
  askLimit: state => state.app.askLimit,
  askCount: state => state.app.askCount,
  notifyOpen: state => {
    if (!state.app.notifyOpen) {
      return false
    }
    if (state.app.userinfo) {
      return state.app.userinfo.notify !== ''
    }
    return false
  },
}
export default getters
