import Vue from 'vue'
import { Button, Field, Icon, Toast, Tabbar, TabbarItem, Image, CellGroup, Cell, Dialog,
    Overlay, Tabs, Tab, Tag, Col, Row, NoticeBar, ActionSheet, RadioGroup, Radio, Loading, Grid, GridItem } from "vant"
import 'vant/lib/button/style/less'
import 'vant/lib/field/style/less'
import 'vant/lib/icon/style/less'
import 'vant/lib/toast/style/less'
import 'vant/lib/tabbar/style/less'
import 'vant/lib/tabbar-item/style/less'
import 'vant/lib/image/style/less'
import 'vant/lib/cell-group/style/less'
import 'vant/lib/cell/style/less'
import 'vant/lib/dialog/style/less'
import 'vant/lib/overlay/style/less'
import 'vant/lib/tabs/style/less'
import 'vant/lib/tab/style/less'
import 'vant/lib/tag/style/less'
import 'vant/lib/col/style/less'
import 'vant/lib/row/style/less'
import 'vant/lib/notice-bar/style/less'
import 'vant/lib/notify/style/less'
import 'vant/lib/action-sheet/style/less'
import 'vant/lib/radio-group/style/less'
import 'vant/lib/radio/style/less'
import 'vant/lib/loading/style/less'
import 'vant/lib/grid/style/less'
import 'vant/lib/grid-item/style/less'

Vue.use(Button)
Vue.use(Field)
Vue.use(Icon)
Vue.use(Toast)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Image)
Vue.use(CellGroup)
Vue.use(Cell)
Vue.use(Dialog)
Vue.use(Overlay)
Vue.use(Tabs)
Vue.use(Tab)
Vue.use(Tag)
Vue.use(Col)
Vue.use(Row)
Vue.use(NoticeBar)
Vue.use(ActionSheet)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Loading)
Vue.use(Grid)
Vue.use(GridItem)
