<template>
  <div class="index-box">
    <div class="contentboxmian">
      <div class="contentbox">
        <div class="leftcontent">
          <el-tabs v-model="activeNameleft" @tab-click="handleClickleft">
            <el-tab-pane label="绘画指令" name="DRAW">
              <div class="taglist" :class="{ expanded: isExpanded }">
                <span>标签：</span>
                <div
                  class="taglistcontent"
                  :style="{ maxHeight: contentHeight }"
                >
                  <el-tag
                    :class="{ 'el-tag--info': true, curr: tagcurr == 0 }"
                    @click="selectone(0)"
                    >全部</el-tag
                  >
                  <el-tag
                    v-for="(item, index) in tagList"
                    :class="{ curr: selectedIds.includes(item.id) }"
                    @click="selectone(item)"
                    :key="index"
                    type="info"
                    >{{ item.tagName }}</el-tag
                  >
                </div>

                <div class="showmore" @click="toggleBox">
                  {{ isExpanded ? "收起" : "展开" }}
                </div>
              </div>
              <div class="taglist taglist2">
                <span>排序：</span>
                <div class="taglistcontent">
                  <el-tag
                    v-for="(item, index) in searchList"
                    :class="{ curr: searchcurr == item.value }"
                    :key="index"
                    @click="selecttwo(item.value)"
                    type="info"
                    >{{ item.label }}</el-tag
                  >
                </div>
              </div>
              <!-- 列表 -->
              <div class="instructionlist" v-loading="loading">
                <div
                  class="instruntblock imgstructionblock"
                  v-for="(item, index) in directList"
                  :key="index"
                >
                  <!-- 标签 推荐 上新等 -->
                  <!-- <div class="imgboxtop" :style="{ 'background-image': 'url(' + item.imgsrc + ')' }">
                                    </div> -->
                  <img
                    class="imgboxtop"
                    :src="item.imgsrc"
                    alt=""
                    style="margin-top: 0"
                    @click="goshow(item)"
                  />
                  <div class="topinst">
                    <div class="topinstleft">
                      <img
                        :src="
                          item.headimgurl
                            ? item.headimgurl
                            : 'https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/logo/apple/60.png'
                        "
                        alt=""
                      />
                      <div class="topinsright">
                        <p>{{ item.nickName }}</p>
                        <p class="timenew">{{ item.gmtCreate }}</p>
                        <!-- <span>关注</span> -->
                      </div>
                    </div>
                    <!-- <span class="guanzhu">关注</span> -->
                  </div>
                  <div class="listyycontent">
                    <h3 @click="goshow(item)">{{ item.squareName }}</h3>
                    <p @click="goshow(item)">{{ item.squareDesc }}</p>
                    <div class="cccontetins">
                      <span
                        v-for="(itm, idx) in item.userChatLogList"
                        :key="idx"
                        @click="goshow(item)"
                        >{{ itm.question }}</span
                      >
                    </div>
                    <div class="cccontenttags">
                      <span v-for="(itm, idx) in item.sysTagList" :key="idx"
                        >#{{ itm.tagName }}
                      </span>
                      <!-- <span v-if="item.tagOne">#{{ item.tagOne }} </span>
                                            <span v-if="item.tagTwo">#{{ item.tagTwo }} </span>
                                            <span v-if="item.tagThree">#{{ item.tagThree }} </span> -->
                    </div>
                  </div>
                  <!-- <div class="instbottomtag">
                                        <span>{{ item.categoryName }} </span>
                                    </div> -->
                  <div class="otherinst">
                    <div class="otherinstleft">
                      <!-- <span><img src="../assets/zan.png" alt="">222</span> -->
                      <span
                        v-if="
                          item.likeStatus == 'NO' || item.likeStatus == null
                        "
                        ><img
                          src="../assets/like.png"
                          @click="dianzan(item)"
                        />{{ item.likeCount }}</span
                      >
                      <span v-if="item.likeStatus == 'YES'"
                        ><img
                          src="../assets/ylike.png"
                          @click="dianzan(item)"
                        />{{ item.likeCount }}</span
                      >
                      <span
                        ><img
                          src="../assets/pl.png"
                          alt=""
                          @click="goshow(item)"
                        />{{ item.commentCount }}</span
                      >
                      <el-popconfirm
                    title="确定删除吗？"
                    @confirm="del_my(item, index)"
                    style="float: right;"
                    v-if="userinfo.userId==7371"
                  >
                    <i slot="reference" class="el-icon-delete"></i>
                  </el-popconfirm>
                    </div>
                    <!-- <button>我也要用</button> -->
                  </div>
                </div>
              </div>
              <button
                class="moreshow"
                @click="more"
                v-if="total > pageNum * pageSize"
              >
                查看更多
              </button>
            </el-tab-pane>
            <el-tab-pane label="聊天指令" name="CHAT">
              <div class="taglist" :class="{ expanded: isExpanded }">
                <span>标签：</span>
                <div
                  class="taglistcontent"
                  :style="{ maxHeight: contentHeight }"
                >
                  <el-tag
                    :class="{ 'el-tag--info': true, curr: tagcurr == 0 }"
                    @click="selectone(0)"
                    >全部</el-tag
                  >
                  <el-tag
                    v-for="(item, index) in tagList"
                    :class="{ curr: selectedIds.includes(item.id) }"
                    @click="selectone(item)"
                    :key="index"
                    type="info"
                    >{{ item.tagName }}</el-tag
                  >
                </div>

                <div class="showmore" @click="toggleBox">
                  {{ isExpanded ? "收起" : "展开" }}
                </div>
              </div>
              <div class="taglist taglist2">
                <span>排序：</span>
                <div class="taglistcontent">
                  <el-tag
                    v-for="(item, index) in searchList"
                    :class="{ curr: searchcurr == item.value }"
                    :key="index"
                    @click="selecttwo(item.value)"
                    type="info"
                    >{{ item.label }}</el-tag
                  >
                </div>
              </div>
              <!-- 列表 -->
              <div class="instructionlist" v-loading="loading">
                <div
                  class="instruntblock"
                  v-for="(item, index) in directList"
                  :key="index"
                >
                  <!-- 标签 推荐 上新等 -->
                  <div class="toptags">推荐</div>
                  <div class="topinst">
                    <div class="topinstleft">
                      <img
                        :src="
                          item.headimgurl
                            ? item.headimgurl
                            : 'https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/logo/apple/60.png'
                        "
                        alt=""
                      />
                      <div class="topinsright">
                        <p>{{ item.nickName }}</p>
                        <p class="timenew">{{ item.gmtCreate }}</p>
                        <!-- <span>关注</span> -->
                      </div>
                    </div>
                    <!-- <span class="guanzhu">关注</span> -->
                  </div>
                  <div class="listyycontent">
                    <h3 @click="goshow(item)">{{ item.squareName }}</h3>
                    <p @click="goshow(item)">{{ item.squareDesc }}</p>
                    <div class="cccontetins" @click="goshow(item)">
                      <span
                        v-for="(itm, idx) in item.userChatLogList"
                        :key="idx"
                        >{{ itm.question }}/{{ itm.answer }}</span
                      >
                    </div>
                    <div class="cccontenttags">
                      <span v-for="(itm, idx) in item.sysTagList" :key="idx"
                        >#{{ itm.tagName }}
                      </span>
                      <!-- <span v-if="item.tagOne">#{{ item.tagOne }} </span>
                                            <span v-if="item.tagTwo">#{{ item.tagTwo }} </span>
                                            <span v-if="item.tagThree">#{{ item.tagThree }} </span> -->
                    </div>
                  </div>
                  <!-- <div class="instbottomtag">
                                        <span>{{ item.categoryName }} </span>
                                    </div> -->
                  <div class="otherinst">
                    <div class="otherinstleft">
                      <!-- <span><img src="../assets/zan.png" alt="">222</span> -->
                      <span
                        v-if="
                          item.likeStatus == 'NO' || item.likeStatus == null
                        "
                        ><img
                          src="../assets/like.png"
                          @click="dianzan(item)"
                        />{{ item.likeCount }}</span
                      >
                      <span v-if="item.likeStatus == 'YES'"
                        ><img
                          src="../assets/ylike.png"
                          @click="dianzan(item)"
                        />{{ item.likeCount }}</span
                      >
                      <!-- <span><img  :src="item.likeStatus=='NO' ? '../assets/like.png' : '../assets/ylike.png'" @click="dianzan(item)">{{
                                                item.likeCount }}</span> -->
                      <span
                        ><img
                          src="../assets/pl.png"
                          alt=""
                          @click="goshow(item)"
                        />{{ item.commentCount }}</span
                      >
                    <el-popconfirm
                    title="确定删除吗？"
                    @confirm="del_my(item, index)"
                    style="float: right;"
                    v-if="userinfo.userId==7371"
                  >
                    <i slot="reference" class="el-icon-delete"></i>
                  </el-popconfirm>
                    </div>
                    
                    <!-- <button>我也要用</button> -->
                  </div>
                </div>
              </div>
              <button
                class="moreshow"
                @click="more"
                v-if="total > pageNum * pageSize"
              >
                查看更多
              </button>
            </el-tab-pane>
            <el-tab-pane label="应用指令" name="APP">
              <div class="taglist" :class="{ expanded: isExpanded }">
                <span>标签：</span>
                <div
                  class="taglistcontent"
                  :style="{ maxHeight: contentHeight }"
                >
                  <el-tag
                    :class="{ 'el-tag--info': true, curr: tagcurr == 0 }"
                    @click="selectone(0)"
                    >全部</el-tag
                  >
                  <el-tag
                    v-for="(item, index) in tagList"
                    :class="{ curr: selectedIds.includes(item.id) }"
                    @click="selectone(item)"
                    :key="index"
                    type="info"
                    >{{ item.tagName }}</el-tag
                  >
                </div>

                <div class="showmore" @click="toggleBox">
                  {{ isExpanded ? "收起" : "展开" }}
                </div>
              </div>
              <div class="taglist taglist2">
                <span>排序：</span>
                <div class="taglistcontent">
                  <el-tag
                    v-for="(item, index) in searchList"
                    :class="{ curr: searchcurr == item.value }"
                    :key="index"
                    @click="selecttwo(item.value)"
                    type="info"
                    >{{ item.label }}</el-tag
                  >
                </div>
              </div>
              <!-- 列表 -->
              <div class="instructionlist" v-loading="loading">
                <div
                  class="instruntblock"
                  v-for="(item, index) in directList"
                  :key="index"
                >
                  
                  <!-- 标签 推荐 上新等 -->
                  <div class="toptags">推荐</div>
                  <div class="topinst">
                    <div class="topinstleft">
                      <img
                        :src="
                          item.headimgurl
                            ? item.headimgurl
                            : 'https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/logo/apple/60.png'
                        "
                        alt=""
                      />
                      <div class="topinsright">
                        <p>{{ item.nickName }}</p>
                        <p class="timenew">{{ item.gmtCreate }}</p>
                        <!-- <span>关注</span> -->
                      </div>
                    </div>
                    <!-- <span class="guanzhu">关注</span> -->
                  </div>
                  <div class="listyycontent">
                    <h3 @click="goshow(item)">{{ item.squareName }}</h3>
                    <p @click="goshow(item)">{{ item.squareDesc }}</p>
                    <div class="cccontetins" @click="goshow(item)">
                      <span
                        v-for="(itm, idx) in item.userChatLogList"
                        :key="idx"
                        >{{ itm.question }}/{{ itm.answer }}</span
                      >
                    </div>
                    <div class="cccontenttags">
                      <span v-for="(itm, idx) in item.sysTagList" :key="idx"
                        >#{{ itm.tagName }}
                      </span>
                      <!-- <span v-if="item.tagOne">#{{ item.tagOne }} </span>
                                            <span v-if="item.tagTwo">#{{ item.tagTwo }} </span>
                                            <span v-if="item.tagThree">#{{ item.tagThree }} </span> -->
                    </div>
                  </div>
                  <!-- <div class="instbottomtag">
                                        <span>{{ item.categoryName }} </span>
                                    </div> -->
                  <div class="otherinst">
                    <div class="otherinstleft">
                      <!-- <span><img src="../assets/zan.png" alt="">222</span> -->
                      <span
                        v-if="
                          item.likeStatus == 'NO' || item.likeStatus == null
                        "
                        ><img
                          src="../assets/like.png"
                          @click="dianzan(item)"
                        />{{ item.likeCount }}</span
                      >
                      <span v-if="item.likeStatus == 'YES'"
                        ><img
                          src="../assets/ylike.png"
                          @click="dianzan(item)"
                        />{{ item.likeCount }}</span
                      >
                      <!-- <span><img  :src="item.likeStatus=='NO' ? '../assets/like.png' : '../assets/ylike.png'" @click="dianzan(item)">{{
                                                item.likeCount }}</span> -->
                      <span
                        ><img
                          src="../assets/pl.png"
                          alt=""
                          @click="goshow(item)"
                        />{{ item.commentCount }}</span
                      >
                      <el-popconfirm
                    title="确定删除吗？"
                    @confirm="del_my(item, index)"
                    style="float: right;"
                    v-if="userinfo.userId==7371"
                  >
                    <i slot="reference" class="el-icon-delete"></i>
                  </el-popconfirm>
                    </div>
                    <!-- <button>我也要用</button> -->
                  </div>
                </div>
              </div>
              <button
                class="moreshow"
                @click="more"
                v-if="total > pageNum * pageSize"
              >
                查看更多
              </button>
            </el-tab-pane>
          </el-tabs>
          <!-- <img src="../assets/chuangzuo.png" alt=""> -->
          <!-- b标签box -->
          <!-- <div class="taglist" :class="{ 'expanded': isExpanded }">
                        <span>标签：</span>
                        <div class="taglistcontent" :style="{ maxHeight: contentHeight }">
                            <el-tag :class="{ 'el-tag--info': true, 'curr': tagcurr == 0 }"
                                @click="selectone(0)">全部</el-tag>
                            <el-tag v-for="(item, index) in tagList" :class="{ 'curr': tagcurr == item.id }"
                                @click="selectone(item.id)" :key="index" type="info">{{ item.tagName }}</el-tag>
                        </div>

                        <div class="showmore" @click="toggleBox">{{ isExpanded ? '收起' : '展开' }}</div>
                    </div>
                    <div class="taglist taglist2">
                        <span>排序：</span>
                        <div class="taglistcontent">
                            <el-tag v-for="(item, index) in searchList" :class="{ 'curr': searchcurr == item.label }"
                                :key="index" @click="selecttwo(item.label)" type="info">{{ item.label }}</el-tag>
                        </div>
                    </div> -->
          <!-- 搜索栏目 -->
          <!-- <div class="searchbox">
                        <span>推荐指令</span>
                        <div class="rightcontentseach">
                            <el-form :inline="true" :model="formInline" class="demo-form-inline">
                                <el-form-item>
                                    <el-input v-model="formInline.user" placeholder="搜索你需要的内容"> <el-button slot="append"
                                            icon="el-icon-search"></el-button></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-select v-model="value" placeholder="请选择">
                                        <el-option v-for="item in options1" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-select v-model="value" placeholder="请选择">
                                        <el-option v-for="item in options2" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div> -->
          <!--列表 -->
        </div>
        <div class="rightcontent">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <!-- <el-tab-pane label="关注用户" name="first">关注用户</el-tab-pane> -->
            <el-tab-pane label="我创建的指令" name="second">
              <div class="instructionlist" v-if="utoken">
                <div
                  class="instruntblock"
                  v-for="(item, index) in mydirectList"
                  :key="index"
                >
                  <el-popconfirm
                    title="确定删除吗？"
                    @confirm="del_my(item, index)"
                    style="position: absolute; top: 10px; right: 20px"
                  >
                    <i slot="reference" class="el-icon-close"></i>
                  </el-popconfirm>

                  <!-- 标签 推荐 上新等 -->
                  <div class="topinst">
                    <div class="topinstleft">
                      <!-- <div class="imgboxtop" :style="{ 'background-image': 'url(' + item.imgsrc + ')' }"></div> -->
                      <img
                        :src="
                          item.headimgurl
                            ? item.headimgurl
                            : 'https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/logo/apple/60.png'
                        "
                        alt=""
                      />
                      <div class="topinsright">
                        <p>{{ item.nickName }}</p>
                        <!-- <span>关注</span> -->
                      </div>
                    </div>
                    <!-- <span class="time">{{ item.gmtCreate }}</span> -->
                  </div>
                  <div class="listyycontent">
                    <h3 @click="goshow(item)">{{ item.squareName }}</h3>
                    <p @click="goshow(item)">{{ item.squareDesc }}</p>
                    <div
                      class="cccontetins"
                      @click="goshow(item)"
                      v-if="item.type == 'CHAT_DIRECTIVE'"
                    >
                      <span
                        v-for="(itm, idx) in item.userChatLogList"
                        :key="idx"
                        >{{ itm.question }}/{{ itm.answer }}</span
                      >
                    </div>
                    <div
                      class="cccontetins"
                      @click="goshow(item)"
                      v-if="item.type == 'DRAW_DIRECTIVE'"
                    >
                      <span
                        v-for="(itm, idx) in item.userChatLogList"
                        :key="idx"
                        >{{ itm.question }}</span
                      >
                    </div>
                    <div
                      class="cccontetins"
                      @click="goshow(item)"
                      v-if="item.type == 'APP_DIRECTIVE'"
                    >
                      <span
                        v-for="(itm, idx) in item.userChatLogList"
                        :key="idx"
                        >{{ itm.question }}/{{ itm.questionTemplate }}</span
                      >
                    </div>
                    <img class="showhsow" :src="item.imgsrc" alt="" />
                    <div class="cccontenttags">
                      <span v-for="(itm, idx) in item.sysTagList" :key="idx"
                        >#{{ itm.tagName }}
                      </span>
                      <!-- <span v-if="item.tagOne">#{{ item.tagOne }} </span>
                                            <span v-if="item.tagTwo">#{{ item.tagTwo }} </span>
                                            <span v-if="item.tagThree">#{{ item.tagThree }} </span> -->
                    </div>
                  </div>
                  <!-- <div class="instbottomtag">
                                        <span>{{ item.categoryName }} </span>
                                    </div> -->
                  <div class="otherinst">
                    <div class="otherinstleft">
                      <!-- <span><img src="../assets/zan.png" alt="">222</span> -->
                      <span
                        ><img src="../assets/like.png" />{{
                          item.likeCount
                        }}</span
                      >
                      <span
                        ><img
                          src="../assets/pl.png"
                          alt=""
                          @click="goshow(item)"
                        />{{ item.commentCount }}</span
                      >
                    </div>
                  </div>
                </div>
                <div
                  style="
                    display: flex;
                    width: 100%;
                    background: #fff;
                    padding-top: 50px;
                  "
                >
                  <button
                    style="margin: 0 auto !important"
                    class="moreshow"
                    @click="moremy"
                    v-if="mytotal > mypageNum * mypageSize"
                  >
                    查看更多
                  </button>
                </div>
              </div>
              <div v-else style="font-size: 12px">
                登录后方可查看自己创建的指令哦～
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <!-- /标签弹窗 -->
    <!-- 登录弹窗 -->
    <el-dialog
      title="更多标签"
      :visible.sync="dialogTableVisiblerag"
      show-close
      width="400px"
      @close="closeDialogtag"
    >
      <div class="tagbox">
        <el-tag v-for="(item, index) in 100" :key="index" type="info"
          >标签三{{ item }}</el-tag
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getUserTagList,
  getDirectiveSquareList,
  saveSquareLike,
  deleteSquareById,
} from "@/api";
import { mapGetters } from "vuex";
export default {
  name: "square",
  components: {},
  computed: {
    ...mapGetters(["userinfo"]),
  },
  data() {
    return {
      activeName: "second",
      tagList: null,
      tagcurr: 0,
      searchcurr: "TYPE_1",
      directList: [],
      mydirectList: [],
      formInline: {},
      searchList: [
        {
          value: "TYPE_1",
          label: "最新发布",
        },
        {
          value: "TYPE_2",
          label: "评论最多",
        },
        // {
        //     value: "TYPE_3",
        //     label: "收藏最多",
        // },
        {
          value: "TYPE_4",
          label: "点赞最多",
        },
      ],
      options1: [
        {
          value: "1",
          label: "最新发布",
        },
        {
          value: "2",
          label: "评论最多",
        },
        {
          value: "3",
          label: "收藏最多",
        },
        {
          value: "4",
          label: "点赞最多",
        },
      ],
      options2: [
        {
          value: "展示图文指令",
          label: "展示图文指令",
        },
        {
          value: "只看聊天指令",
          label: "只看聊天指令",
        },
        {
          value: "只看绘画指令",
          label: "只看绘画指令",
        },
      ],
      tagType: "CHAT",
      utoken: null,
      activeNameleft: "DRAW",
      selectedTags: [],
      selectedIds: [],
      total: 0,
      mytotal: 0,
      isExpanded: false,
      pageNum: 1,
      pageSize: 12,
      mypageNum: 1,
      mypageSize: 3,
      contentHeight: "38px",
      dialogTableVisiblerag: false,
      loading: false,
    };
  },
  watch: {},
  beforeDestroy() {},
  mounted() {
    // this.getSysTagListmethod()
    // this.getDirectiveSquareList()
    // if (localStorage.getItem('token')) {
    //     this.utoken = localStorage.getItem('token')
    //     this.getDirectiveSquareListmy()
    // }
  },
  activated() {
    this.getSysTagListmethod();
    this.getDirectiveSquareList();
    if (localStorage.getItem("token")) {
      this.utoken = localStorage.getItem("token");
      this.getDirectiveSquareListmy();
    }
  },
  methods: {
    more() {
      this.pageNum += 1;
      this.getDirectiveSquareList();
    },
    moremy() {
      this.mypageNum += 1;
      this.getDirectiveSquareListmy();
    },
    dianzan(item) {
      // 判断登录 没登录先登录
      if (!localStorage.getItem("token")) {
        this.$message({
          type: "error",
          message: "请先登录",
          duration: 1500,
        });
        return false;
      }
      console.log(item);
      let data = {
        likeStatus: item.likeStatus == "YES" ? "NO" : "YES",
        squareId: item.id,
      };
      saveSquareLike(data).then((res) => {
        console.log(res);
        this.getDirectiveSquareList();
      });
      console.log(item);
    },
    goshow(item) {
      console.log("gogo", item);
      if (item.type == "APP_DIRECTIVE") {
        // localStorage.setItem("Aiappdata", JSON.stringify(item));
        this.$forceUpdate();
        this.$router.push({
          path: "/showdetaildis/" + item.id,
          query: {
            param: "square",
          },
        });
      } else {
        this.$router.push({ path: "/showdetail/" + item.id });
      }
    },
    // 获取我创建的
    getDirectiveSquareListmy() {
      let data = {
        page: this.mypageNum,
        size: this.mypageSize,
        myCreate: "1",
      };
      const mycurpage = this.mypageNum;
      getDirectiveSquareList(data).then((res) => {
        console.log(res);
        const datas = res.obj.records;
        let arrr = [];
        for (let a in datas) {
          if (
            datas[a].type == "DRAW_DIRECTIVE" &&
            datas[a].userChatLogList[0]
          ) {
            // console.log(datas[a])
            let imgsrc = JSON.parse(datas[a].userChatLogList[0].answer)[0].url;
            // console.log(imgsrc)
            arrr.push({
              ...datas[a],
              imgsrc: imgsrc,
            });
          } else {
            arrr.push(datas[a]);
          }
        }
        if (mycurpage > 1) {
          this.mydirectList = this.mydirectList.concat(arrr);
        } else {
          this.mydirectList = arrr;
        }
        this.mytotal = res.obj.total;

        // console.log(arrr)
      });
    },
    // 获取广场列表
    getDirectiveSquareList() {
      let data = {
        page: this.pageNum,
        size: this.pageSize,
        directiveType:
          this.activeNameleft == "CHAT"
            ? "CHAT_DIRECTIVE"
            : this.activeNameleft == "DRAW"
            ? "DRAW_DIRECTIVE"
            : "APP_DIRECTIVE",
        orderByType: this.searchcurr,
        sysTagIdList: this.selectedIds.length < 1 ? null : this.selectedIds,
      };
      const curpage = this.pageNum;
      // console.log(curpage)
      getDirectiveSquareList(data).then((res) => {
        console.log(res);
        const datas = res.obj.records;
        let arrr = [];
        for (let a in datas) {
          if (
            datas[a].type == "DRAW_DIRECTIVE" &&
            datas[a].userChatLogList[0]
          ) {
            // console.log(datas[a])
            let imgsrc = JSON.parse(datas[a].userChatLogList[0].answer)[0].url;
            // console.log(imgsrc)
            arrr.push({
              ...datas[a],
              imgsrc: imgsrc,
            });
          } else {
            arrr.push(datas[a]);
          }
        }
        if (curpage > 1) {
          this.directList = this.directList.concat(arrr);
        } else {
          this.directList = arrr;
        }
        this.total = res.obj.total;
        // console.log(arrr)
      });
    },
    selectone(tag) {
      if (tag == 0) {
        this.tagcurr = 0;
        this.selectedTags = [];
        this.selectedIds = [];
        this.getDirectiveSquareList();
        return;
      }
      // this.tagcurr = data
      // console.log(this.selectedIds.includes(tag.id))
      // if (this.selectedIds.length >= 3&&!this.selectedIds.includes(tag.id)) {
      if (!this.selectedIds.includes(tag.id)) {
        this.tagcurr = null;
        this.selectedTags.push(tag);
        this.selectedIds.push(tag.id);
      } else if (this.selectedIds.includes(tag.id)) {
        const index = this.selectedTags.indexOf(tag);
        // console.log('111',index)

        this.selectedTags.splice(index, 1);
        this.selectedIds.splice(index, 1);
      }

      console.log(this.selectedIds);

      this.getDirectiveSquareList();

      // console.log('data', data)
    },
    selecttwo(data) {
      this.searchcurr = data;
      console.log(data);
      this.getDirectiveSquareList();
    },
    tableftnethod(type) {
      console.log(type);
      this.activeNameleft = type;
    },
    getSysTagListmethod() {
      let data = {
        tagType: this.activeNameleft,
      };
      getUserTagList(data).then((res) => {
        this.tagList = res.obj;
      });
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleClickleft(tab) {
      this.loading = true;
      // console.log(tab)
      this.tagType = tab.index == 0 ? "DRAW" : tab.index == 1 ? "CHAT" : "APP";
      this.activeNameleft =
        tab.index == 0 ? "DRAW" : tab.index == 1 ? "CHAT" : "APP";
      this.getSysTagListmethod();
      this.getDirectiveSquareList();
      setTimeout(() => {
        this.loading = false;
      }, 1000);
      this.pageNum = 1;
    },
    toggleBox() {
      this.isExpanded = !this.isExpanded;
      this.contentHeight = this.isExpanded ? "none" : "38px"; // 设置内容的高度
    },
    showmore() {
      this.dialogTableVisiblerag = true;
    },
    closeDialogtag() {
      this.dialogTableVisiblerag = false;
    },
    del_my(e, q) {
      console.log(e, q);
      let data = {
        id: e.id,
      };
      deleteSquareById(data).then((res) => {
        if (res.success == true) {
          this.$message({
            type: "success",
            message: "删除成功",
          });
          for (let i = 0; i < this.mydirectList.length; i++) {
            if (this.mydirectList[i].id == e.id) {
              this.mydirectList.splice(i, 1);
            }
          }
          for (let i = 0; i < this.directList.length; i++) {
            if (this.directList[i].id == e.id) {
              this.directList.splice(i, 1);
            }
          }
        } else {
          this.$message({
            type: "error",
            message: `删除失败`,
          });
        }
        console.log(res);
      });
    },
  },
};
</script>
<style scoped>
body::-webkit-scrollbar {
  display: none;
}
.tag-box .content {
  transition: max-height 0.3s ease;
  max-height: 38px;
  overflow: hidden;
  /* 初始显示的高度，可以根据实际需求调整 */
}

.tag-box.expanded .content {
  max-height: none;
}

.taglistcontent {
  overflow: hidden;
}
</style>
<style lang="less" scoped>
.tagbox {
  max-height: 400px;
  overflow: auto;

  .el-tag {
    margin: 5px 5px;
    cursor: pointer;
  }
}

.contentboxmian {
  width: 1200px;
  margin: 0 auto;

  .contentbox {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .leftcontent {
      width: calc(1200px - 310px);

      .el-tabs {
        padding: 20px 20px;
      }

      .el-tabs__nav-scroll {
        display: flex !important;
      }

      img {
        width: 100%;
        margin-top: 20px;
      }

      .taglist2 {
        padding: 0 0 20px !important;
        margin-top: 0 !important;
      }

      .taglist {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        // height: 115px;
        overflow: auto;
        background: #ffffff;
        border-radius: 8px 8px 0px 0px;
        padding: 20px 0;
        margin-top: 20px;

        .taglistcontent {
          width: calc(100% - 150px);

          .el-tag {
            margin: 5px 5px;
            cursor: pointer;
          }

          .curr {
            background: #f1f7ff;
            border-radius: 3px;
            color: #519eff !important;
          }
        }

        .showmore {
          color: #519eff !important;
          cursor: pointer;
          position: relative;
        }

        // span {
        //     padding: 5px 15px;
        //     border-radius: 3px;
        //     cursor: pointer;
        // }
      }

      .searchbox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 20px 0 0;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #474b51;
        line-height: 20px;

        .rightcontentseach {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          align-content: center;
          width: 90%;

          .el-form-item {
            margin-bottom: 0 !important;
          }
        }
      }
    }

    .rightcontent {
      background-color: #ffffff;
      width: 290px;
      min-height: 200px;
      padding: 20px;

      .instructionlist {
        position: relative;
        z-index: 99;
        margin: 0;
        background: #f4f4f4;

        .instruntblock {
          margin: 0;
          width: 250px;
          position: relative;

          .listyycontent {
            min-height: 100px;
            height: auto !important;
          }

          .otherinstleft {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
          }
        }
      }
    }
  }
}
</style>
<style scoped>
.instructionlist {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  position: relative;
  z-index: 99;
}

.instruntblock {
  background: #ffffff;
  border-radius: 8px;
  position: relative;
  /* width: 435px; */
  width: 415px;
  height: 371px;
  padding: 20px 16px;
  margin: 9px 0;
  border: 1px solid #ddd;
  margin-right: 20px !important;
  cursor: pointer;
}

.instruntblock:nth-child(2n + 2) {
  margin-right: 0 !important;
}

.instruntblock .toptags {
  position: absolute;
  right: 0;
  top: 0;
  width: 47px;
  height: 20px;
  background: linear-gradient(310deg, #3c83ff 0%, #619fff 100%);
  border-radius: 0px 0px 0px 15px;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  text-align: center;
}

.topinst {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.topinstleft {
  display: flex;
  align-items: center;
}

.topinstleft .topinsright {
  margin-left: 10px;
}

.topinstleft .topinsright span {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #519eff;
  line-height: 12px;
}

.topinstleft .topinsright p {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #2c343e;
  line-height: 12px;
}

.topinstleft img {
  width: 37px !important;
  height: 37px !important;
  border-radius: 50%;
  margin-top: 0 !important;
}

.timenew {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #969899 !important;
  line-height: 12px;
  margin-top: 5px;
}

.guanzhu {
  width: 76px;
  height: 33px;
  background: #519eff;
  border-radius: 6px;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.yguanzhu {
  width: 76px;
  height: 33px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #191919;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.topinst .time {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #969899;
  line-height: 12px;
}

.instruntblock .listyycontent {
  /* max-height: 436px; */
  height: 236px;
}

.instruntblock .listyycontent p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #969899;
  line-height: 14px;
  margin-top: 10px;
  word-break: break-all;
  overflow: hidden;
  font-family: PingFangSC-Medium, PingFang SC;
  line-height: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
}

.instruntblock .listyycontent h3 {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #292929;
  line-height: 16px;
  margin-top: 12px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
}

.instruntblock .listyycontent .cccontetins {
  margin-top: 16px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #969899;
  overflow: hidden;
  line-height: 22px;
  display: -webkit-box;
  /*使用旧版本的 WebKit 内核浏览器*/
  -webkit-box-orient: vertical;
  /*设置文本在垂直方向上排列*/
  -webkit-line-clamp: 7;
  /*限制显示三行，超出部分隐藏*/
  text-overflow: ellipsis;
  overflow: hidden;
  /*超出部分显示省略号*/
  max-height: 150px;
}

.instruntblock .instbottomtag {
  margin-top: 10px;
}

.instruntblock .instbottomtag span {
  display: inline-block;
  background: #f4f5f5;
  border-radius: 2px;
  padding: 4px 10px;
  background: #f4f5f5;
  border-radius: 2px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #474b51;
  line-height: 12px;
}

.instruntblock .listyycontent .cccontenttags {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #19559e;
  line-height: 12px;
  margin-top: 8px;
}

.instruntblock .otherinst {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 999;
}

.otherinstleft span {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #989898;
  line-height: 12px;
  display: inline-block;
  margin-right: 20px;
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.otherinst button {
  width: 89px;
  height: 33px;
  background: #ffffff;
  border-radius: 17px;
  border: 1px solid #f0f0f0;
  outline: none;
  cursor: pointer;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  line-height: 17px;
}

.otherinstleft img {
  display: inline-block;
  width: 15px !important;
  height: auto;
  margin-right: 6px;
  margin-top: 0 !important;
}

.otherinstleft {
  display: flex;
}

.green {
  background: linear-gradient(310deg, #2fc3b0 0%, #34c4a4 100%) !important;
}

.haveimgblock {
}

.imgandword {
  position: relative;
  padding: 0 0 0 166px;
}

.el-tabs {
  background-color: #ffffff !important;
}

.leftcontent .el-tabs__nav-wrap {
  /* border: 1px solid red */
}

.imgstructionblock {
}

.imgstructionblock {
  width: 270px !important;
  height: 440px !important;
  padding: 0 !important;
  overflow: hidden;
}

.imgstructionblock:nth-child(2n + 2) {
  margin-right: 20px !important;
}

.imgstructionblock:nth-child(3n + 3) {
  margin-right: 0 !important;
}

.imgboxtop {
  width: 270px;
  height: 210px;
}

.imgstructionblock .listyycontent {
  padding: 0 16px;
}

.imgstructionblock .instbottomtag {
  padding: 0 16px;
}

.imgstructionblock .otherinst {
  padding: 0 16px;
}

.imgstructionblock .cccontetins {
  margin-top: 16px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #969899;
  overflow: hidden;
  line-height: 22px;
  max-height: 44px !important;
  height: 44px !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2 !important;
  text-overflow: ellipsis;
  overflow: hidden;
}

.imgstructionblock .listyycontent {
  height: auto !important;
}

.imgstructionblock .topinst {
  padding: 0 16px;
}

.imgstructionblock .otherinstleft {
  display: flex;
  width: 100%;
  /* justify-content: space-between; */
}

.imgstructionblock .otherinstleft span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rightcontent .instruntblock {
  margin-right: 0 !important;
  border: none !important;
  height: auto !important;
  padding-top: 0 !important;
  border-bottom: 1px dashed #ddd !important;
}

.rightcontent .otherinstleft span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.showhsow {
  max-width: 290px !important;
  height: auto !important;
}

.moreshow {
  display: block;
  width: 222px;
  height: 39px;
  background: #ffffff;
  border-radius: 20px;
  border: 1px solid #519eff;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #519eff;
  margin: 40px auto 80px;
  cursor: pointer;
}
</style>