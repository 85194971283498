<template>
    <div class="examples">
      <div>
        <h3 class="default-text">你可以这样问我</h3>
      </div>
      <div class="questionbox">
        <div class="examples-btn" v-for="(item, index) in examples" :key="index" @click="handleExample(item)" type="default"
          round>{{ item }}</div>
      </div>
    </div>
  </template>
  <script>
  export default {
    name: 'QuestionExamples2',
    props: {
      from: {
        type: String,
        default: 'chat'
      },
    },
    data() {
      return {
        activeName: '',
        tagList: null,
        drawer: false,
        examples:['企业定制流程？','企业定制可以支持托管上传领域知识库吗？','企业定制有哪些服务和哪些优势？','企业定制可以通过api接入到我们自己的系统里吗？','你们企业定制现在是怎么收费的？','系统异常问题，我要反馈']
      }
    },
    mounted() {
    },
    methods: {
     
      moreshow() {
        this.drawer = true
      },
      handleExample(example) {
        this.$eventBus.$emit(this.from + 'example2', example)
      }
    }
  }
  </script>
  <style lang="less" scoped>
  .questionbox {
    margin-top: 20px;
  }
  
  .examples {
    width: 80%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    font-size: 14px;
  
    .default-text {
      color: #333333;
      
    }
  }
  
  .questionbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  
    .examples-btn {
      width: 30%;
      height: 66px;
      border-radius: 8px;
      box-sizing: border-box;
      line-height: 26px;
      cursor: pointer;
      font-size: 12px;
      padding: 10px;
      margin-bottom: 12px;
      background-color: #f7f7f8;
      background-color: rgb(255 255 255);
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden; //溢出内容隐藏
      text-overflow: ellipsis; //文本溢出部分用省略号表示
      display: -webkit-box; //特别显示模式
      -webkit-line-clamp: 2; //行数
      line-clamp: 2;
      -webkit-box-orient: vertical; //盒子中内容竖直排列
      border: 1px solid #E5E5E5;
    }
  
  }
  
  .more {
    cursor: pointer;
    margin-top: 20px;
  }
  .disbox{
    padding: 20px 20px;
    .infinite-list-item{
      background: #F6F7FB;
      padding: 5px 10px;
      height: 40px;
      list-style: none;
      line-height:40px;
      color: #333;
      margin-bottom: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      cursor: pointer;
    }
  }
  </style>
  