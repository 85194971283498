<template>
  <div class="index" style="min-height: calc(100vh - 92px)">
    <div class="lefttools">
      <div class="chatlist">
        <div class="addchat" @click="newchat()">
          <i class="el-icon-plus"></i> 聊天窗口
        </div>
        <div class="chatlistbox">
          <div
            class=""
            v-for="(item, index) in historyList"
            :key="index"
            @click="gothis(item)"
          >
            <i class="el-icon-chat-dot-round"></i>
            <span>{{ item.chatTitle }}</span>
            <i
              @click.stop="deleteDialog(item, item.id)"
              class="el-icon-delete"
            ></i>
          </div>
        </div>
      </div>
    </div>
    <div
      class="chat-panel"
      :style="`height: ${height}px;overflow-y: auto;overflow-x: hidden;`"
    >
      <div
        :style="`height: ${
          height - 100
        }px;overflow-y: auto;overflow-x: hidden;`"
      >
        <div ref="qaContent" v-if="conversationList.length > 0">
          <conversation
            v-for="(item, index) in conversationList"
            :key="index"
            :conversation="item"
          ></conversation>
          <div
            id="conversationListEnd"
            style="height: 0px; overflow: hidden"
          ></div>
        </div>

        <question-examples
          v-else
          title="三只阿猫智能助手"
          style="margin-top: 20px"
          :examples="examples"
        ></question-examples>
      </div>
      <search-input
        :chatid="chatid"
        :inputvalue="inputvalue"
        @shuaxing="newchat()"
        ref="childRef"
      ></search-input>

      <van-dialog
        v-model="showImgDialog"
        :title="showImgTitle"
        @close="handleShowImgClose"
      >
        <img style="width: 100%" :src="showImgUrl" />
      </van-dialog>
      <!-- 能量 不够弹窗 -->
      <el-dialog title="" :visible.sync="dialogTableVisiblebugou" width="400px">
        <div class="titledes">
          您的能量已不足,请去公众号 购买套餐 或者 完成任务 获取能量!
        </div>
        <div class="actbtn">
          <!-- <div class="actblock">去做任务</div>
        <div class="actblock">购买套餐</div> -->
        </div>
      </el-dialog>
      <!-- /导出指令 -->
      <el-dialog
        title="导出指令"
        :visible.sync="daochudrawer"
        width="600px"
        direction="rtl"
      >
        <!-- 选择导出到哪儿 -->
        <div class="daochuone" v-if="currdaochu == 1">
          <div class="daochubox">
            <div class="daochu" @click="nextdaochu('SQUARE')">
              <img src="../assets/dc1.png" alt="" />导出到广场
            </div>
            <div class="daochu" @click="nextdaochu('MARKET')">
              <img src="../assets/dc2.png" alt="" />导出到市场
            </div>
          </div>
        </div>
        <div class="one" v-if="currdaochu == 2">
          <!-- <div class="daochuliaotianlist">
            <el-checkbox-group v-model="checkList">
              <el-checkbox :label="item" v-for="(item, index) in conversationList" :key="index">
                <div class="daochublock">
                  <img src="https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/logo/apple/60.png" alt="">
                  <div class="contentdaochu">{{ item.messages[1].text }}</div>
                </div>
              </el-checkbox>
            </el-checkbox-group>

          </div> -->
          <!-- /作品设置 -->
          <div class="settingmsg">
            <el-form ref="form" :model="form" label-width="80px">
              <el-form-item label="作品名称:">
                <el-input v-model="form.name" placeholder="(必填)"></el-input>
              </el-form-item>
              <el-form-item label="作品描述:">
                <el-input
                  v-model="form.desc"
                  type="textarea"
                  placeholder="(非必填)"
                ></el-input>
              </el-form-item>
              <!-- <el-form-item label="作品分类:">
                <div class="selectbox">
                  <div class="blockselect">
                    <span>一级分类：</span>
                    <el-select v-model="bigcatevalue" placeholder="请选择一级分类" @change="bigcatechange">
                      <el-option v-for="item in bigoptions" :key="item.id" :label="item.name" :value="item.id">
                      </el-option>
                    </el-select>
                  </div>
                  <div class="blockselect">
                    <span>二级分类：</span>
                    <el-select v-model="subcatevalueid" placeholder="请选择二级分类" @change="subchange">
                      <el-option v-for="item in suboptions" :key="item.id" :label="item.name" :value="item.id">
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </el-form-item> -->
              <el-form-item label="标签:">
                <el-select
                  style="width: 100%"
                  v-model="tagvalue"
                  multiple
                  filterable
                  :multiple-limit="3"
                  allow-create
                  default-first-option
                  placeholder="请选择标签"
                  @change="tagchange"
                >
                  <el-option
                    v-for="item in tagList"
                    :key="item.id"
                    :label="item.tagName"
                    :value="item.tagName"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="fabuchangjian"
                  >确认发布</el-button
                >
                <el-button @click="cancle">取消</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="one"></div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import Conversation from "@/components/Conversation.vue";
import QuestionExamples from "@/components/QuestionExamples.vue";
import SearchInput from "@/components/SearchInput.vue";
// import { tickets, answer, getSysTagList, getRandomSysTag, getChatList, getChatLogByChatId, updateChat } from '@/api'
import {
  getGroupEnergyBalance,
  tickets,
  answer,
  getUserTagList,
  getRandomSysTag,
  getChatList,
  getChatLogByChatId,
  updateChat,
  getCategoryList,
  exportDirective,
} from "@/api";
import chatgpt from "@/utils/chatgpt";
import bing from "@/utils/bing";
import stabilityai from "@/utils/stabilityai";
import { mapGetters } from "vuex";
export default {
  name: "chat",
  components: {
    Conversation,
    QuestionExamples,
    SearchInput,
  },
  computed: {
    ...mapGetters([
      "notifyOpen",
      "userinfo",
      "clientHeight",
      "shared",
      "askCount",
      "askLimit",
      "channelList",
    ]),
    height() {
      let h = this.clientHeight - 160;
      if (this.notifyOpen) {
        h -= 40;
      }
      return h;
    },
    memberCard() {
      let r = null;
      if (this.userinfo.cardList && this.userinfo.cardList.length > 0) {
        this.userinfo.cardList.forEach((i) => {
          if (i.cardType === "Member") {
            r = i;
          }
        });
      }
      return r;
    },
  },
  data() {
    return {
      questionParam: null,
      bugou: false,
      form: {
        name: "",
        desc: "",
      },
      conversationList: [],
      checkList: [],
      bigoptions: [],
      suboptions: [],
      tagvalue: [],
      currdaochu: 1,
      exportChannel: null,
      historyList: [],
      chatid: null,
      pushStatus: null,
      subcatevalue: null,
      subcatevalueid: null,
      bigcatevalue: null,
      cateoptions: [],
      tagsoptions: [],
      chatLevel: null,
      daochudrawer: false,
      searchChannelList: null,
      total: 0,
      pages: {
        page: 1,
        size: 10,
      },
      dialogTableVisiblebugou: false,
      examples: [],
      showImgDialog: false,
      value: "",
      showImgUrl: "",
      cateid: "",
      showImgTitle: "",
      showSelectSearchChannel: false,
      sysTagList: [],
      bingConversation: null,
      inputvalue: "",
      groupDataType: "",
      newChatLog: [],
    };
  },
  watch: {},
  beforeDestroy() {
    this.$eventBus.$off("chatsearch");
    this.$eventBus.$off("chatstop");
    this.$eventBus.$off("cahtclearChat");
  },
  created() {
    let newarr = [];
    const ChannelList = JSON.parse(localStorage.getItem("ChannelList"));
    if (ChannelList) {
      ChannelList.forEach((i) => {
        if (!i.disabled) {
          if (i.type === "chat") {
            newarr.push(i);
          }
        }
      });
    }

    // console.log(newarr)
    this.searchChannelList = newarr;
    // 看看有没有值
    if (this.$route.query.param) {
      const paramValue = this.$route.query.param
        ? JSON.parse(this.$route.query.param)
        : "";
      console.log("paramValue", paramValue);
      const aa = paramValue.map(function (obj) {
        return obj.question;
      });
      // console.log(aa)
      this.inputvalue = aa.join(",");
      // this.$eventBus.$emit(this.from + 'example', paramValue[0].question)
    }
    // 分类
    // this.getCategoryList()
    // this.$getLoginInfo();
    // 聊天历史
    this.getChatListLog();
    // 聊天标签
    this.getRandomSysTagmethod();
    this.getSysTagListmethod();
  },
  mounted() {
    // const str = localStorage.getItem('conversationList')
    // if (str && str !== '') {
    //   this.conversationList = JSON.parse(str)
    // }

    this.$eventBus.$on("chat" + "search", (param) => {
      this.handleSearch(param);
    });
    this.$eventBus.$on("chat" + "stop", () => {
      this.handleStop();
    });
    this.$eventBus.$on("chat" + "clearChat", () => {
      this.conversationList = [];
    });
    localStorage.removeItem('msgList')
  },
  activated() {
    this.scrollToBottom();
    // 看看有没有值
    if (this.$route.query.param) {
      const paramValue = this.$route.query.param
        ? JSON.parse(this.$route.query.param)
        : "";
      console.log("paramValue", paramValue);
      const aa = paramValue.map(function (obj) {
        return obj.question;
      });
      // console.log(aa)
      this.inputvalue = aa.join(",");
      // this.$eventBus.$emit(this.from + 'example', paramValue[0].question)
    }
  },
  methods: {
    cancle() {
      this.daochudrawer = false;
    },
    bigcatechange(e) {
      console.log(e);
      this.cateid = e;
      this.subcatevalue = null;
      this.getCategoryListsub();
    },
    subchange(e) {
      const result = this.suboptions.filter((item) => item.id === e);
      // console.log('result',result)

      this.subcatevalue = result[0].name;
    },
    tagchange(e) {
      const result = e.map((tagName) => {
        let item = this.tagList.find((i) => i.tagName === tagName);
        if (item) {
          return item;
        } else {
          return { tagName: tagName, tagType: null };
        }
      });

      // console.log('result', result)
      // console.log('e', e)
      // sysTagList
      this.sysTagList = result;
    },
    // 获取分类数据
    getCategoryListsub() {
      let data = {
        id: this.cateid,
      };
      getCategoryList(data).then((res) => {
        this.suboptions = res.obj;
      });
    },
    getCategoryList() {
      let data = {
        id: "",
      };
      getCategoryList(data).then((res) => {
        this.bigoptions = res.obj;
      });
    },
    // 发布创建
    fabuchangjian() {
      // console.log('this.form', this.form)
      // console.log('subcatevalue子集id', this.subcatevalueid)
      // console.log('subcatevalue', this.subcatevalue)
      // console.log('sysTagList', this.sysTagList)
      // console.log('this.checkList', this.checkList)
      // if (this.checkList.length < 1 || this.exportChannel == null || this.subcatevalueid == null || this.subcatevalue == null || this.form.name == '' || this.form.desc == '') {
      //   this.$message({
      //     type: 'error',
      //     message: '请检查数据是否完整'
      //   });
      //   return false
      // }
      if (
        this.exportChannel == null ||
        this.form.name == "" ||
        this.sysTagList == ""
      ) {
        this.$message({
          type: "error",
          message: "请检查数据是否完整",
          duration: 1500,
        });
        return false;
      }
      let result = [];

      this.conversationList.forEach((chat) => {
        chat.messages.forEach((message) => {
          if (message.type === "question") {
            result.push({
              id: message.id,
              chatId: chat.chatId,
              chatChannel: chat.channel.channelName,
              question: message.text,
              answer: "",
            });
          } else if (message.type === "answer") {
            let lastQ = result[result.length - 1];
            if (
              lastQ &&
              lastQ.id === message.id &&
              lastQ.chatId === chat.chatId &&
              lastQ.chatChannel === chat.channel.channelName
            ) {
              lastQ.answer = message.text;
            }
          }
        });
      });
      // console.log(this.conversationList)
      let datas = {
        exportChannel: this.exportChannel,
        sourceId: this.conversationList[0].chatId,
        // 'categoryId': this.subcatevalueid,
        // 'categoryName': this.subcatevalue,
        sysTagList: this.sysTagList,
        exportSource: "CHAT_EXPORT",
        name: this.form.name,
        desc: this.form.desc,
        directiveType: "CHAT_DIRECTIVE",
        chatLogList: result,
      };
      console.log(datas);
      exportDirective(datas).then((res) => {
        if (res.success == true) {
          this.exportChannel = "";
          // this.subcatevalueid = ''
          // this.subcatevalue = ''
          this.sysTagList = "";
          this.form.name = "";
          this.form.desc = "";
          this.checkList = [];
          this.$message({
            type: "success",
            message: "导出成功！",
            duration: 1500,
          });
          this.currdaochu = 1;
          this.daochudrawer = false;
        }
      });
    },
    nextdaochu(dt) {
      this.exportChannel = dt;
      this.currdaochu += 1;
    },
    dcfatherMethod() {
      console.log("导出父组件方法");
      // const isdaochu = this.pushStatus
      // if (isdaochu != null) {
      //   this.$message({
      //     type: 'error',
      //     message: '已被导出，请勿重新操作'
      //   });
      //   return false
      // }
      this.daochudrawer = true;

      if (this.conversationList.length < 1) {
        this.$message({
          type: "error",
          message: "当前没有内容可导出",
          duration: 1500,
        });
        this.daochudrawer = false;
      }
    },
    // 新会话
    newchat() {
      this.$refs.childRef.input_stop();
      this.conversationList = [];
      this.chatid = null;
      localStorage.removeItem('msgList')
    },
    updateChat(datas) {
      updateChat(datas).then((res) => {
        console.log("删除成功", res);
        this.$message({
          type: "success",
          message: "删除成功",
          duration: 1500,
        });
      });
    },
    // 删除单条方法
    deleteDialog(id, ids) {
      this.historyList.splice(id, 1);
      let datas = {
        chatId: ids,
        chatDeleteTag: "YES",
      };
      this.updateChat(datas);
    },
    // 根据id查历史
    gothis(item) {
      console.log("chatInfo", item);
      this.pushStatus = item.pushStatus;
      let data = {
        chatId: item.id, // 单条记录的id
        groupId: this.isgroup ? this.isgroup.id : null,
      };
      this.chatid = item.id;
      getChatLogByChatId(data).then((res) => {
        this.conversationList = res.obj.records;
        localStorage.setItem('msgList',JSON.stringify(res.obj.records))
        console.log(this.conversationList);
      });
    },
    // 获取历史聊天
    getChatListLog() {
      const token = localStorage.getItem("token");
      if (!token) {
        return false;
      }
      console.log(JSON.parse(localStorage.getItem("groupCon")));
      this.isgroup = JSON.parse(localStorage.getItem("groupCon"));
      let datas = {
        ...this.pages,
        chatDeleteTag: "NO", // 删除标记：YES = 已删除，NO = 未删除
        groupId: this.isgroup ? this.isgroup.id : null,
      };
      getChatList(datas).then((res) => {
        console.log(res);
        this.historyList = res.obj.records;
        this.total = res.obj.total;
      });
    },
    getRandomSysTagmethod() {
      let data = {
        tagType: "CHAT",
      };
      getRandomSysTag(data).then((res) => {
        this.examples = res.obj.tagTextList.splice(0, 9);
      });
    },
    getSysTagListmethod() {
      let data = {
        tagType: "CHAT",
      };
      getUserTagList(data).then((res) => {
        this.tagList = res.obj;
      });
    },
    scrollToBottom() {
      // let scrollElem = this.$refs.qaContent
      // if (scrollElem) {
      //   scrollElem.scrollTo({
      //     top: scrollElem.scrollHeight,
      //     behavior: 'auto'
      //   })
      // }
      let end = document.getElementById("conversationListEnd");
      if (end) {
        end.scrollIntoView();
      }
    },
    handleShowImgClose() {
      this.$store.dispatch("app/setShared", true);
    },
    async getAnswerV3(apiKey, serverUrl, maxTokens, param, params) {
      // console.log('param',param)
      // console.log('params',params)
      if (this.userinfo.userNo === "1760168472489234433") {
        console.log("test");
      }
      if (
        param.searchChannel.channelName === "ChatGPT3" ||
        param.searchChannel.channelName === "ChatGPT4" ||
        param.searchChannel.channelName === "XfSpark"
      ) {
        let temperature = 1;
        if (param.tempParam.code === "creative") {
          temperature = 1.2;
        } else if (param.tempParam.code === "balanced") {
          temperature = 1;
        } else if (param.tempParam.code === "precise") {
          temperature = 0.7;
        }
        //apiKey, serverUrl, maxTokens, temperature, params
        param.client = chatgpt.chatgpt3(
          apiKey,
          serverUrl,
          maxTokens,
          temperature,
          params
        );
      } else if (param.searchChannel.channelName === "Bing") {
        param.client = await bing.chat(param.tempParam.code, params);
      } else if (param.searchChannel.channelName === "Stabilityai") {
        param.client = stabilityai.join("", param);
      }
      console.log("这个是拿到的param.client", param.client);
      console.log("这个是拿到的param", param);
    },
    handleStop() {
      if (this.conversationList && this.conversationList.length > 0) {
        this.conversationList[
        this.conversationList.length - 1
            ].messages[1].open = true;
        this.conversationList[
        this.conversationList.length - 1
            ].messages[1].loading = false;

      }
      this.$eventBus.$emit("chat" + "searchDone");
      if (this.questionParam && this.questionParam.client) {
        this.questionParam.client.close();
      }
    },
    handleSearch(param) {
      if (!localStorage.getItem("token")) {
        this.$message({
          type: "error",
          message: "请先登录",
          duration: 1500,
        });
        return false;
      }
      console.log("channelList", this.searchChannelList);
      console.log("param.searchChannel", param.searchChannel.channelName);
      console.log(
        "this.searchChannelList.filter(item => item.channelName === param.searchChannel.channelName)[0]",
        this.searchChannelList.filter(
          (item) => item.channelName === param.searchChannel.channelName
        )[0]
      );
      const needUse = this.searchChannelList.filter(
        (item) => item.channelName === param.searchChannel.channelName
      )[0].energyPoints;
      console.log(JSON.parse(localStorage.getItem("groupCon")));
      const isgroup = JSON.parse(localStorage.getItem("groupCon"));
      var nowNum = null;
      if (isgroup) {
        const udata = this.userinfo;
        let data = { groupId: udata.id, page: 1, size: 100 };
        getGroupEnergyBalance(data).then((res) => {
          console.log(res);
          nowNum = res.obj.currentEnergy;
          console.log("目前还有" + nowNum);
          console.log("目前还有needUse" + needUse);
          if (nowNum - needUse < 0) {
            // 能量不够
            console.log("能量不够啦");
            this.dialogTableVisiblebugou = true;
            this.$refs.childRef.isStop();
            return false;
          }
        });
      } else {
        console.log(this.userinfo);
        const udata = this.userinfo;
        nowNum = udata ? udata.userEnergyResponse.userAvailableEnergy * 1 : "";
        console.log("目前还有" + nowNum);
        console.log("目前还有needUse" + needUse);
        if (nowNum - needUse < 0) {
          // 能量不够
          console.log("能量不够啦");
          this.dialogTableVisiblebugou = true;
          this.$refs.childRef.isStop();
          return false;
        }
      }

      //判断能量
      let params = param;
      // console.log(params)
      params.chatChannelEnum = param.searchChannel.channelName;
      params.chatLevel = param.searchChannel.chatLevel;
      params.groupDataType = this.groupDataType;
      params.chatId = this.chatid;
      console.log(params);
      this.questionParam = params;
      this.questionParam.onEvent = (param) => {
        switch (param.type) {
          case "OPEN":
            break;
          case "UPDATE":
            this.conversationList[
              this.conversationList.length - 1
            ].messages[1].open = true;
            this.conversationList[
              this.conversationList.length - 1
            ].messages[1].loading = true;
            param.content=param.content.replace(/```javascript/g, "\n```javascript\n");
            param.content=param.content.replace(/```java/g, "\n```java\n");
            param.content=param.content.replace(/```js/g, "\n```js\n");
            param.content=param.content.replace(/```python/g, "\n```python\n");
            param.content=param.content.replace(/```php/g, "\n```php\n");
            param.content=param.content.replace(/```c#/g, "\n```c#\n");
            param.content=param.content.replace(/```html/g, "\n```html\n");
            param.content=param.content.replace(/```css/g, "\n```css\n");
            param.content=param.content.replace(/```Ruby/g, "\n```Ruby\n");
            param.content=param.content.replace(/```Swift/g, "\n```Swift\n");
            param.content=param.content.replace(/```Go/g, "\n```Go\n");
            this.conversationList[
              this.conversationList.length - 1
            ].messages[1].text = param.content
            // console.log(param.content);
            // if (this.memberCard === null && param.tips === 'length') {
            //   this.conversationList[this.conversationList.length - 1].messages[1].text += '【字数超过限制，您可在 "个人中心"  充值会员 获得无限制体验】'
            //   this.$toast('字数超过限制，您可在 "个人中心"  充值会员 获得无限制体验')
            // }
            this.scrollToBottom();
            if (param.tips == "stop") {
              let data = {
                chatId: param.chatId, // 单条记录的id
                groupId: this.isgroup ? this.isgroup.id : null,
              };
              this.chatid = param.chatId;
              getChatLogByChatId(data).then((res) => {
                console.log(res.obj.records);
                this.newChatLog = res.obj.records;
                console.log(
                  this.newChatLog[this.newChatLog.length - 1].messages[1].id
                );
                localStorage.setItem(
                  "newChatLog",
                  this.newChatLog[this.newChatLog.length - 1].messages[1].id
                );
              });
            }
            break;
          case "DONE": {
            this.conversationList[
              this.conversationList.length - 1
            ].messages[1].open = true;
            this.conversationList[
              this.conversationList.length - 1
            ].messages[1].loading = false;

            if (
              this.conversationList[this.conversationList.length - 1]
                .channel !== "Stabilityai"
            ) {
              if (
                this.conversationList[this.conversationList.length - 1]
                  .chatId &&
                this.conversationList[this.conversationList.length - 1]
                  .chatId !== ""
              ) {
                answer({
                  chatId:
                    this.conversationList[this.conversationList.length - 1]
                      .chatId,
                  answer:
                    this.conversationList[this.conversationList.length - 1]
                      .messages[1].text,
                });
              }
            }

            this.$eventBus.$emit("chat" + "searchDone");
            const list = [];
            this.conversationList.forEach((i) => {
              if (
                this.conversationList[this.conversationList.length - 1]
                  .channel !== "Stabilityai"
              ) {
                list.push(i);
              }
            });
            localStorage.setItem("conversationList", JSON.stringify(list));
            this.getChatListLog();
            break;
          }
          case "ERROR":
            this.conversationList[
              this.conversationList.length - 1
            ].messages[1].open = true;
            this.conversationList[
              this.conversationList.length - 1
            ].messages[1].loading = false;
            this.conversationList[
              this.conversationList.length - 1
            ].messages[1].text = "【服务器繁忙，请稍后重试！】";
            this.scrollToBottom();
            this.$eventBus.$emit("chat" + "searchDone");
            break;
        }
      };

      this.conversationList.push({
        chatId: "",
        channel: param.searchChannel,
        messages: [
          {
            type: "question",
            icon:
              this.userinfo.headimgurl !== ""
                ? this.userinfo.headimgurl
                : "manager",
            text: param.question,
            open: true,
            loading: false,
          },
          {
            type: "answer",
            icon: param.searchChannel.channelName,
            text: "",
            open: false,
            loading: false,
            answerLikeTag: "EMPTY",
          },
        ],
        stop: false,
        connected: false,
      });

      tickets({
        channelName: param.searchChannel.channelName,
        question: param.question,
      })
        .then((res) => {
          if (res.obj.count !== null && res.obj.count <= 0) {
            // param.onEvent({ type: 'UPDATE', content: '【当日免费次数已用完，您可在 "个人中心"  充值会员 获得无限制额度 或 邀请好友(推广1人将新增3次免费额度）】' })
            param.onEvent({ type: "DONE" });
            return;
          } else {
            this.scrollToBottom();

            this.$getLoginInfo();

            this.conversationList[this.conversationList.length - 1].chatId =
              res.obj.chatId;

            const chatServerUrl = "/api/search/v3";
            const apiKey = res.obj.apiKey;
            const maxTokens = res.obj.maxTokens;

            this.getAnswerV3(
              apiKey,
              chatServerUrl,
              maxTokens,
              this.questionParam,
              params
            );
          }
        })
        .catch((error) => {
          console.log(error);
          param.onEvent({
            type: "UPDATE",
            content: "【服务器繁忙，请稍后重试！】",
          });
          param.onEvent({ type: "DONE" });
        });
    },
  },
};
</script>
<style lang="less" scoped>
.contentdaochu {
  width: 270px;
  word-spacing: normal;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.index {
  display: flex;
  position: relative;

  .lefttools::-webkit-scrollbar {
    display: none;
  }
  .lefttools {
    min-width: 240px;
    height: 88vh;
    overflow: auto;
    box-sizing: border-box;
    padding: 20px 20px;
    color: #ffffff;
    background-color: #131313;
    font-size: 12px;

    .top {
      display: flex;
      justify-content: center;
      align-items: center;

      .logo {
        display: block;
        width: 64px;
        height: 64px;
        margin-right: 10px;
      }
    }

    .chatlist {
      .addchat {
        line-height: 40px;
        cursor: pointer;
        border: 1px dashed #dddddd;
        border-radius: 8px;
        text-align: center;
      }

      .chatlistbox {
        div {
          margin-top: 10px;
          line-height: 40px;
          text-align: center;
          border: 1px solid #dddddd;
          border-radius: 8px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;

          i {
            display: inline-block;
            margin: 0 10px;
          }
        }
      }
    }
  }

  .chat-panel {
    box-sizing: border-box;
    position: relative;
    z-index: auto;
    flex: auto;
    overflow: hidden;
    background: #ffffff;
    height: calc(100vh - 92px) !important;
  }
}

.titledes {
  text-align: center;
  font-size: 18px;
}

.actbtn {
  display: flex;
  justify-content: center;
}

.actblock {
  background: #378efc;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 8px;
  margin: 20px 20px;
}

.actblock:nth-child(1) {
}

.actblock:nth-child(2) {
  color: #9e5211;
  background: linear-gradient(177.23deg, #fffbf3 13.41%, #fbd898 97.69%);
}

.daochubox {
  display: flex;
  align-items: center;
  justify-content: center;

  .daochu {
    border: 1px solid #ddd;
    padding: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    cursor: pointer;

    img {
      margin-right: 20px;
      display: block;
    }
  }
}

.daochuliaotianlist {
  max-height: calc(50vh);
  height: calc(50vh);
  border-bottom: 1px solid #ddd;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px;
  max-width: 100%;

  .el-checkbox-group {
    .el-checkbox {
      display: flex;
      align-items: center;
      margin-top: 30px;

      .el-checkbox__label {
        .daochublock {
          display: flex;
          align-items: center;

          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 20px;
          }
        }
      }
    }
  }
}

.settingmsg {
  padding: 20px;

  .selectbox {
    display: flex;

    .blockselect {
      margin-right: 20px;
    }
  }
}

.el-dialog {
  border-radius: 12px !important;
}
</style>
<style scoped>
::v-deep .el-dialog {
  border-radius: 12px !important;
}
</style>
