<template>
  <div :style="`background-color: #ffffff; overflow-y: auto; height: ${height}px`">
    <div class="second-panel" style="display: flex; flex-direction: row; padding: 1rem 2rem; align-items: flex-start;">
      <van-image round width="5rem" height="5rem" :src="userinfo.headimgurl"/>
      <div style="padding: 0.3rem 1rem 0 1rem; display: flex; flex-direction: column;">
        <span class="default-text" style="font-size: 1rem; font-weight: bold;">{{ userinfo.nickname }}</span>
        <!--        <span v-if="memberCard === null" class="default-text" style="font-size: 0.68rem; color: #ccc;">{{`剩余${userinfo.freeTickets}次免费机会`}}</span>-->
        <div v-if="memberCard" style="display: flex; flex-direction: row; align-items: flex-end;">
          <van-tag type="primary" size="medium">{{ memberCard.cardName }}</van-tag>
          <span class="default-text" style="font-size: 0.68rem; color: #ccc; margin-left: 0.2rem;">{{
              memberCard.endTime ?
                  (memberCard.endTime + '到期') : '永久有效'
            }}</span>
        </div>
        <div v-if="partnerCard" style="display: flex; flex-direction: row; align-items: flex-end; padding-top: 0.2rem;">
          <van-tag type="warning" size="medium">{{ partnerCard.cardName }}</van-tag>
          <span class="default-text" style="font-size: 0.68rem; color: #ccc; margin-left: 0.2rem;">{{
              partnerCard.endTime ?
                  (partnerCard.endTime + '到期') : '永久有效'
            }}</span>
        </div>
      </div>
    </div>

    <!--    <div style="padding-bottom: 6rem;">-->
    <!--      -->
    <!--&lt;!&ndash;      <span class="default-text" style="color: #111111;">会员中心</span>&ndash;&gt;-->
    <!--    </div>-->

    <div style="padding-bottom: 2rem;">
      <a v-if="itemList.length > 0" class="menu-item member-card"
         style="flex-direction: column; align-items: flex-start;">
        <div
            style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; width: 100%;">
          <div style="display: flex; flex-direction: row; align-items: center;">
            <van-icon name="gem-o" size="2rem" color="rgb(178 178 67)"/>
            <span style="margin-left: 0.3rem;">会员中心</span>
          </div>
          <span style="font-size: 0.9rem; color: #ccc;">无限畅聊 独享接口 超低延迟</span>
          <!--        <van-icon name="arrow" />-->
        </div>

        <div style="width: 100%;">
          <div style="display: flex; flex-direction: row; justify-content: center;">
            <van-button v-for="(item, index) in itemList" :key="index"
                        :type="selectedItemIndex === index ? 'info' : 'default'"
                        style="height: 4rem; margin: 0.2rem 0.2rem; border-radius: 1rem;"
                        @click="handleSelectedItem(index)">
              <div style="display: flex; flex-direction: column;">
                <span>{{ item.itemName }}</span>
                <span>￥{{ item.price }}</span>
                <span style="text-decoration: line-through;">￥{{ item.originalPrice }}</span>
              </div>
            </van-button>
          </div>

          <div
              style="margin-top: 1rem; display: flex; flex-direction: column; justify-content: center; align-items: center;">
            <van-button round style="width: 100%;" type="primary" :disabled="selectedItemIndex === '' || payLoading"
                        @click="handlePay">立即开通（限时特价）
            </van-button>
            <span style="font-size: 0.8rem; margin-top: 1rem;">会员服务为虚拟产品，支付后不支持退款</span>
          </div>
        </div>
      </a>

      <!-- <a class="menu-item" @click="handleShare">
        <div style="display: flex; flex-direction: row; align-items: center;">
          <van-icon name="share-o" size="2rem" color="rgb(178 178 67)" />
          <span style="margin-left: 0.3rem;">推广分享</span>
        </div>
        <span class="menu-item-tips">{{ shareTips }}</span>
        <van-icon name="arrow" />
      </a> -->
      <a class="menu-item" v-if="partnerItemList.length > 0" @click="handleGoPartner">
        <div style="display: flex; flex-direction: row; align-items: center;">
          <van-icon name="user-o" size="2rem" color="rgb(150 38 181)"/>
          <span style="margin-left: 0.3rem;">成为代理人</span>
        </div>
        <span class="menu-item-tips">赚取全额返佣</span>
        <van-icon name="arrow"/>
      </a>
      <a class="menu-item" @click="handleGoCommission" style="display: none;">
        <div style="display: flex; flex-direction: row; align-items: center;">
          <van-icon name="balance-list-o" size="2rem" color="#ffd01e"/>
          <span style="margin-left: 0.3rem;">推广记录</span>
        </div>
        <!--        <span style="font-size: 0.2rem;">每分享1次课获得3次提问机会</span>-->
        <van-icon name="arrow"/>
      </a>
      <a class="menu-item" @click="handleShowPublicImg">
        <div style="display: flex; flex-direction: row; align-items: center;">
          <van-icon name="star-o" size="2rem" color="#ee0a24"/>
          <span style="margin-left: 0.3rem;">关注公众号</span>
        </div>
        <span class="menu-item-tips">防止找不到</span>
        <!--        <span style="font-size: 0.2rem;">每分享1次课获得3次提问机会</span>-->
        <van-icon name="arrow"/>
      </a>
      <a class="menu-item" @click="handleShowQqGroupImg">
        <div style="display: flex; flex-direction: row; align-items: center;">
          <van-icon name="friends-o" size="2rem" color="#1989fa"/>
          <span style="margin-left: 0.3rem;">阿猫智能社区</span>
        </div>
        <!--        <span style="font-size: 0.2rem;">每分享1次课获得3次提问机会</span>-->
        <van-icon name="arrow"/>
      </a>
      <a class="menu-item" @click="handleShowContractImg">
        <div style="display: flex; flex-direction: row; align-items: center;">
          <van-icon name="friends-o" size="2rem" color="#1989fa"/>
          <span style="margin-left: 0.3rem;">寻求商务合作</span>
        </div>
        <!--        <span style="font-size: 0.2rem;">每分享1次课获得3次提问机会</span>-->
        <van-icon name="arrow"/>
      </a>
      <!--      <van-cell title="推广分享" is-link @click="handleShare"></van-cell>-->
      <!--      &lt;!&ndash;      <van-cell title="推广记录" is-link></van-cell>&ndash;&gt;-->
      <!--      <van-cell title="关注公众号" is-link @click="handleShowPublicImg"></van-cell>-->
      <!--      <van-cell title="寻求商务合作" is-link @click="handleShowContractImg"></van-cell>-->
    </div>

    <!--    <van-dialog v-model="showImgDialog" :title="showImgTitle" @close="handleShowImgClose">-->
    <!--      <img style="width: 100%;" :src="showImgUrl" />-->
    <!--    </van-dialog>-->

    <van-overlay :show="showImgDialog">
      <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; height: 100%;">
        <span style="font-size: 0.8rem; margin-bottom: 0.5rem;" class="default-text">长按识别二维码</span>
        <img :style="'width: ' + showImgWidth" :src="showImgUrl"/>
        <van-icon name="cross" size="3rem" color="#fff" style="margin-top: 1rem;" @click="showImgDialog = false"/>
      </div>
    </van-overlay>

    <canvas v-show="false" id="myCanvas" width="200" height="100"/>
    <vue-qr id="shareQr" v-if="userinfo.userNo" v-show="false" :callback="handleShareQrCallback" :size="200"
            :margin="10"
            :correctLevel="3" :text="shareUrl" :logoSrc="userinfo.headimgurl ? userinfo.headimgurl : logoSrc"></vue-qr>

    <van-overlay :show="showShareDialog">
      <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; height: 100%;">
        <!--        <img style="width: 20rem;" :src="showImgUrl" />-->
        <div id="test"></div>
        <!--        <div id="test2"></div>-->
        <!--        <canvas  id="canvas" v-show="false"/>-->
        <van-icon name="cross" size="3rem" color="#fff" style="margin-top: 1rem;" @click="showShareDialog = false"/>
        <!--        <vue-qr :size="200" :correctLevel="3" :text="shareUrl" :logoSrc="userinfo.headimgurl"></vue-qr>-->
        <!--        <van-icon name="cross" size="3rem" style="margin-top: 1rem;" @click="showShareDialog = false"/>-->
      </div>
    </van-overlay>

    <van-overlay :show="showPartnerDialog">
      <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; height: 100%;">
        <div style="width: 18rem; background-color: #fff; padding: 0.5rem; border-radius: 1rem;">
          <!--          <span class="default-text" style="margin-bottom: 0.5rem; color: #111111;">选择代理会员</span>-->
          <van-tabs style="margin-top: 1rem;" type="card" @change="handlePartnerChange">
            <van-tab v-for="(item, index) in partnerItemList" :key="index" :title="item.itemName" :name="index + ''">
              <van-cell-group class="partner-item-panel">
                <van-field class="item-field" label-width="4rem" label="商品名称:" input-align="left">
                  <template #input>
                    <span style="color: #111111;">{{ item.itemName }}</span>
                  </template>
                </van-field>
                <van-field class="item-field" label-width="4rem" label="商品描述:" input-align="left">
                  <template #input>
                    <div v-html="item.itemDesc" class="item-desc-panel">
                    </div>
                  </template>
                </van-field>
                <van-field class="item-field" label-width="4rem" label="支付金额:" input-align="left">
                  <template #input>
                    <span style="color: #111111;">￥{{ item.price }}</span>
                  </template>
                </van-field>
                <!--                <van-cell title="商品名称" :value="item.itemName" />-->
                <!--                <van-cell title="商品描述" :value="item.itemDesc" >-->
                <!--                  <template #default>-->
                <!--                    <div v-html="item.itemDesc" class="item-desc-panel">-->

                <!--                    </div>-->
                <!--&lt;!&ndash;                    <span style="white-space: pre-wrap;">{{item.itemDesc}}</span>&ndash;&gt;-->
                <!--                  </template>-->
                <!--                </van-cell>-->
                <!--                <van-cell title="支付金额" :value="item.price" >-->
                <!--                  <template #default>-->
                <!--                    <span style="white-space: pre-wrap;">￥{{item.price}}</span>-->
                <!--                  </template>-->
                <!--                </van-cell>-->
              </van-cell-group>
            </van-tab>
          </van-tabs>
          <van-field class="withdraw-field" label-width="7.5rem" v-model="withdrawAccount" label="收款微信号(必填):"
                     placeholder="" :readonly="withdrawAccountSet"/>
          <div style="display: flex; flex-direction: column; align-items: center;">
            <van-button style="width: 100%; margin-top: 1rem;" round type="primary" :disabled="payLoading"
                        @click="handlePayPartner">确认支付
            </van-button>
            <span style="font-size: 0.8rem; margin-top: 0.5rem;">此产品支付后不支持退款</span>
          </div>
        </div>

        <van-icon name="cross" size="3rem" color="#fff" style="margin-top: 1rem;" @click="showPartnerDialog = false"/>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import vueQr from 'vue-qr'
import {mapGetters} from 'vuex'
import shareImg from '@/assets/poster.jpg'
import qqGroupImg from '@/assets/qqGroup.jpg'
// import contractImg from '@/assets/contract.jpeg'
// import publicImg from '@/assets/public.jpeg'
import {createOrder, items, getWithdrawAccount, updateWithdrawAccount} from '@/api'

export default {
  name: 'profile',
  components: {
    vueQr
  },
  computed: {
    ...mapGetters([
      'userinfo',
      'clientHeight'
    ]),
    height() {
      let h = this.clientHeight - 60
      if (this.notifyOpen) {
        h -= 40
      }
      return h
    },
    shareTips() {
      if (!this.partnerCard) {
        // let r = ''
        // if (this.commissionRate !== '' && this.commissionRate !== '0') {
        //   r = `赚取${this.commissionRate}%佣金`
        // }
        // if (r !== '') {
        //   r += '和'
        // }
        // r += '奖励3次免费额度'
        // return r
        return '赚取10%佣金'
      } else {
        // if (this.commissionRate !== '' && this.commissionRate !== '0') {
        //   return `赚取${this.commissionRate}%佣金`
        // }
        return `赚取全额佣金`
      }
    },
    commissionRate() {
      if (this.partnerCard) {
        return this.partnerCard.commissionRate
      }
      if (this.memberCard) {
        return this.memberCard.commissionRate
      }
      return this.userinfo.defaultCommissionRate
    },
    memberCard() {
      let r = null
      if (this.userinfo.cardList && this.userinfo.cardList.length > 0) {
        this.userinfo.cardList.forEach(i => {
          if (i.cardType === 'Member') {
            r = i
          }
        })
      }
      return r
    },
    partnerCard() {
      let r = null
      if (this.userinfo.cardList && this.userinfo.cardList.length > 0) {
        this.userinfo.cardList.forEach(i => {
          if (i.cardType === 'Partner') {
            r = i
          }
        })
      }
      return r
    },
    shareUrl() {
      return window.location.protocol + "//" + window.location.host + '?id=' + this.userinfo.recommendCode + '&v=' + new Date().getTime()
    }
  },
  data() {
    return {
      payLoading: false,
      payOrder: {
        orderNo: '',
        status: '',
      },
      qrcodeUrl: '',
      weixinJsBridge: null,
      logoSrc: 'http://wx.qlogo.cn/mmopen/dH8QVxmk2IUllibUomcBVh9AfSgAIlKAofcDfCSTYTNUibSlj2IlrsqYbNDPx7Bfo8jCFye3s88RIUB1F1f7OLicrHdvFyEYSWo/64',
      itemList: [],
      partnerItemList: [],
      showShareDialog: false,
      selectedItemIndex: '',
      selectedPartnerItemIndex: '0',
      showImgDialog: false,
      showImgUrl: '',
      showImgTitle: '',
      showImgWidth: '200px',
      showPartnerDialog: false,
      withdrawAccount: '',
      withdrawAccountSet: false,
      shareImgOk: false,
    }
  },
  mounted() {
    this.weixinJsBridge = window.WeixinJSBridge
    items().then(res => {
      const list = res.obj
      this.itemList = []
      this.partnerItemList = []
      list.forEach(i => {
        if (i.itemType === 'Member') {
          this.itemList.push(i)
        } else if (i.itemType === 'Partner') {
          this.partnerItemList.push(i)
        }
      })
      if (this.itemList.length > 0) {
        this.selectedItemIndex = this.itemList.length - 1
      }
    })
  },
  methods: {
    handleShareQrCallback() {
      this.getShareImg()
    },
    getShareImg() {

      // 创建canvas元素

      if (this.shareImgOk) {
        return
      }

      const canvas = document.getElementById('myCanvas');
      const ctx = canvas.getContext('2d');

      // 设置canvas尺寸和样式
      canvas.width = 1587;
      canvas.height = 2245;
      // canvas.style.border = '1px solid #000';

      // 绘制背景图像和文本等元素
      const backgroundImage = new Image();
      backgroundImage.src = shareImg;
      backgroundImage.onload = () => {
        ctx.drawImage(backgroundImage, 0, 0, canvas.width, canvas.height);
        ctx.font = 'bold 48px Arial';
        ctx.fillStyle = '#fff';
        // ctx.fillText('1111', 100, 100);

        let shareQr = document.getElementById('shareQr')
        ctx.drawImage(shareQr, 165, 1495, 330, 330);
        let base64 = canvas.toDataURL('image/png');
        // let img = document.getElementById('myCanvas');

        const sharePoster = new Image()
        sharePoster.src = base64
        document.getElementById('test').appendChild(sharePoster)

        this.shareImgOk = true

      };
    },
    handleShowImgClose() {
      this.$store.dispatch('app/setShared', true)
    },
    handleSelectedItem(index) {
      this.selectedItemIndex = index
    },
    handlePartnerChange(tab) {
      this.selectedPartnerItemIndex = tab
    },
    handlePayPartner() {
      this.payLoading = true
      let item = this.partnerItemList[this.selectedPartnerItemIndex]
      if (!this.withdrawAccountSet) {
        if (this.withdrawAccount === '') {
          this.$toast('请输入收款微信账号')
          this.payLoading = false
          return
        }

        updateWithdrawAccount({withdrawAccount: this.withdrawAccount}).then(() => {
          createOrder({itemId: item.itemId}).then(res => {
            const params = JSON.parse(res.obj.params)
            this.payOrder.orderNo = res.obj.orderNo
            this.invokeWxPay(params)
          }).catch(() => {
            this.payLoading = false
          })
        }).catch(() => {
          this.payLoading = false
        })
      } else {
        createOrder({itemId: item.itemId}).then(res => {
          const params = JSON.parse(res.obj.params)
          this.payOrder.orderNo = res.obj.orderNo
          this.invokeWxPay(params)
        }).catch(() => {
          this.payLoading = false
        })
      }
    },
    handlePay() {
      this.payLoading = true
      createOrder({itemId: this.itemList[this.selectedItemIndex].itemId}).then(res => {
        const params = JSON.parse(res.obj.params)
        this.payOrder.orderNo = res.obj.orderNo
        this.invokeWxPay(params)
      }).catch(() => {
        this.payLoading = false
      })
    },
    invokeWxPay(params) {
      this.weixinJsBridge.invoke(
          'getBrandWCPayRequest',
          params,
          (res) => {
            this.payLoading = false
            //{"err_msg": "get_brand_wcpay_request:cancel"}
            //{"err_msg": "get_brand_wcpay_request:ok"}
            if (res && res.err_msg && res.err_msg === 'get_brand_wcpay_request:ok"') {
              this.payOrder.status = true
              this.$toast('支付成功')
              setTimeout(() => {
                this.$getLoginInfo()
              }, 10000)
            }
          }
      )
    },
    handleShare() {
      this.showShareDialog = true
      this.showImgUrl = shareImg
      // this.showImgTitle = '关注公众号'
      // this.showImgDialog = true
    },
    handleShowPublicImg() {
      this.showImgUrl = "https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/static/all/public.jpeg"
      this.showImgTitle = '关注公众号'
      this.showImgWidth = '200px'
      this.showImgDialog = true
    },
    handleShowContractImg() {
      this.showImgUrl = "https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/static/all/public.jpeg"
      this.showImgTitle = '商务合作'
      this.showImgWidth = '200px'
      this.showImgDialog = true
    },
    handleShowQqGroupImg() {
      this.showImgUrl = qqGroupImg
      this.showImgTitle = '阿猫智能社区'
      this.showImgWidth = '20rem'
      this.showImgDialog = true
    },
    handleGoPartner() {
      if (!this.withdrawAccountSet) {
        getWithdrawAccount().then(res => {
          if (res.obj && res.obj !== '') {
            this.withdrawAccount = res.obj
            this.withdrawAccountSet = true
          }
          this.showPartnerDialog = true
        })
      } else {
        this.showPartnerDialog = true
      }

    },
    handleGoCommission() {
      this.$router.push({path: '/commission'})
    },
  }
}
</script>
<style>
.menu-item {
  padding: 0.5rem 0.5rem;
  margin: 1rem 1rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #f7f8fa;
  color: #323233;
  cursor: pointer;
}

.menu-item:hover {
  background: #e4e8ee;
}

.menu-item:active {
  background: #e4e8ee;
}

.member-card:hover {
  background: #f7f8fa;
}

.member-card:active {
  background: #f7f8fa;
}

.partner-item-panel .van-cell {
  background-color: #fff;
}

#test {
  display: flex;
  justify-content: center;
  margin: 0 1.5rem;
}

#test img {
  width: 100%;
}

#test2 img {
  width: 100px;
}

.menu-item-tips {
  font-size: 0.9rem;
  color: #5f99e4;
}

.item-desc-panel {
  display: flex;
  flex-direction: column;
  color: #111111;
}

.item-desc-panel span {
  word-break: break-all;
  white-space: pre-line;
}
</style>
