<template>
  
  <div>
    <el-tabs v-model="activeName" type="card">
    <el-tab-pane label="代理介绍" name="1">
      <div class="jieshao">
        <div class="top">
          <img
            src="../../public/static/img/daili/成为代理人 赚取高额佣金@2x.png"
            alt=""
          />
        </div>
        <div class="taocan">
          <div class="taocan1">
            <p class="h1">
              基础版<span style="font-size: 16px">(期限1年)</span>
            </p>
            <p>成为初级代理人，推广享受返佣！</p>
            <p>
              1、LV1等级：累计返佣金额≤10000元（含代理人+套餐）政策：30%直接返佣+10%间接返佣
            </p>
            <p>
              2、LV2等级：累计返佣金额10000-100000元（含代理人+套餐）政策：30%直接返佣+10%间接返佣
            </p>
            <p>
              3、LV3等级：累计返佣金额100000以上（含代理人+套餐）政策：30%直接返佣+10%间接返佣；
            </p>
            <p style="margin-top: 30px">•获取佣金：推广享受40%-50%佣金</p>
            <p>•赠送：1个月套餐权益</p>
            <p>•特权：拥有GPT产品新功能优先体验权</p>
            <p class="jia">¥{{  datalist.price }}</p>
            <div class="pay" @click="gopay">购买套餐</div>
          </div>
          <div class="taocan1">
            <p class="h1">升级版</p>
            <p>
              适合可将阿猫机器人接入自己的小程序、公众号、软件等。我们为你单独开版，功能内核以及分销体系沿用阿猫现有。
            </p>
            <p style="margin-top: 30px">•可拥有：自己的分销体系</p>
            <p>•定价权:享有用户付费定价权</p>
            <p
              style="
                text-align: center;
                margin: auto;
                font-size: 22px;
                color: #000;
                margin-top: 10px;
              "
            >
              扫码咨询
            </p>
            <img
              class="ma"
              src="https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/static/all/public.jpeg"
              alt=""
            />
            <p style="text-align: center">
              详细代理定制服务政策，请联系商务咨询
            </p>
          </div>
        </div>
      </div>
    </el-tab-pane>
    <el-tab-pane label="推广记录" name="2">
      <div class="tuiguang">
        <h3>数据汇总</h3>
        <div class="tui_top">
          <div class="tui_top1">
            <span class="txt1"
              >{{ userinfo.balance }}
              <span style="font-size: 16px; color: #7e8da0">元</span></span
            >
            <span class="txt2">账户余额</span>
            <img
              class="img1"
              src="../../public/static/img/编组 7@2x.png"
              alt=""
              style="width: 45px;"
            />
          </div>
          <div class="tui_top1">
            <span class="txt1"
              >{{ userinfo.sumMoney ? userinfo.sumMoney : "0.00" }}
              <span style="font-size: 16px; color: #7e8da0">元</span></span
            >
            <span class="txt2">累计收益</span>
            <img
              class="img1"
              src="../../public/static/img/编组 2@2x.png"
              alt=""
              style="width: 45px;"
            />
          </div>
          <div class="tui_top1">
            <span class="txt1"
              >{{ userinfo.userCount }}
              <span style="font-size: 16px; color: #7e8da0">人</span></span
            >
            <span class="txt2">累计推广</span>
            <img
              class="img1"
              src="../../public/static/img/编组 7(2).png"
              alt=""
              style="width: 45px;"
            />
          </div>
        </div>
        <el-tabs v-model="tab" style="width: 90%; margin: auto" :stretch=true>
          <el-tab-pane label="推广记录" name="one">
            <div class="tui_list">
              <div class="con" v-for="(item) in list" :key="item">
                <img :src="item.headimgurl" alt="" />
                <span class="name">{{ item.nickname }}</span>
                <span class="time">{{ item.createTime }}</span>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="佣金记录" name="tow">
            <div class="tui_list">
              <div class="con" v-for="(item) in commissionsList" :key="item">
                <img :src="item.headimgurl" alt="" />
                <span class="name">{{ item.nickname }}</span>
                <span class="time">+{{ item.amount }}</span>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-tab-pane>
    <el-tab-pane label="收费设置" name="3" v-if="userinfo.role=='SPLV3'">
      <el-tabs v-model="tab2" style="width: 90%; margin: auto" :stretch=true>
        <el-tab-pane label="基础收费" name="one1">
          <div class="shuofei">
            <div
              class="gai1"
              v-for="(item, index) in energyItemList"
              :key="index"
            >
              <p>{{ item.itemName }}</p>
              <span style="margin-left: 0">价格：</span>
              <el-input
                placeholder="请输入内容"
                type="number"
                v-model="item.partnerPrice"
                style="width: 300px"
              >
                <template slot="append">元</template>
              </el-input>
              <span
                >套餐成本：<span
                  style="color: #000; margin-left: 0; font-size: 18px"
                  >{{ item.costPrice }}元</span
                ></span
              >
            </div>
            <div
              class="gai1"
              v-for="(item, index) in agentItemList"
              :key="index"
            >
              <p>{{ item.itemName }}</p>
              <span style="margin-left: 0">价格：</span>
              <el-input
                placeholder="请输入内容"
                type="number"
                v-model="item.partnerPrice"
                style="width: 300px"
              >
                <template slot="append">元</template>
              </el-input>
              <span
                >套餐成本：<span
                  style="color: #000; margin-left: 0; font-size: 18px"
                  >{{ item.costPrice }}元</span
                ></span
              >
            </div>
            <div class="save" @click="save1">保存设置</div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="返佣比例" name="tow1">
          <div class="fan_all">
            <div class="second-line">
              <div class="topbox">
                <div class="left">代理费直佣</div>
                <div class="right">
                  返佣比例：
                  <el-input
                    type="number"
                    max="100"
                    min="1"
                    maxlength="3"
                    placeholder="请输入内容"
                    v-model="commissionRateConfig.agentFeeDirectCommissionRate"
                    style="width: 200px"
                  >
                    <template slot="append">%</template>
                  </el-input>
                </div>
              </div>

              <div
                class="topbox"
                v-for="(
                  item, index
                ) in commissionRateConfig.indirectCommissionRateList"
                :key="index"
              >
                <div class="left">
                  代理费间佣LV{{ item.level }}
                  <div class="weight"></div>
                </div>
                <div class="right">
                  返佣比例：
                  <el-input
                    type="number"
                    max="100"
                    min="1"
                    maxlength="3"
                    placeholder="请输入内容"
                    v-model="item.agentFeeCommissionRate"
                    style="width: 200px"
                  >
                    <template slot="append">%</template>
                  </el-input>
                </div>
              </div>

              <div class="topbox">
                <div class="left">套餐费直佣</div>
                <div class="right">
                  返佣比例：
                  <el-input
                    type="number"
                    max="100"
                    min="1"
                    maxlength="3"
                    placeholder="请输入内容"
                    v-model="commissionRateConfig.itemFeeDirectCommissionRate"
                    style="width: 200px"
                  >
                    <template slot="append">%</template>
                  </el-input>
                </div>
              </div>

              <div
                class="topbox"
                v-for="(
                  item, index
                ) in commissionRateConfig.indirectCommissionRateList"
                :key="index"
              >
                <div class="left">
                  套餐费间佣LV{{ item.level }}
                  <div class="weight"></div>
                </div>
                <div class="right">
                  返佣比例：
                  <el-input
                    type="number"
                    max="100"
                    min="1"
                    maxlength="3"
                    placeholder="请输入内容"
                    v-model="item.itemFeeCommissionRate"
                    style="width: 200px"
                  >
                    <template slot="append">%</template>
                  </el-input>
                </div>
              </div>
            </div>
            <div class="second-line">
              <div class="topbox">
                <div class="left">员工代理费直佣</div>
                <div class="right">
                  返佣比例：
                  <el-input
                    type="number"
                    max="100"
                    min="1"
                    maxlength="3"
                    placeholder="请输入内容"
                    v-model="
                      staffCommissionRateConfig.agentFeeDirectCommissionRate
                    "
                    style="width: 200px"
                  >
                    <template slot="append">%</template>
                  </el-input>
                </div>
              </div>

              <div
                class="topbox"
                v-for="(
                  item, index
                ) in staffCommissionRateConfig.indirectCommissionRateList"
                :key="index"
              >
                <div class="left">
                  员工代理费间佣LV{{ item.level }}
                  <div class="weight"></div>
                </div>
                <div class="right">
                  返佣比例：
                  <el-input
                    type="number"
                    max="100"
                    min="1"
                    maxlength="3"
                    placeholder="请输入内容"
                    v-model="item.agentFeeCommissionRate"
                    style="width: 200px"
                  >
                    <template slot="append">%</template>
                  </el-input>
                </div>
              </div>

              <div class="topbox">
                <div class="left">员工套餐费直佣</div>
                <div class="right">
                  返佣比例：
                  <el-input
                    type="number"
                    max="100"
                    min="1"
                    maxlength="3"
                    placeholder="请输入内容"
                    v-model="
                      staffCommissionRateConfig.itemFeeDirectCommissionRate
                    "
                    style="width: 200px"
                  >
                    <template slot="append">%</template>
                  </el-input>
                </div>
              </div>

              <div
                class="topbox"
                v-for="(
                  item, index
                ) in staffCommissionRateConfig.indirectCommissionRateList"
                :key="index"
              >
                <div class="left">
                  员工套餐费间佣LV{{ item.level }}
                  <div class="weight"></div>
                </div>
                <div class="right">
                  返佣比例：
                  <el-input
                    type="number"
                    max="100"
                    min="1"
                    maxlength="3"
                    placeholder="请输入内容"
                    v-model="item.itemFeeCommissionRate"
                    style="width: 200px"
                  >
                    <template slot="append">%</template>
                  </el-input>
                </div>
              </div>
            </div>
          </div>
          <div class="save" @click="save2">保存设置</div>
        </el-tab-pane>
      </el-tabs>
    </el-tab-pane>
    <el-tab-pane label="代理人账户" name="4" v-if="userinfo.role=='SPLV1'||userinfo.role=='SPLV2'||userinfo.role=='SPLV3'">
      <div class="zhanghu">
        <div class="top_but" @click="geturl">+ 分享邀请加入</div>
        <div class="userList">
          <el-table :data="tableData" stripe style="width: 100%">
            <el-table-column prop="nickname" label="用户名" width="180">
            </el-table-column>
            <el-table-column prop="createTime" label="加入日期">
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-tab-pane>
  </el-tabs>
  <el-dialog
      title="微信支付"
      :visible.sync="tanchuangbox"
      width="400px"
      :close-on-press-escape="false"
      :before-close="handleCloseshowlianxi2"
    >
      <div
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        "
      >
        <span style="margin-bottom: 20px">请扫码完成支付！</span>
        <img
          style="width: 300px"
          :src="weima"
          alt=""
        />
      </div>
    </el-dialog>
  </div>
</template>
  <script>
import {
  commissions,
  users,
  partnerItems,
  commissionRateConfig,
  updateItem,
  updateCommissionRateConfig,
  staffs,
  items,
  pay,
  geytMa,
  OrderStatus,
} from "@/api";
import { mapGetters } from "vuex";
export default {
  name: "daili",
  components: {},
  computed: {
    ...mapGetters(["userinfo", "notifyOpen", "clientHeight"]),
  },
  data() {
    return {
      activeName: "1",
      liat: [],
      commissionsList: [],
      tab: "one",
      tab2: "one1",
      input1: null,
      input2: null,
      input3: null,
      energyItemList: [],
      agentItemList: [],
      commissionRateConfig: [],
      staffCommissionRateConfig: [],
      tableData: [],
      inviteUrl:'',
      datalist:[],
      tanchuangbox: false,
    };
  },
  watch: {},
  beforeDestroy() {},
  mounted() {
    this.getlist();
    items().then((res) => {
      console.log(res.obj);
      if (res.obj.length >= 1) {
        const carddata = res.obj;
        let newArr = carddata.filter((obj) => {
          let regExp = new RegExp("CARD_TYPE_PARTNER", "i");
          return regExp.test(obj) || regExp.test(obj.itemType);
        });
        console.log(newArr);
        this.datalist = newArr[0];
      }
      console.log("代理卡", this.datalist);
    });
  },
  activated() {},
  methods: {
    getlist() {
      users().then((res) => {
        console.log(res.obj);
        this.list = res.obj;
      });
      commissions().then((res) => {
        console.log(res.obj);
        this.commissionsList = res.obj;
      });
      staffs().then((res) => {
        console.log(res.obj);
        this.tableData = res.obj;
      });
      partnerItems().then((res) => {
        console.log(res.obj);
        this.energyItemList = [];
        this.agentItemList = [];
        const items = res.obj;
        items.forEach((i) => {
          if (i.itemType === "CARD_TYPE_PARTNER") {
            this.agentItemList.push(i);
          } else {
            this.energyItemList.push(i);
          }
        });
      });
      commissionRateConfig().then((res) => {
        console.log(res.obj);
        this.commissionRateConfig = res.obj.commissionRateConfig;
        this.staffCommissionRateConfig = res.obj.staffCommissionRateConfig;
      });
    },
    geturl(){
      this.inviteUrl = window.location.protocol + "//" + window.location.host + '?id=' + this.userinfo.recommendCode + '&staff=true'
      console.log(this.inviteUrl);
      this.$copyText(this.inviteUrl).then(() => {
        this.$message({
            type: "success",
            message: `复制成功`,
            duration:1500
          });
      }).catch(err => {
        console.error('copy', err)
      })
    },

    save1() {
      const items = [];
      for (let index = 0; index < this.energyItemList.length; index++) {
        const i = this.energyItemList[index];
        if (
          i.partnerPrice === null ||
          parseFloat(i.partnerPrice) < parseFloat(i.costPrice)
        ) {
          this.$message({
            type: "error",
            message: `${i.itemName}价格不能小于成本价`,
            duration:1500
          });
          return;
        }
        items.push({
          itemId: i.itemId,
          price: i.partnerPrice,
        });
      }
      for (let index = 0; index < this.agentItemList.length; index++) {
        const i = this.agentItemList[index];
        if (
          i.partnerPrice === null ||
          parseFloat(i.partnerPrice) < parseFloat(i.costPrice)
        ) {
          this.$message({
            type: "error",
            message: `${i.itemName}价格不能小于成本价`,
            duration:1500
          });
          return;
        }
        items.push({
          itemId: i.itemId,
          price: i.partnerPrice,
        });
      }
      let data = {
        items: items,
      };
      updateItem(data).then((res) => {
        console.log(res.success);
        if (res.success == true) {
          this.$message({
            type: "success",
            message: "提交成功",
            duration:1500
          });
        } else {
          this.$message({
            type: "error",
            message: res.errorMessage,
            duration:1500
          });
        }
      });
    },

    save2() {
      let data = {
        commissionRateConfig: this.commissionRateConfig,
        staffCommissionRateConfig: this.staffCommissionRateConfig,
      };
      updateCommissionRateConfig(data).then((res) => {
        console.log(res.success);
        if (res.success == true) {
          this.$message({
            type: "success",
            message: "提交成功",
            duration:1500
          });
        } else {
          this.$message({
            type: "error",
            message: res.errorMessage,
            duration:1500
          });
        }
      });
    },
    gopay() {
      let data = {
        aiItemList: [
          {
            cardType: this.datalist.itemType,
            id: this.datalist.itemId,
            number:1,
          },
        ],
        qrPay: true,
      };
      pay(data).then((res) => {
        console.log(res);
        let data1 = { codeData: res.obj.params };
      
        geytMa(data1).then((res) => {
            // const blob = new Blob([res]);
            const url = window.URL.createObjectURL(res);
            this.weima = url;
            console.log(this.weima);
            this.tanchuangbox = true;
          })
        this.getOrderStatus(res.obj.orderNo.toString());
      });
    },
    getOrderStatus(data) {
      let data1 = {
        orderNo: data,
      };
      console.log(1111);
      this.Status = setInterval(() => {
        OrderStatus(data1).then((res) => {
          console.log(res);
          if (res.obj == "Success") {
            this.$message({
              type: "success",
              message: `支付成功`,
              duration: 1500,
            });
            this.tanchuangbox = false;
            clearInterval(this.Status);
            return
          } else if (res.obj == "Failed") {
            this.$message({
              type: "error",
              message: `支付失败`,
              duration: 1500,
            });
            this.tanchuangbox = false;

            clearInterval(this.Status);
            return

          }
        });
      }, 1000);
    },
  },
};
</script>
  <style lang="less" scoped>
.jieshao {
  width: 100%;
  height: 700px;
  .top {
    width: 830px;
    height: 100px;
    margin: auto;
    border-radius: 40px;
    background: url(../../public/static/img/daili/编组\ 54@2x.png);
    background-size: cover;
    background-position: center top -65px;
    display: flex;
    align-items: center;
    img {
      width: 200px;
      margin-left: 100px;
    }
  }

  .taocan {
    width: 65%;
    height: 520px;
    margin: auto;
    margin-top: 20px;
    // border: 1px red solid;
    display: flex;
    justify-content: space-between;
    .taocan1 {
      width: 380px;
      height: 510px;
      // border: 1px red solid;
      background: #ffffff;
      box-shadow: 0px 8px 120px 20px rgba(210, 216, 218, 0.26);
      border-radius: 12px;
      padding: 20px;
      position: relative;
      .h1 {
        font-size: 28px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #222222;
        line-height: 28px;
        margin-bottom: 30px;
      }
      p {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #9a9a9c;
        line-height: 24px;
        margin-top: 5px;
      }
      .jia {
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #f83d4c;
        line-height: 20px;
        margin-top: 30px;
      }
      .pay {
        width: 100px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        color: #ffffff;
        border-radius: 5px;
        background: #00a2ff;
        position: absolute;
        bottom: 20px;
        right: 40px;
      }
      .ma {
        width: 200px;
        margin-top: 10px;
        margin: auto;
      }
    }
  }
}
.tuiguang {
  width: 100%;
  h3 {
    margin-left: 30px;
  }
  .tui_top {
    width: 90%;
    height: 150px;
    margin: auto;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    .tui_top1 {
      width: 260px;
      height: 129px;
      background: #ffffff;
      box-shadow: 0px 2px 28px 0px rgba(197, 197, 197, 0.26);
      border-radius: 16px;
      position: relative;
      .txt1 {
        font-size: 34px;
        font-family: DINCondensed-Bold, DINCondensed;
        font-weight: bold;
        color: #03174c;
        line-height: 32px;
        position: absolute;
        top: 30px;
        left: 10px;
      }
      .txt2 {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #7e8da0;
        line-height: 16px;
        position: absolute;
        top: 70px;
        left: 20px;
      }
      .img1 {
        position: absolute;
        top: 30px;
        right: 20px;
      }
    }
  }
  .tui_list::-webkit-scrollbar {
    display: none;
  }
  .tui_list {
    width: 90%;
    height: 400px;
    margin: auto;
    overflow-y: scroll;
    .con {
      width: 100%;
      height: 80px;
      position: relative;
      margin-top: 10px;
      img {
        width: 70px;
        height: 70px;
        border-radius: 35px;
        margin-left: 20px;
      }
      .name {
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 17px;
        position: absolute;
        top: 30px;
        left: 100px;
      }
      .time {
        font-size: 22px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 17px;
        position: absolute;
        top: 30px;
        right: 40px;
      }
    }
  }
}
.shuofei {
  width: 100%;
  padding: 20px;
  .gai1 {
    p {
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #4f5153;
      line-height: 12px;
      margin-bottom: 20px;
      margin-top: 20px;
    }
    span {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #999999;
      line-height: 14px;
      margin-left: 60px;
    }
  }
  .save {
    width: 150px;
    height: 40px;
    background: #00a2ff;
    color: #fff;
    margin: auto;
    margin-top: 50px;
    border-radius: 5px;
    line-height: 40px;
    text-align: center;
  }
}
.save {
  width: 150px;
  height: 40px;
  background: #00a2ff;
  color: #fff;
  margin: auto;
  margin-top: 50px;
  border-radius: 5px;
  line-height: 40px;
  text-align: center;
}
.fan_all {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.topbox {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  .right {
    margin-left: 20px;
  }
}
.zhanghu {
  padding: 10px;
  .top_but {
    width: 130px;
    height: 40px;
    border-radius: 5px;
    text-align: center;
    line-height: 40px;
    color: #fff;
    background: #00a2ff;
  }
}
</style>
  