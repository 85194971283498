import WebSocketAsPromised from "websocket-as-promised";
import {v4 as uuidv4} from 'uuid'

let session_hash

export default {
    async join(channelName, params) {

        console.log(channelName, params)

        session_hash = uuidv4()

        const bingWs = new WebSocketAsPromised('wss://stabilityai-stable-diffusion-1.hf.space/queue/join', {
            packMessage: data => {
                return JSON.stringify(data)
            },
            unpackMessage: data => {
                return JSON.parse(data)
            },
        })

        bingWs.onUnpackedMessage.addListener((eventData) => {
            console.log(eventData)
            if (eventData.msg === 'send_hash') {
                bingWs.sendPacked({"session_hash":session_hash,"fn_index":3})
            } else if (eventData.msg === 'send_data') {
                bingWs.sendPacked({"fn_index":3,"data":[params.question],"session_hash":session_hash})
            } else if (eventData.msg === 'process_starts') {
                console.log('start')
            } else if (eventData.msg === 'process_completed') {
                let res = ''
                if (eventData.output.data && eventData.output.data.length > 0) {
                    eventData.output.data[0].forEach(i => {
                        res += `<img class="ai-pic" alt src="${i}"/>`
                    })
                }

                res = `<div class="ai-pic-panel">${res}</div>`

                params.onEvent({type: 'UPDATE', content: res, channelName: channelName})
                params.onEvent({type: 'DONE', channelName: channelName})
            }
        })

        bingWs.onClose.addListener(() => {
            console.log('close')
        })

        await bingWs.open()
        return bingWs
    },
}
