import axios from 'axios'
import store from '@/store'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: "https://3amao.com/api",
    // baseURL: "http://3amao.test.1search.top/api",
    // baseURL: "http://192.168.1.54:9090/api",
    // baseURL: "http://192.168.1.54:9090/api",
    // baseURL: process.env.VUE_APP_BASE_API,
    // 超时
    timeout: 60000
})
// console.log('baseURL', process.env.VUE_APP_BASE_API)
// request拦截器
service.interceptors.request.use(config => {

    const token = localStorage.getItem('token')
    // console.log('config',config)
    let stoken = store.getters
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false
    if (token && !isToken) {
        config.headers['ai_token'] = token
    }
    if (stoken) {
        console.log()
    }
    if (token) {
        config.headers['serviceAuthentication'] = token

    }
    // get请求映射params参数
    if (config.method === 'get' && config.params) {
        let url = config.url + '?';
        for (const propName of Object.keys(config.params)) {
            const value = config.params[propName];
            var part = encodeURIComponent(propName) + "=";
            if (value !== null && typeof (value) !== "undefined") {
                if (typeof value === 'object') {
                    for (const key of Object.keys(value)) {
                        let params = propName + '[' + key + ']';
                        var subPart = encodeURIComponent(params) + "=";
                        url += subPart + encodeURIComponent(value[key]) + "&";
                    }
                } else {
                    url += part + encodeURIComponent(value) + "&";
                }
            }
        }
        url = url.slice(0, -1);
        config.params = {};
        config.url = url;
    }
    // console.log('config',config)
    return config
}, error => {
    // console.log('req error', error)
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
    if (res.status !== 200) {
        return Promise.reject(res)
    }

    if (res.data.success) {
        return res.data
    }

    if (res.data.errorCode === 'no_login') {
        // const domain = window.location.protocol + "//" + window.location.host;
        // const url = domain + '/api/auth/wechat/login' + window.location.search
        // window.location.replace(url)
        return Promise.reject(res.data)
    }
    let arr=['/file/createQRCode']

    if (arr.includes(res.config.url)) {
        return res.data;
    }
    return Promise.reject(res.data)

},
    error => {
        return Promise.reject(error)
    }
)

export default service
