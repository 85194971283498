<template>
  <div class="drawbox">
    <div class="draw content contentdraw">
      <!-- 左边 -->
      <div class="left">
        <div
          class="top"
          style="color: #ffffff; font-size: 20px; margin-bottom: 20px"
        >
          历史记录
        </div>
        <div class="leftlist">
          <div
            class="leftblock"
            v-for="(item, index) in hisList"
            @click="godetail(item, 'his')"
            :key="index"
          >
            <span style="max-width: 80%;margin-right: 10px;">{{
              JSON.parse(item.record).question.slice(0,8)+'...'
            }}</span>
            <!-- <i @click="deleteDialog(item, item.id)" class="el-icon-delete"></i> -->
          </div>
          <div
            v-if="hisList.length == 0"
            class="listblock"
            style="font-size: 14px; text-align: center; padding: 20px"
          >
            还没有历史哦～快去创建吧～
          </div>
        </div>
      </div>
      <!-- 中间 -->
      <div class="contentcenter">
        <div class="taglist" :class="{ expanded: isExpanded }">
          <span>标签：</span>
          <div class="taglistcontent" :style="{ maxHeight: contentHeight }">
            <el-tag
              :class="{ 'el-tag--info': true, curr: tagcurr == 0 }"
              @click="selectone(0)"
              >全部</el-tag
            >
            <el-tag
              v-for="(item, index) in tagListlist"
              :class="{ curr: selectedIds.includes(item.id) }"
              @click="selectone(item)"
              :key="index"
              type="info"
              >{{ item.tagName }}</el-tag
            >
          </div>

          <div class="showmore" @click="toggleBox">
            {{ isExpanded ? "收起" : "展开" }}
          </div>
        </div>
        <div style="height: 30px; background-color: #fff"></div>
        <!-- <div
          class="taglist taglist2"
          style="display: flex; justify-content: flex-end; margin-top: 20px"
        >
          <el-button type="primary" round @click="createmuban"
            >创建指令模版</el-button
          >
        </div> -->

        <!-- <div class="taglist taglist2">
          <span>排序：</span>
          <div class="taglistcontent">
            <el-tag v-for="(item, index) in searchList" :class="{ 'curr': searchcurr == item.value }" :key="index"
              @click="selecttwo(item.value)" type="info">{{ item.label }}</el-tag>
          </div>
        </div> -->

        <div class="cappcontent" v-loading="loading">
          <div v-if="!tempList[0]"></div>
          <div style="font-size: 24px; margin: auto" v-if="!tempList[0]">
            该分类下暂时没有模板~
          </div>
          <div class="listblock" v-for="(item, index) in tempList" :key="index">
            <div class="topbox">
              <div class="topudata">
                <img
                  :src="
                    item.uid != 0
                      ? item.headimgurl
                      : 'https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/logo/apple/60.png'
                  "
                  alt=""
                />
                <div class="udata">
                  <div class="name">
                    {{ item.uid != 0 ? item.userName : "系统默认" }}
                  </div>
                  <div class="time">{{ item.time }}</div>
                </div>
              </div>
              <!-- <span class="guanzhu">关注</span> -->
            </div>
            <h3 @click="godetail(item)" class="title">{{ item.name }}</h3>
            <p @click="godetail(item)" class="des">{{ item.template }}</p>
            <!-- <div class="descontent">
              {{ item.record }}
            </div> -->
            <div class="taglistlist">
              <span v-for="(itm, index) in item.tags" :key="index"
                >#{{ itm }}</span
              >
            </div>
            <div class="bottom">
              <div class="bottomleft">
                <!-- <span><img src="../assets/zan.png" alt="">222</span>
                <span><img src="../assets/zan.png" alt="">222</span>
                <span><img src="../assets/zan.png" alt="">222</span> -->
                <!-- <span v-if="item.likeStatus == 'NO'"><img src="../assets/like.png" @click="dianzan(item)">{{
                  item.likeCount }}</span>
                <span v-if="item.likeStatus == 'YES'"><img src="../assets/ylike.png" @click="dianzan(item)">{{
                  item.likeCount }}</span>
                <span><img src="../assets/pl.png" alt="" @click="goshow(item)">{{
                  item.commentCount }}</span> -->
              </div>
              <div @click="godetail(item)" class="mygo">我也要用</div>
            </div>
          </div>
          <div
            v-if="!tempList"
            class="list"
            style="
              width: 100%;
              background: #ffffff;
              font-size: 14px;
              text-align: center;
              padding: 20px;
            "
          >
            暂无数据～
          </div>
        </div>
      </div>

      <!-- 右边 -->
      <div class="right">
        <div class="chuangjian" type="text" @click="chuangjian">创建应用</div>
        <div class="righttabbox">
          <div v-if="isLogin">
            <div class="rightdes">
              <h2>快捷入口<span>长按标签拖动顺序</span></h2>

              <div class="bianji" v-if="!isEdit" @click="bianji" type="text">
                编辑
              </div>
              <div class="bianji" v-else @click="baocunquick" type="text">
                完成编辑
              </div>
            </div>
            <div class="catarightlist">
              <draggable element="ul" v-model="quitcata">
                <li
                  :class="{ oneblock: true }"
                  v-for="(item, index) in quitcata"
                  :key="index"
                  @click="selectcate2(item)"
                >
                  <!-- <i v-if="isEdit" class="el-icon-plus icon"></i> -->
                  <i
                    v-if="isEdit"
                    class="el-icon-close icon"
                    @click="delquitcate(item)"
                  ></i>
                  <img
                    class="icon1"
                    :src="item.headIcon ? item.headIcon : item.icon"
                    alt=""
                  />

                  <span>{{ item.name }}</span>
                </li>
              </draggable>
            </div>
          </div>

          <div class="righttabbox">
            <div class="rightdes">
              <h2>我创建的应用<span></span></h2>

              <div
                class="bianji"
                style="color: red"
                type="text"
                @click="delShow"
                v-if="isEdit2 == false && defaultListmy.length != 0"
              >
                删除
              </div>
              <div
                class="bianji"
                type="text"
                @click="delShow"
                v-if="isEdit2 == true && defaultListmy.length != 0"
              >
                完成
              </div>
            </div>
            <div
              v-if="defaultListmy.length == 0"
              style="color: #707070; padding: 20px; :10px ; text-align: center"
            >
              暂无自己的分类~
            </div>
            <div v-for="(item, index) in defaultListmy" :key="index" v-else>
              <div class="rightdes">
                <h2>{{ item.name }}</h2>
                <i
                  @click.stop="del(item)"
                  v-if="isEdit2"
                  :class="{
                    icon: true,
                    'el-icon-close': isEdit2 == true,
                  }"
                ></i>
              </div>
              <div class="catarightlist">
                <ul>
                  <li
                    :class="{
                      oneblock: true,
                      currli: currcate ? currcate.id == itm.id : false,
                    }"
                    @click="selectcate(itm)"
                    v-for="(itm, idx) in item.children"
                    :key="idx"
                  >
                    <i
                      @click.stop="del(itm)"
                      v-if="isEdit2"
                      :class="{
                        icon: true,
                        'el-icon-close': isEdit2 == true,
                      }"
                    ></i>
                    <span class="txt">{{ itm.name.charAt(0) }}</span>
                    <img
                      class="icon1"
                      src="../../public/static/img/xiezuo.png"
                      alt=""
                    />
                    <span>{{ itm.name }}</span>
                    <!-- <span>{{ itm.count }}</span> -->
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div v-for="(item, index) in defaultList" :key="index">
            <div class="rightdes">
              <h2>{{ item.name }}</h2>
            </div>
            <div class="catarightlist">
              <ul>
                <li
                  :class="{
                    oneblock: true,
                    currli: currcate ? currcate.id == itm.id : false,
                  }"
                  v-for="(itm, idx) in item.children"
                  :key="idx"
                  @click="selectcate(itm)"
                >
                  <i
                    @click.stop="del(itm)"
                    v-if="isEdit2 && itm.userId != 0"
                    :class="{
                      icon: true,
                      'el-icon-close': isEdit2 == true,
                    }"
                  ></i>
                  <i
                    @click.stop="addquick(itm)"
                    v-if="isEdit"
                    :class="{
                      icon: true,
                      'el-icon-plus': !quitcataids.includes(itm.id),
                      'el-icon-close': quitcataids.includes(itm.id),
                    }"
                  ></i>

                  <img
                    class="icon1"
                    :src="itm.headIcon"
                    alt=""
                    v-if="itm.headIcon"
                  />
                  <span class="txt" v-if="!itm.headIcon">{{
                    itm.name.charAt(0)
                  }}</span>
                  <img
                    class="icon1"
                    src="../../public/static/img/xiezuo.png"
                    alt=""
                    v-if="!itm.headIcon"
                  />
                  <!-- <i class="el-icon-close icon"></i> -->
                  <span>{{ itm.name }}</span>
                  <!-- <span @click="selectcate(itm)">{{ itm.count }}</span> -->
                </li>
              </ul>
            </div>
          </div>

          <!-- <div class="rightdes">
                <h2>写作</h2>
              </div>
              <div class="catarightlist">
                <ul>
                  <li v-for="(item, index) in list" :key="index">{{ item.name }}</li>
                </ul>
              </div> -->
        </div>
      </div>
    </div>
    <!-- 创建场景弹窗 -->
    <el-dialog
      title="创建应用"
      :visible.sync="iscreate"
      style="border-radius: 12px"
    >
      <div class="addbox">
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item>
            <div class="selectbox">
              <div class="blockselect">
                <span>一级分类：</span>
                <!-- <el-select
                  v-model="bigcatevalue"
                  multiple
                  filterable
                  :multiple-limit="1"
                  allow-create
                  :default-first-option="true"
                  placeholder="请选择一级分类"
                  @change="bigcatechange"
                >
                  <el-option
                    v-for="item in bigoptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select> -->
                <el-autocomplete
                  v-model="state1"
                  :fetch-suggestions="querySearchAsync"
                  value-key="name"
                  placeholder="请输入内容"
                  @select="handleSelect"
                  @change="autocChange"
                ></el-autocomplete>
              </div>
              <div class="blockselect">
                <span>二级分类：</span>
                <!-- <el-select
                  v-model="subcatevalue"
                  multiple
                  filterable
                  :multiple-limit="1"
                  allow-create
                  :default-first-option="true"
                  placeholder="请选择二级分类"
                  @change="subchange"
                >
                  <el-option
                    v-for="item in suboptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select> -->
                <el-autocomplete
                  v-model="state2"
                  :fetch-suggestions="querySearchAsync2"
                  value-key="name"
                  placeholder="请输入内容"
                  @select="handleSelect2"
                  @change="autocChange2"
                ></el-autocomplete>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="appTemplateaddCategoryListmethod"
              >确认创建</el-button
            >
            <el-button @click="cancle">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import {
  getDefaultCategoryList,
  appTemplateaddCategoryList,
  getUserTagList,
  getTemplateList,
  getQuickCategoryList,
  addQuickCategoryList,
  deleteFen,
} from "@/api";
// import chatgpt from '@/utils/chatgpt'
// import bing from '@/utils/bing'
// import stabilityai from '@/utils/stabilityai'
import { mapGetters } from "vuex";
export default {
  name: "app",
  components: {
    draggable,
  },
  computed: {
    ...mapGetters(["userinfo", "channelList"]),
  },
  data() {
    return {
      state1: "",
      state2: "",
      loading: false,
      listcateq: [],
      list: [
        {
          id: 1,
          name: "电子商务",
        },
        {
          id: 2,
          name: "美食",
        },
        {
          id: 3,
          name: "音乐",
        },
        {
          id: 4,
          name: "健康医疗",
        },
      ],
      contentHeight: "38px",
      defaultListdata: [],
      iscreate: false,
      selectedTags: [],
      selectedIds: [],
      tagcurr: 0,
      currcate: null,
      searchList: [
        {
          value: "TYPE_1",
          label: "最新发布",
        },
        {
          value: "TYPE_2",
          label: "评论最多",
        },
        // {
        //     value: "TYPE_3",
        //     label: "收藏最多",
        // },
        {
          value: "TYPE_4",
          label: "点赞最多",
        },
      ],
      activeName: "first",
      defaultList: [],
      defaultListmy: [],
      tagListlist: [],
      bigoptions: [],
      suboptions: [],
      subcata: [],
      isEdit: false,
      isEdit2: false,
      bigcatevalue: [],
      bigcateid: "",
      subcateid: "",
      subcatevalue: [],
      quickarr: null,
      bigsort: 0,
      catesort: 0,
      form: {},
      quitcata: [],
      quitcataids: [],
      currcatadata: [],
      isLogin: false,
      tagList: null,
      isExpanded: false,
      searchcurr: "TYPE_1",
      isgroup: null,
      hisList: [],
    };
  },
  watch: {},
  activated() {
    if (this.$route.query.param) {
      this.iscreate = true;
    }
    this.getSysTagListmethod();
  },
  created() {
    console.log(JSON.parse(localStorage.getItem("groupCon")));
    this.isgroup = JSON.parse(localStorage.getItem("groupCon"));
    let newarr = [];
    const ChannelList = JSON.parse(localStorage.getItem("ChannelList"));
    // console.log('ChannelList', ChannelList)
    ChannelList.forEach((i) => {
      if (!i.disabled) {
        if (i.type === "draw") {
          newarr.push(i);
        }
      }
    });
    // console.log(newarr)
    this.searchChannelList = newarr;
    this.searchChannel = newarr[0].channelName;
    this.searchChannel1 = newarr[0].channelDesc + newarr[0].desc;

    this.getDefaultCategorythod();
    this.getTemplateListmethod();
    this.getTemplateListmethodhis();
    if (localStorage.getItem("token")) {
      this.isLogin = true;
      this.getQuickCategoryListmethod();
    }
  },
  beforeDestroy() {},
  mounted() {
    console.log(JSON.parse(localStorage.getItem("groupCon")));
    this.isgroup = JSON.parse(localStorage.getItem("groupCon"));
  },
  methods: {
    autocChange() {
      console.log(this.state1);
      this.bigcateid=''
      for (let i = 0; i < this.bigoptions.length; i++) {
        if (this.state1 == this.bigoptions[i].name) {
          console.log("已有分类");
          this.suboptions = this.bigoptions[i].children;
          this.catesort =
            this.bigoptions[i].children[
              this.bigoptions[i].children.length - 1
            ].sort;
          this.bigcateid = this.bigoptions[i].id;
        }
      }
      console.log(this.bigcateid); 
    },
    autocChange2() {
      console.log(this.state2);
      this.subcateid=''
      for (let i = 0; i < this.suboptions.length; i++) {
        if (this.state2 == this.suboptions[i].name) {
          console.log("已有2分类");
          this.subcateid = this.suboptions[i].id;
          this.currcatadata = this.suboptions[i];
        }
      }
      console.log(this.subcateid); 
    },
    querySearchAsync(queryString, cb) {
      var restaurants = this.bigoptions;
      var results = queryString
        ? restaurants.filter(this.createStateFilter(queryString))
        : restaurants;
      cb(results);
    },
    createStateFilter(queryString) {
      return (state1) => {
        return (
          state1.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    handleSelect(item) {
      console.log(item);
      this.suboptions = item.children;
      this.catesort = item.children[item.children.length - 1].sort;
      this.bigcateid = item.id;
    },

    querySearchAsync2(queryString, cb) {
      var restaurants = this.suboptions;
      var results = queryString
        ? restaurants.filter(this.createStateFilter2(queryString))
        : restaurants;
      cb(results);
    },
    createStateFilter2(queryString) {
      return (state1) => {
        return (
          state1.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    handleSelect2(item) {
      console.log(item);
      this.subcateid = item.id;
      console.log(this.suboptions);
      this.currcatadata = item;
    },
    del(e) {
      let data = {
        groupId: this.isgroup ? this.isgroup.id : null,
        id: e.id,
      };
      deleteFen(data).then((res) => {
        console.log(res);
        this.$message({
          type: "success",
          message: "删除成功",
          duration: 1500,
        });
        this.getDefaultCategorythod();
      });
    },
    delShow() {
      this.isEdit2 = !this.isEdit2;
    },
    // 去详情
    godetail(item, from = "Aiapp") {
      console.log("gogogo", item);
      localStorage.setItem("Aiappdata", JSON.stringify(item));
      this.$router.push({
        path: "/showdetaildis/" + item.id,
        query: {
          param: from,
        },
      });
    },
    // 保存快捷分类
    baocunquick() {
      console.log(this.quitcata);
      console.log(this.quickarr);
      const dt = this.quitcata;
      for (let a in dt) {
        dt[a].sort = a + 1;
      }
      console.log("dt", dt);
      // this.quickarr
      this.isEdit = false;
      let datas = {
        id: this.quickarr[0].id,
        sort: this.quickarr[0].sort,
        secondList: dt,
        groupId: this.isgroup ? this.isgroup.id : null,
      };
      this.addQuickCategoryListmethod(datas);
    },
    addquick(item) {
      console.log(item);
      const idcurr = item.id;
      if (!this.quitcataids.includes(idcurr)) {
        this.quitcata.push(item);
        this.quitcataids.push(item.id);
      } else {
        const index = this.quitcata.indexOf(item);
        // console.log('111',index)
        this.quitcata.splice(index, 1);
        this.quitcataids.splice(index, 1);
      }
      // this.listcateq =  this.quitcata
      this.$forceUpdate();
    },
    // 点击了编辑
    bianji() {
      this.isEdit = true;
    },
    // 快捷入口
    getQuickCategoryListmethod() {
      let data = {
        groupId: this.isgroup ? this.isgroup.id : null,
      };
      getQuickCategoryList(data).then((res) => {
        console.log("aaaaaaaaa", res.obj);
        this.quitcata = res.obj;
        for (let i = 0; i < this.quitcata.length; i++) {
          if (this.quitcata[i].headIcon == null) {
            this.quitcata[i].headIcon =
              this.quitcata[i].templateRespList[0].headIcon;
          }
        }
        console.log(this.quitcata);
        this.quitcataids = Object.values(res.obj).map((item) => item.cid);
      });
    },
    // 新增快捷
    addQuickCategoryListmethod(data) {
      addQuickCategoryList(data).then((res) => {
        console.log(res);
        this.isEdit = false;
      });
    },
    // 历史
    getTemplateListmethodhis() {
      let data = {
        groupId: this.isgroup ? this.isgroup.id : null,
        isHistory: 1,
        isEnterpriseShow: 1,
        name: "",
      };
      getTemplateList(data).then((res) => {
        console.log(res);
        this.hisList = res.obj;
      });
    },
    // 获取模板列表
    getTemplateListmethod() {
      this.tempList = [];
      let data = {
        cid: this.currcate != null ? this.currcate.id : null,
        isHistory: 0,
        isEnterpriseShow: 1,
        tags: this.selectedIds,
        name: "",
        groupId: this.isgroup ? this.isgroup.id : null,
      };
      getTemplateList(data).then((res) => {
        console.log(res);
        this.tempList = res.obj;
        console.log(this.tempList);
        this.loading = false;
        this.$forceUpdate();
      });
    },
    createmuban() {
      if (!localStorage.getItem("token")) {
        this.$message({
          type: "error",
          message: "请先登录",
          duration: 1500,
        });
        return false;
      }
      // console.log('this.currcate', this.currcate)
      if (!this.currcate) {
        this.$message({
          type: "error",
          message: "请先选择分类",
          duration: 1500,
        });
        return false;
      }
      this.$router.push({
        path: "/create", // 目标路由的名称或路径
        query: {
          param: JSON.stringify(this.currcatadata),
        },
      });
    },
    // 删除
    delquitcate(item) {
      const idx = this.quitcata.indexOf(item);
      this.quitcata.splice(idx, 1);
      this.quitcataids.splice(idx, 1);
    },

    selectone(tag) {
      this.currcate = null;
      if (tag == 0) {
        console.log("全部");
        this.tagcurr = 0;
        this.selectedTags = [];
        this.selectedIds = [];
        this.getTemplateListmethod();
        return;
      }
      if (!this.selectedIds.includes(tag.id)) {
        this.tagcurr = null;
        this.selectedTags.push(tag);
        this.selectedIds.push(tag.id);
      } else if (this.selectedIds.includes(tag.id)) {
        const index = this.selectedTags.indexOf(tag);
        // console.log('111',index)

        this.selectedTags.splice(index, 1);
        this.selectedIds.splice(index, 1);
      }

      console.log(this.selectedIds);

      this.getTemplateListmethod();

      // console.log('data', data)
    },
    getSysTagListmethod() {
      let data = {
        groupId: this.isgroup ? this.isgroup.id : null,
        tagType: "APP",
      };
      getUserTagList(data).then((res) => {
        this.tagListlist = res.obj;
      });
    },
    cancle() {
      this.iscreate = false;
    },
    chuangjian() {
      if (!localStorage.getItem("token")) {
        this.$message({
          type: "error",
          message: "请先登录",
          duration: 1500,
        });
        return false;
      }
      this.iscreate = true;
    },
    bigcatechange(e) {
      // this.bigcatevalue = e[0]
      console.log(e);
      const etype = typeof e[0];
      this.subcatevalue = [];
      if (etype == "number") {
        let resultbig = this.bigoptions.filter((item) => item.id == e);
        // 选择的
        this.suboptions = resultbig[0].children;

        this.catesort =
          resultbig[0].children[resultbig[0].children.length - 1].sort;

        this.bigcateid = e[0];
      } else {
        // 新增
        this.bigcatevalue = e;
        this.suboptions = [];
      }
    },
    subchange(e) {
      const etypedata = typeof e[0];
      if (etypedata == "number") {
        this.subcateid = e[0];
        console.log(this.suboptions);
        for (let i = 0; i < this.suboptions.length; i++) {
          if (this.suboptions[i].id == this.subcateid) {
            console.log(this.suboptions[i]);
            this.currcatadata = this.suboptions[i];
          }
        }
      } else {
        // 新增
        this.subcatevalue = e;
      }
    },
    // c创建场景
    appTemplateaddCategoryListmethod() {
      console.log(this.bigcatevalue, this.subcatevalue);
      if (this.state1 == '' || this.state2 == '') {
        this.$message({
          type: "error",
          message: "请完善分类",
          duration: 1500,
        });
      } else {
        const bigcate = this.state1;
        // const bigcatetype = typeof this.bigcatevalue[0];
        const subcate = this.state2;
        // const subcatetype = typeof this.subcatevalue[0];
        // console.log(bigcatetype);
        // console.log(subcatetype);
        // return
        let datas = {};
        if (this.bigcateid!='') {
          // 老排序
          datas.id = this.bigcateid;
          datas.pid = 0;
          datas.sort = this.bigoptions.filter(
            (item) => item.id == bigcate
          ).sort;
          if (this.subcateid!='') {
            this.iscreate = false;
            let data = {
              id: this.subcateid,
              name: this.suboptions.filter((item) => item.id == this.subcateid)[0]
                .name,
              sort: this.catesort,
            };
            this.$router.push({
              path: "/create", // 目标路由的名称或路径
              query: {
                param: JSON.stringify(data),
              },
            });
            this.state1 = '';
            this.state2 = '';
          } else {
            datas.secondList = [
              {
                id: null,
                isEnterpriseShow: 1,
                name: subcate,
                pid: null,
                secondList: [],
                sort: this.catesort + 1,
              },
            ];
            console.log(datas);
            appTemplateaddCategoryList(datas).then((res) => {
              console.log(res);
              this.iscreate = false;
              this.state1 = '';
              this.state2 = '';
              this.getDefaultCategorythod();
              this.currcatadata = res.obj;
              this.$router.push({
                path: "/create", // 目标路由的名称或路径
                query: {
                  param: JSON.stringify(this.currcatadata),
                },
              });
            });
          }
        } else {
          // 新的
          datas.sort = this.bigsort + 1;
          datas.name = this.state1;
          datas.pid = 0;
          (datas.groupId = this.isgroup ? this.isgroup.id : null),
            (datas.secondList = [
              {
                id: null,
                isEnterpriseShow: 1,
                name: subcate,
                pid: null,
                secondList: [],
                sort: 0,
              },
            ]);
          console.log(datas);
          appTemplateaddCategoryList(datas).then((res) => {
            console.log(res);
            this.iscreate = false;
            this.bigcatevalue = [];
            this.subcatevalue = [];
            this.getDefaultCategorythod();
            let data = {
              id: res.obj.id,
              name: res.obj.name,
              sort: res.obj.sort,
            };
            this.$router.push({
              path: "/create", // 目标路由的名称或路径
              query: {
                param: JSON.stringify(data),
              },
            });
            this.$forceUpdate();
          });
        }
      }
    },
    selectcate(item) {
      this.loading = true;
      console.log(item);
      console.log(item.userId == 0);
      this.currcate = item;
      this.currcatadata = item;
      const catalistdata = this.defaultListdata;
      this.tempList = [];
      this.selectedIds=[]
      console.log(catalistdata);
      this.getTemplateListmethod();
    },
    selectcate2(item) {
      this.selectedIds=[]
      console.log(item);
      this.currcate = item;
      this.currcate.id = this.currcate.cid;
      this.getTemplateListmethod();
    },
    toggleBox() {
      this.isExpanded = !this.isExpanded;
      this.contentHeight = this.isExpanded ? "none" : "38px"; // 设置内容的高度
    },
    // 获取分类快捷入口

    removeItemByName(array, name) {
      return array.filter((item) => item.name !== name);
    },
    getItemByName(array, name) {
      return array.filter((item) => item.name === name);
    },
    getTemplateRespList(arr) {
      // 对输入的数组进行校验，避免 undefined
      if (!Array.isArray(arr)) {
        console.error("输入参数不是一个数组！");
        return [];
      }

      return arr.reduce((acc, curr) => {
        // 对数组中的每一个对象进行校验，避免 undefined
        if (!curr.templateRespList) {
          console.warn("数组中存在无 templateRespList 的对象！");
          return acc;
        }

        // 将当前对象的 templateRespList 展开并加入新数组
        return [...acc, ...curr.templateRespList];
      }, []);
    },
    // 获取分类
    getDefaultCategorythod() {
      this.defaultList = [];
      this.defaultListmy = [];
      this.bigoptions = [];
      this.suboptions = [];
      let data = {
        groupId: this.isgroup ? this.isgroup.id : null,
      };
      getDefaultCategoryList(data).then((res) => {
        const arr = this.removeItemByName(res.obj, "快捷入口");
        this.bigoptions = this.removeItemByName(res.obj, "快捷入口");
        this.quickarr = this.getItemByName(res.obj, "快捷入口");
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].notMyCategory == true) {
            this.defaultList.push(arr[i]);
          } else {
            this.defaultListmy.push(arr[i]);
          }
        }
      });
    },
    handleSortChange() {},
    handleClick(tab, event) {
      console.log(tab, event);
      if (tab.index == 1) {
        if (!localStorage.getItem("token")) {
          this.$message({
            type: "error",
            message: "请先登录",
            duration: 1500,
          });
          return false;
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
* {
  box-sizing: border-box;
}
body::-webkit-scrollbar {
  display: none;
}
.drawbox {
  .content {
    display: flex;
    justify-content: space-between;
    flex: 1;

    .left {
      width: 235px;
      height: 90vh;
      background: #1d1d1d;
      padding: 20px;
      overflow-y: scroll;
      position: fixed;
      left: 0;
      color: #fff;
      .top {
        width: 100%;
        text-align: center;
      }

      .el-button {
        margin-bottom: 20px;
      }

      .leftlist {
        line-height: 40px;

        .leftblock {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          height: 40px;
          line-height: 40px;
          cursor: pointer;
          border-radius: 8px;
          border: 1px solid #dddddd;
          text-align: center;
          margin-top: 5px;
          margin-bottom: 5px;
          padding-left: 10px;
        }

        .leftcurr {
          background: #f7f9fa;
        }
      }
    }
    .left::-webkit-scrollbar {
      display: none;
    }

    .contentcenter {
      min-width: 700px;
      background: #f6f6f6;
      border-radius: 8px;
      margin: 0 20px;
      flex: 1;
      width: calc(1200px - 310px);
      padding-right: 270px;
      padding-left: 230px;

      .cappcontent {
        display: grid;
        grid-template-columns: auto auto auto;
        flex-wrap: wrap;
        padding-top: 20px;

        // border: 1px solid red;

        .listblock :nth-child(1),
        .listblock :nth-child(4n + 4) {
          margin: 20px 20px 20px 0;
        }

        .listblock {
          width: 95%;
          height: 260px;
          background: #ffffff;
          border-radius: 8px;
          margin: 0 20px 20px 0;
          overflow: hidden;
          padding: 6px 16px 16px 16px;
          position: relative;
          margin: auto;
          margin-bottom: 30px;

          .title {
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #292929;
            line-height: 16px;
            margin-top: 15px;
          }

          .des {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            margin-top: 10px;
            line-height: 18px;
            display: -webkit-box;
            /*使用旧版本的 WebKit 内核浏览器*/
            -webkit-box-orient: vertical;
            /*设置文本在垂直方向上排列*/
            -webkit-line-clamp: 3;
            /*限制显示三行，超出部分隐藏*/
            text-overflow: ellipsis;
            overflow: hidden;
            /*超出部分显示省略号*/
          }

          .descontent {
            overflow: hidden;
            /*限制溢出部分不可见*/
            display: -webkit-box;
            /*使用旧版本的 WebKit 内核浏览器*/
            -webkit-box-orient: vertical;
            /*设置文本在垂直方向上排列*/
            -webkit-line-clamp: 3;
            /*限制显示三行，超出部分隐藏*/
            text-overflow: ellipsis;
            /*超出部分显示省略号*/
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #969899;
            line-height: 18px;
            height: 56px;
            margin: 10px 0 0;
            overflow: hidden;
          }
        }

        .bottom {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 10px 0 0;
          position: absolute;
          bottom: 5px;
          right: 30px;

          .mygo {
            width: 89px;
            height: 33px;
            background: #3eadf7;
            border-radius: 17px;
            border: 1px solid #f0f0f0;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 17px;
          }

          .bottomleft {
            display: flex;
            flex: 1;

            span {
              margin: 0;
              display: flex;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #989898;
              line-height: 12px;
              margin-right: 8px;

              img {
                margin: 0 8px 0 0;
                width: 15px;
              }
            }
          }
        }

        .taglistlist {
          margin: 10px 0 0;

          span {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #19559e;
            line-height: 12px;
          }
        }

        .topbox {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin: 0;

          .guanzhu {
            width: 76px;
            height: 33px;
            background: #519eff;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 17px;
            cursor: pointer;
          }

          .topudata {
            display: flex;
            flex-direction: row;
            margin: 0;

            .udata {
              margin: 0;

              .name {
                margin: 0;
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #2c343e;
                line-height: 12px;
              }

              .time {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #969899;
                line-height: 12px;
                margin-top: 10px;
              }
            }

            img {
              display: block;
              width: 38px;
              height: 38px;
              border-radius: 50%;
              margin: 0 10px 0 0;
            }
          }
        }
      }
    }

    // display: flex;
    // height: 100%;
    // flex-direction: column;
    // align-items: center;
    // justify-content: center;
    // position: relative;
    .taglist2 {
      padding: 0 20px 20px !important;
      margin-top: 0 !important;
    }

    .taglist {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      // height: 60px;
      overflow: hidden;
      background: #ffffff;
      border-radius: 8px 8px 0px 0px;
      padding: 20px;
      padding-bottom: 0;

      .taglistcontent {
        width: calc(100% - 150px);

        .el-tag {
          margin: 5px 5px;
          cursor: pointer;
        }

        .curr {
          background: #f1f7ff;
          border-radius: 3px;
          color: #519eff !important;
        }
      }

      .showmore {
        color: #519eff !important;
        cursor: pointer;
        position: relative;
      }

      // span {
      //     padding: 5px 15px;
      //     border-radius: 3px;
      //     cursor: pointer;
      // }
    }
  }

  .right {
    width: 280px;
    height: 90vh;
    overflow-y: scroll;
    background: #ffffff;
    padding: 20px 30px;
    position: fixed;
    right: 0;

    .chuangjian {
      width: 200px;
      background: #3eadf7;
      padding: 10px;
      text-align: center;
      border-radius: 25px;
      color: #fff;
      margin: auto;
      margin-bottom: 20px;
    }

    .righttabbox {
      .rightdes {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;

        h2 {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
          line-height: 14px;
        }

        span {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 14px;
          margin-left: 10px;
        }

        .bianji {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #519eff;
          line-height: 14px;
          cursor: pointer;
        }
      }

      .catarightlist {
        margin-top: 20px;

        ul {
          display: grid;
          grid-template-columns: auto auto auto;

          li:nth-child(2n + 2) {
            margin-right: 0 !important;
          }

          .currli {
            background: #ffffff;
            border-radius: 6px;
            border: 1px solid #519eff;
            color: #519eff;
          }

          li {
            display: inline-block;
            width: 70px;
            height: 100px;
            background: #ffffff;
            border-radius: 6px;
            border: 1px solid #e8e8e9;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-flow: column;
            white-space: nowrap;
            //overflow: hidden;
            text-overflow: ellipsis;
            margin-right: 10px;
            margin-top: 10px;
            cursor: pointer;

            span {
              margin: 0 6px;
              margin-top: 10px;
              font-size: 12px;
              word-wrap: break-word;
            }
            .icon1 {
              width: 40px;
              height: 40px;
            }
          }
        }
      }
    }

    // .el-tabs {
    //   width: 268px;
    //   height: 40px;
    //   background: #F8F8F8;
    //   border-radius: 18px;

    //   .el-tabs__item.is-active {
    //     width: 123px;
    //     height: 40px;
    //     background: #519EFF;
    //     border-radius: 18px;
    //   }
    // }
  }
}

.selectbox {
  display: flex;

  .blockselect {
    margin-right: 20px;
  }
}

.oneblock {
  position: relative;

  .txt {
    position: absolute;
    top: 20px;
    font-size: 20px;
  }
  .icon {
    position: absolute;
    right: -10px;
    top: -10px;
    border: 1px solid #333;
    border-radius: 50%;
    z-index: 99;
  }
}
</style>
<style scoped>
::v-deep .el-dialog {
  border-radius: 12px !important;
}

.el-icon-plus {
  background-color: #519eff !important;
  border: 1px solid #519eff !important;
  color: #fff !important;
  cursor: pointer;
}
</style>
