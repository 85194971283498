<template>
  <div class="all">
    <el-menu
      default-active="2"
      class="el-menu-vertical-demo"
      @select="handleselect"
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b"
      style="width: 15%; position: fixed; height: 100vh"
    >
      <el-menu-item index="2" v-if="group_role == 'ADMIN'">
        <i class="el-icon-s-custom"></i>
        <span slot="title">人员管理</span>
      </el-menu-item>
      <el-menu-item index="1">
        <i class="el-icon-menu"></i>
        <span slot="title">领域模型管理</span>
      </el-menu-item>
      <el-menu-item index="3">
        <i class="el-icon-s-claim"></i>
        <span slot="title">模型召回监控</span>
      </el-menu-item>
      <el-menu-item index="4" v-if="group_role == 'ADMIN'">
        <i class="el-icon-info"></i>
        <span slot="title">ApiKey管理</span>
      </el-menu-item>

    </el-menu>
    <div v-if="page == 1" class="right_con">
      <div class="fileUp">
        <el-upload
          class="upload-demo"
          :action="baseUrl + '/api/file/upload'"
          ref="upload"
          :headers="upload"
          :on-preview="handlePreview"
          :on-success="handleAvatarSuccess"
          :on-remove="handleRemove"
          :before-upload="beforeAvatarUpload"
          multiple
          :limit="1"
          :on-exceed="handleExceed"
          :file-list="fileList"
          style="width: 40%; text-align: center; margin: auto"
        >
          <el-button size="big" type="primary" style="font-size: 18px">{{
            fileName ? "更新" : "上传"
          }}</el-button>
          <div slot="tip" class="el-upload__tip" style="font-size: 16px">
            点击上传你的企业领域知识库<br />(支持csv,pdf,xlsx,txt,docx格式的文件)
          </div>
        </el-upload>
      </div>

      <div style="position: relative">
        <p
          style="
            position: absolute;
            top: 10px;
            right: 10px;
            color: #3895ff;
            z-index: 1099;
            cursor: default;
          "
          @click="ismoban_show"
        >
          模板设置
        </p>
        <div
          v-loading="loading"
          element-loading-text="正在解析中"
          style="
            width: 100%;
            height: 100px;
            position: absolute;
            top: 40px;
            z-index: 999;
          "
        ></div>
        <el-table
          :data="tableData2"
          style="width: 100%; margin-top: 20px; z-index: 998"
          v-loading="loading"
          element-loading-spinner="el"
        >
          <el-table-column
            prop="text_content"
            :label="fileName ? fileName : '领域库详情'"
          >
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div v-if="page == 2" class="right_con">
      <div class="link" @click="link">邀请成员加入</div>
      <el-table :data="tableData" style="width: 100%; margin-top: 30px">
        <el-table-column prop="nickName" label="姓名" width="180">
        </el-table-column>
        <el-table-column prop="userRole" label="权限" width="180">
          <template slot-scope="scope">
            <span>{{
              scope.row.userRole == "ADMIN"
                ? "管理员"
                : scope.row.userRole == "EXPERT"
                ? "专家"
                : "成员"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="inviteNickName" label="邀请人姓名" width="100">
        </el-table-column>
        <el-table-column prop="gmtCreate" label="加入时间" width="250">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              style="margin-right: 10px"
              size="mini"
              @click="handleEdit(scope.$index, scope.row)"
              >编辑</el-button
            >
            <el-popconfirm
              title="确定要移出吗？"
              @confirm="handleDelete(scope.$index, scope.row)"
            >
              <el-button slot="reference" size="mini" type="danger"
                >移出</el-button
              >
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-if="page == 3" class="right_con">
      <div class="link" @click="iszhaohui = true">召回设置</div>
      <el-table :data="tableData3" style="width: 100%; margin-top: 30px">
        <el-table-column prop="question" label="问题"> </el-table-column>
        <el-table-column
          prop="questionCompleteValue"
          label="实际问题"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column prop="answer" label="回答" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="recall" label="召回原因" width="180">
          <template slot-scope="scope">
            <span>{{ scope.row.recall == 1 ? "被踩" : "错误回答" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="gmtUpdate" label="召回" width="250">
        </el-table-column>
      </el-table>
    </div>
    <div class="api right_con" v-if="page == 4">
      <p>
        当前企业ApiKey：<i>{{ groupList.apikey.substring(0, 5)+'********************************'+groupList.apikey.slice(-5) }}</i
        ><i
          class="el-icon-document-copy"
          style="color: #3fa2ff; margin-left: 20px; margin-right: 20px"
          @click="copy(groupList.apikey)"
        ></i>
      </p>
      <p>
        当前企业Secret：<i>{{ groupList.secret.substring(0, 5)+'********************************'+groupList.secret.slice(-5)}}</i
        ><i
          class="el-icon-document-copy"
          style="color: #3fa2ff; margin-left: 20px"
          @click="copy(groupList.secret)"
        ></i>
      </p>
      <el-button type="primary" @click="newApikey">重新生成</el-button>
    </div>
    <el-dialog
      title="召回源配置"
      :visible.sync="iszhaohui"
      style="border-radius: 12px"
    >
      <div class="addbox">
        <el-form ref="form" :model="zhaohuiList" label-width="80px">
          <el-form-item>
            <el-checkbox-group v-model="zhaohuiList.menuList">
              <el-checkbox label="被踩召回" name="cai"></el-checkbox><br />
              <el-checkbox label="回答错误召回" name="moban"></el-checkbox
              ><br />
            </el-checkbox-group>
            <el-input
              type="textarea"
              placeholder="请输入回答错误的内容"
              :rows="3"
              v-model="zhaohuiList.moban"
              :disabled="
                zhaohuiList.menuList.indexOf('回答错误召回') != -1
                  ? false
                  : true
              "
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="updata2">保存</el-button>
            <el-button @click="iszhaohui = false">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog
      title="模板设置"
      :visible.sync="ismoban"
      style="border-radius: 12px; z-index: 999999"
    >
      <div class="addbox">
        <el-input
          type="textarea"
          :rows="3"
          v-model="mobanList.moban.title"
          style="margin-bottom: 10px"
        ></el-input>
        <p style="margin-bottom: 30px; margin-left: 10px">
          信息如下: {信息内容}<br />
          <br />问题: {问题内容}<br /><br />
          回答: {回答内容}
        </p>
        <el-button type="primary" @click="updata">保存</el-button>
        <el-button @click="ismoban = false">取消</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="编辑"
      :visible.sync="iscreate"
      style="border-radius: 12px"
    >
      <div class="addbox">
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="特殊资源">
            <el-radio-group v-model="form.resource">
              <el-radio label="ADMIN">管理员</el-radio>
              <el-radio label="EXPERT">专家</el-radio>
              <el-radio label="ORDINARY">成员</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">保存</el-button>
            <el-button @click="iscreate = false">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
    <script>
import {
  generateGroupLink,
  getGroupUserList,
  updateGroupUserRole,
  deleteGroupUserle,
  updateUserGroup,
  uploadGroupKnowledgeFile,
  getGroupChunkList,
  newApiKey,
  getUserGroup,
  getGroupRecallList,
} from "@/api";
import { mapGetters } from "vuex";
export default {
  name: "group",
  components: {},
  computed: {
    ...mapGetters(["baseUrl", "userinfo", "notifyOpen", "clientHeight"]),
  },
  data() {
    return {
      loading: false,
      groupList: [],
      tableData: [],
      iscreate: false,
      form: { id: null, resource: "", i: 1 },
      tableData2: [],
      tableData3: [],
      edit_name: "",
      isinput: false,
      activeName: "more",
      fileList: [],
      fileName: "",
      upload: { serviceAuthentication: localStorage.getItem("token") },
      group_role: null,
      page: 2,
      iszhaohui: false,
      ismoban: false,
      zhaohuiList: {
        menuList: ["被踩召回", "回答错误召回"],
        moban: "",
      },
      mobanList: {
        moban:{
          title:''
        },
      },
    };
  },
  watch: {},
  beforeDestroy() {},
  mounted() {
    let obj = JSON.parse(localStorage.getItem("groupCon"));
    let user = JSON.parse(localStorage.getItem("udata"));
    if (obj == null) {
      console.log(11111111);
    } else {
      for (let i = 0; i < user.userGroupList.length; i++) {
        if (user.userGroupList[i].groupId == obj.id) {
          this.group_role = user.userGroupList[i].userRole;
        }
      }
    }
    let data1 = {
      groupId: JSON.parse(localStorage.getItem("groupCon")).id,
    };
    getGroupRecallList(data1).then((res) => {
      console.log(res.obj);
      this.tableData3 = res.obj;
    });
    getUserGroup(data1).then((res) => {
      console.log(res.obj);
      this.groupList = res.obj;
      if (res.obj.groupLikeConf == 1 && res.obj.groupTitleConf == 1) {
        this.zhaohuiList.menuList = ["被踩召回", "回答错误召回"];
      } else if (res.obj.groupLikeConf == 1 && res.obj.groupTitleConf == 0) {
        this.zhaohuiList.menuList = ["被踩召回"];
      } else if (res.obj.groupLikeConf == 0 && res.obj.groupTitleConf == 0) {
        this.zhaohuiList.menuList = [];
      } else if (res.obj.groupLikeConf == 0 && res.obj.groupTitleConf == 1) {
        this.zhaohuiList.menuList = ["回答错误召回"];
      }
      this.zhaohuiList.moban = res.obj.groupRecallTitle;
      this.mobanList.moban =res.obj.groupTemplate? JSON.parse(res.obj.groupTemplate):{title:''};
      this.fileName = this.groupList.groupFileJson?JSON.parse(this.groupList.groupFileJson)[0].fileName:'';
      let data = {
        groupCode: this.groupList.groupCode,
      };
      this.loading = true;
      getGroupChunkList(data).then((res) => {
        this.loading = false;
        console.log(res.obj);
        this.tableData2 = res.obj;
      });
      console.log(this.groupList);
      this.getlist();
    });
  },
  activated() {
    let data1 = {
      groupId: JSON.parse(localStorage.getItem("groupCon")).id,
    };
    getGroupRecallList(data1).then((res) => {
      console.log(res.obj);
      this.tableData3 = res.obj;
    });
  },
  methods: {
    ismoban_show() {
      console.log(1);
      this.ismoban = true;
    },
    updata() {
      let data = {
        groupTemplate: JSON.stringify(this.mobanList.moban),
        id: this.groupList.id,
      };
      updateUserGroup(data).then((res) => {
        console.log(res.obj);
        if (res.success == true) {
          this.$message({
            type: "success",
            message: "保存成功",
          });
          this.ismoban = false;
        } else {
          this.$message({
            type: "error",
            message: `保存失败`,
          });
        }
      });
    },
    updata2() {
      console.log(this.zhaohuiList);
      let data = {
        id: this.groupList.id,
        groupTitleConf:
          this.zhaohuiList.menuList.indexOf("回答错误召回") == -1 ? 0 : 1,
        groupLikeConf:
          this.zhaohuiList.menuList.indexOf("被踩召回") == -1 ? 0 : 1,
        groupRecallTitle: this.zhaohuiList.moban,
      };
      updateUserGroup(data).then((res) => {
        console.log(res.obj);
        if (res.success == true) {
          this.$message({
            type: "success",
            message: "保存成功",
          });
          this.iszhaohui = false;
        } else {
          this.$message({
            type: "error",
            message: `保存失败`,
          });
        }
      });
    },
    handleselect(index, indexPath) {
      console.log(index, indexPath);
      this.page = index;
    },
    getlist() {
      let data = {
        groupId: this.groupList.id,
        page: 1,
        size: 100,
      };
      getGroupUserList(data).then((res) => {
        console.log(res.obj);
        this.tableData = res.obj.records;
      });
    },
    link() {
      console.log(this.groupList);
      let data = {
        groupCode: this.groupList.groupCode,
        groupId: this.groupList.id,
        groupName: this.groupList.groupName,
        type: "APPROVAL_REQUIRED",
      };
      generateGroupLink(data).then((res) => {
        console.log(res.obj);
        this.$copyText(res.obj)
          .then(() => {
            this.$message({
              type: "success",
              message: `复制成功`,
              duration: 1500,
            });
          })
          .catch((err) => {
            console.error("copy", err);
          });
      });
    },
    copy(e) {
      this.$copyText(e)
        .then(() => {
          this.$message({
            type: "success",
            message: `复制成功`,
            duration: 1000,
          });
        })
        .catch((err) => {
          console.error("copy", err);
        });
    },
    newApikey() {
      let data = {
        groupId: this.groupList.id,
      };
      newApiKey(data).then((res) => {
        console.log(res.obj);
        if (res.success == true) {
          this.$message({
            type: "success",
            message: "生成成功",
          });
          this.groupList.secret = res.obj.secret;
          this.groupList.apikey = res.obj.apikey;
          console.log(this.groupList);
          localStorage.setItem("groupCon", JSON.stringify(this.groupList));
        } else {
          this.$message({
            type: "error",
            message: `生成失败`,
          });
        }
      });
    },
    handleEdit(index, row) {
      if (row.userId == this.userinfo.userId) {
        this.$message({
          type: "error",
          message: `不能操作自己`,
        });
      } else {
        this.iscreate = true;
        this.form.id = row.id;
        this.form.i = index;
        this.form.resource = row.userRole;
        console.log(index, row);
      }
    },
    onSubmit() {
      let data = {
        groupId: this.groupList.id,
        id: this.form.id,
        userRole: this.form.resource,
      };
      updateGroupUserRole(data).then((res) => {
        console.log(res.obj);
        if (res.success == true) {
          this.$message({
            type: "success",
            message: "修改成功",
          });
          this.iscreate = false;
          this.tableData[this.form.i].userRole = this.form.resource;
        } else {
          this.$message({
            type: "error",
            message: `修改失败`,
          });
        }
      });
    },
    handleDelete(index, row) {
      if (row.userId == this.userinfo.userId) {
        this.$message({
          type: "error",
          message: `不能移出自己`,
        });
      } else {
        let data = {
          groupId: this.groupList.id,
          id: row.id,
        };
        deleteGroupUserle(data).then((res) => {
          console.log(res.obj);
          if (res.success == true) {
            this.$message({
              type: "success",
              message: "已移出",
            });
            this.iscreate = false;
            this.tableData.splice(this.tableData[this.form.i], 1);
          } else {
            this.$message({
              type: "error",
              message: `移出失败`,
            });
          }
        });
      }
      console.log(index, row);
    },
    edit_group() {
      if (this.edit_name == "") {
        this.$message({
          type: "error",
          message: `请输入要修改的企业名称`,
        });
      } else {
        let data = {
          groupName: this.edit_name,
          id: this.groupList.id,
        };
        updateUserGroup(data).then((res) => {
          console.log(res.obj);
          if (res.success == true) {
            this.$message({
              type: "success",
              message: "修改成功",
            });
            this.isinput = false;
            this.groupList.groupName = this.edit_name;
            // window.location.reload(true);
            this.$emit("update", this.edit_name);
          } else {
            this.$message({
              type: "error",
              message: `修改失败`,
            });
          }
        });
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    beforeAvatarUpload(file) {
      console.log(file.type);
      const isJPG =
        file.type === "text/csv" ||
        file.type === "application/pdf" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        file.type === "text/plain";

      if (!isJPG) {
        this.$message.error("上传文件仅支持pdf,csv,xlsx,txt,docx格式的文件!");
      }
      return isJPG;
    },
    handleAvatarSuccess(res) {
      if (res.success == true) {
        this.fileName = res.obj.fileName;
        this.$message({
          type: "success",
          message: `上传成功正在解析`,
        });
        setTimeout(() => {
          this.$refs.upload.clearFiles();
        }, 1000);
        this.loading = true;
        let data = {
          groupCode: this.groupList.groupCode,
          groupFileList: [res.obj],
          groupId: this.groupList.id,
        };
        uploadGroupKnowledgeFile(data).then((res) => {
          console.log(res.obj);
          if (res.success == true) {
            localStorage.setItem("groupDataType", "WITHIN");
            getGroupChunkList(data).then((res) => {
              console.log(res.obj);
              this.tableData2 = res.obj;
              this.loading = false;
            });
            this.$getLoginInfo();
          } else {
            this.$message({
              type: "error",
              message: `解析出错`,
            });
            this.loading = false;
          }
        });
        console.log(this.imageUrl);
      } else {
        this.$message({
          type: "error",
          message: `上传失败`,
        });
      }
    },
    handleExceed(files, fileList) {
      console.log(fileList);
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件`
      );
    },
    beforeRemove(file, fileList) {
      console.log(fileList);
      return this.$confirm(`确定移除 ${file.name}？`);
    },
  },
};
</script>
<style lang="less" scoped>
.right_con {
  width: 85%;
  margin-left: 16%;
  padding: 20px;
}
.all {
  width: 100%;
  min-height: 700px;
  background: #fff;
  display: flex;
  .link {
    width: 130px;
    height: 40px;
    border-radius: 5px;
    text-align: center;
    line-height: 40px;
    color: #fff;
    background: #00a2ff;
    margin-top: 20px;
  }
}
.api {
  font-size: 18px;
  text-align: center;
  p {
    line-height: 50px;
  }
}
.fileUp {
  width: 100%;
  // display: flex;

  .show_file {
    width: 100%;
    text-align: center;
    padding-bottom: 20px;
    img {
      width: 80px;
      margin: auto;
    }
  }
}
</style>
