<template>
  <div class="zhilingvbox">
    <div class="left">
      <div class="showcontentbox" v-if="showdetail">
        <div class="ccontent">
          <h3>
            {{
              showdetail.squareName ? showdetail.squareName : showdetail.name
            }}
          </h3>
          <div class="userbox">
            <div class="leftbox">
              <img
                :src="
                  showdetail.headimgurl
                    ? showdetail.headimgurl
                    : 'https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/logo/apple/60.png'
                "
                alt=""
              />
              <div class="leftcontentbox">
                <h3>
                  {{
                    showdetail.nickName
                      ? showdetail.nickName
                      : showdetail.userName
                  }}
                </h3>
                <p>
                  {{
                    showdetail.gmtCreate
                      ? showdetail.gmtCreate
                      : showdetail.time
                  }}
                </p>
              </div>
            </div>
            <div class="rightbox">
              <!-- <el-button type="primary">关注</el-button> -->
              <!-- <el-button type="primary" @click="goAct(showdetail)"
                >去使用</el-button
              > -->
            </div>
          </div>
          <div style="margin-top: 20px" v-if="isfrom == 'square'">
            <el-tag
              type="info"
              style="margin-right: 10px"
              v-for="(item, index) in showdetail.sysTagList"
              :key="index"
              >{{ item.tagName }}</el-tag
            >
          </div>
          <div style="margin-top: 20px" v-else>
            <el-tag
              type="info"
              style="margin-right: 10px"
              v-for="(item, index) in showdetail.tags"
              :key="index"
              >{{ item }}</el-tag
            >
          </div>
          <div class="contet">
            {{
              showdetail.squareDesc
                ? showdetail.squareDesc
                : showdetail.description
            }}
          </div>
          <div class="contet">{{ showdetail.template }}</div>
          <div class="chatlist">
            <h2>聊天内容</h2>
            <div class="chatlistlist" v-if="isfrom == 'square'">
              <div v-for="(itm, idx) in showdetail.userChatLogList" :key="idx">
                <div class="answer">
                  <div>
                    <div class="rightbox">
                      {{ itm.question }}
                    </div>
                    <img :src="showdetail.headimgurl" alt="" />
                  </div>
                </div>
                <div class="question">
                  <div>
                    <img
                      src="https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/logo/apple/60.png"
                      alt=""
                    />
                    <div class="rightbox">
                      <pre>{{ itm.answer }}</pre>
                    </div>
                    <!-- <div class="rightbox" v-if="showdetail.type == 'DRAW_DIRECTIVE'">
                      <img class="imgboxshow" v-for="(itemimg, indeximg) in showdetail.imgsrc" :key="indeximg"
                        :src="itemimg.url" alt="">
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="chatlistlist" v-else>
              <div v-for="(itm, idx) in showdetail.userChatLogList" :key="idx">
                <div class="answer">
                  <div>
                    <div class="rightbox">
                      {{ itm.question }}
                    </div>
                    <img :src="showdetail.headimgurl" alt="" />
                  </div>
                </div>
                <div class="question">
                  <div>
                    <img
                      src="https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/logo/apple/60.png"
                      alt=""
                    />
                    <div class="rightbox">
                      <pre>{{ itm.answer }}</pre>
                    </div>
                    <!-- <div class="rightbox" v-if="showdetail.type == 'DRAW_DIRECTIVE'">
                      <img class="imgboxshow" v-for="(itemimg, indeximg) in showdetail.imgsrc" :key="indeximg"
                        :src="itemimg.url" alt="">
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 评论 -->
        <div class="conmmentbox" v-if="isfrom == 'square'">
          <h3>评论（{{ showdetail.commentCount }}）</h3>
          <div class="commentaddbox">
            <img :src="showdetail.headimgurl" alt="" />
            <div class="shurukuang">
              <el-input
                type="textarea"
                placeholder="说两句吧~"
                v-model="textarea"
                maxlength="30"
                show-word-limit
              ></el-input>
            </div>
            <el-button
              class="pinglun"
              @click="pinglun"
              v-if="!ishuifu"
              type="primary"
              >评论</el-button
            >
            <el-button class="pinglun" @click="huifu2" v-else type="primary"
              >回复</el-button
            >
          </div>
          <div class="commentlist">
            <div
              class="commentlistblock"
              v-for="(item, index) in showdetail.squareCommentResponseList"
              :key="index"
            >
              <div class="bigcomment">
                <img :src="showdetail.headimgurl" alt="" />
                <div class="commentlistblockcontent">
                  <div>
                    <span class="title">{{ item.commentNickName }}</span
                    ><span class="time">{{ item.gmtCreate }}</span>
                  </div>
                  <p class="">{{ item.commentText }}</p>
                  <div class="huifu" @click="huifu(item)" type="info">回复</div>
                </div>
              </div>
              <div
                class="reply"
                v-for="(itm, idx) in item.squareCommentReplyResponseList"
                :key="idx"
              >

                <img :src="itm.headimgurl" alt="" />
                <div class="commentlistblockcontent">
                  <div>
                    <span class="title">{{ itm.replyNickName }}</span
                    >回复<span class="title">{{ itm.receiveNickName }}</span
                    ><span class="time">{{ itm.gmtCreate }}</span>
                  </div>
                  <p class="">{{ itm.commentReplyText }}</p>
                  <div class="huifu" type="info" @click="huifu(itm)">回复</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="righttextarea">
        <el-input
          type="textarea"
          class="custom-textarea2"
          @change="changeright"
          :row="8"
          v-model="righttextarea"
          maxlength="1000"
          show-word-limit
        >
        </el-input>
      </div>
      <div class="listexample">
        <div class="rightlist" v-if="catalist.length >= 1">
          <div class="listshow">
            <div v-for="(item, index) in catalist" :key="index">
              <div class="catename">{{ item.catename }}</div>
              <div class="taglist">
                <el-tag
                  v-for="(tag, idx) in item.subcate"
                  @click="select(tag, index)"
                  :effect="actthis.includes(tag) ? 'dark' : 'plain'"
                  :key="idx"
                  closable
                  :disable-transitions="false"
                  @close="handleClose(tag)"
                >
                  {{ tag }}
                </el-tag>
                <el-input
                  style="max-width: 200px; margin-top: 20px"
                  class="input-new-tag"
                  v-if="actthisadd.includes(index)"
                  v-model="inputValue"
                  size="small"
                  @blur="handleInputConfirm(index)"
                >
                </el-input>
                <el-button
                  v-else
                  class="button-new-tag"
                  size="small"
                  @click="showInput(index)"
                  >添加 +</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rightlist">
        <el-select
          v-model="searchChannel1"
          placeholder="选择平台"
          style="width: 300px"
          @change="select11()"
        >
          <el-option
            v-for="(item, index) in searchChannelList"
            :key="index"
            :label="item.channelDesc"
            :value="item.channelNameLevel"
            :disabled="item.disabled"
          >
            <span>{{ item.channelDesc }}</span
            ><span class="small"> {{ item.desc }}</span></el-option
          >
        </el-select>
        <el-select
          style="margin-left: 20px; width: 100px"
          v-model="searchForm1.tempParam"
          placeholder="选择模式"
          @change="select22()"
        >
          <el-option
            v-for="(item, index) in tempParams"
            :key="index"
            :label="item.name"
            :value="item.code"
          ></el-option>
        </el-select>
        <div style="margin-top: 20px">
          <span style="margin-right: 20px; cursor: pointer" @click="qingkong"
            ><i class="el-icon-delete" style="margin-right: 5px"></i
            >清空对话</span
          >
          <span
          v-if="!loading"
            style="margin-right: 20px; cursor: pointer"
            @click="handleSearch"
            ><i class="el-icon-cloudy" style="margin-right: 5px"></i>生成</span
          >
          <span
            v-if="loading"
            style="margin-right: 20px; cursor: pointer"
            @click="handleStop"
            ><i class="el-icon-unlock" style="margin-right: 5px"></i>停止</span
          >
          <el-dropdown style="margin-left: 5px" @command="goChuang">
            <span><i class="el-icon-top">导出</i></span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="1">导出到广场</el-dropdown-item>
              <el-dropdown-item command="2">导出到市场</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="contentboxresult">
        <vue-markdown
          class="markdown-body"
          :anchorAttributes="{ target: '_blank' }"
          :source="result + (loading ? loadingSpan : '')"
        >
        </vue-markdown>
      </div>
      <div id="chatTemplateCopy" style="padding-top: 1rem"></div>
    </div>
    <!-- 能量 不够弹窗 -->
    <el-dialog title="" :visible.sync="dialogTableVisiblebugou" width="400px">
      <div class="titledes">
        您的能量已不足,请去公众号 购买套餐 或者 完成任务 获取能量!
      </div>
      <div class="actbtn">
        <!-- <div class="actblock">去做任务</div>
        <div class="actblock">购买套餐</div> -->
      </div>
    </el-dialog>

    <el-dialog
      title="导出"
      width="600px"
      :visible.sync="changjingdialogTableVisible"
    >
      <div v-loading="loading1">
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="作品名称:">
            <el-input v-model="form1.name"></el-input>
          </el-form-item>
          <el-form-item label="作品描述:">
            <el-input v-model="form1.desc" type="textarea"></el-input>
          </el-form-item>
          <el-form-item label="标签:">
            <el-select
              style="width: 100%"
              v-model="tagvalue"
              multiple
              filterable
              :multiple-limit="3"
              allow-create
              default-first-option
              placeholder="请选择标签"
              @change="tagchange"
            >
              <el-option
                v-for="item in tagList"
                :key="item.id"
                :label="item.tagName"
                :value="item.tagName"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="baocunchangjingsave">立即导出</el-button>
            <el-button @click="changjingdialogTableVisible = false"
              >取消</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import VueMarkdown from "vue-markdown";
import { mapGetters } from "vuex";
import chatgpt from "@/utils/chatgpt";
import {
  getDirectiveSquareInfo,
  saveSquareComment,
  saveCommentReply,
  tickets,
  appTemplateupdateTemplateRecord,
  appTemplateaddTemplate,
  getUserTagList,
  exportDirective,
} from "@/api";
export default {
  name: "chshowdetailat",
  components: {
    VueMarkdown,
  },
  computed: {
    ...mapGetters(["userinfo", "notifyOpen", "clientHeight"]),
    height() {
      let h = this.clientHeight - 60;
      if (this.notifyOpen) {
        h -= 40;
      }
      return h;
    },
  },
  data() {
    return {
      loadingSpan:
        '<span class="typed-cursor" style="margin-left: 0.3rem; color: #fff;">▋</span>',
      searchChannelList: "",
      actthisadd: [],
      actthis: [],
      catalist: [],
      isfrom: "Aiapp",
      dialogTableVisiblebugou: false,
      searchForm1: {
        question: "",
        tempParam: { name: "平衡", code: "balanced", subname: "适合综合科类" },
      },
      searchForm: {
        question: "",
        tempParam: { name: "平衡", code: "balanced", subname: "适合综合科类" },
      },
      tempParams: [
        { name: "精确", code: "precise", subname: "适合理工科类" },
        { name: "创意", code: "creative", subname: "适合文史科类" },
        { name: "平衡", code: "balanced", subname: "适合综合科类" },
      ],
      id: null,
      ishuifu: false,
      text: "",
      inputValue: "",
      reply: null,
      righttextarea: null,
      textarea: "",
      showdetail: null,
      searchChannel: "",
      searchChannelListcurr: null,
      loading: false,
      searchChannel1: "",
      result: "",
      daochudapp: false,
      form: {
        name: "",
      },
      form1: {
        name: "",
        desc: "",
      },
      currcate: "",
      changjingdialogTableVisible: false,
      tagvalue: [],
      tagList: [],
      lefttextarea: "",
      changjingid: "",
      loading1: false,
    };
  },
  activated() {
    console.log('2走这边');

    // if (this.$route.query.param) {
    //   const category = this.$route.query.param
    //     ? JSON.parse(this.$route.query.param)
    //     : "";
    //   console.log("category", category);
    //   this.currcate = category;
    // }
    let newarr = [];
    const ChannelList = JSON.parse(localStorage.getItem("ChannelList"));
    console.log("ChannelList", ChannelList);
    ChannelList.forEach((i) => {
      if (!i.disabled) {
        if (i.type === "chat") {
          newarr.push(i);
        }
      }
    });
    // console.log(newarr)
    this.searchChannelList = newarr;
    this.searchChannelListcurr = newarr[0];
    this.searchChannel = newarr[0];
    this.searchChannel1 = newarr[0].channelDesc + newarr[0].desc;

    // isfrom 如果来源是场景应用 就不显示评论
    if (this.$route.query.param) {
      this.isfrom = this.$route.query.param;
      if (this.$route.query.param != "square") {
        this.showdetail = JSON.parse(localStorage.getItem("Aiappdata"));
        console.log('走这边3',this.showdetail);
        if (JSON.parse(localStorage.getItem("Aiappdata")).record!='') {
          this.showdetail.userChatLogList = [
          JSON.parse(JSON.parse(localStorage.getItem("Aiappdata")).record),
        ];
        }else{
          this.showdetail.userChatLogList =[]
        }

        console.log("this.showdetail", this.showdetail);
        this.currcate = this.showdetail.cid;
        this.lefttextarea = this.showdetail.template;
        this.result=''
        this.$forceUpdate();
        this.goAct();
      } else {
        this.id = this.$route.params.id;
        this.getDirectiveSquareInfo();
      }
    }
    this.$forceUpdate();

  },
  mounted() {

    console.log(this.showdetail);
    this.$forceUpdate();

    this.getSysTagListmethod();
    // console.log('this.$route.params.id', this.$route.params.id)
  },
  methods: {
    appTemplateupdateTemplateRecordmethod() {
      let res = {
        answer: this.result,
        chatChannel: this.searchChannel.channelName,
        question: this.righttextarea,
      };
      let data = {
        id: this.showdetail.sourceId,
        // "isHistory": 1,
        record: JSON.stringify(res),
        // 'sysTags': this.showdetail.sysTagList,
        // "name": this.showdetail.squareName,
        // "description": this.showdetail.squareDesc,
        // "template": this.showdetail.userChatLogList[0].questionTemplate,
      };
      appTemplateupdateTemplateRecord(data).then((res) => {
        console.log(res);
        // this.changjingid = res.obj
        // this.changjingdialogTableVisible = false
      });
    },
    baocunchangjingsave() {
      console.log(this.tagvalue);
      if (this.tagvalue.length!=0&&this.form1.name!=''&&this.form1.desc!='') {
        this.loading1 = true;
      let res = {
        answer: this.result,
        chatChannel: this.searchChannel.channelName,
        question: this.righttextarea,
      };
      let data = {
        cid: this.currcate,
        isHistory: 0,
        record: JSON.stringify(res),
        sysTags: this.sysTagList,
        name: this.form1.name,
        description: this.form1.desc,
        sort: 1,
        template: this.lefttextarea,
      };
      appTemplateaddTemplate(data).then((res) => {
        console.log(res);
        this.changjingid = res.obj;
        this.changjingdialogTableVisible = false;
        this.fabuchangjian();
      });
      }else{
        this.$message({
            type: "error",
            message: "去检查信息是否完善",
            duration:1500
          });
      }

    },
    fabuchangjian() {
      let datas = {
        exportChannel: "SQUARE",
        sourceId: this.changjingid, //保存的场景id
        directiveType: "APP_DIRECTIVE",
      };
      console.log(datas);
      exportDirective(datas).then((res) => {
        if (res.success == true) {
          this.loading1 = false;
          this.sysTagList = "";
          this.form1.name = "";
          this.form1.desc = "";
          this.checkList = [];
          this.$message({
            type: "success",
            message: "导出成功！",
            duration:1500
          });
          this.changjingdialogTableVisible = false;
        } else {
          this.$message({
            type: "error",
            message: "导出失败！",
            duration:1500
          });
        }
      });
    },
    cancle() {},
    changeright() {
      // alert('执行没有')
      // this.righttextarea = ''
      this.catalist = [];
    },
    getSysTagListmethod() {
      let data = {
        tagType: "APP",
      };
      getUserTagList(data).then((res) => {
        this.tagList = res.obj;
      });
    },
    removeSubcate(a, subcate) {
      for (let i = 0; i < a.length; i++) {
        const index = a[i].subcate.indexOf(subcate);
        if (index > -1) {
          a[i].subcate.splice(index, 1);
        }
      }
      return a;
    },
    goChuang(command) {
      if (command == 1) {
        if (this.result != "" && this.loading == false) {
          console.log("去广场");
          this.changjingdialogTableVisible = true;
        } else {
          this.$message({
            type: "error",
            message: "当前无生成内容，请生成或等待生成完毕！",
            duration:1500
          });
        }
      } else {
        this.$message("敬请期待");
      }
    },
    handleClose(tag) {
      console.log(tag);
      console.log(this.catalist);
      this.catalist = this.removeSubcate(this.catalist, tag);
      this.$forceUpdate();
      // this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },
    daochudaochu() {
      this.daochudapp = true;
    },
    select(tag, index) {
      // console.log(tag, index)
      let tagact = this.actthis;
      tagact[index] = tag;
      this.actthis = tagact;
      // console.log('this.actthis',this.actthis)
      this.$forceUpdate();
      let muban = this.showdetail.template;
      this.righttextarea = this.replaceString(muban, tagact);
      // this.righttextarea = this.formatStrings(this.lefttextarea, tagact)
      // console.log('this.formatStrings(this.lefttextarea, this.actthis)',this.formatStrings(this.lefttextarea, this.actthis))
      // const ttt = this.formatStrings(this.lefttextarea,this.actthis)

      // console.log(tagact)
    },

    handleInputConfirm(index) {
      let inputValue = this.inputValue;

      // this.actthisadd.splice(inedx, 1)

      if (inputValue) {
        this.catalist[index].subcate.push(inputValue);
        this.$forceUpdate();
      }
      console.log("actthisadd", this.actthisadd);
      this.actthisadd = [];
      // this.inputVisible = false;
      this.inputValue = "";
    },
    showInput(index) {
      this.actthisadd.push(index);
      this.inputVisible = true;
      // this.$nextTick(() => {
      //     this.$refs.saveTagInput.$refs.input.focus();
      // });
    },
    replaceString(originalString, array) {
      var regex = /@\{([^[\]]+)\[([^[\]]+)\]\}/g;
      var matches = originalString.match(regex);

      if (matches) {
        for (var i = 0; i < matches.length; i++) {
          var match = matches[i];
          // var placeholder = match.substring(2, match.length - 1);
          var replacement = array[i] || "";
          originalString = originalString.replace(match, replacement);
        }
      }

      return originalString;
    },
    tagchange(e) {
      console.log(e);
      const result = e.map((tagName) => {
        let item = this.tagList.find((i) => i.tagName === tagName);
        if (item) {
          return { id: item.id, tagName: item.tagName, tagType: item.tagType };
        } else {
          return { tagName: tagName, tagType: null };
        }
      });

      console.log("result", result);
      // console.log('e', e)
      // sysTagList
      this.sysTagList = result;
    },
    qingkong() {
      this.result = "";
      this.righttextarea = "";
    },
    scrollToBottom() {
      let end = document.getElementById("chatTemplateCopy");
      if (end) {
        end.scrollIntoView();
      }
    },
    handleSearch() {
      if (!localStorage.getItem("token")) {
        this.$message({
          type: "error",
          message: "请先登录",
          duration:1500
        });
        return false;
      }
      if (this.righttextarea === "") {
        this.$message({
          type: "error",
          message: "请输入内容",
          duration:1500
        });
        return false;
      }
      console.log("channelList", this.searchChannelList);
      const needUse = this.searchChannelList.filter(
        (item) => item.channelName === this.searchChannel.channelName
      )[0].energyPoints;
      const udata = this.userinfo;
      let nowNum = udata
        ? udata.userEnergyResponse.userAvailableEnergy * 1
        : "";
      console.log("目前还有" + nowNum);
      console.log("目前还有needUse" + needUse);
      if (nowNum - needUse < 0) {
        // 能量不够
        console.log("能量不够啦");
        this.dialogTableVisiblebugou = true;
        return false;
      }
      let that = this;
      const param = {
        onEvent(param) {
          switch (param.type) {
            case "OPEN":
              break;
            case "UPDATE":
              that.loading = true;
              that.result = param.content;
              that.scrollToBottom();
              break;
            case "DONE": {
              that.loading = false;
              let restttdata = {
                answer: that.result,
                chatChannel: that.searchChannel.channelName,
                question: that.righttextarea,
              };

              let addres = {
                answer: that.result,
                chatChannel: that.searchChannel.channelName,
                question: that.righttextarea,
              };
              let adddata = {
                cid: that.showdetail.cid
                  ? that.showdetail.cid
                  : that.showdetail.categoryId,
                isHistory: 1,
                record: JSON.stringify(addres),
                // 'sysTags': that.showdetail.tags,
                name: that.showdetail.name
                  ? that.showdetail.name
                  : that.showdetail.squareName,
                description: that.showdetail.squareDesc
                  ? that.showdetail.squareDesc
                  : that.showdetail.description,
                template: that.showdetail.template,
              };
              // appTemplateupdateTemplateRecord(restttdata)
              // appTemplateaddTemplate()
              if (that.isfrom == "Aiapp") {
                appTemplateaddTemplate(adddata);
                appTemplateupdateTemplateRecord({
                  id: that.showdetail.sourceId
                    ? that.showdetail.sourceId
                    : that.showdetail.id,
                  record: JSON.stringify(restttdata),
                });
              } else {
                appTemplateaddTemplate(adddata);
              }

              // answer({ chatId: this.chatId, answer: that.result })
              // 保存
              break;
            }
            case "ERROR":
              that.loading = false;
              that.result = "【服务器繁忙，请稍后重试！】";
              break;
          }
        },

        question: this.righttextarea,
        newChat: true,
      };

      that.loading = true;

      tickets({
        channelName: this.searchChannel.channelName,
        question: this.righttextarea,
      })
        .then((res) => {
          if (res.obj.count !== null && res.obj.count <= 0) {
            param.onEvent({
              type: "UPDATE",
              content:
                '【当日免费次数已用完，您可在 "个人中心"  充值会员 获得无限制额度 或 邀请好友(推广1人将新增3次免费额度）】',
            });
            param.onEvent({ type: "DONE" });
            return;
          } else {
            this.$getLoginInfo();

            this.chatId = res.obj.chatId;

            const chatServerUrl ="/api/search/v3";
            const apiKey = res.obj.apiKey;
            const maxTokens = res.obj.maxTokens;
            let params = param;
            params.chatChannelEnum = this.searchChannel.channelName;
            params.chatLevel = this.searchChannelListcurr.chatLevel;
            this.client = chatgpt.chatgpt3(
              apiKey,
              chatServerUrl,
              maxTokens,
              1.2,
              params
            );
          }
        })
        .catch((error) => {
          console.log(error);
          param.onEvent({
            type: "UPDATE",
            content: "【服务器繁忙，请稍后重试！】",
          });
          param.onEvent({ type: "DONE" });
        });
    },
    handleCopy(content) {
      this.$copyText(content)
        .then(() => {
          this.$toast("复制成功");
        })
        .catch((err) => {
          console.error("copy", err);
        });
    },
    handleStop() {
      if (this.client) {
        this.client.close();
      }
      this.loading = false;
    },
    select22() {
      const result = this.tempParams.filter(
        (item) => item.code === this.searchForm1.tempParam
      );
      this.searchForm.tempParam = result;
    },
    select11() {
      const result2 = this.searchChannelList.filter(
        (item) => item.channelNameLevel === this.searchChannel1
      )[0];
      this.searchChannel1 = result2.channelDesc + result2.desc;
      this.searchChannel = result2;
      console.log(this.searchChannel1, this.searchChannel);
    },
    //
    goAct() {
      // console.log(item);
      let item = this.showdetail;
      let muban = item.template
        ? item.template
        : item.userChatLogList[0].questionTemplate;
      // console.log(item)
      const arr = this.parseReport(muban);
      this.catalist = arr;
      this.actthis = arr.map((item) => item.subcate[0]);
      this.righttextarea = this.convertString(muban);
      // this.formatString()
      // console.log(this.actthis)
      // 判断是聊天还是画画 type==DRAW_DIRECTIVE 画画 CHAT_DIRECTIVE 聊天
    },
    parseReport(str) {
      const matches = str.match(/@{([^}]*)}/g); // 找到所有@{...}的匹配项
      const newarr = matches.map((match) => {
        const [catename, subcateStr] = match.slice(2, -1).split("["); // 分离出类别名和选项字符串
        const subcate = subcateStr.slice(0, -1).split(","); // 把选项字符串转为数组
        return { catename, subcate };
      });
      return newarr;
    },
    convertString(str) {
      // 匹配@{...}的正则表达式
      const regex = /@\{([^}]+)\}/g;
      // 将@{...}替换成相应的值
      const newStr = str.replace(regex, (match, p1) => {
        // p1为匹配到的内容，例如"主题[教学,web开发,行政工作,酒店前台]"
        const [type, options] = p1.split("[");
        console.log(type);
        // type为"主题"，options为"教学,web开发,行政工作,酒店前台]"
        const value = options.slice(0, -1).split(",")[0];
        // value为"教学"
        return value;
      });
      // 去掉多余的逗号和空格
      return newStr.replace(/,\s+/g, ", ").replace(", ", "");
    },
    pinglun() {
      if (this.textarea.trim().length < 1) {
        this.$message({
          type: "error",
          message: "内容不能为空！",
          duration:1500
        });
        return false;
      }
      let data = {
        commentText: this.textarea,
        squareId: this.showdetail.id,
      };
      saveSquareComment(data).then((res) => {
        console.log(res);
        if (res.success) {
          this.$message({
            type: "success",
            message: "评论成功！",
            duration:1500
          });
          this.textarea = "";
          this.getDirectiveSquareInfo();
        }
      });
    },
    huifu(item) {
      this.ishuifu = true;
      console.log(item);
      this.reply = item;
    },
    huifu2() {
      if (this.textarea.trim().length < 1) {
        this.$message({
          type: "error",
          message: "内容不能为空！",
          duration:1500
        });
        return false;
      }
      console.log("this.reply", this.reply);
      let data = {
        commentId: this.reply.squareCommentId
          ? this.reply.squareCommentId
          : this.reply.id,
        receiveUserId: this.reply.commentUserId
          ? this.reply.commentUserId
          : this.reply.receiveUserId,
        replyText: this.textarea,
        squareId: this.showdetail.id,
      };
      saveCommentReply(data).then((res) => {
        if (res.success) {
          this.$message({
            type: "success",
            message: "评论成功！",
            duration:1500
          });
          this.textarea = "";
          this.getDirectiveSquareInfo();
        }
      });
    },
    getDirectiveSquareInfo() {
      let data = {
        id: this.id,
      };
      getDirectiveSquareInfo(data).then((res) => {
        const datas = res.obj;
        this.showdetail = datas;
        this.showdetail.template = datas.userChatLogList[0].questionTemplate;
        console.log('0000000',this.showdetail);

        // this.showdetail.tags = Object.values(datas).map((item) => item.tagName);
        console.log('11111111',this.showdetail);
        this.currcate = this.showdetail.categoryId;
        this.lefttextarea = this.showdetail.template;
        this.result=''
        this.$forceUpdate();

        // let arrr = []
        if (datas.type == "DRAW_DIRECTIVE") {
          let imgsrc = JSON.parse(datas.userChatLogList[0].answer);
          this.showdetail.imgsrc = imgsrc;
        }
        console.log(this.showdetail);
        this.goAct();
        // for (let a in datas) {
        //   if (datas[a].type == "DRAW_DIRECTIVE") {
        //     console.log(datas[a])
        //     let imgsrc = JSON.parse(datas[a].userChatLogList[0].answer)[0].url
        //     // console.log(imgsrc)
        //     arrr.push({
        //       ...datas[a],
        //       imgsrc: imgsrc
        //     })

        //   } else {
        //     arrr.push(datas[a])
        //   }
        // }
        // this.showdetail = arrr
      });
    },
  },
};
</script>
<style lang="less" scoped>
.zhilingvbox {
  display: flex;
  width: 1230px;
  margin: 0 auto;
  justify-content: space-between;

  .left {
    width: 637px !important;
  }

  .right {
    width: 573px !important;
    margin-top: 20px;
    padding: 20px;
  }
}

.showcontentbox {
  margin: 20px 0 0;

  .ccontent {
    padding: 60px 30px;
    background-color: #ffffff;

    h3 {
      font-size: 30px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #292929;
      line-height: 52px;
      margin-bottom: 60px;
    }

    .userbox {
      display: flex;
      justify-content: space-between;

      .leftbox {
        display: flex;
        align-items: center;

        h3 {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #2c343e;
          line-height: 16px;
          margin-bottom: 0;
        }

        p {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #969899;
          line-height: 12px;
          margin-top: 8px;
        }

        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin-right: 14px;
        }
      }
    }

    .contet {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      margin-top: 30px;
      line-height: 28px;
    }

    .chatlist {
      margin-top: 100px;

      h2 {
        display: block;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #222222;
        padding: 0 0 24px;
        border-bottom: 1px solid #efefef;
      }
    }

    .chatlistlist {
      // display: flex;
      // flex-wrap: wrap;
      // justify-content: space-between;

      .answer {
        margin: 50px 0 0;
        display: flex;
        justify-content: flex-end;

        > div {
          display: flex;
        }

        img {
          width: 48px;
          height: 48px;
          border-radius: 50%;
        }

        .rightbox {
          min-width: 480px;
          max-width: calc(1200px - 88px - 156px - 156px);
          min-height: 46px;
          background: #f4f4f4;
          position: relative;
          margin-right: 35px;
          padding: 20px;

          pre {
            word-wrap: break-word;
            white-space: break-spaces;
          }

          &::before {
            position: absolute;
            right: -16px;
            top: 50%;
            margin-top: -11px;
            z-index: 99;
            content: "";
            display: inline-block;
            border-top: 14px solid transparent;
            border-bottom: 14px solid transparent;
            border-left: 22px solid #f4f4f4;
          }
        }
      }

      .question {
        margin: 50px 0 0;
        display: flex;
        justify-content: flex-start;

        > div {
          display: flex;
        }

        img {
          width: 48px;
          height: 48px;
          border-radius: 50%;
        }

        .rightbox {
          padding: 20px;
          min-width: 480px;
          max-width: calc(1200px - 88px - 156px - 156px);
          min-height: 46px;
          background: #f4f4f4;
          position: relative;
          margin-left: 35px;

          pre {
            word-wrap: break-word;
            white-space: break-spaces;
          }

          &::before {
            position: absolute;
            left: -16px;
            top: 50%;
            margin-top: -11px;
            z-index: 99;
            content: "";
            display: inline-block;
            border-top: 14px solid transparent;
            border-bottom: 14px solid transparent;
            border-right: 22px solid #f4f4f4;
          }
        }
      }
    }
  }

  .conmmentbox {
    // width: 1200px;
    margin: 20px auto;
    padding: 60px 30px;
    background-color: #ffffff;

    .commentlist {
      .reply {
        display: flex;
        padding-left: 50px;
        margin-top: 15px;

        img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          margin-right: 20px;
        }

        .commentlistblockcontent {
          .huifu {
            width: 44px;
            height: 26px;
            background: #f5f5f5;
            border-radius: 4px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #222222;
            line-height: 12px;
            border: none;
            cursor: pointer;
            outline: none;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 12px;
          }

          p {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #222222;
            line-height: 20px;
            margin: 8px 0;
          }

          > div {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #222222;
            line-height: 14px;

            .title {
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #222222;
              line-height: 14px;
              margin-right: 10px;
            }

            .title:nth-child(2) {
              margin-left: 10px;
            }

            .time {
              font-size: 12px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #bababa;
              line-height: 12px;
            }
          }
        }
      }

      .bigcomment {
        display: flex;

        img {
          width: 35px;
          height: 35px;
          border-radius: 50%;
        }

        .commentlistblockcontent {
          margin-left: 15px;

          .huifu {
            width: 44px;
            height: 26px;
            background: #f5f5f5;
            border-radius: 4px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #222222;
            line-height: 12px;
            border: none;
            cursor: pointer;
            outline: none;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 12px;
          }

          p {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #222222;
            line-height: 20px;
            margin-top: 13px;
          }

          > div {
            display: flex;
            align-items: center;

            .title {
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #222222;
              line-height: 14px;
            }

            .time {
              font-size: 12px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #bababa;
              line-height: 12px;
              margin-left: 10px;
            }
          }
        }
      }
    }

    .commentaddbox {
      margin-top: 40px;
      display: flex;
      flex-wrap: wrap;

      .pinglun {
        align-self: flex-end;
        margin-left: 50px;
        margin-top: 20px;
      }

      img {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        margin-right: 13px;
      }

      .shurukuang {
        width: 528px;
        height: 97px;
        border-radius: 8px;
        opacity: 0.5;

        // border: 1px solid #979797;
        .el-textarea {
          height: 100%;
          display: block;
        }
      }
    }
  }
}

.commentlistblock {
  margin-top: 30px;
}

.imgboxshow {
  display: block;
  max-width: 670px !important;
  min-width: 200px !important;
  height: auto !important;
  margin-top: 20px;
  border-radius: 0 !important;
}

::v-deep .el-textarea__inner {
  min-height: 97px !important;
}

::v-deep .custom-textarea2 .el-textarea__inner {
  min-height: 230px !important;
}

.rightlist {
  background: #fff;
  padding: 20px;
}

.rightlist {
  margin-top: 20px;
  background: #fff;
  padding: 25px;

  .catename {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 14px;
    margin: 10px 0;
  }

  .taglist {
    .el-tag {
      margin-right: 10px;
      cursor: pointer;
    }
  }
}

.contentboxresult {
  background: #fff;
  padding: 20px;
  margin-top: 20px;
}
</style>
