import WebSocketAsPromised from "websocket-as-promised";
import {websocketUtils} from '@/utils/bing/utils'
import {bingCreate} from '@/api'

let bingConversation = null

const buildChatRequest = (conversation, message) => {
    // const styleOption = styleOptionMap[conversation.conversationStyle]
    const styleOption = 'harmonyv3'
    return {
        arguments: [
            {
                source: 'cib',
                optionsSets: [
                    'deepleo',
                    'nlu_direct_response_filter',
                    'disable_emoji_spoken_text',
                    'responsible_ai_policy_235',
                    'enablemm',
                    'dtappid',
                    'rai253',
                    'dv3sugg',
                    styleOption,
                ],
                allowedMessageTypes: ['Chat', 'InternalSearchQuery'],
                isStartOfSession: conversation.invocationId === 0,
                message: {
                    author: 'user',
                    inputMethod: 'Keyboard',
                    text: message,
                    messageType: 'Chat',
                },
                conversationId: conversation.conversationId,
                conversationSignature: conversation.conversationSignature,
                participant: { id: conversation.clientId },
            },
        ],
        invocationId: conversation.invocationId.toString(),
        target: 'chat',
        type: 4,
    }
}

const convertMessageToMarkdown = (message) => {
    if (message.messageType === 'InternalSearchQuery') {
        return message.text
    }
    for (const card of message.adaptiveCards) {
        for (const block of card.body) {
            if (block.type === 'TextBlock') {
                return block.text
            }
        }
    }
    return ''
}

export default {
    async chat(conversationStyle, params) {

        if (bingConversation !== null) {
            if (bingConversation.invocationId >= 10) {
                bingConversation = null
            }
        }

        if (bingConversation === null) {
            const res = await bingCreate()
            if (res.obj === null) {
                params.onEvent({type: 'UPDATE', content: '【服务器繁忙，请稍后重试！】'})
                params.onEvent({type: 'DONE'})
                return
            }
            bingConversation = res.obj
            bingConversation.invocationId = 0
            bingConversation.conversationStyle = conversationStyle
        }

        const bingWs = new WebSocketAsPromised('wss://sydney.bing.com/sydney/ChatHub', {
            packMessage: websocketUtils.packMessage,
            unpackMessage: websocketUtils.unpackMessage,
        })

        bingWs.onUnpackedMessage.addListener((events) => {
            for (const event of events) {
                if (JSON.stringify(event) === '{}') {
                    bingWs.sendPacked({ type: 6 })
                    bingWs.sendPacked(buildChatRequest(bingConversation, params.question))
                    bingConversation.invocationId += 1
                } else if (event.type === 6) {
                    bingWs.sendPacked({ type: 6 })
                } else if (event.type === 3) {
                    console.log('DONE')
                    params.onEvent({type: 'DONE'})
                    bingWs.removeAllListeners()
                    bingWs.close()
                } else if (event.type === 1) {
                    if (event.arguments && event.arguments.length > 0) {
                        if (event.arguments[0].messages && event.arguments[0].messages.length > 0) {
                            params.onEvent({type: 'UPDATE', content: convertMessageToMarkdown(event.arguments[0].messages[0])})
                        }
                    }
                    // const text = convertMessageToMarkdown(event.arguments[0].messages[0])
                    // const text = event.arguments[0].messages[0]
                    // params.onEvent({ type: 'UPDATE_ANSWER', data: { text } })
                } else if (event.type === 2) {
                    console.log(event)
                    if (event.item && event.item.result && event.item.result.error) {
                        params.onEvent({type: 'ERROR'})
                        bingWs.removeAllListeners()
                        bingWs.close()
                        bingConversation = null
                    }
                }
            }
        })

        bingWs.onClose.addListener(() => {
            params.onEvent({ type: 'DONE' })
            console.log('close')
        })

        await bingWs.open()
        bingWs.sendPacked({ protocol: 'json', version: 1 })
        return bingWs
    },
}
