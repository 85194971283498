<template>
  <div class="white-panel" :style="`height: ${height}px; overflow: auto;`">
    <div style="padding: 0rem 1rem;">
      <div v-for="(item, index) in templateTypeList" :key="index">
        <h3 class="chat-template-type-title">{{item.name}}</h3>
        <div class="chat-template-item-panel">
          <div>
            <van-grid :column-num="3">
              <van-grid-item class="chat-template-item" v-for="(tmp, tmpIndex) in item.list" :key="tmpIndex" @click="handleGoTemplate(tmp)">
                <div style="display: flex; flex-direction: column; align-items: center;">
                  <img :src="tmp.headIcon" style="width: 2rem; height: 2rem;">
                  <span style="font-size: 0.8rem;">{{tmp.name}}</span>
                </div>
              </van-grid-item>
            </van-grid>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {templateList} from '@/api'
import {mapGetters} from "vuex";
export default {
  name: 'ChatTemplate',
  computed: {
    ...mapGetters([
      'notifyOpen',
      'clientHeight',
    ]),
    height() {
      let h = this.clientHeight - 50 - 10
      if (this.notifyOpen) {
        h -= 40
      }
      return h
    },
  },
  data() {
    return {
      templateTypeList: []
    }
  },
  mounted() {
    this.getTemplateList()
  },
  methods: {
    getTemplateList() {
      templateList().then(res => {
        this.templateTypeList = res.obj
      })
    },
    handleGoTemplate(tmp) {
      this.$router.push({name: 'templateDetail', query: {id: tmp.id}})
    }
  }
}
</script>
<style lang="less">
.chat-template-type-title {
  padding: 0;
  margin-top: 1rem;
  margin-bottom: 0.1rem;
}
.chat-template-item-panel {
  //padding: 1rem 0.1rem;
  background-color: #f7f8fa;
}
.chat-template-item .van-grid-item__content {
  background-color: #f7f8fa;
}
.chat-template-item .van-grid-item__content::after {
  border: none;
}
.chat-template-item {
  padding: 1.5rem 0;
  border: 0.01rem solid #cccccc;
}
</style>
