<template>
  <div>
    <van-popup v-model="show" @closed="closepop" position="left" :style="{ width:'80%',height: '100vh' }" >
      <van-list
        >
          <van-cell v-for="item in list" :key="item" :title="item" />
        </van-list>
    </van-popup>
  </div>
</template>
<script>
import Vue from 'vue';
import { Popup } from 'vant';
Vue.use(Popup);
import { List } from 'vant';
Vue.use(List);
import ConversationContent from '@/components/ConversationContent.vue'
export default {
  name: 'history',
  components: {
    ConversationContent
  },
  props: {
  },

  data() {
    return {
      show: true,
      list: [1,2,3,4],
      loading: false,
      finished: false,
    }
  },
  methods:{
    closepop(){
      this.show = false
    }
  }
}
</script>
<style lang="less">
.van-popup,.van-cell{
  background: #fff;
}
</style>
