<template>
  <div class="home-box">
    <!-- 顶部 -->
    <div class="hometop">
      <van-icon name="wap-nav" @click='showPopup' size="40" />
      <div class="tabbox">
        <div @click="change(0)" :class="{ 'active': active == 0 }">聊天</div>
        <div  @click="change(1)" :class="{ 'active': active == 1 }">AI绘画</div>
      </div>
      <van-icon v-if="active == 0"  class="rselfright" name="plus" size="20" />
    </div>
    <!-- 引入历史记录绘画列表组件 -->
<HistoryDialog v-if="show" ></HistoryDialog>

    <!-- 右边tab聊天和绘画切换 -->
<chatcon v-if="active == 0 "></chatcon>
<drawcon  v-if="active == 1 "></drawcon>
  </div>
</template>
<script>

import HistoryDialog from '@/components/HistoryDialog.vue'
import chatcon from './chat.vue'
import drawcon from './draw.vue'
export default {
  name: 'home',
  components: {
    chatcon,
    HistoryDialog,
    drawcon
  },
  data() {
    return {
      show: false,
      active: 0,
      
    }
  },
  watch: {
  },
  beforeDestroy() {
    
  },
  mounted() {

  },
  activated() {
    this.scrollToBottom()
  },
  methods: {
    showPopup() {
      this.show = true;
    },
    change(a){
      this.active = a
    },
    scrollToBottom() {
      // let scrollElem = this.$refs.qaContent
      // if (scrollElem) {
      //   scrollElem.scrollTo({
      //     top: scrollElem.scrollHeight,
      //     behavior: 'auto'
      //   })
      // }
      let end = document.getElementById('conversationListEnd')
      if (end) {
        end.scrollIntoView()
      }
    },
  }
}
</script>
<style lang="less">
.hometop {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.tabbox {
  display: flex;
  height: 40px;
}

.tabbox>div {
  margin: 0 1rem;
  line-height: 40px;
  position: relative;
}

.tabbox>div::after {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  display: block;
  height: .25rem;
  background-color: transparent;
}

.tabbox>div.active::after {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  display: block;
  height: .25rem;
  background-color: blue;
}
.rselfright{
  position: absolute;
  right: 1rem;
}
</style>
