<template>
  <div class="createbox">
    <div class="contentcreate">
      <div class="headbread">
        <div @click="$router.push('/app')">
          <span class="el-icon-back"></span>返回场景应用
        </div>
      </div>
      <div class="maincontentcontent">
        <div class="left">
          <div class="lefttop">
            <el-popover placement="top-start" width="800" trigger="hover">
              <video
                style="width: 100%"
                src="https://organ-logo.oss-cn-chengdu.aliyuncs.com/amao/video/aiAppStudy.mp4"
                autoplay
                muted
                controls
              ></video>
              <el-button
                style="margin-bottom: 20px; text-align: left"
                type="text"
                slot="reference"
                >如何正确创造模板</el-button
              >
            </el-popover>
            <el-input
              type="textarea"
              class="custom-textarea2"
              :row="8"
              placeholder="请输入模板：例如：写一篇周报, 主题为@{主题[教学,web开发,行政工作,酒店前台]}一周工作汇报,汇报对象为@{汇报对象[部门经理,部门主管,老板]},所属部门为@{所属部门[市场部,开发部,设计部]},@{字数[200,1000]}字以内''"
              v-model="lefttextarea"
              maxlength="1000"
              show-word-limit
            >
            </el-input>
            <div class="addTxt" @click="shili_show=true" v-if="!shili_show">+选项</div>
            <div style="display: inline-block;" v-if="shili_show">
              <div class="shili_inp" style="display: inline-block;margin-left: 10px;">
                主题<input
                  type="text"
                  v-model="select_1"
                  style="border: 1px #000 solid; width: 80px; margin-left: 10px;height: 25px;padding: 5px;"
                />
              </div>
              <div class="taglist" style="display: inline-block;margin-left: 10px;margin-top: 5px;margin-bottom: 20px;" >
                选项
                <el-tag
                  v-for="(tag, idx) in select_list"
                  @click="select(tag, index)"
                  :effect="actthis.includes(tag) ? 'dark' : 'plain'"
                  :key="idx"
                  closable
                  :disable-transitions="false"
                  @close="handleClose2(tag)"
                  style="margin-left: 5px;"
                >
                  {{ tag }}
                </el-tag>
                <el-input
                  style="max-width: 100px; margin-left: 5px;"
                  class="input-new-tag"
                  v-if="actthisadd.includes(index)"
                  v-model="select_2"
                  size="small"
                  @blur="handleInputConfirm2()"
                >
                </el-input>
                <el-button
                  v-else
                  class="button-new-tag"
                  size="small"
                  @click="showInput(index)"
                  style="margin-left: 5px;"
                  >添加 +</el-button
                >
              </div>
              <img src="../../public/static/img/dui1.png" alt="" style="width: 30px;display: inline-block;margin-left: 30px;vertical-align: middle;margin-top: -8px;" @click="addTxt"/>
            </div>
          </div>

          <div class="leftbottom">
            <span @click="daochudaochu" v-if="!isgroup"
              ><i class="el-icon-top">导出</i></span
            >
            <span @click="baocunchangjing"
              ><i class="el-icon-folder-add">保存到应用</i></span
            >
          </div>
        </div>

        <div class="conten">
          <span @click="viewmethod"><i class="el-icon-view">预览</i></span>
        </div>

        <div class="right">
          <div class="righttop">
            <p>预览文本</p>
            <div class="">
              <el-input
                type="textarea"
                class="custom-textarea"
                :row="8"
                placeholder="请输入内容"
                v-model="righttextarea"
                maxlength="1000"
                show-word-limit
              >
              </el-input>
            </div>
          </div>
          <div class="rightlist" v-if="catalist.length >= 1">
            <div class="listshow">
              <div v-for="(item, index) in catalist" :key="index">
                <div class="catename">{{ item.catename }}</div>
                <div class="taglist">
                  <el-tag
                    v-for="(tag, idx) in item.subcate"
                    @click="select(tag, index)"
                    :effect="actthis.includes(tag) ? 'dark' : 'plain'"
                    :key="idx"
                    closable
                    :disable-transitions="false"
                    @close="handleClose(tag)"
                  >
                    {{ tag }}
                  </el-tag>
                  <el-input
                    style="max-width: 200px; margin-top: 20px"
                    class="input-new-tag"
                    v-if="actthisadd.includes(index)"
                    v-model="inputValue"
                    size="small"
                    @blur="handleInputConfirm(index)"
                  >
                  </el-input>
                  <el-button
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="showInput(index)"
                    >添加 +</el-button
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="rightlist" v-loading="loading2">
            <el-select
              v-model="searchChannel1"
              style="width: 280px"
              placeholder="选择平台"
              @change="select22()"
            >
              <el-option
                v-for="(item, index) in searchChannelList"
                :key="index"
                :label="item.channelName"
                :value="item.channelNameLevel"
                :disabled="item.disabled"
              >
                <span>{{ item.channelDesc }}</span
                ><span class="small"> {{ item.desc }}</span></el-option
              >
            </el-select>
            <el-select
              style="margin-left: 20px; width: 100px"
              v-model="searchForm1.tempParam"
              placeholder="选择模式"
              @change="select11()"
            >
              <el-option
                v-for="(item, index) in tempParams"
                :key="index"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
            <div
            class="change"
            style="
              display: flex;
              align-items: center;
              line-height: 30px;
              margin-left: 5px;
              margin-top: 5px;
              cursor: default;
            "
            v-if="isgroup"

          >
            <el-checkbox label="挂载领域知识模型" name="type" style="transform: scale(1.2);margin-left: 10px;" v-model="checked" @change="changeType"></el-checkbox>
          </div>
            <div style="margin-top: 20px">
              <span
                style="margin-right: 20px; cursor: pointer"
                @click="qingkong"
                ><i class="el-icon-delete" style="margin-right: 5px"></i
                >清空对话</span
              >
              <div
                style="
                  width: 120px;
                  margin: auto;
                  cursor: pointer;
                  background-color: #39c0ff;
                  color: #fff;
                  padding: 10px;
                  text-align: center;
                  border-radius: 5px;
                "
                @click="handleSearch"
                v-if="!loading"
              >
                <i class="el-icon-cloudy" style="margin-right: 5px"></i>测试生成
              </div>
              <div
                v-if="loading"
                style="
                  width: 120px;
                  margin: auto;
                  cursor: pointer;
                  background-color: #39c0ff;
                  color: #fff;
                  padding: 10px;
                  text-align: center;
                  border-radius: 5px;
                "
                @click="handleStop"
              >
                <i class="el-icon-unlock" style="margin-right: 5px"></i>停止
              </div>
            </div>
          </div>

          <div class="contentboxresult" v-if="result">
            <vue-markdown
              class="markdown-body"
              :anchorAttributes="{ target: '_blank' }"
              :source="result + (loading ? loadingSpan : '')"
            >
            </vue-markdown>
          </div>
          <div id="chatTemplateCopy" style="padding-top: 1rem"></div>
        </div>
      </div>
    </div>
    <!-- 能量 不够弹窗 -->
    <el-dialog title="" :visible.sync="dialogTableVisiblebugou" width="400px">
      <div class="titledes">
        您的能量已不足,请去公众号 购买套餐 或者 完成任务 获取能量!
      </div>
      <div class="actbtn">
        <!-- <div class="actblock">去做任务</div>
        <div class="actblock">购买套餐</div> -->
      </div>
    </el-dialog>
    <!-- 保存场景 -->
    <el-dialog
      title="保存场景"
      width="600px"
      :visible.sync="changjingdialogTableVisible"
    >
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="作品名称:">
          <el-input v-model="form1.name" placeholder="(必填)"></el-input>
        </el-form-item>
        <el-form-item label="作品描述:">
          <el-input v-model="form1.desc" type="textarea" placeholder="(非必填)"></el-input>
        </el-form-item>
        <el-form-item label="作品分类:">
          <span>{{ currcate.name }}</span>
        </el-form-item>
        <el-form-item label="标签:">
          <el-select
            style="width: 100%"
            v-model="tagvalue"
            multiple
            filterable
            :multiple-limit="3"
            allow-create
            default-first-option
            placeholder="请选择标签"
            @change="tagchange"
          >
            <el-option
              v-for="item in tagList"
              :key="item.id"
              :label="item.tagName"
              :value="item.tagName"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="一级分类">
                    <el-select style="width:100%" v-model="bigcatevalue" placeholder="请选择一级分类" @change="bigcatechange1">
                        <el-option v-for="item in bigoptions" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="二级分类">
                    <el-select style="width:100%" v-model="subcatevalueid" placeholder="请选择二级分类" @change="subchange1">
                        <el-option v-for="item in suboptions" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="baocunchangjingsave"
            >立即创建</el-button
          >
          <el-button @click="changjingdialogTableVisible = false"
            >取消</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- /导出指令 -->
    <el-dialog
      title="导出指令"
      :visible.sync="daochudapp"
      width="600px"
      direction="rtl"
    >
      <!-- 选择导出到哪儿 -->
      <div class="daochuone" v-if="currdaochu == 1">
        <div class="daochubox">
          <div
            :class="{ daochu: true, daochucurr: exportChannel == 'SQUARE' }"
            @click="nextdaochu('SQUARE')"
          >
            <img src="../assets/dc1.png" alt="" />导出到广场
          </div>
          <div
            :class="{ daochu: true, daochucurr: exportChannel == 'MARKET' }"
            @click="nextdaochu('MARKET')"
          >
            <img src="../assets/dc2.png" alt="" />导出到市场
          </div>
        </div>
        <div class="daochubox" style="margin-top: 20px">
          <el-button type="primary" @click="fabuchangjian">确认发布</el-button>
          <el-button @click="daochudapp = false">取消</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import VueMarkdown from "vue-markdown";
import chatgpt from "@/utils/chatgpt";
import { mapGetters } from "vuex";
import {
  tickets,
  appTemplateaddTemplate,
  getDefaultCategoryList,
  getUserTagList,
  exportDirective,
  getGroupEnergyBalance,
} from "@/api";
export default {
  name: "create",
  components: {
    VueMarkdown,
  },
  computed: {
      ...mapGetters(["userinfo", "channelList"]),

    },
  data() {
    return {
      isgroup: null,
      loading2:false,
      checked:false,
      groupDataType: "GENERAL",
      select_list: [],
      select_1: "",
      select_2: "",
      shili_show:false,
      loadingSpan:
        '<span class="typed-cursor" style="margin-left: 0.3rem; color: #fff;">▋</span>',
      searchChannelList: "",
      searchForm1: {
        question: "",
        tempParam: { name: "平衡", code: "balanced", subname: "适合综合科类" },
      },
      searchForm: {
        question: "",
        tempParam: { name: "平衡", code: "balanced", subname: "适合综合科类" },
      },
      tempParams: [
        { name: "精确", code: "precise", subname: "适合理工科类" },
        { name: "创意", code: "creative", subname: "适合文史科类" },
        { name: "平衡", code: "balanced", subname: "适合综合科类" },
      ],
      lefttextarea:
        "把下面这段@{内容[我是一只小鸟,我的家乡]}, 用@{语气[温柔,高兴]}的语气,@{风格[金庸,鲁迅]}的风格,并且将它改写",
      righttextarea: "",
      dynamicTags: [],
      catalist: [],
      subcatevalue: null,
      subcatevalueid: null,
      subcatevalueid1: null,
      daochudapp: false,
      defaultList: [],
      defaultListmy: [],
      bigcatevalue: null,
      bigcatevalue1: null,
      cateoptions: [],
      tagsoptions: [],
      tagvalue: [],
      tagList: null,
      form: {
        name: "",
      },
      actthisadd: [],
      actthis: [],
      bigoptions: [],
      suboptions: [],
      exportChannel: null,
      canbaocun: false,
      searchChannel: "",
      searchChannelListcurr: null,
      loading: false,
      searchChannel1: "",
      result: "",
      changjingid: null,
      currcate: null,
      changjingdialogTableVisible: false,
      dialogTableVisiblebugou: false,
      inputVisible: false,
      currdaochu: 1,
      form1: {
        name: "",
        desc: "",
      },
      inputValue: "",
    };
  },
  watch: {},
  beforeDestroy() {},
  mounted() {
    console.log(1);
    localStorage.removeItem('groupDataType')
    // location.reload();
  },
  created() {},
  activated() {
    console.log(2);
    this.currcate = null;
    this.isgroup = JSON.parse(localStorage.getItem("groupCon"));
    if (this.$route.query.param) {
      const category = this.$route.query.param
        ? JSON.parse(this.$route.query.param)
        : "";
      console.log("category", category);
      this.currcate = category;
    }
    let newarr = [];
    const ChannelList = JSON.parse(localStorage.getItem("ChannelList"));
    console.log("ChannelList", ChannelList);
    ChannelList.forEach((i) => {
      if (!i.disabled) {
        if (i.type === "chat") {
          newarr.push(i);
        }
      }
    });
    // console.log(newarr)
    this.searchChannelList = newarr;
    this.searchChannelListcurr = newarr[0];
    this.searchChannel = newarr[0];
    this.searchChannel1 = newarr[0].channelDesc + newarr[0].desc;
    this.getDefaultCategorythod();
    this.getSysTagListmethod();
  },
  methods: {
    nextdaochu(dt) {
      this.exportChannel = dt;
      this.$forceUpdate();
      // this.currdaochu += 1
    },
    changeType() {
      this.checked=false
      if (this.groupDataType == "GENERAL") {
        let user=this.userinfo
        for (let i = 0; i < user.userGroupList.length; i++) {
          if (JSON.parse(localStorage.getItem("groupCon")).id==user.userGroupList[i].groupId) {
            this.index=i
          }
        }
        console.log(user.userGroupList[this.index].groupVo.groupFileJson);
        if (user.userGroupList[this.index].groupVo.groupFileJson) {
          this.$emit('shuaxing')
          this.loading2 = true;
          localStorage.setItem("groupDataType", "WITHIN");
          setTimeout(() => {
            this.loading2 = false;
            this.groupDataType = "WITHIN";
          }, 1000);
          this.checked=true
        } else {
          localStorage.removeItem("groupDataType");
          if (user.userGroupList[this.index].userRole=='ORDINARY') {
            this.$dialog
            .confirm({
              title: "提示",
              message: "当前企业暂未上传领域知识库，请联系管理员或专家上传",
            })
            .then(() => {
            })
            .catch(() => {});
          } else {
            this.$dialog
            .confirm({
              title: "提示",
              message: "当前企业暂未上传领域知识库，请前往上传",
            })
            .then(() => {
              this.$router.push({ path: "/my?soat=" + 4 });
            })
            .catch(() => {});
          }

        }
      } else {
        this.$emit('shuaxing')
        this.loading2 = true;
        localStorage.removeItem("groupDataType");
        setTimeout(() => {
          this.groupDataType = "GENERAL";
          this.loading2 = false;
        }, 1000);
        this.checked=false
      }
    },
    fabuchangjian() {
      console.log("currimgdata", this.currimgdata);
      console.log("checkList", this.checkList);
      // console.log('this.form', this.form)
      // console.log('subcatevalue子集id', this.subcatevalueid)
      // console.log('subcatevalue', this.subcatevalue)
      // console.log('sysTagList', this.sysTagList)
      // console.log('this.checkList', this.checkList)
      // if(this.currimgdata==null||this.checkList.length<1||this.exportChannel==null||this.subcatevalueid==null||this.subcatevalue==null|| this.form.name==''||this.form.desc==''){
      //   this.$message({
      //       type: 'error',
      //       message: '请检查数据是否完整'
      //     });
      //     return false
      // }
      //   if(this.exportChannel==null||this.form.name==''||this.form.desc==''||this.sysTagList==''){
      //     this.$message({
      //         type: 'error',
      //         message: '请检查数据是否完整'
      //       });
      //       return false
      //   }
      // let result = [{
      //     "question": this.righttextarea,
      //     "id": this.changjingid,
      //     "chatChannel": this.searchChannel.channelName,
      //     "answer": JSON.stringify(this.result),
      //     "questionTemplate": this.lefttextarea
      // }];
      let datas = {
        exportChannel: this.exportChannel,
        // 'categoryId': this.subcatevalueid1,
        // 'categoryName': this.subcatevalue1,
        // 'sysTagList': this.sysTagList,
        sourceId: this.changjingid, //保存的场景id
        // "name": this.form1.name,
        // "desc": this.form1.desc,
        directiveType: "APP_DIRECTIVE",
        // "chatLogList": result
      };
      console.log(datas);
      exportDirective(datas).then((res) => {
        if (res.success == true) {
          this.exportChannel = "";
          this.subcatevalueid1 = "";
          this.subcatevalue1 = "";
          this.sysTagList = "";
          this.form1.name = "";
          this.form1.desc = "";
          this.checkList = [];
          this.$message({
            type: "success",
            message: "导出成功！",
            duration: 1500,
          });
          this.daochudapp = false;
          this.currdaochu = 1;
        }
      });
    },
    getSysTagListmethod() {
      let data = {
        tagType: "APP",
        groupId: this.isgroup ? this.isgroup.id : null,
      };
      getUserTagList(data).then((res) => {
        this.tagList = res.obj;
      });
    },
    addTxt() {
      if (this.select_1&&this.select_list.length!=0) {
        this.shili_show=false
      console.log(this.select_1);
      console.log(this.select_list);
      let txt=''
      for (let i = 0; i < this.select_list.length; i++) {
        txt=txt+this.select_list[i]+','
      }
      txt=txt.slice(0, -1)
      this.lefttextarea = this.lefttextarea + `@{${this.select_1}[${txt}]}`;
      this.select_1=''
      this.select_2=''
      this.select_list=[]
      }else{
        this.$message({
          type: "error",
          message: "请输入主题，且至少创建一个可选项",
          duration: 1500,
        });
      }

    },
    daochudaochu() {
      console.log(this.changjingid);
      if (!this.changjingid) {
        this.$message({
          type: "error",
          message: "你还没有保存到场景，不能导出",
          duration: 1500,
        });
        return false;
      }
      this.daochudapp = true;
    },
    bigcatechange(e) {
      // console.log(e)
      // this.cateid = e
      // this.subcatevalue = null
      // console.log(this.bigoptions)
      console.log(this.bigoptions.filter((item) => item.id == e));
      this.suboptions = this.bigoptions.filter(
        (item) => item.id == e
      )[0].subcata;
    },
    bigcatechange1(e) {
      // console.log(e)
      // this.cateid = e
      // this.subcatevalue = null
      // console.log(this.bigoptions)
      console.log(this.bigoptions.filter((item) => item.id == e));
      this.suboptions = this.bigoptions.filter(
        (item) => item.id == e
      )[0].subcata;
    },
    subchange(e) {
      const result = this.suboptions.filter((item) => item.id === e);
      console.log("result", result);

      this.subcatevalue = result[0].name;
    },
    subchange1(e) {
      const result = this.suboptions.filter((item) => item.id === e);
      console.log("result", result);

      // this.subcatevalue1 = result[0].name
    },
    // 获取分类快捷入口
    // 获取分类
    getDefaultCategorythod() {
      let data = {
        groupId: this.isgroup ? this.isgroup.id : null,
      };
      getDefaultCategoryList(data).then((res) => {
        // let result = res.obj.filter(item => item.pid === 0);
        // console.log(result)
        const arr = res.obj;
        let result = [];

        for (let i = 0; i < arr.length; i++) {
          if (arr[i].pid === 0) {
            let obj = {
              name: arr[i].name,
              pid: arr[i].pid,
              id: arr[i].id,
              count: arr[i].count,
              isEnterpriseShow: arr[i].isEnterpriseShow,
              sort: arr[i].sort,
              userId: arr[i].userId,
              subcata: [],
            };
            result.push(obj);
          }
        }

        for (let i = 0; i < result.length; i++) {
          for (let j = 0; j < arr.length; j++) {
            if (arr[j].pid === result[i].id) {
              let obj = {
                name: arr[j].name,
                pid: arr[j].pid,
                id: arr[j].id,
                count: arr[j].count,
                isEnterpriseShow: arr[j].isEnterpriseShow,
                sort: arr[j].sort,
                userId: arr[j].userId,
              };
              result[i].subcata.push(obj);
            }
          }
        }
        this.defaultList = result;
        let resultbig = result.filter((item) => item.pid === 0);
        this.bigoptions = resultbig;
        this.suboptions = resultbig[0].subcata;

        this.bigsort = resultbig[resultbig.length - 1].sort;
        // console.log(result)
        // console.log(resultsub)/]
      });
    },
    baocunchangjing() {
      // 保存场景
      console.log(this.canbaocun);
      if (this.changjingid) {
        this.$message({
          type: "error",
          message: "不能重复",
        });
        return false;
      }
      if (!this.canbaocun) {
        this.$message({
          type: "error",
          message: "生成内容还未结束，请稍后再试",
          duration: 1500,
        });
        return false;
      }
      this.changjingdialogTableVisible = true;
    },

    baocunchangjingsave() {
      let res = {
        answer: this.result,
        chatChannel: this.searchChannel.channelName,
        question: this.righttextarea,
      };
      let data = {
        cid: this.currcate.id,
        isHistory: 0,
        record: JSON.stringify(res),
        sysTags: this.sysTagList,
        name: this.form1.name,
        description: this.form1.desc,
        sort: 1,
        template: this.lefttextarea,
        groupId: this.isgroup ? this.isgroup.id : null,
      };
      appTemplateaddTemplate(data).then((res) => {
        console.log(res);
        this.changjingid = res.obj;
        this.changjingdialogTableVisible = false;
        if (res.success == true) {
          this.$message({
            type: "success",
            message: "保存成功",
          });
        } else {
          this.$message({
            type: "error",
            message: `保存失败`,
          });
        }
      });
    },
    tagchange(e) {
      console.log(e);
      const result = e.map((tagName) => {
        let item = this.tagList.find((i) => i.tagName === tagName);
        if (item) {
          return { id: item.id, tagName: item.tagName, tagType: item.tagType };
        } else {
          return { tagName: tagName, tagType: null };
        }
      });

      console.log("result", result);
      // console.log('e', e)
      // sysTagList
      this.sysTagList = result;
    },
    // 添加  生成内容之后才可以点保存到场景 保存到场景之后才能点导出

    appTemplateaddTemplatemethod(data) {
      //  let datas = {
      //  }

      appTemplateaddTemplate(data).then((res) => {
        console.log(res);
        this.changjingid = res.obj;
        this.changjingdialogTableVisible = false;
      });
    },
    qingkong() {
      this.result = "";
      this.righttextarea = "";
    },
    scrollToBottom() {
      let end = document.getElementById("chatTemplateCopy");
      if (end) {
        end.scrollIntoView();
      }
    },
    cancle() {},
    handleSearch() {
      this.result=''
      if (!localStorage.getItem("token")) {
        this.$message({
          type: "error",
          message: "请先登录",
          duration: 1500,
        });
        return false;
      }
      if (this.righttextarea === "") {
        this.$message({
          type: "error",
          message: "请输入内容",
          duration: 1500,
        });
        return false;
      }
      console.log("channelList", this.searchChannelList);
      const needUse = this.searchChannelList.filter(
        (item) => item.channelName === this.searchChannel.channelName
      )[0].energyPoints;
      console.log(JSON.parse(localStorage.getItem("groupCon")));
      const isgroup = JSON.parse(localStorage.getItem("groupCon"));
      var nowNum = null;
      if (isgroup) {
        const udata = this.userinfo;
        let data = { groupId: udata.id, page: 1, size: 100 };
        getGroupEnergyBalance(data).then((res) => {
          console.log(res);
          nowNum = res.obj.currentEnergy;
          console.log("目前还有" + nowNum);
          console.log("目前还有needUse" + needUse);
          if (nowNum - needUse < 0) {
            // 能量不够
            console.log("能量不够啦");
            this.dialogTableVisiblebugou = true;
            this.$refs.childRef.isStop();
            return false;
          }
        });
      } else {
        const udata = this.userinfo;
        nowNum = udata ? udata.userEnergyResponse.userAvailableEnergy * 1 : "";
        console.log("目前还有" + nowNum);
        console.log("目前还有needUse" + needUse);
        if (nowNum - needUse < 0) {
          // 能量不够
          console.log("能量不够啦");
          this.dialogTableVisiblebugou = true;
          this.$refs.childRef.isStop();
          return false;
        }
      }
      let that = this;
      const param = {
        onEvent(param) {
          switch (param.type) {
            case "OPEN":
              break;
            case "UPDATE":
              that.loading = true;
              that.result = param.content;
              that.scrollToBottom();
              break;
            case "DONE": {
              that.loading = false;
              that.canbaocun = true;

              // answer({ chatId: this.chatId, answer: that.result })
              // 保存
              break;
            }
            case "ERROR":
              that.loading = false;
              that.result = "【服务器繁忙，请稍后重试！】";
              break;
          }
        },

        question: this.righttextarea,
        newChat: true,
      };

      that.loading = true;

      tickets({
        channelName: this.searchChannel.channelName,
        question: this.righttextarea,
      })
        .then((res) => {
          if (res.obj.count !== null && res.obj.count <= 0) {
            param.onEvent({
              type: "UPDATE",
              content:
                '【当日免费次数已用完，您可在 "个人中心"  充值会员 获得无限制额度 或 邀请好友(推广1人将新增3次免费额度）】',
            });
            param.onEvent({ type: "DONE" });
            return;
          } else {
            this.$getLoginInfo();

            this.chatId = res.obj.chatId;

            const chatServerUrl ="/api/search/v3";
            const apiKey = res.obj.apiKey;
            const maxTokens = res.obj.maxTokens;
            let params = param;
            params.chatChannelEnum = this.searchChannelListcurr.channelName;
            params.chatLevel = this.searchChannelListcurr.chatLevel;
            localStorage.removeItem('msgList')
            this.client = chatgpt.chatgpt3(
              apiKey,
              chatServerUrl,
              maxTokens,
              1.2,
              params
            );
          }
        })
        .catch((error) => {
          console.log(error);
          param.onEvent({
            type: "UPDATE",
            content: "【服务器繁忙，请稍后重试！】",
          });
          param.onEvent({ type: "DONE" });
        });
    },
    handleCopy(content) {
      this.$copyText(content)
        .then(() => {
          this.$toast("复制成功");
        })
        .catch((err) => {
          console.error("copy", err);
        });
    },
    handleStop() {
      if (this.client) {
        this.client.close();
      }
      this.loading = false;
    },
    select22() {
      const result = this.tempParams.filter(
        (item) => item.code === this.searchForm1.tempParam
      );
      this.searchForm.tempParam = result;
      console.log(this.searchForm.tempParam);
    },
    select11() {
      const result2 = this.searchChannelList.filter(
        (item) => item.channelNameLevel === this.searchChannel1
      )[0];
      console.log(this.searchChannel1);
      this.searchChannel1 = result2.channelDesc + result2.desc;
      this.searchChannel = result2;
    },
    select(tag, index) {
      // console.log(tag, index)
      let tagact = this.actthis;
      tagact[index] = tag;
      this.actthis = tagact;
      // console.log('this.actthis',this.actthis)
      this.$forceUpdate();

      this.righttextarea = this.replaceString(this.lefttextarea, tagact);
      // this.righttextarea = this.formatStrings(this.lefttextarea, tagact)
      // console.log('this.formatStrings(this.lefttextarea, this.actthis)',this.formatStrings(this.lefttextarea, this.actthis))
      // const ttt = this.formatStrings(this.lefttextarea,this.actthis)

      // console.log(tagact)
    },
    replaceString(originalString, array) {
      var regex = /@\{([^[\]]+)\[([^[\]]+)\]\}/g;
      var matches = originalString.match(regex);

      if (matches) {
        for (var i = 0; i < matches.length; i++) {
          var match = matches[i];
          // var placeholder = match.substring(2, match.length - 1);
          var replacement = array[i] || "";
          originalString = originalString.replace(match, replacement);
        }
      }

      return originalString;
    },
    formatStrings(inputString, inputArray) {
      console.log("inputString", inputString);
      console.log("inputArray", inputArray);
      const pattern = /@\{(.+?)\[(.+?)\]\}/g;
      const outputString = inputString.replace(pattern, (_, key, options) => {
        const choices = options.split(",");
        const index = inputArray.findIndex((value) => choices.includes(value));
        if (index == -1) {
          return inputArray[index];
        } else {
          return inputArray[index];
        }
      });
      console.log(outputString);
      return outputString;
    },
    viewmethod() {
      const matchesstr = this.lefttextarea.match(/@{([^}]*)}/g);
      if (matchesstr == null) {
        this.$message({
          type: "error",
          message: "输入模板格式不正确，请参考正确格式",
          duration: 1500,
        });
        return false;
      }
      console.log(matchesstr);

      const arr = this.parseReport(this.lefttextarea);
      this.catalist = arr;
      this.actthis = arr.map((item) => item.subcate[0]);
      this.righttextarea = this.convertString(this.lefttextarea);
      // this.formatString()
      console.log(this.actthis);
    },
    parseReport(str) {
      const matches = str.match(/@{([^}]*)}/g); // 找到所有@{...}的匹配项
      const newarr = matches.map((match) => {
        const [catename, subcateStr] = match.slice(2, -1).split("["); // 分离出类别名和选项字符串
        const subcate = subcateStr.slice(0, -1).split(","); // 把选项字符串转为数组
        return { catename, subcate };
      });
      return newarr;
    },
    formatString(str, values) {
      return str.replace(
        /@{(\w+)\[(\w+(?:,\w+)*)\]}/g,
        function (match, key, options) {
          var optionsArr = options.split(",");
          var index = Math.floor(Math.random() * optionsArr.length);
          return values[key][optionsArr[index]];
        }
      );
    },
    convertString(str) {
      // 匹配@{...}的正则表达式
      const regex = /@\{([^}]+)\}/g;
      // 将@{...}替换成相应的值
      const newStr = str.replace(regex, (match, p1) => {
        // p1为匹配到的内容，例如"主题[教学,web开发,行政工作,酒店前台]"
        const [type, options] = p1.split("[");
        console.log(type);
        // type为"主题"，options为"教学,web开发,行政工作,酒店前台]"
        const value = options.slice(0, -1).split(",")[0];
        // value为"教学"
        return value;
      });
      // 去掉多余的逗号和空格
      return newStr.replace(/,\s+/g, ", ").replace(", ", "");
    },
    removeSubcate(a, subcate) {
      for (let i = 0; i < a.length; i++) {
        const index = a[i].subcate.indexOf(subcate);
        if (index > -1) {
          a[i].subcate.splice(index, 1);
        }
      }
      return a;
    },
    removeSubcate2(a, subcate) {
      for (let i = 0; i < a.length; i++) {
        if (a[i]==subcate) {
          a.splice(i, 1);
        }
      }
      return a;
    },
    handleClose(tag) {
      console.log(tag);
      console.log(this.catalist);
      this.catalist = this.removeSubcate(this.catalist, tag);
      this.$forceUpdate();
      // this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },
    handleClose2(tag) {
      console.log(tag);
      console.log(this.select_list);
      this.select_list = this.removeSubcate2(this.select_list, tag);
      this.$forceUpdate();
      // this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },

    showInput(index) {
      this.actthisadd.push(index);
      this.inputVisible = true;
      // this.$nextTick(() => {
      //     this.$refs.saveTagInput.$refs.input.focus();
      // });
    },

    handleInputConfirm(index) {
      let inputValue = this.inputValue;

      // this.actthisadd.splice(inedx, 1)

      if (inputValue) {
        this.catalist[index].subcate.push(inputValue);
        this.$forceUpdate();
      }
      console.log("actthisadd", this.actthisadd);
      this.actthisadd = [];
      // this.inputVisible = false;
      this.inputValue = "";
    },
    handleInputConfirm2() {
      let inputValue = this.select_2;

      // this.actthisadd.splice(inedx, 1)

      if (inputValue) {
        this.select_list.push(inputValue);
        this.$forceUpdate();
      }
      console.log("actthisadd", this.actthisadd);
      // this.inputVisible = false;
      this.select_2 = "";
      this.actthisadd = [];
    },
  },
};
</script>
<style lang="less" scoped>
.addTxt {
  width: 60px;
  height: 30px;
  display: inline-block;
  line-height: 30px;
  text-align: center;
  border: 1px solid #c5c5c5;
  margin-top: 10px;
  cursor: pointer;
}
.createbox {
  .contentcreate {
    width: 1220px;
    margin: 0 auto;

    .headbread {
      cursor: pointer;
      height: 56px;
      line-height: 56px;
      width: 100%;

      > div {
        display: block;
      }
    }

    .maincontentcontent {
      display: flex;
      justify-content: space-between;

      .left {
        background: #fff;
        height: 610px;
        width: 612px;
        padding: 40px;

        .lefttop {
          min-height: 462px;
          // overflow: auto;
          border-bottom: 1px dashed #ddd;
          padding-bottom: 10px;
        }

        .leftbottom {
          display: flex;
          justify-content: flex-start;

          span {
            margin-right: 20px;
            margin-top: 25px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 14px;
            cursor: pointer;
          }
        }
      }

      .right {
        min-height: 850px;
        width: 595px;

        p {
          margin-bottom: 15px;
        }

        .righttop {
          background: #fff;

          padding: 25px;

          .el-textarea {
            // min-height: 250px;
          }
        }

        .contentboxresult {
          min-height: 200px;
        }

        .rightlist {
          margin-top: 20px;
          background: #fff;
          padding: 25px;

          .catename {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #000000;
            line-height: 14px;
            margin: 10px 0;
          }

          .taglist {
            .el-tag {
              margin-right: 10px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.contentboxresult {
  background: #ffffff;
  padding: 25px;
  margin-top: 20px;
}

.daochubox {
  display: flex;
  align-items: center;
  justify-content: center;

  .daochucurr {
    border: 1px solid #409eff !important;
  }

  .daochu {
    border: 1px solid #ddd;
    padding: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    cursor: pointer;

    img {
      margin-right: 20px;
      display: block;
    }
  }
}

.daochuliaotianlist {
  max-height: calc(50vh);
  height: calc(50vh);
  border-bottom: 1px solid #ddd;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px;
  max-width: 100%;

  .el-checkbox-group {
    .el-checkbox {
      display: flex;
      align-items: center;
      margin-top: 30px;

      .el-checkbox__label {
        .daochublock {
          display: flex;
          align-items: center;

          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 20px;
          }
        }
      }
    }
  }
}

.settingmsg {
  padding: 20px;

  .selectbox {
    display: flex;

    .blockselect {
      margin-right: 20px;
    }
  }
}

.el-dialog {
  border-radius: 12px !important;
}
</style>
<style scoped>
::v-deep .el-textarea__inner {
  min-height: 250px !important;
}

::v-deep .custom-textarea2 .el-textarea__inner {
  min-height: 350px !important;
  margin-bottom: 10px;
}

::v-deep .el-dialog {
  border-radius: 12px !important;
}

.conten {
  width: 80px;
  margin: 20px;
  background: #42b3ff;
  height: 40px;
  color: #fff;
  line-height: 40px;
  text-align: center;
  border-radius: 10px;
  font-size: 18px;
  margin-top: 300px;
}
</style>
